import React from "react";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import { TC, T, TB } from "../../../Constants";

export type FormProps = {
    /** A string version of the value */
    str_value?: string;
    /** The real value saved */
    value?: any;
    /** The _id of the item that will have a value certified */
    item_id: string;
    /** The prop of the item that will be certified */
    prop: string;
    /** Display the form in a modal ? */
    popup?: boolean;
    /** Extra style properties for the modal */
    modal?: M.StyleModalProps;
    /** The form id of the item */
    form: string;
    /** Callback for form cancellation */
    quit?: () => void;
    /** Callback for submission */
    submit?: (certification: T.Certification) => void;
}

const Form: React.FC<FormProps> = ({ submit, ...props }) => {
    const lg = H.useLanguage();
    const [{ userId }] = H.useAuth();
    const [errors, set_errors] = React.useState<T.Errors<T.Certification>>({});
    const [certification, set_certification] = React.useState<T.Certification>({
        _id: "",
        owner: "",
        files: [],
        data_date: "",
        description: "",
        prop: props.prop,
        item: props.item_id,
        value: TB.valueToString(props.value),
    });

    React.useEffect(() => {
        if (userId) set_certification(p => ({ ...p, owner: userId }));
    }, [userId]);

    const on_submit = React.useCallback(() => {
        let new_errors: typeof errors = {};
        let data_data = TB.getDate(certification.data_date);
        if (!data_data) new_errors.data_date = TC.GLOBAL_REQUIRED_FIELD;

        if (Object.keys(new_errors).length > 0) set_errors(new_errors);
        else if (submit) submit(certification);
    }, [submit, certification]);

    const footer = React.useMemo(() => <C.Flex className="w-100" justifyContent="end" alignItems="center">
        <C.Button text={TC.GLOBAL_SAVE} icon="save" onClick={on_submit} />
    </C.Flex>, [on_submit]);

    return React.createElement(
        props.popup ? M.BlankModal : React.Fragment,
        props.popup ? {
            ...props.modal,
            footer,
            onQuit: props.quit,
            size: props.modal?.size || "sm",
            title: props.modal?.title || lg.getStaticText(TC.CERT_FORM_CERTIFY_FOR, lg.getTextObj(props.form, props.prop, props.prop)),
        } as M.BlankModalProps : null,
        <>
            <C.Form.ComponentWrapper
                labelPosition="left"
                label={TC.CERT_FORM_VALUE}
                children={<C.Flex className="w-100" justifyContent="start" alignItems="center">
                    {TB.valueToString(props.str_value) || <span className="fst-italic text-muted">{lg.getStaticText(TC.CERTIF_EMPTY_VALUE)}</span>}
                </C.Flex>}
            />

            <C.Form.TextField
                textArea
                rows={3}
                autoExpand
                labelPosition="left"
                error={errors.description}
                label={TC.CERT_FORM_DESCRIPTION}
                value={certification.description}
                onChange={description => set_certification(p => ({ ...p, description }))}
            />
            <C.Form.DateTime
                labelPosition="left"
                error={errors.data_date}
                label={TC.CERT_FORM_DATE}
                value={certification.data_date}
                onChange={date => set_certification(p => ({ ...p, data_date: date }))}
            />
            <C.Form.FileUploader
                multiple
                labelPosition="left"
                error={errors.files}
                label={TC.CERT_FORM_FILE}
                value={certification.files}
                onChange={files => set_certification(p => ({ ...p, files }))}
            />

            {!props.popup && footer}
        </>
    );
}

export default Form;