import React from "react";
import Formula from "./Formula";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import { DS, FP, RESOURCE, RIGHTS, T, TB, TC } from "../../../Constants";

//#region Types
type UnitInfos = {
    /** The type of input */
    type: "text" | "select";
    /** Should the input be disabled */
    disabled: boolean;
    /** If type === "select", the options available */
    options?: T.Option[];
}

export type DataFormProps = {
    /** Default values to pre-populate the field on creation of a new dataset */
    default_values?: Partial<T.DataSet>;
    /** The default dataset */
    dataset?: T.DataSet;
    /** Show the form in a popup */
    popup?: boolean;
    /** The origin of the dataset */
    origin: string;
    /** Props for the modal */
    modalProps?: M.StyleModalProps;
    /** Save callback */
    onSave?: (dataset: T.DataSet) => void;
    /** Only read the dataset, no edit */
    readOnly?: boolean;
}

type DataSetError = Partial<
    /** Regular field errors */
    Record<"name" | "unit" | "type" | "cumulated" | "global" | "src" | "aggregate" | "flow", string>
    /** Formula error list */
    & { formulas: Record<"formula" | "date", string>[] }
    /** Tag id */
    & { tag: Record<"id" | "station", string> }
    /** Flow */
    & { flow: Record<"in" | "out", string> }
>
//#endregion

//#region Constants
const Options = DS.DATASETS;

const TEXT_CODES = [
    TC.DATASET_FORMULAS, TC.DATASET_START_DATE, TC.DATASET_ADD_FORMULA, TC.GLOBAL_SAVE, TC.DATASET_AGGREGATION,
    TC.DATASET_CUMULATED_TOOLTIP, TC.DATASET_NON_CUMULATED_TOOLTIP,
];

const getDataSet = (origin = "", dataset?: T.DataSet, default_value?: Partial<T.DataSet>): T.DataSet => {
    if (dataset && TB.mongoIdValidator(dataset?._id)) return dataset;
    return { _id: "", origin, name: "", unit: "kWh", tags: [], type: "ELEC", normalized: "false", src: "manual", cumulated: false, global: false, aggregate: "sum", ...default_value };
}
//#endregion

const DataForm: React.FC<DataFormProps> = ({ onSave, ...props }) => {
    const rights = H.useRights();
    const [{ userId }] = H.useAuth();
    const lg = H.useLanguage(TEXT_CODES);
    const isSaving = H.useBoolean(false);
    const [error, setError] = React.useState<DataSetError>({});
    const [tags, setTags, tagsStatus] = H.useAsyncState<T.Option[]>([]);
    const [isBuilding, setIsBuilding, buildingStatus] = H.useAsyncState(false);
    const [stations, setStations, stationStatus] = H.useAsyncState<T.Option[]>([]);
    const [note_tags, set_note_tags, note_tags_status] = H.useAsyncState<T.Option[]>([]);
    const [dataset, setDataset] = React.useState(getDataSet(props.origin, props.dataset, props.default_values));

    //#region Check Is Building
    React.useEffect(() => {
        let isSubscribed = true;
        S.getSubmissionType(props.origin)
            .then(({ data }) => isSubscribed && setIsBuilding(data === FP.BUILDING_FORM, "done"))
            .catch(() => isSubscribed && setIsBuilding(false, "error"));
        return () => { isSubscribed = false };
    }, [setIsBuilding, props.origin]);
    //#endregion

    //#region Access
    const allowedEdit = React.useMemo(() => rights.isRightAllowed(RIGHTS.NRJ.WRITE_NRJ_TAGS, props.origin), [rights, props.origin]);
    const readOnly = React.useMemo(() => props.readOnly || !allowedEdit, [allowedEdit, props.readOnly]);
    //#endregion

    //#region Fetch Stations & Tags
    React.useEffect(() => {
        let isSubscribed = true;
        if (TB.mongoIdValidator(props.origin)) S.getStations({ roots: props.origin })
            .then(({ data }) => isSubscribed && setStations(data, "done"))
            .catch(() => isSubscribed && setStations([], "error"));
        else setStations([], "done");

        return () => { isSubscribed = false };
    }, [props.origin, setStations]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (dataset.tag?.station) S.getTags(dataset.tag.station.toString())
            .then(({ data }) => isSubscribed && setTags(data, 'done'))
            .catch(() => isSubscribed && setTags([], "error"));
        else setTags([], "done");
        return () => { isSubscribed = false };
    }, [dataset.tag?.station, setTags]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (dataset.origin) S.getNoteTags({ context: { roots: dataset.origin }, user: userId, type: "dataset" })
            .then(({ data }) => isSubscribed && set_note_tags(data, 'done'))
            .catch(() => isSubscribed && set_note_tags([], "error"));
        else set_note_tags([], "done");
        return () => {
            isSubscribed = false;
            set_note_tags([], "load");
        };
    }, [userId, dataset.origin, set_note_tags]);
    //#endregion

    //#region Reset Errors
    React.useEffect(() => {
        if (error.name && dataset.name) setError(p => ({ ...p, name: undefined }));
    }, [error.name, dataset.name]);

    React.useEffect(() => {
        if (error.type && dataset.type) setError(p => ({ ...p, type: undefined }));
    }, [error.type, dataset.type]);

    React.useEffect(() => {
        if (error.unit && dataset.unit) setError(p => ({ ...p, unit: undefined }));
    }, [error.unit, dataset.unit]);

    React.useEffect(() => {
        if (error.src && dataset.src) setError(p => ({ ...p, src: undefined }));
    }, [error.src, dataset.src]);

    React.useEffect(() => {
        if (error.cumulated && typeof dataset.cumulated === "boolean") setError(p => ({ ...p, cumulated: undefined }));
    }, [error.cumulated, dataset.cumulated]);

    React.useEffect(() => {
        if (error.aggregate && dataset.aggregate) setError(p => ({ ...p, aggregate: undefined }));
    }, [error.aggregate, dataset.aggregate]);
    //#endregion

    //#region Base Form
    const unit_infos = React.useMemo<UnitInfos>(() => {
        let unit = Options.type.filter(o => o.value === dataset.type)[0]?.unit;

        // No unit restriction
        if (unit === undefined) return { type: "text", disabled: false };
        // One unit imposed
        else if (!Array.isArray(unit)) return { type: "text", disabled: true };
        // Choice between multiple unit
        else return { type: "select", disabled: false, options: unit.map(value => ({ value, label: value })) };
    }, [dataset.type]);

    const onChange = React.useMemo(() => ({
        src: (src?: T.DataSet["src"]) => setDataset(p => ({
            ...p,
            src,
            cumulated: src === "calculated" ? false : p.cumulated,
            normalized: src === "calculated" ? "false" : p.normalized,
            formulas: src === "calculated" ? [{ formula: "" }] : undefined,
            tag: src === "automatic" ? { id: NaN, station: NaN } : undefined,
            aggregate: src === "calculated" ? "avg" : (p.aggregate === "annualthreshold" || p.aggregate === "factor" ? "avg" : p.aggregate),
        })),
        type: (type?: T.DataSet["type"]) => {
            let unit = "";
            let config_unit = Options.type.filter(t => t.value === type)[0]?.unit;
            // Default is first option
            if (Array.isArray(config_unit)) unit = config_unit[0];
            // Unit is pre-defined
            else if (config_unit) unit = config_unit;
            setDataset(p => ({ ...p, unit, type }));
        }
    }), []);
    //#endregion

    //#region Extra
    const setAuto = React.useMemo(() => ({
        setStation: (station?: string) => {
            // Set tags as loading
            setTags([], "load");
            // Reset tag errors
            setError(p => ({ ...p, tag: undefined }));
            // Update station and reset tag
            setDataset(p => ({ ...p, tag: { station: parseInt(station), id: NaN } }));
        },
        setTag: (tag?: string) => {
            // Reset tag error
            setError(p => ({ ...p, tag: undefined }));
            // Update tag
            setDataset(p => ({ ...p, tag: { ...p.tag, id: parseInt(tag) } }));
        },
    }), [setTags]);

    const setFormula = React.useMemo(() => ({
        add: () => setDataset(p => ({ ...p, formulas: p.formulas.concat({ formula: "" }) })),
        remove: (index: number) => setDataset(p => ({ ...p, formulas: p.formulas.filter((f, i) => i !== index) })),
        setStart: (index: number, start?: string) => {
            setDataset(p => ({ ...p, formulas: p.formulas.map((f, i) => i === index ? { ...f, start } : f) }));
            setError(p => {
                if (!p.formulas?.[index]?.date) return p;
                return { ...p, formulas: p.formulas.map((f, i) => i === index ? { ...f, start: undefined } : f) };
            });
        },
        setFormula: (index: number, formula: string) => {
            setDataset(p => ({ ...p, formulas: p.formulas.map((f, i) => i === index ? { ...f, formula } : f) }));
            setError(p => {
                if (!p.formulas?.[index]?.formula) return p;
                return { ...p, formulas: p.formulas.map((f, i) => i === index ? { ...f, formula: undefined } : f) };
            });
        },
    }), []);

    const add_tag = React.useCallback((text: string) => new Promise((resolve, reject) => {
        let submission = { name: text, users: [userId], sites: [], type: "dataset" } as T.NoteTag;
        S.createSubmission({ submission, path: FP.NOTE_TAG_FORM }).then(({ data }) => {
            let new_tag = data.submissions[0] as T.Submission<T.NoteTag>;
            if (new_tag) {
                let new_option = { label: new_tag.data.name, value: new_tag._id } as T.Option;
                set_note_tags(p => p.concat(new_option));
                setDataset(p => ({ ...p, tags: p.tags.concat(new_option.value) }));
                resolve(new_option);
            }
            else resolve(null);
        }).catch(reject);
    }), [set_note_tags, userId]);
    //#endregion

    //#region Saving
    const checkErrors = React.useCallback(() => {
        let errors: typeof error = {};

        if (!dataset.src) errors.src = TC.GLOBAL_REQUIRED_FIELD;
        if (!dataset.name) errors.name = TC.GLOBAL_REQUIRED_FIELD;
        if (!dataset.type) errors.type = TC.GLOBAL_REQUIRED_FIELD;
        if (typeof dataset.cumulated !== "boolean") errors.cumulated = TC.GLOBAL_REQUIRED_FIELD;
        if (!dataset.aggregate && dataset.src !== "calculated") errors.aggregate = TC.GLOBAL_REQUIRED_FIELD;

        // Unit is up to the user to choose
        if (!unit_infos.disabled) {
            // No unit provided
            if (!dataset.unit) errors.unit = TC.GLOBAL_REQUIRED_FIELD;
        }

        if (dataset.src === "automatic") {
            let invalidTag = false, invalidStation = false;
            if (typeof dataset.tag.id !== "number" || isNaN(dataset.tag.id)) invalidTag = true;
            if (typeof dataset.tag.id !== "number" || isNaN(dataset.tag.id)) invalidStation = true;

            if (invalidTag || invalidStation) errors.tag = {
                id: invalidTag && TC.GLOBAL_REQUIRED_FIELD,
                station: invalidStation && TC.GLOBAL_REQUIRED_FIELD,
            };
        }
        else if (dataset.src === "calculated") {
            for (let i = 0; i < dataset.formulas.length; i++) {
                let currentDate = TB.getDate(dataset.formulas[i]?.start);
                let formerDate = TB.getDate(dataset.formulas[i - 1]?.start);

                let missingDate = false, invalidDate = false;
                if (i > 0 && !currentDate) missingDate = true;
                else if (i > 1 && currentDate && formerDate) invalidDate = currentDate.getTime() < formerDate.getTime();

                if (missingDate || invalidDate) {
                    let error = missingDate
                        ? TC.GLOBAL_REQUIRED_FIELD
                        : (invalidDate ? TC.DATASET_ERROR_DATE_START : undefined);

                    if (!Array.isArray(errors.formulas)) errors.formulas = [];
                    errors.formulas[i] = { date: error, formula: "" };
                }
            }
        }

        return errors;
    }, [dataset, unit_infos.disabled]);

    const onSaveLocal = React.useCallback(() => {
        let errors = checkErrors();
        if (Object.keys(errors).length > 0) setError(errors);
        else {
            isSaving.setTrue();
            S.updateDataset(dataset)
                .then(({ data }) => onSave?.(data))
                .catch(M.Alerts.updateError)
                .finally(isSaving.setFalse)
        }
    }, [dataset, isSaving, onSave, checkErrors]);

    const saveButton = React.useMemo(() => !readOnly && <C.Flex className="mb-2" alignItems="center" justifyContent="end">
        <C.Button
            onClick={onSaveLocal}
            text={TC.GLOBAL_SAVE}
            disabled={!TB.mongoIdValidator(dataset.origin)}
            icon={{ icon: "save", spinIcon: "spinner", spin: isSaving.value }}
        />
    </C.Flex>, [onSaveLocal, isSaving.value, dataset.origin, readOnly]);
    //#endregion

    return React.createElement(
        props.popup ? M.BlankModal : React.Fragment,
        props.popup ? {
            ...props.modalProps,
            footer: saveButton,
            size: props.modalProps?.size || "md",
            title: props.modalProps?.title || TC.DATASET_FORM,
            maxBodyHeight: props.modalProps?.maxBodyHeight || "55vh",
        } as M.BlankModalProps : null,
        <C.Spinner status={buildingStatus}>
            {!props.popup && saveButton}

            {!TB.mongoIdValidator(dataset.origin) && <C.ErrorBanner type="danger" message={TC.DATASET_ERROR_NO_ORIGIN} />}
            {!allowedEdit && !props.readOnly && <C.ErrorBanner canHide type="info" textCode={TC.DATA_FORM_NO_WRITE_ACCESS} />}

            <BS.Row className="g-2">
                <BS.Col md={6}>
                    <C.Form.TextField
                        disabled={readOnly}
                        value={dataset.name}
                        label={TC.DATASET_NAME}
                        error={{ code: error.name || "" }}
                        onChange={name => setDataset(p => ({ ...p, name }))}
                    />
                </BS.Col>
                <BS.Col md={3}>
                    <C.Form.Select
                        disabled={readOnly}
                        value={dataset.type}
                        options={Options.type}
                        label={TC.DATASET_TYPE}
                        onChange={onChange.type}
                        error={{ code: error.type || "" }}
                    />
                </BS.Col>
                <BS.Col md={3}>
                    {unit_infos.type === "text"
                        ? <C.Form.TextField
                            value={dataset.unit}
                            label={TC.DATASET_UNIT}
                            disabled={unit_infos.disabled}
                            error={{ code: error.unit || "" }}
                            onChange={unit => setDataset(p => ({ ...p, unit }))}
                        />
                        : <C.Form.Select
                            value={dataset.unit}
                            label={TC.DATASET_UNIT}
                            options={unit_infos.options}
                            error={{ code: error.unit || "" }}
                            typeahead={{ hideClearButton: true }}
                            onChange={unit => setDataset(p => ({ ...p, unit }))}
                        />
                    }
                </BS.Col>
            </BS.Row>
            <BS.Row className="g-2">
                <BS.Col md={6}>
                    <C.Form.Select
                        value={dataset.src}
                        options={Options.src}
                        label={TC.DATASET_SRC}
                        onChange={onChange.src}
                        error={{ code: error.src || "" }}
                        disabled={readOnly || TB.mongoIdValidator(dataset._id)}
                    />
                </BS.Col>
                <BS.Col md={6}>
                    <C.Form.Select
                        disabled={readOnly}
                        value={dataset.aggregate}
                        label={TC.DATASET_AGGREGATION}
                        error={{ code: error.aggregate || "" }}
                        onChange={aggregate => setDataset(p => ({ ...p, aggregate }))}
                        options={dataset.src !== "calculated" ? Options.aggregate.filter(a => a.value !== "annualthreshold" && a.value !== "factor") : Options.aggregate}
                    />
                </BS.Col>
            </BS.Row>
            {dataset.src !== "calculated" && <BS.Row className="align-items-center">
                <BS.Col md={3}>
                    <C.Form.RadioBool
                        noBottomMargin
                        disabled={readOnly}
                        name="radio_cumulated"
                        value={!!dataset.cumulated}
                        label={TC.DATASET_CUMULATED}
                        labelPosition="top"
                        error={{ code: error.cumulated || "" }}
                        onChange={cumulated => setDataset(p => typeof cumulated === "boolean" ? { ...p, cumulated } : p)}
                        tooltip={<C.Flex className="fs-85" alignItems="center" justifyContent="between">
                            <C.Flex direction="column" alignItems="center" className="me-3">
                                <div className="mb-2">{lg.getStaticText(TC.DATASET_CUMULATED_TOOLTIP)}</div>
                                <div>
                                    <img src={RESOURCE.RESOURCE_URL(RESOURCE.GRAPH_BAR_CUMULATED)} alt="" width={50} height={50} />
                                </div>
                            </C.Flex>
                            <C.Flex direction="column" alignItems="center">
                                <div className="mb-2">{lg.getStaticText(TC.DATASET_NON_CUMULATED_TOOLTIP)}</div>
                                <div>
                                    <img src={RESOURCE.RESOURCE_URL(RESOURCE.GRAPH_BAR_NON_CUMULATED)} alt="" width={50} height={50} />
                                </div>
                            </C.Flex>
                        </C.Flex>}
                    />
                </BS.Col>
                <BS.Col>
                    <C.Form.Select
                        disabled={readOnly}
                        value={dataset.normalized}
                        options={Options.normalized}
                        label={TC.DATASET_NORMALIZED}
                        onChange={normalized => setDataset(p => ({ ...p, normalized }))}
                    />
                </BS.Col>
            </BS.Row>}
            <BS.Row className="g-2">
                <BS.Col md={6}>
                    <C.Form.Select
                        multiple
                        disabled={readOnly}
                        options={note_tags}
                        value={dataset.tags}
                        label={TC.DATASET_TAGS}
                        loading={note_tags_status === "load"}
                        onChange={tags => setDataset(p => ({ ...p, tags }))}
                        typeahead={{ allowNewOption: true, onAddOption: add_tag }}
                    />
                </BS.Col>

                {isBuilding && <BS.Col>
                    <C.Form.RadioBool
                        noBottomMargin
                        disabled={readOnly}
                        labelPosition="top"
                        name="radio_is_head"
                        value={!!dataset.global}
                        label={TC.DATASET_IS_HEAD}
                        error={{ code: error.global || "" }}
                        onChange={global => setDataset(p => typeof global === "boolean" ? { ...p, global } : p)}
                    />
                </BS.Col>}
            </BS.Row>

            {dataset.src === "calculated" && <>
                {/* Header */}
                <BS.Row className="mb-3">
                    <BS.Col md={12}>
                        <BS.Row className="text-center align-items-center justify-content-center fw-bold">
                            <BS.Col md={8}>
                                {lg.getStaticText(TC.DATASET_FORMULAS)}
                            </BS.Col>
                            <BS.Col md={3}>
                                {lg.getStaticText(TC.DATASET_START_DATE)}
                            </BS.Col>
                            <BS.Col md={1}></BS.Col>
                        </BS.Row>
                    </BS.Col>
                </BS.Row>
                {/* Body */}
                <BS.Row className="mb-3">
                    <BS.Col md={12}>
                        {dataset.formulas.map((f, fIndex) => <BS.Row className="mb-2 text-center align-items-center justify-content-center" key={fIndex}>
                            <BS.Col md={8}>
                                <Formula
                                    value={f.formula}
                                    disabled={readOnly}
                                    origin={props.origin}
                                    currentDataset={dataset._id}
                                    onChange={f => setFormula.setFormula(fIndex, f)}
                                />
                            </BS.Col>
                            <BS.Col md={3}>
                                <C.Form.DateTime
                                    noBottomMargin
                                    value={f.start}
                                    disabled={fIndex === 0 || readOnly}
                                    onChange={date => setFormula.setStart(fIndex, date)}
                                    error={{ code: error.formulas?.[fIndex]?.date || "" }}
                                />
                            </BS.Col>
                            <BS.Col md={1}>
                                {fIndex > 0 && !readOnly && <C.IconButton
                                    size="md"
                                    icon="times"
                                    variant="danger"
                                    onClick={() => setFormula.remove(fIndex)}
                                />}
                            </BS.Col>
                        </BS.Row>)}
                    </BS.Col>
                </BS.Row>
                {/* Footer */}
                {!readOnly && <BS.Row className="mb-3">
                    <BS.Col md={12}>
                        <C.Flex justifyContent="end" alignItems="center">
                            <C.Button size="sm" onClick={setFormula.add} icon={{ icon: "plus" }} text={TC.DATASET_ADD_FORMULA} />
                        </C.Flex>
                    </BS.Col>
                </BS.Row>}
            </>}

            {dataset.src === "automatic" && <BS.Row className="g-2">
                <BS.Col md={6}>
                    <C.Form.Select
                        options={stations}
                        disabled={readOnly}
                        label={TC.DATASET_STATION}
                        onChange={setAuto.setStation}
                        typeahead={{ dropdownFit: true }}
                        loading={stationStatus === "load"}
                        error={{ code: error.tag?.station }}
                        value={dataset.tag?.station?.toString?.()}
                    />
                </BS.Col>
                <BS.Col md={6}>
                    <C.Form.Select
                        options={tags}
                        disabled={readOnly}
                        label={TC.DATASET_TAG}
                        onChange={setAuto.setTag}
                        loading={tagsStatus === "load"}
                        error={{ code: error.tag?.id }}
                        typeahead={{ dropdownFit: true }}
                        value={dataset.tag?.id?.toString?.()}
                    />
                </BS.Col>
            </BS.Row>}
        </C.Spinner>
    );
}

export default DataForm;