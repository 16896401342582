import React from "react";
import Data from "./Data";
import * as F from "../../Form";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import Remarques from "./Remarques";
import * as C from "../../../Common";
import * as D from "../../Dashboard";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import ControlList from "./ControlList";
import { RegWizard } from "../../RegDoc";
import { T, TC } from "../../../Constants";

//#region Types
type MissionResumeCDM = ReturnType<T.API.Utils.Missions.MissionResumeCDM>;
//#endregion

//#region Constants
type Tabs = {
    /** The label displayed in the tab */
    label: string;
    /** The label displayed in the crumb */
    crumb: string;
    /** The key to know which tab is active */
    key: "mission" | "equip" | "rem" | "data" | "reg" | "summary";
    /** Requires an extra task to be displayed */
    task?: "reg";
}

const TABS = [
    { label: TC.CERT_HISTORY_MISSION, key: "mission", crumb: TC.CERT_HISTORY_MISSION },
    { label: TC.CDM_MISSION_EQUIP_PANEL, key: "equip", crumb: TC.CDM_MISSION_EQUIP_CRUMB },
    { label: TC.CDM_MISSION_REM_PANEL, key: "rem", crumb: TC.CDM_MISSION_REM_PANEL },
    { label: TC.CDM_MISSION_DATA_PANEL, key: "data", crumb: TC.CDM_MISSION_DATA_CRUMB },
    { label: TC.CDM_MISSION_REG_PANEL, key: "reg", crumb: TC.CDM_MISSION_REG_PANEL, task: "reg" },
    { label: TC.CDM_MISSION_SUMMARY_PANEL, key: "summary", crumb: TC.CDM_MISSION_SUMMARY_PANEL },
] as Tabs[];
//#endregion

const CDM: React.FC = () => {
    const [roots, mission_id] = H.useRoots();
    const reload_datasets = H.useBoolean(true);
    const updated_mission = H.useBoolean(false);
    const mission_ref = React.useRef<F.Missions.MissionFormRef>(null);
    const [current_tab, set_current_tab] = React.useState<Tabs["key"]>("mission");
    const { updateCrumbs, resetCrumbs } = H.useCrumbs(TC.GLOBAL_CDM_MISSION_TITLE);
    const [loaded_tabs, set_loaded_tabs] = React.useState<Tabs["key"][]>(["mission"]);
    const [mission_state, set_mission_state, status] = H.useAsyncState<MissionResumeCDM>({ asset_id: "", mission_type: "cdm", mission_id: "", has_reg: false, read_only: true });

    //#region Tabs & Crumbs
    React.useEffect(() => {
        let tab = TABS.filter(t => t.key === current_tab)[0];
        if (tab) updateCrumbs({ label: tab.label }, 1);
    }, [resetCrumbs, updateCrumbs, current_tab]);

    const change_tab = React.useCallback((tab: typeof current_tab) => {
        const tab_setter = (keep_mission_loaded = true) => {
            // Update the current tab
            set_current_tab(tab);
            // Update the list of loaded tab
            set_loaded_tabs(p => {
                let new_loaded = p.includes(tab) ? p : p.concat(tab);
                if (!keep_mission_loaded) return new_loaded.filter(t => t !== "mission");
                else return new_loaded;
            });
        }

        // Ask to confirm the changes made in the mission
        if (current_tab === "mission" && updated_mission.value) M.askConfirm({ title: TC.MISSION_WIZARD_AUTO_SAVE_MISSION, text: TC.MISSION_WIZARD_AUTO_SAVE_MISSION_TEXT }).then(confirmed => {
            if (confirmed) {
                let result = mission_ref.current?.save?.();
                if (result && result !== "errors") result.then(success => {
                    if (success) {
                        tab_setter();
                        updated_mission.setFalse();
                    }
                });
            }
            else tab_setter(false);
        });
        else tab_setter();
    }, [current_tab, updated_mission]);

    const available_tabs = React.useMemo<Tabs[]>(() => {
        if (status !== "done") return [];
        return TABS.filter(t => {
            if (t.task) {
                if (t.task === "reg") return mission_state.has_reg;
                else return false;
            }
            else return true;
        });
    }, [mission_state.has_reg, status]);

    const onSaveMission = React.useCallback<F.Missions.MissionFormProps["onSave"]>(mission => {
        if (mission !== "deleted" && mission !== "error") set_mission_state(p => ({ ...p, has_reg: mission.tasks.some(t => t.type === "reg") }));
    }, [set_mission_state]);

    // eslint-disable-next-line react-hooks/exhaustive-deps -- Dependency is not necessary, and I don't want the warning to show up
    React.useEffect(() => updated_mission.setFalse(), [mission_id]);
    //#endregion

    //#region Load data
    React.useEffect(() => {
        let isSubscribed = true;
        S.missionResumeCDM()
            .then(({ data }) => {
                if (isSubscribed) {
                    let crumb_title = data.mission_type === "edl_tech" ? TC.MISSION_TYPES_EDL : TC.GLOBAL_CDM_MISSION_TITLE;
                    updateCrumbs({ label: crumb_title }, 0, true);
                    set_mission_state(data, "done");
                }
            })
            .catch(() => isSubscribed && set_mission_state({ asset_id: "", mission_id: "", mission_type: "cdm", has_reg: false, read_only: true }, "error"));
        return () => {
            resetCrumbs();
            isSubscribed = false;
            set_current_tab("mission");
            set_loaded_tabs(["mission"]);
            set_mission_state({ asset_id: "", mission_id: "", mission_type: "cdm", has_reg: false, read_only: true }, "load");
        };
    }, [set_mission_state, resetCrumbs, updateCrumbs, mission_id]);
    //#endregion

    //#region Update Mission
    const update = React.useMemo(() => ({
        structure: (structure: T.Mission["structure"]) => {
            S.saveMissionDataStructure(structure)
                .then(() => set_mission_state(p => ({ ...p, structure })))
                .catch(M.Alerts.updateError);
        },
        extra: (params: Parameters<typeof S.saveMissionExtra>[0]) => {
            S.saveMissionExtra(params)
                .then(({ data }) => set_mission_state(p => ({ ...p, extra: data })))
                .catch(M.Alerts.updateError);
        },
    }), [set_mission_state]);
    //#endregion

    return <C.Flex direction="column" className="flex-grow-1">
        <C.Spinner status={status}>

            <BS.Row className="g-1">
                {available_tabs.map(tab => <BS.Col key={tab.key}>
                    <C.Button
                        size="sm"
                        text={tab.label}
                        className="w-100"
                        onClick={() => change_tab(tab.key)}
                        variant={tab.key === current_tab ? "primary" : "link"}
                    />
                </BS.Col>)}
            </BS.Row>

            {loaded_tabs.includes("mission") && <div className="flex-grow-1 my-3" hidden={current_tab !== "mission"}>
                <F.Missions.MissionForm
                    no_delete
                    ref={mission_ref}
                    onSave={onSaveMission}
                    asset={mission_state.asset_id}
                    onChange={updated_mission.setTrue}
                    mission_id={mission_state.mission_id}
                />
            </div>}

            {loaded_tabs.includes("equip") && <div className="flex-grow-1 my-3" hidden={current_tab !== "equip"}>
                <ControlList read_only={mission_state.read_only} asset={mission_state.asset_id} new_datasets={reload_datasets.setTrue} />
            </div>}

            {/* Different handling than other tabs, because table still appears if flex is hidden */}
            {current_tab === "rem" && <C.Flex className="flex-grow-1 my-3">
                <Remarques asset={mission_state.asset_id} read_only={mission_state.read_only} />
            </C.Flex>}

            {loaded_tabs.includes("reg") && <div className="flex-grow-1 my-3" hidden={current_tab !== "reg"}>
                <RegWizard read_only={mission_state.read_only} context={roots} />
            </div>}

            {loaded_tabs.includes("data") && <div hidden={current_tab !== "data"} className="flex-grow-1 my-3">
                <Data
                    onChange={update.structure}
                    read_only={mission_state.read_only}
                    structure={mission_state.structure}
                    reload_data={reload_datasets.value}
                    on_data_reloaded={reload_datasets.setFalse}
                />
            </div>}

            {loaded_tabs.includes("summary") && <div className="flex-grow-1 my-3" hidden={current_tab !== "summary"}>
                <div className="mb-3">
                    <C.Form.TextField
                        textArea
                        rows={4}
                        max_height="350px"
                        disabled={mission_state.read_only}
                        value={mission_state.extra?.cdm?.opinion}
                        label={TC.MISSION_CDM_MAINTENANCE_OPINION}
                        onChange={value => update.extra({ type: "cdm", prop: "opinion", value })}
                    />
                    <C.Form.TextField
                        textArea
                        rows={4}
                        max_height="350px"
                        label={TC.MISSION_CDM_REMS_RESUME}
                        disabled={mission_state.read_only}
                        value={mission_state.extra?.cdm?.resume}
                        onChange={value => update.extra({ type: "cdm", prop: "resume", value })}
                    />
                </div>
                <D.EquipmentsIndicators root={mission_state.asset_id} mission={mission_state.mission_id} show_extra_graph />
            </div>}

        </C.Spinner>
    </C.Flex>;
}

export default CDM;