import axios from "axios";
import { T, URL } from "../../Constants";

const { FORM_API } = URL;

//#region Types
type GetFormsSummary = T.API.ToAxios<T.API.Form.GetFormsSummary>;
type GetFormComponents = T.API.ToAxios<T.API.Form.GetFormComponents>;
//#endregion

export const getFormComponents: GetFormComponents = p => axios.get(FORM_API + "components", { params: p });
export const getSubmissionAndCertifications = <A>(p: T.API.Form.GetSubmissionFormParams) => axios.post<T.API.Form.GetSubmissionFormResults<A>>(FORM_API + "submission", p);

/** Create a summary of all the forms present in the database */
export const getFormsSummary: GetFormsSummary = () => axios.get(FORM_API + "summary");