import { TB } from "../../../Constants";
import { FormCheck } from "react-bootstrap";
import { useLanguage } from "../../../hooks";
import React, { FC, useEffect, useMemo } from "react";
import { ComponentWrapper, InputProps } from "../Input";

export type RadioButtonsProps = InputProps & {
    name?: string;
    inline?: boolean;
    values?: { label: string, value: string }[];
}

const RadioButtons: FC<RadioButtonsProps> = ({ inline, name, values, ...props }) => {
    const lg = useLanguage();

    const radioName = useMemo(() => name || props.prop || "radio", [props.prop, name]);
    const isDisabled = useMemo(() => props.disabled || props.noOverride, [props.disabled, props.noOverride]);
    const vValues = useMemo(() => TB.getArray(values).filter(v => TB.getString(v?.label)), [values]);

    useEffect(() => {
        let codes = vValues.map(v => v.label).filter(TB.isTextCode);
        if (codes.length > 0) lg.fetchStaticTranslations(codes);
    }, [vValues, lg]);

    return <ComponentWrapper {...props} disabled={isDisabled}>
        {vValues.map(v => <FormCheck
            type="radio"
            key={v.value}
            inline={inline}
            name={radioName}
            disabled={isDisabled}
            checked={props.value === v.value}
            label={lg.getStaticText(v.label)}
            onChange={e => props.onChange?.(v.value)}
        />)}
    </ComponentWrapper>
}

export default RadioButtons;