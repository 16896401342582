import moment from "moment";
import { T, TB, URL } from "../Constants";
import socketIoClient from 'socket.io-client';
import { renderAlert } from '../Components/Modal';

const CODES = { CONNECT_SOCKET: "CONNECT", DISCONNECT_SOCKET: "DISCONNECT" };
type DispatchGuess = (params: { type: string, payload?: T.AnyObject }) => void;
type socketFn = (socket?: T.Socket | null, action?: { type: string, payload?: T.AnyObject }) => void;

const socketRedux: socketFn = (socket = null, action) => {
    let { type = null } = TB.validObject(action) ? action : {};

    const connectSocket = () => {
        if (socket !== null) return socket;
        /* @ts-ignore */
        let newSocket: T.Socket | null = socketIoClient(URL.APP_DOMAIN, { path: URL.GLOBAL_SOCKET });

        if (newSocket !== null) {
            // Set the defaults callbacks
            newSocket.on("client_log", console.log);
            newSocket.on("no_maintenance", () => localStorage.removeItem("disableLog"));
            newSocket.on("alert", params => TB.validObject(params) ? renderAlert(params) : null);

            newSocket.on("forced_disconnect", () => {
                let disableLog = localStorage.getItem("disableLog");
                if (disableLog === null || new Date().getTime() > new Date(disableLog).getTime()) {
                    localStorage.clear();
                    localStorage.setItem("disableLog", moment().add(15, "minute").toISOString());
                    window.location.replace("/login");
                }
                else if (window.location.pathname !== "/login") window.location.replace("/login");
            });
        }

        return newSocket;
    };

    const disconnectSocket = () => {
        if (socket !== null) socket.disconnect();
        return null;
    };

    switch (type) {
        case CODES.CONNECT_SOCKET: return connectSocket();
        case CODES.DISCONNECT_SOCKET: return disconnectSocket();

        default: return socket;
    }
}

export default socketRedux;

export const CONNECT_SOCKET = () => (dispatch?: DispatchGuess) => dispatch?.({ type: CODES.CONNECT_SOCKET });
export const DISCONNECT_SOCKET = () => (dispatch?: DispatchGuess) => dispatch?.({ type: CODES.DISCONNECT_SOCKET });