import React from "react";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import AccessRoles from "./AccessRoles";
import { useNavigate } from "react-router-dom";
import { Table, TableProps, } from "../Gestion";
import AccessPermisions from "./AccessPermissions";
import AccessUserProfile from "./AccessUserProfile";
import { FP, T, TABS, TB, TC } from "../../Constants";
import { CellsTypes as CT } from "../Gestion/AgGridDefs";

//#region Types
type User = T.API.Access.AccessUsersTableData["users"][number];
type Panel = "users" | "permissions" | "roles" | "special_roles";
type Group = T.API.Access.AccessUsersTableData["groups"][number];
export type AccessUsersStateProps = { root: string, contextName: string };
//#endregion

//#region Constants
const TEXT_CODES = [
    TC.GLOBAL_NEW, TC.ACCESS_USERS, TC.ACCESS_GROUPS, TC.ACCESS_USER_PERMISSIONS, TC.ACCESS_USER_NO_USER_GROUP,
    TC.ACCESS_USERS_TREE_ALL,
];
//#endregion

const AccessUsers: React.FC = () => {
    const socket = H.useSocket();
    const [forms] = H.useFormIds();
    const navigate = useNavigate();
    const lg = H.useLanguage(TEXT_CODES);
    H.useCrumbs(TC.ACCESS_CRUMB, "/access");
    const [search, setSearch] = React.useState("");
    const [setCardRef, cardSize] = H.useElementSize();
    const [panel, setPanel] = React.useState<Panel>("users");
    const [activeUser, setActiveUser] = React.useState(null);
    const [setCardHeaderRef, cardHeaderSize] = H.useElementSize();
    const [{ isAdmin, userId }] = H.useAuth({ tabName: TABS.USERS_TABLE });
    const [{ groups, users }, setItems, itemStatus] = H.useAsyncState<T.API.Access.AccessUsersTableData>({ groups: [], users: [] });

    //#region Callbacks
    const user_cb = React.useMemo(() => ({
        user_tree: (user: typeof users[number]) => navigate("/tree", { state: { root: user._id } }),
        remove: (user: typeof users[number]) => {
            M.askConfirm().then(confirmed => {
                if (confirmed) S.archiveUser(user._id)
                    .then(() => {
                        // Kick the user by force
                        socket.emit("kick_user", user._id);
                        // Remove user locally
                        setItems(p => ({ ...p, users: p.users.filter(u => u._id !== user._id) }))
                    })
                    .catch(M.Alerts.deleteError);
            });
        },
        create: () => {
            M.askNameMail({ modal: { title: TC.CREAT_USER } }).then(infos => {
                if (infos) S.mailExists({ mail: infos.email, is_oversight: true }).then(({ data }) => {
                    // Email does not exists yet and cannot be used
                    if (data === "forbidden") M.renderAlert({ type: "error", message: TC.AUTH_NEW_USER_MAIL_FORBIDDEN });
                    // Email is not already used. Create a temporary and unusable user
                    else if (data === "free") S.createShellUser(infos).then(({ data }) => {
                        M.renderAlert({ type: "success", message: TC.AUTH_NEW_USER_SUCCESS });
                        setItems(p => ({
                            ...p,
                            users: p.users.concat({ _id: data._id, name: data.data.name, email: data.data.email, groups: [], groups_ids: [], active: false }),
                        }));
                    }).catch(M.Alerts.updateError);
                    // Already made a demand to oversee this user
                    else if (data === "duplicate") M.renderAlert({ type: "error", message: TC.AUTH_NEW_USER_MAIL_DUPE });
                    // Email is already used, set up confirmation
                    else M.askConfirm({ title: TC.GLOBAL_CONFIRM, text: TC.AUTH_SIGNIN_ASK_OVERSEE }).then(confirmed => {
                        if (confirmed) S.confirmUserOrder(data._id)
                            .then(() => M.renderAlert({ type: "success", message: TC.AUTH_NEW_USER_MAIL_SUCCESS }))
                            .catch(M.Alerts.loadError);
                    });
                }).catch(M.Alerts.loadError);
            });
        },
        all_user_tree: () => {
            if (isAdmin) {
                let allUsersIds = users.map(u => u._id);
                navigate("/tree", { state: { root: allUsersIds, nameContext: "Users" } });
            }
            else navigate("/tree", { state: { root: userId } })
        },
        profile: (user: typeof users[number]) => {
            M.renderBlankModal({
                isFullScreen: true,
                title: TC.ACCESS_USERS_TITLE,
                children: <AccessUserProfile user={user._id} />
            });
        },
        inline_edit: (params => {
            let row = params.data,
                old_value = params.oldValue,
                field = params.colDef.field as keyof User;

            // This field can't be edited
            if (field !== "name") M.Alerts.updateError();
            // Can the user edit this user
            else if ((params.newValue as string).length === 0) M.renderAlert({ type: "warning", message: TC.GLOBAL_REQUIRED_FIELD });
            else {
                const updatePromise = new Promise<User>((resolve, reject) => {
                    let api_params = {
                        field: "name",
                        _id: row._id,
                        old_value: old_value,
                        new_value: params.newValue,
                    } as Parameters<typeof S.editUserField>[0];

                    S.editUserField(api_params).then(({ data }) => {
                        if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                            if (confirmed) S.editUserField({ ...api_params, force_update: true }).then(({ data }) => {
                                if (data === "changed") reject("Error");
                                else resolve({ ...row, name: params.newValue });
                            }).catch(reject);
                        });
                        else resolve({ ...row, name: params.newValue });
                    }).catch(reject);
                });

                updatePromise
                    .then(user => setItems(p => ({ ...p, users: p.users.map(u => u._id === user._id ? user : u) })))
                    .catch(M.Alerts.updateError);
            }
        }) as TableProps<User>["onValueChange"],
    }), [navigate, setItems, isAdmin, socket, userId, users]);

    const user_options = React.useMemo(() => users.map(u => ({ label: u.name, value: u._id })), [users]);

    const group_cb = React.useMemo(() => ({
        remove: (group: typeof groups[number]) => {
            M.askConfirm().then(confirmed => {
                if (confirmed) S.removeGroup(group._id)
                    .then(() => setItems(p => {
                        let groups = p.groups.filter(g => g._id !== group._id);

                        let users = p.users.map(u => {
                            if (!u.groups_ids.includes(group._id)) return u;
                            // Flag in case two groups have the same name, only remove one
                            let removed = false;
                            return {
                                ...u,
                                groups: u.groups.filter(n => {
                                    // Name are different, keep it
                                    if (n !== group.name) return true;
                                    // A name was already removed, so no need to remove another one
                                    if (removed) return true;
                                    // Remove the name and flag that there has been a removal already
                                    removed = true;
                                    return false;
                                }),
                                groups_ids: u.groups_ids.filter(id => id !== group._id),
                            }
                        });

                        return { ...p, groups, users }
                    }))
                    .catch(M.Alerts.deleteError);
            });
        },
        create: () => {
            let forcedSubmission = [{ prop: "admin", value: [userId] }, { prop: "roles", value: [] }];

            M.renderFormModal({ path: FP.GROUP_FORM, forcedSubmission }).then(group => {
                if (group) S.getAccessGroup(group._id)
                    .then(({ data }) => setItems(p => {
                        let groups = p.groups.concat(data);
                        let users = p.users.map(u => {
                            let group = data.filter(g => g.owner === u._id || g.admins_ids.includes(u._id) || g.users_ids.includes(u._id));
                            if (group.length === 0) return u;
                            return {
                                ...u,
                                groups: u.groups.concat(group.map(g => g.name)),
                                groups_ids: u.groups_ids.concat(group.map(g => g._id)),
                            }
                        });

                        return { ...p, groups, users };
                    }))
                    .catch(M.Alerts.updateError);
            });
        },
        inline_edit: (params => {
            let row = params.data,
                old_value = params.oldValue,
                field = params.colDef.field as keyof Group;

            // User must be an admin of the group (or a regular admin) to edit the group
            if (!row.admins_ids.includes(userId) && !isAdmin) M.Alerts.haveNotRight();
            // Don't accept empty name
            else if (field === "name" && params.newValue.length === 0) M.renderAlert({ type: "warning", message: TC.GLOBAL_REQUIRED_FIELD });
            else {
                let was_edited = true;
                // Special property case
                if (field === "users") {
                    old_value = row.users_ids;
                    // Check if the value truly was edited
                    if (old_value.length === params.newValue.length) was_edited = params.newValue.every(v => old_value?.includes?.(v));
                }

                if (was_edited) {
                    const updatePromise = new Promise<Group>((resolve, reject) => {
                        let api_params = {
                            _id: row._id,
                            new_value: params.newValue,
                            field: field === "users" ? "members" : field,
                            old_value: field === "users" ? row.users_ids : old_value,
                        } as Parameters<typeof S.editGroupField>[0];

                        S.editGroupField(api_params).then(({ data }) => {
                            if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                                if (confirmed) S.editGroupField({ ...api_params, force_update: true }).then(({ data }) => {
                                    if (data === "changed") reject("Error");
                                    else if (field !== "users") resolve({ ...row, [field]: params.newValue });
                                    else {
                                        let new_users_names = users.filter(u => params.newValue.includes(u._id)).map(u => u.name);
                                        resolve({ ...row, users: new_users_names, users_ids: params.newValue });
                                    }
                                }).catch(reject);
                            });
                            else if (field !== "users") resolve({ ...row, [field]: params.newValue });
                            else {
                                let new_users_names = users.filter(u => params.newValue.includes(u._id)).map(u => u.name);
                                resolve({ ...row, users: new_users_names, users_ids: params.newValue });
                            }
                        }).catch(reject);
                    });

                    updatePromise
                        .then(group => setItems(p => ({ ...p, groups: p.groups.map(g => g._id === group._id ? group : g) })))
                        .catch(M.Alerts.updateError);
                }
            }
        }) as TableProps<Group>["onValueChange"],
    }), [setItems, userId, isAdmin, users]);
    //#endregion

    //#region Columns
    const forms_ids = React.useMemo(() => ({ user: forms[FP.USER_FORM], group: forms[FP.GROUP_FORM] }), [forms]);

    const grid_buttons = React.useMemo(() => ({
        user: {
            remove: {
                action: user_cb.remove,
                content: <i className="fa fa-trash"></i>,
                buttonProps: { className: "btn-none text-dark", variant: "light" },
            },
            profile: {
                action: user_cb.profile,
                content: <i className="fa fa-id-card"></i>,
                buttonProps: { className: "btn-none text-dark", variant: "light" },
            },
            tree: {
                action: user_cb.user_tree,
                content: <i className="fa fa-project-diagram"></i>,
                buttonProps: { className: "btn-none text-dark", variant: "light" },
            },
        },
        group: {
            remove: {
                action: group_cb.remove,
                content: <i className="fa fa-trash"></i>,
                buttonProps: { className: "btn-none text-dark", variant: "light" },
            }
        }
    }), [user_cb, group_cb]);

    const user_columns = React.useMemo<TableProps<User>["columns"]>(() => [
        { field: "name", headerName: "name", editable: true, params: { header_id: forms_ids.user } },
        { field: "email", headerName: "email", params: { header_id: forms_ids.user } },
        { field: "phone", headerName: "phone", params: { header_id: forms_ids.user } },
        { field: "company", headerName: "company", params: { header_id: forms_ids.user } },
        { field: "active", headerName: TC.ACCESS_USER_ACTIVATED, type: CT.TYPE_CHECKBOX },
        { field: "groups", headerName: TC.ACCESS_USER_GROUP },
        { field: "profile", headerName: TC.ACCESS_USERS_PROFILE, type: CT.TYPE_ACTION_BUTTON, params: grid_buttons.user.profile },
        { field: "tree", headerName: TC.ACCESS_USERS_TREE, type: CT.TYPE_ACTION_BUTTON, params: grid_buttons.user.tree },
        { field: "delete", headerName: TC.GLOBAL_DELETE, type: CT.TYPE_ACTION_BUTTON, params: grid_buttons.user.remove },
    ], [grid_buttons.user, forms_ids.user]);

    const group_columns = React.useMemo<TableProps<Group>["columns"]>(() => [
        { field: "name", headerName: "name", params: { header_id: forms_ids.group } },
        { field: "description", headerName: "description", params: { header_id: forms_ids.group } },
        { field: "users", headerName: "members", type: CT.TYPE_SELECT, params: { header_id: forms_ids.group, multiple: true, values: user_options, field_value: "users_ids" } },
        { field: "is_admin", headerName: TC.ACCESS_GROUP_AM_I_ADMIN, type: CT.TYPE_CHECKBOX, editable: false },
        { field: "delete", headerName: TC.GLOBAL_DELETE, type: CT.TYPE_ACTION_BUTTON, params: grid_buttons.group.remove },
    ], [grid_buttons.group, forms_ids.group, user_options]);
    //#endregion

    //#region Fetch Data
    React.useEffect(() => {
        let isSubscribed = true;

        S.getAccessGroupsItems()
            .then(reply => isSubscribed && setItems(reply.data, "done"))
            .catch(() => {
                if (isSubscribed) {
                    M.Alerts.infiniteLoadError();
                    setItems({ groups: [], users: [] }, "error");
                }
            });

        return () => {
            isSubscribed = false;
            setItems({ groups: [], users: [] }, "load");
        };
    }, [setItems]);
    //#endregion

    //#region Panels
    const panelList = React.useMemo(() => [
        { prop: "users" as Panel, label: TC.ACCESS_USER_USERS_GROUPS },
        { prop: "permissions" as Panel, label: TC.ACCESS_USER_PERMISSIONS },
        { prop: "roles" as Panel, label: TC.ACCESS_USER_ROLES },
        { prop: "special_roles" as Panel, label: TC.ACCESS_ROLE_SPECIAL_ROLES, hidden: !isAdmin }
    ], [isAdmin]);
    //#endregion

    //#region Languages
    React.useEffect(() => {
        let codes = panelList.map(c => c.label).filter(TB.isTextCode);
        if (codes.length > 0) lg.fetchStaticTranslations(codes);
    }, [panelList, lg]);

    React.useEffect(() => {
        let ids = [forms[FP.GROUP_FORM], forms[FP.USER_FORM]].filter(TB.mongoIdValidator);
        if (ids.length > 0) lg.fetchObjectTranslations(ids);
    }, [forms, lg]);
    //#endregion

    //#region Panels Options
    type UserOption = T.Option<{ icon: string, mail?: string }>;

    const userOptions = React.useMemo<UserOption[]>(() => {
        let g: UserOption[] = groups.map(g => ({ label: g.name, value: g._id, icon: "users" }));
        let u: UserOption[] = users.map(u => ({ label: u.name, value: u._id, icon: "user", mail: u.email }));

        let all = u.concat(g).sort(TB.sortOptions);
        if (!TB.validString(search)) return all;
        return all.filter(s => TB.areStringSimilar(search, s.label) || TB.areStringSimilar(search, s.mail));
    }, [users, groups, search]);
    //#endregion

    return <div className="w-100">

        <C.Flex className="mb-2">
            {panelList.map(p => !p.hidden && <C.Button
                key={p.prop}
                text={p.label}
                className="me-2"
                active={panel === p.prop}
                onClick={() => setPanel(p.prop)}
            />)}
        </C.Flex>

        {panel === "users" && <C.Flex direction="column" style={{ height: "94%" }}>
            <C.Flex direction="column" className="flex-grow-1">
                <C.Flex className="mb-2" alignItems="center" justifyContent="between" >
                    <h5>{lg.getStaticText(TC.ACCESS_USERS)}</h5>
                    <BS.ButtonGroup>
                        <C.Button onClick={user_cb.create} icon="plus" size="sm" text={TC.GLOBAL_NEW} />
                        <C.Button variant='secondary' onClick={user_cb.all_user_tree} icon="project-diagram" size="sm">
                            {isAdmin ? TC.ACCESS_USERS_TREE_ALL : TC.ACCESS_USER_SELF_TREE}
                        </C.Button>
                    </BS.ButtonGroup>
                </C.Flex>

                <div className="flex-grow-1">
                    <Table<User>
                        rows={users}
                        status={itemStatus}
                        columns={user_columns}
                        adaptableId="access_user"
                        onValueChange={user_cb.inline_edit}
                        autoFit={["tree", "delete", "profile"]}
                        columns_base={["filterable", "sortable"]}
                    />
                </div>
            </C.Flex>

            <C.Flex direction="column" className="flex-grow-1">
                <C.Flex className="mb-2" alignItems="center" justifyContent="between" >
                    <h5>{lg.getStaticText(TC.ACCESS_GROUPS)}</h5>
                    <C.Button onClick={group_cb.create} size="sm" icon="plus">
                        {TC.GLOBAL_NEW}
                    </C.Button>
                </C.Flex>
                <div className="flex-grow-1">
                    <Table<Group>
                        rows={groups}
                        autoFit="delete"
                        status={itemStatus}
                        columns={group_columns}
                        adaptableId="access_group"
                        onValueChange={group_cb.inline_edit}
                        columns_base={["filterable", "sortable", "editable"]}
                    />
                </div>
            </C.Flex>

        </C.Flex>}

        {panel === "permissions" && <div className="h-100">
            <h5 className="mb-2">{lg.getStaticText(TC.ACCESS_USER_PERMISSIONS)}</h5>
            <C.Flex ref={setCardRef} style={{ height: "88%" }}>
                <BS.Card className="text-break me-2" style={{ maxWidth: "250px" }}>
                    <BS.Card.Header ref={setCardHeaderRef}>
                        <BS.InputGroup>
                            <BS.FormControl value={search} onChange={e => setSearch(e.target.value)} />
                            <BS.InputGroup.Text>
                                <i className="fa fa-search"></i>
                            </BS.InputGroup.Text>
                        </BS.InputGroup>
                    </BS.Card.Header>
                    <BS.Card.Body className="p-0">
                        {userOptions.length === 0
                            ? <div className="text-muted text-center">{lg.getStaticText(TC.ACCESS_USER_NO_USER_GROUP)}</div>
                            : <BS.ListGroup style={{ maxHeight: (cardSize.height - cardHeaderSize.height) + "px", overflowY: "scroll" }} variant="flush">
                                {userOptions.map(o => <BS.ListGroup.Item active={o.value === activeUser} key={o.value} className="pointer" onClick={() => setActiveUser(o.value)}>
                                    <div>
                                        <i className={"me-2 fa fa-" + o.icon}></i>
                                        {o.label}
                                    </div>
                                    {o.mail && <div className="text-muted fs-85">{o.mail}</div>}
                                </BS.ListGroup.Item>)}
                            </BS.ListGroup>}
                    </BS.Card.Body>
                </BS.Card>
                <div className="flex-grow-1">
                    {TB.mongoIdValidator(activeUser)
                        ? <AccessPermisions target={activeUser} />
                        : <C.ErrorBanner type="info" textCode={TC.ACCESS_USER_NO_SELECT} />}

                </div>
            </C.Flex>
        </div>}

        {panel === "roles" && <div className="h-100" children={<AccessRoles />} />}
        {panel === "special_roles" && <div className="h-100" children={<AccessRoles is_special_roles />} />}
    </div >;
}

export default AccessUsers;