import { Flex } from "../Layout";
import { TB } from "../../Constants";
import { CloseButton } from "react-bootstrap";
import { useBoolean, useLanguage } from "../../hooks";
import React, { FC, ReactNode, useCallback, useEffect, useMemo } from "react";
import { CheckCircleOutline, ErrorOutline, Info, Warning, } from "@material-ui/icons";

export type ErrorBannerProps = {
    message?: string;
    textCode?: string;
    canHide?: boolean;
    size?: "sm" | "md";
    showIcon?: boolean;
    close?: () => void;
    children?: ReactNode;
    /** Do remove all margins */
    no_margins?: boolean;
    type: "danger" | "warning" | "info" | "success";
}

const ErrorBanner: FC<ErrorBannerProps> = ({ showIcon = true, size = "md", canHide, children, type = "danger", textCode, message, close, ...props }) => {
    const hidden = useBoolean(false);
    const { getStaticElem, fetchStaticTranslations } = useLanguage();

    useEffect(() => fetchStaticTranslations([textCode].filter(TB.validString)), [textCode, fetchStaticTranslations]);

    const acceptedTypes = useMemo(() => ["success", "danger", "info", "warning"], []);
    const validType = useMemo(() => acceptedTypes.includes(type) ? type : "error", [acceptedTypes, type]);
    const validMessage = useMemo(() => TB.validString(message) ? getStaticElem(message) : null, [message, getStaticElem]);

    const icon = useMemo(() => {
        switch (validType) {
            case "info": return <Info />;
            case "warning": return <Warning />;
            case "error": return <ErrorOutline />;
            case "success": return <CheckCircleOutline />;
            default: return <Info />;
        }
    }, [validType]);

    const sizePaddingMargin = useMemo(() => {
        if (size === "sm") return props.no_margins ? "p-1 m-0" : "p-1 my-2";
        else if (props.no_margins) return "m-0";
        else return "my-3";
    }, [size, props.no_margins]);

    const onClose = useCallback(() => {
        if (typeof close === "function") close();
        else if (canHide) hidden.setFalse();
    }, [close, hidden, canHide]);

    const content = useMemo(() => children || <div>
        {textCode && <p className="m-0">{getStaticElem(textCode ?? "")}</p>}
        {validMessage && <p className="m-0">{validMessage}</p>}
    </div>, [children, validMessage, getStaticElem, textCode]);

    return !hidden.value && <Flex className={`alert alert-${type} ${sizePaddingMargin}`} alignItems="center">
        {(canHide || close) && <div className="me-3">
            <CloseButton onClick={onClose} />
        </div>}
        {showIcon && <div className="me-3">{icon}</div>}
        {content}
    </Flex>;
}

export default ErrorBanner;