import React from "react";
import * as S from "../../../services";
import { InputGroup } from "react-bootstrap";
import { FP, T, TAG, TB } from "../../../Constants";
import InnerHtml from "dangerously-set-html-content";
import { InputProps, ComponentWrapper } from "../Input";
import { useAuth, useFormIds, useLanguage } from "../../../hooks";
import { renderFormModal } from "../../../Components/Modal";
import { TypeAhead, TypeAheadProps, TypeaheadRef } from "../../Item";

//#region Type
export type SelectRef = TypeaheadRef;

export type SelectProps = InputProps & {
    prefix?: string;
    suffix?: string;
    loading?: boolean;
    multiple?: boolean;
    /** The values for which the label should be rendered in bold */
    bold_values?: T.AllowArray<string>;
    addResource?: boolean;
    valueProperty?: string;
    positionFixed?: boolean;
    addResourceLabel?: string;
    data?: { resource?: string };
    typeahead?: Partial<TypeAheadProps>;
    dataSrc?: T.FormComponentsMain["dataSrc"];
    /** Do not show the clear button in the input field */
    no_clear_btn?: boolean;
}
//#endregion

const Select = React.forwardRef<SelectRef, SelectProps>(({ multiple, onChange, addResourceLabel, options, addResource, valueProperty, ...props }, ref) => {
    const lg = useLanguage();
    const [forms] = useFormIds();
    const [{ userId }] = useAuth();
    const select_ref = React.useRef<TypeaheadRef>(null);
    React.useImperativeHandle(ref, () => select_ref.current, []);
    const isDisabled = React.useMemo(() => props.disabled || props.noOverride, [props.disabled, props.noOverride]);

    //#region Options
    const vOptions = React.useMemo(() => TB.getArray(options).filter(o => TB.validObject(o) && o.label), [options]);

    const selectedOptions = React.useMemo(() => {
        if (multiple) {
            let vValue = TB.arrayWrapper(props.value)
                .map(v => TB.validObject(v) ? JSON.stringify(v) : v);
            return vValue;
        }
        let testVal = props.value;
        if (TB.validObject(props.value)) testVal = JSON.stringify(props.value);
        return testVal;
    }, [props.value, multiple]);
    //#endregion

    //#region Visual
    React.useEffect(() => {
        let codes = [props.suffix, props.prefix].filter(TB.isTextCode);
        if (codes.length > 0) lg.fetchStaticTranslations(codes);
    }, [lg, props.suffix, props.prefix]);

    const vPrefix = React.useMemo(() => props.prefix && lg.getStaticText(props.prefix), [props.prefix, lg]);
    const vSuffix = React.useMemo(() => props.suffix && lg.getStaticText(props.suffix), [props.suffix, lg]);
    //#endregion

    //#region Select
    const bold_values = React.useMemo(() => TB.arrayWrapper(props.bold_values).filter(TB.validString), [props.bold_values]);

    const renderItem = React.useCallback((option: T.Sockets.Form.FormOptions["options"][number]) => {
        if (typeof props?.typeahead?.renderItem === "function") return props.typeahead.renderItem(option);
        else if (TB.validString(option.template)) return <InnerHtml html={option.template} />;
        else if (TB.isTextCode(option.label)) return lg.getStaticText(option.label);
        else if (bold_values.includes(option.value)) return <strong>{option.label}</strong>;
        return option.label;
    }, [lg, bold_values, props.typeahead]);

    const onUpdate = React.useCallback((options: T.Sockets.Form.FormOptions["options"]) => {
        if (!isDisabled) {
            let val = multiple ? options.map(o => o.value) : options[0]?.value || null;
            onChange?.(val);
        }
    }, [onChange, isDisabled, multiple]);
    //#endregion

    //#region Add Option
    React.useEffect(() => {
        if (TB.isTextCode(addResourceLabel)) lg.fetchStaticTranslations([addResourceLabel]);
    }, [lg, addResourceLabel]);

    const canAddOption = React.useMemo(() => {
        if (props.typeahead?.allowNewOption) return true;
        if (!addResource) return false;
        if (isDisabled) return false;
        let allow = true;
        // Allow Add options for the model, only if there is a brand
        if (props.formId === forms[FP.EQUIPEMENT_FORM]) {
            if (props.prop === "model") allow = TB.mongoIdValidator(props.fullSubmission?.brand);
        }
        return allow;
    }, [forms, isDisabled, addResource, props.fullSubmission, props.formId, props.prop, props.typeahead?.allowNewOption]);

    const onAddResource = React.useCallback((text: string) => {
        if (typeof props.typeahead?.onAddOption === "function") props.typeahead.onAddOption(text);
        else {
            /** The path to open to choose what kind of sub to create */
            let path = "",
                /** The default submission to pre-fill the form */
                submission = {},
                /** Determines whether to show the form modal, or to directly create the submission */
                direct_creation = false;

            // Check if the parameters are valid for a tag creation
            let tag_creation = TAG.tags_infos.find(t => props.formId === forms[t.form] && t.prop === props.prop);
            if (tag_creation) {
                direct_creation = true;
                path = FP.NOTE_TAG_FORM;
                (submission as Partial<T.NoteTag>) = { name: text, users: [userId], sites: [], type: tag_creation.type };
            }
            // Handle other special formio cases here
            else if (props.formId === forms[FP.EQUIPEMENT_FORM] || props.formId === forms[FP.EQUIPSTORE_FORM]) {
                // Handle brand creation
                if ((props.prop as keyof (T.EquipmentData | T.EquipStore)) === "brand") {
                    path = FP.BRAND_FORM;
                    (submission as Partial<T.BrandData>) = { name: text?.toUpperCase?.() || "" };
                }
                // Handle Model creation
                else if ((props.prop as keyof (T.EquipmentData | T.EquipStore)) === "model") {
                    path = FP.TYPE_FORM;
                    (submission as Partial<T.ModelData>) = { brand: props.fullSubmission?.brand, type: text?.toUpperCase?.() };
                }
            }

            if (direct_creation) S.createSubmission({ submission, path }).then(({ data }) => {
                let new_value = data.submissions[0]._id;
                if (new_value) {
                    let updated_value = data.submissions[0]?._id;
                    if (multiple) updated_value = selectedOptions.concat(data.submissions.map(s => s._id)).filter(x => x !== null);
                    onChange?.(updated_value);
                }
            })
            else renderFormModal({ path, modalProps: { size: "md" }, forcedSubmission: TB.submissionToArrayUpdate(submission) })
                .then(submission => {
                    if (submission) {
                        let updated_value = submission._id;
                        if (multiple) updated_value = selectedOptions.concat(submission._id).filter(x => x !== null);
                        onChange?.(updated_value);
                    }
                });
        }
    }, [onChange, forms, selectedOptions, userId, props.typeahead, props.formId, props.prop, props.fullSubmission, multiple]);
    //#endregion

    const to_string = React.useCallback(() => {
        let values = TB.arrayWrapper(selectedOptions);
        let translated_values = vOptions.filter(o => values.includes(o.value))
            .map((o: any) => {
                if (TB.mongoIdValidator(o.value) && o.prop) return lg.getTextObj(o.value, o.prop, o.label);
                else return lg.getStaticText(o.label) || o.label;
            });
        return translated_values.join(", ");
    }, [selectedOptions, vOptions, lg]);

    return <ComponentWrapper {...props} get_str_value={to_string} disabled={isDisabled}>
        <InputGroup>
            {vPrefix && <InputGroup.Text>
                {vPrefix}
            </InputGroup.Text>}

            <TypeAhead
                {...props?.typeahead}
                ref={select_ref}
                options={vOptions}
                multiple={multiple}
                onChange={onUpdate}
                disabled={isDisabled}
                renderItem={renderItem}
                loading={props.loading}
                keepOpenOnSelect={multiple}
                onAddOption={onAddResource}
                allowNewOption={canAddOption}
                selectedItems={selectedOptions}
                new_option_hint_label={addResourceLabel}
                hideClearButton={typeof props.typeahead?.hideClearButton === "boolean" ? props.typeahead.hideClearButton : props.no_clear_btn}
            />

            {vSuffix && <InputGroup.Text>
                {vSuffix}
            </InputGroup.Text>}
        </InputGroup>
    </ComponentWrapper>
});

export default Select;