import * as S from "../../../services";
import { TB } from "../../../Constants";
import { Alerts } from "../../../Components/Modal";
import { Button, ButtonGroup } from "react-bootstrap";
import SignaturePad from "react-signature-pad-wrapper";
import { ComponentWrapper, InputProps } from "../Input";
import { useBoolean, useLanguage } from "../../../hooks";
import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";

export type SignatureProps = InputProps & {
    footer?: string;
    minWidth?: string;
    maxWidth?: string;
    penColor?: string;
    backgroundColor?: string;
}

const Signature: FC<SignatureProps> = ({ onChange, footer, minWidth, maxWidth, penColor = "black", backgroundColor = "rgb(245,245,235)", ...props }) => {
    const lg = useLanguage();
    const padRef = useRef(null);
    const hasDrawn = useBoolean(false);
    const isUploading = useBoolean(false);

    //#region Language
    useEffect(() => {
        if (TB.isTextCode(footer)) lg.fetchStaticTranslations([footer]);
    }, [footer, lg]);
    //#endregion

    //#region Save Temporary File
    const isSavedImage = useMemo(() => TB.isFile(props.value?.[0]), [props.value]);
    useEffect(() => isUploading.value && hasDrawn.setFalse(), [hasDrawn, isUploading]);

    const onSave = useCallback(() => {
        let b64 = padRef.current?.signaturePad?.toDataURL?.("image/jpeg");
        if (!TB.validString(b64)) Alerts.loadError();
        else {
            isUploading.setTrue();
            S.postTempFile("signature.png", b64)
                .then(({ data }) => onChange?.(data))
                .catch(Alerts.loadError)
                .finally(isUploading.setFalse);
        }
    }, [onChange, isUploading]);

    const savedSignature = useMemo(() => <img src={props.value?.[0]?.url || ""} alt="" />, [props.value]);
    //#endregion

    //#region Signature Pad
    const isDisabled = useMemo(() => props.disabled || props.noOverride, [props.disabled, props.noOverride]);
    useEffect(() => padRef?.current?.signaturePad?.addEventListener?.("endStroke", hasDrawn.setTrue), [hasDrawn]);
    const footerElem = useMemo(() => footer && <div className="p-1 mb-1 text-center">{lg.getStaticElem(footer)}</div>, [footer, lg]);

    const clearValue = useCallback(() => {
        onChange?.(null);
        padRef.current?.clear?.()
    }, [onChange]);

    const signaturePad = useMemo(() => <>
        {/* @ts-ignore */}
        {!isDisabled && <SignaturePad ref={padRef} options={{ penColor, backgroundColor, minWidth, maxWidth }} redrawOnResize />}
        {isDisabled && <div style={{ minWidth, maxWidth, backgroundColor, minHeight: "200px", cursor: "not-allowed" }}></div>}
    </>, [isDisabled, penColor, backgroundColor, maxWidth, minWidth]);
    //#endregion

    return <ComponentWrapper {...props} disabled={isDisabled}>
        <div className="position-relative">
            <ButtonGroup className="position-absolute top-0 start-0">
                <Button disabled={isDisabled} size="sm" variant="danger" onClick={clearValue}>
                    <i className="fa fa-redo p-1"></i>
                </Button>
                <Button className={hasDrawn.value ? "pulse" : ""} disabled={isDisabled} size="sm" onClick={onSave}>
                    {isUploading.value && <i className="fa fa-spinner fa-spin"></i>}
                    <i className="fa fa-save p-1"></i>
                </Button>
            </ButtonGroup>

            {isSavedImage ? savedSignature : signaturePad}
            {footerElem}
        </div>
    </ComponentWrapper>
}

export default Signature;