import axios from "axios";
import { getHeader } from "../Headers";
import { T, URL } from "../../Constants";

const { DATASETS_API } = URL;

type GetTags = T.API.ToAxios<T.API.Utils.Datasets.GetTags>;
type GetDataset = T.API.ToAxios<T.API.Utils.Datasets.GetDataset>;
type GetStations = T.API.ToAxios<T.API.Utils.Datasets.GetStations>;
type LinkOptions = T.API.ToAxios<T.API.Utils.Datasets.LinkOptions>;
type UpdateDataset = T.API.ToAxios<T.API.Utils.Datasets.UpdateDataset>;
type AssignDataset = T.API.ToAxios<T.API.Utils.Datasets.AssignDataset>;
type RemoveDataset = T.API.ToAxios<T.API.Utils.Datasets.RemoveDataset>;
type FormulaOptions = T.API.ToAxios<T.API.Utils.Datasets.FormulaOptions>;
type UnassignDataset = T.API.ToAxios<T.API.Utils.Datasets.UnassignDataset>;
type GetContextOrigins = T.API.ToAxios<T.API.Utils.Datasets.GetContextOrigins>;
type UpdateDatasetField = T.API.ToAxios<T.API.Utils.Datasets.UpdateDatasetField>;
type GetDatasetsOptions = T.API.ToAxios<T.API.Utils.Datasets.GetDatasetsOptions>;
type GetDatasetItemTable = T.API.ToAxios<T.API.Utils.Datasets.GetDatasetItemTable>;
type GetFormulaFormProps = T.API.ToAxios<T.API.Utils.Datasets.GetFormulaFormProps>;
type UpdateDatasetOrigin = T.API.ToAxios<T.API.Utils.Datasets.UpdateDatasetOrigin>;
type GetDatasetOriginTable = T.API.ToAxios<T.API.Utils.Datasets.GetDatasetOriginTable>;
type FindEnergyLinksOrigin = T.API.ToAxios<T.API.Utils.Datasets.FindEnergyLinksOrigin>;
type FindDatasetsReferences = T.API.ToAxios<T.API.Utils.Datasets.FindDatasetsReferences>;
type CreateManualEquivalent = T.API.ToAxios<T.API.Utils.Datasets.CreateManualEquivalent>;
type GetDatasetContextTable = T.API.ToAxios<T.API.Utils.Datasets.GetDatasetContextTable>;
type FindEnergyLinksContext = T.API.ToAxios<T.API.Utils.Datasets.FindEnergyLinksContext>;
type ContextEntitiesToUpdate = T.API.ToAxios<T.API.Utils.Datasets.ContextEntitiesToUpdate>;
type GetContextDatasetsOptions = T.API.ToAxios<T.API.Utils.Datasets.GetContextDatasetsOptions>;
type GetContextOriginsWithDataset = T.API.ToAxios<T.API.Utils.Datasets.GetContextOriginsWithDataset>;

export const getStations: GetStations = context => axios.post(DATASETS_API + "stations", context);
export const getTags: GetTags = station => axios.get(DATASETS_API + "tags", { params: { station } });
export const getFormulaFormProps: GetFormulaFormProps = () => axios.get(DATASETS_API + "formula/forms");
export const getLinkOptions: LinkOptions = params => axios.get(DATASETS_API + "links/options", { params });
export const getFormulaItems: FormulaOptions = origin => axios.post(DATASETS_API + "formula/items", { origin });
export const getContextOrigins: GetContextOrigins = context => axios.post(DATASETS_API + "context/origins", context);
export const assignDataset: AssignDataset = params => axios.post(DATASETS_API + "link/assign", params, getHeader("EDIT"));
export const getDatasetOriginTable: GetDatasetOriginTable = origin => axios.post(DATASETS_API + "table/origin", { origin });
export const findEnergyLinksOrigin: FindEnergyLinksOrigin = origin => axios.post(DATASETS_API + "links/origin", { origin });
export const getDatasetContextTable: GetDatasetContextTable = context => axios.post(DATASETS_API + "table/context", context);
export const findEnergyLinksContext: FindEnergyLinksContext = context => axios.post(DATASETS_API + "links/context", context);
export const contextEntitiesToUpdate: ContextEntitiesToUpdate = context => axios.post(DATASETS_API + "entity/update", context);
export const unassignDataset: UnassignDataset = params => axios.post(DATASETS_API + "link/unassign", params, getHeader("EDIT"));

/** Update a dataset's tags */
export const update_dataset_field: UpdateDatasetField = params => axios.post(DATASETS_API + "edit/field", params, getHeader("EDIT"));
/** Search if a dataset is used in other datasets formulas */
export const findDatasetsReferences: FindDatasetsReferences = dataset => axios.post(DATASETS_API + "references", { dataset });
/** Deletes a dataset and the prediction model associated with it */
export const removeDataset: RemoveDataset = dataset => axios.post(DATASETS_API + "remove", { dataset }, getHeader("DELETE"));
/** Get datasets table items based on datasets ids */
export const getDatasetItemTable: GetDatasetItemTable = ids => axios.post(DATASETS_API + "table/items", { ids });
/** Update / Create a new dataset */
export const updateDataset: UpdateDataset = dataset => axios.post(DATASETS_API + "update", dataset, getHeader("EDIT"));
/** Get datasets options from on or many origins */
export const getDatasetsOptions: GetDatasetsOptions = origin => axios.post(DATASETS_API + "origin/options", { origin });
/** Get datasets options from a context */
export const getContextDatasetsOptions: GetContextDatasetsOptions = context => axios.post(DATASETS_API + "context/options", context);
/** Get a context's origins, but only those with at least one dataset */
export const getContextOriginsWithDatasets: GetContextOriginsWithDataset = context => axios.post(DATASETS_API + "context/origins_datasets", context);
/** Get a dataset based on its id */
export const getDataset: GetDataset = dataset => axios.post(DATASETS_API + "get", { dataset });
/** Create a manual equivalent for an automatic reading dataset */
export const createManualEquivalent: CreateManualEquivalent = dataset => axios.post(DATASETS_API + "equivalent", { dataset }, getHeader("CREATE"));
/** Update the origin of a dataset */
export const updateDatasetOrigin: UpdateDatasetOrigin = params => axios.post(DATASETS_API + "origin/update", params);