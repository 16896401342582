import React from "react";
import * as SBT from "./Types";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as PM from "../../../PurposeModal";
import { TC, T, TB } from "../../../Constants";
import * as US from "../../../services/user.service";

const StateSaver: React.FC<SBT.SideBarToolProps> = props => {
    const set_default = H.useBoolean(false);
    const [auth] = H.useAuth({ forcedLog: false });
    const [states, set_states, status] = H.useAsyncState<T.TableState[]>([]);

    //#region Style
    React.useEffect(() => {
        // Update the container to make it take the whole width available
        if (props.reactContainer) props.reactContainer.className = "w-100";
    }, [props.reactContainer]);
    //#endregion

    //#region Current & Saved state manipulations
    const current = React.useMemo(() => ({
        dismiss_charts: () => (props.api.getChartModels() || []).forEach(c => props.api.getChartRef(c.chartId)?.destroyChart?.()),
        get: () => ({
            filters: props.api?.getFilterModel?.() || {},
            chartModels: props.api?.getChartModels?.() || [],
            columnState: props.columnApi?.getColumnState?.() || [],
            /* @ts-ignore */
            adaptable: props.api?.__adaptable?.adaptableStore?.currentStorageState || {},
        }),
        save: () => {
            // Ask if the state is generic or which users it should be assigned to
            const generic_promise = new Promise<"cancelled" | (Record<"isGeneric", boolean> & Partial<Record<"users", string[]>>)>(resolve => {
                // Only admin can create generic states or assign a state to someone else
                if (!auth.isAdmin) resolve({ isGeneric: false, users: [auth.userId] });
                // Ask if the state is a generic one
                else M.askConfirm({ title: TC.STATE_SAVER_NEW_STATE_TITLE, text: TC.STATE_SAVER_NEW_IS_GEN, noText: TC.GLOBAL_NO }).then(isGeneric => {
                    // User cancelled
                    if (isGeneric === null) resolve("cancelled");
                    // State is generic
                    else if (isGeneric) resolve({ isGeneric });
                    // State is not generic, choose which user(s) to assign it to
                    else PM.renderMultipleUsersSelect({ includeSelf: true, includeAdmin: auth.isAdmin, required: true, label: TC.USER_GESTION, value: [auth.userId] }).then(users => {
                        if (!users) resolve("cancelled");
                        else resolve({ isGeneric, users });
                    });
                });
            });
            // We now know more about the state
            generic_promise.then(options => {
                // Ask for the name of the state
                if (options !== "cancelled") M.askPrompt({ title: TC.STATE_SAVER_NEW_STATE_TITLE, description: TC.STATE_SAVER_NEW_STATE_TEXT }).then(name => {
                    // Create the state(s)
                    if (name) US.createTableStates({ ...current.get(), name, origin: props.origin }, options.isGeneric, auth.userId, options.users).then(({ data }) => {
                        if (Array.isArray(data)) set_states(p => p.concat(data.map(TB.transformTableState)));
                        else M.Alerts.updateError();
                    }).catch(M.Alerts.updateError);
                });
            });
        },
        reset: () => {
            /* @ts-ignore Reset Adaptable */
            props.api?.__adaptable?.api?.configApi?.reloadPredefinedConfig?.();
            // Remove the charts
            current.dismiss_charts();
            // Reset the columns
            props.columnApi.resetColumnState();
        },
    }), [props.api, props.columnApi, props.origin, auth.isAdmin, auth.userId, set_states]);

    const saved = React.useMemo(() => ({
        delete: (state: T.TableState) => M.askConfirm().then(confirmed => {
            if (confirmed) US.removeTableStateFromId(state._id).then(({ data }) => {
                if (data?.hasFailed) M.Alerts.deleteError();
                else set_states(p => p.filter(s => state._id !== s._id));
            }).catch(M.Alerts.deleteError);
        }),
        update: (state: T.TableState) => M.askConfirm({ text: TC.STATE_SAVER_CONFIRM_UPDATE, title: TC.STATE_SAVER_UPDATE }).then(confirmed => {
            if (confirmed) {
                let updates = current.get();
                US.updateTableStates(state._id, updates).then(({ data }) => {
                    if (!data?.ok) M.Alerts.updateError();
                    else set_states(p => p.map(s => s._id === state._id ? { ...state, ...updates } : s));
                }).catch(M.Alerts.updateError);
            }
        }),
        set: (state: T.TableState) => {
            // Remove charts that may be left
            current.dismiss_charts();
            /* @ts-ignore Set the adaptable state */
            if (Object.keys(state.adaptable).length > 0) props.api?.__adaptable?.api?.configApi?.reloadPredefinedConfig?.(state.adaptable);
            /* @ts-ignore Tell Adaptable to reset */
            else props.api?.__adaptable?.api?.configApi?.reloadPredefinedConfig?.();
            props.api.setFilterModel(state.filters);
            props.columnApi.applyColumnState({ state: state.columnState, applyOrder: true });
            state.chartModels.forEach(chart => props.api.restoreChart(chart));
        },
        to_default: (state: T.TableState) => {
            US.setTableStatesDefault(state._id).then(() => {
                // Remove this state as default
                if (state.default_users && state.default_users.includes(auth.userId)) set_states(p => p.map(ts => {
                    if (ts._id !== state._id) return ts;
                    return { ...ts, default_users: ts.default_users.filter(u => u !== auth.userId) };
                }));
                // Set this state as the default
                else set_states(p => p.map(ts => {
                    if (ts._id === state._id) return { ...ts, default_users: (ts.default_users || []).concat(auth.userId) };
                    else if (ts.default_users && ts.default_users.includes(auth.userId)) return { ...ts, default_users: ts.default_users.filter(u => u !== auth.userId) };
                    else return ts;
                }));
            }).catch(M.Alerts.updateError);
        },
    }), [auth.userId, current, props.api, props.columnApi, set_states]);
    //#endregion

    //#region Load the states
    React.useEffect(() => {
        if (!set_default.value && status === "done") {
            let default_state = states.filter(d => d.default_users && d.default_users.includes(auth.userId))[0];
            if (default_state) {
                saved.set(default_state);
                M.renderAlert({ type: "info", message: TC.TABLE_STATE_MSG_DEFAULT, title: TC.TABLE_STATE_INSERT_DEFAULT });
            }
            set_default.setTrue();
        }
    }, [auth.userId, saved, set_default, states, status]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (props.origin) US.getTableStatesForOrigin(props.origin).then(({ data }) => {
            if (Array.isArray(data)) set_states(data.map(TB.transformTableState), "done");
            else set_states([], "error");
        }).catch(() => isSubscribed && set_states([], "error"));
        else set_states([], "done");
        return () => {
            isSubscribed = false;
            set_states([], "load");
        }
    }, [props.origin, set_states]);
    //#endregion

    return <C.Spinner status={status}>
        <BS.Stack className="p-2">

            <BS.ButtonGroup>
                <C.Button icon="save" variant="info" onClick={current.save} />
                <C.Button icon="redo" variant="danger" onClick={current.reset} />
            </BS.ButtonGroup>

            <BS.ListGroup className="mt-2">
                {states.map(state => <BS.ListGroupItem className="d-flex justify-content-between w-100" key={state._id}>
                    <div className="pointer" onClick={() => saved.set(state)}>
                        <i className={`fa fa-${state.isGeneric ? "certificate" : "user"} me-2`}></i>
                        {state.name}
                    </div>
                    {(auth.isAdmin || !state.isGeneric) && <C.Flex>
                        <div onClick={() => saved.to_default(state)}>
                            <i className={`fa fa-bookmark pointer me-2 ${(state.default_users || []).includes(auth.userId) ? "text-primary" : ""}`}></i>
                        </div>
                        <div onClick={() => saved.update(state)}>
                            <i className="fa fa-edit pointer me-2"></i>
                        </div>
                        <div onClick={() => saved.delete(state)}>
                            <i className="fa fa-times text-danger pointer"></i>
                        </div>
                    </C.Flex>}
                </BS.ListGroupItem>)}
            </BS.ListGroup>

        </BS.Stack>
    </C.Spinner>;
};

//#region Exports
export const Config: SBT.SideBarTool = {
    id: 'stateSave',
    iconKey: 'stateSaver',
    toolPanel: StateSaver,
    labelKey: 'stateSaver',
    labelDefault: "Configurations",
}
export const Icon = '<i class="fa fa-database"></i>';
//#endregion