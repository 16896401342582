import _ from "lodash";
import React from "react";
import * as M from "../../Modal";
import * as G from "../../Gestion";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import * as PM from "../../../PurposeModal";
import { StyleModalProps, BlankModal } from "../../Modal";
import { T, DS, TC, FP, TB, LT } from "../../../Constants";
import { CellsTypes as CT } from "../../Gestion/AgGridDefs";

export type TagMapProps = {
    /** Callback after the creation of the datasets */
    on_validate: (datasets: T.DataSet[]) => void;
    /** Display the mapper in a popup */
    popup?: boolean;
    /** Callback to cancel the creation of datasets */
    quit?: () => void;
    /** The current context, to find the stations and the elements */
    context: T.ContextParams;
    /** Extra Style to customize the modal */
    modal?: StyleModalProps;
    /** The _id of the station to load the tags from */
    station: string;
}

type TableProps = G.TableProps<Row>;
type Row = ReturnType<T.API.Utils.Energy.TagMapperData>["tags"][number];

const Icons = {
    path: {
        [FP.SITE_FORM]: "city",
        [FP.BUILDING_FORM]: "building",
        [FP.EQUIPEMENT_FORM]: "wrench",
    },
    type: {
        zone: "map-marker-alt",
        floor: "map-marker-alt",
        local: "map-marker-alt",
        parking: "parking",
    } as Record<T.EmplacementData["type"], string>,
};

const TagMap: React.FC<TagMapProps> = ({ on_validate, ...props }) => {
    const lg = H.useLanguage();
    const is_saving = H.useBoolean(false);
    const table = React.useRef<G.TableRef<Row>>(null);
    const [added_units, set_units] = React.useState<string[]>([]);
    const errors = React.useRef<Record<string, (keyof typeof rows[number])[]>>({});
    const [infos, set_infos, status] = H.useAsyncState<ReturnType<T.API.Utils.Energy.TagMapperData>>({ tags: [], elements: [] });

    React.useEffect(() => {
        let isSubscribed = true;
        S.tagMapperData({ context: props.context, station: props.station })
            .then(({ data }) => {
                if (isSubscribed) {
                    set_infos(data, "done");
                    // Set the default units
                    let other_units = _.uniq(data.tags.filter(t => t.type === "OTHER").map(t => t.unit).filter(TB.validString));
                    set_units(other_units);
                }
            })
            .catch(() => isSubscribed && set_infos({ elements: [], tags: [] }, "error"));
        return () => {
            isSubscribed = false;
            set_infos({ elements: [], tags: [] }, "load");
        }
    }, [props.station, props.context, set_infos]);

    const rows = React.useMemo(() => infos.tags.map(tag => ({
        ...tag,
        tr_type: tag.type ? lg.getStaticText(DS.DATASETS.type.filter(t => t.value === tag.type)[0]?.label) : "",
        tr_agg: tag.aggregate ? lg.getStaticText(DS.DATASETS.aggregate.filter(t => t.value === tag.aggregate)[0]?.label) : "",
        tr_normalized: tag.normalized ? lg.getStaticText(DS.DATASETS.normalized.filter(t => t.value === tag.normalized)[0]?.label) : "",
    })), [lg, infos.tags]);

    const options = React.useMemo(() => ({
        unit: (row?: Row) => {
            if (!row) return [];
            let available_units = DS.DATASETS.type.filter(t => t.value === row.type)[0]?.unit
            // Dataset type 'other', offers the created units as options
            if (row.type) return added_units.map(u => ({ label: u, value: u }));
            else if (Array.isArray(available_units)) return available_units.map(u => ({ label: u, value: u }));
            else if (typeof available_units === "string") return [{ label: available_units, value: available_units }];
            else return [{ label: "", value: "" }];
        },
        add_unit: (text: string, row: Row) => {
            // Add this tag to the list of created units
            set_units(u => _.uniq(u.concat(text)));
            // Update this dataset to create this tag
            set_infos(p => ({ ...p, tags: p.tags.map(tag => tag.tag_id === row.tag_id ? { ...tag, unit: text } : tag) }));
            // Return the new option
            return { label: text, value: text };
        },
        add_origin: ((text, row) => {
            let select_params = {
                isRequired: true,
                defaultVal: FP.EQUIPEMENT_FORM,
                title: TC.TAG_MAPPER_PATH_SELECT,
                options: [FP.EQUIPEMENT_FORM, FP.EMPLACEMENT_FORM].map(v => ({ label: v, value: v })),
            } as Parameters<typeof M.askSelect>[0];
            // Ask wether to create an equipment or an emplacement
            M.askSelect(select_params).then(path => {
                // Select a site to pick the parent from
                if (path) PM.renderSiteSelect({ context: props.context, isRequired: true }).then(root => {
                    // Pick a parent
                    if (root) M.renderLightTree({ root, selection: row.origin, restrictOwnLinks: true, linkRestriction: {}, style: { className: "z-index-higher-modal" } }).then(parent => {
                        // Create the new element
                        if (parent) M.renderFormModal<Record<"name", string>>({ path, forcedSubmission: TB.submissionToArrayUpdate({ name: text }) }).then(element => {
                            // Link the element to a parent
                            if (element) S.attachNode({ children: element._id, parent, type: LT.LINK_TYPE_OWN }).then(() => {
                                // Load the element to be added in the list of options
                                S.getContextOptions({ add_all_parents: true, context: { roots: element._id } }).then(({ data }) => {
                                    // Update the local state
                                    set_infos(p => ({
                                        ...p,
                                        // Add the new element to the list of elements
                                        elements: _.uniqBy(p.elements.concat(data), "value"),
                                        // Update the tags with the new origin
                                        tags: p.tags.map(tag => {
                                            if (tag.tag_id !== row.tag_id) return tag;
                                            else return { ...row, origin: element._id, origin_name: element.data.name };
                                        })
                                    }));
                                }).catch(M.Alerts.loadError);
                            }).catch(M.Alerts.updateError);
                        });
                    });
                });
            });
        }) as G.ColDefParams<Row>["typeahead"]["onAddOption"],
        render_origin_option: ((option: typeof infos["elements"][number]) => <div>
            <i className={`me-2 fa fa-${Icons.type[option?.emplacement] || Icons.path[option?.type]}`}></i>
            <span>{option.label}</span>
            {option?.full_path?.length > 0 && <span className="text-muted ms-2">({option.full_path.join(" -> ")})</span>}
        </div>) as G.ColDefParams<Row>["typeahead"]["renderItem"],
    }), [props.context, added_units, set_infos]);

    const get_cell_class = React.useCallback<Exclude<G.ColDef<Row>["cellClass"], string | string[]>>(row => {
        let errors_fields: string[] = errors.current[row?.data?.tag_id] || [];
        if (errors_fields.includes(row.colDef?.field)) return "bg-danger border border-white border-2";
    }, [errors]);

    const columns = React.useMemo<TableProps["columns"]>(() => [
        { field: "tag_name", headerName: TC.DATASET_TAG, editable: false },
        {
            field: "origin_name",
            type: CT.TYPE_SELECT,
            cellClass: get_cell_class,
            headerName: TC.DATASET_ORIGIN,
            params: {
                auto_fit: true,
                values: infos.elements,
                typeahead: { allowNewOption: true, renderItem: options.render_origin_option, onAddOption: options.add_origin },
            }
        },
        { field: "name", headerName: TC.DATASET_NAME, cellClass: get_cell_class },
        {
            field: "tr_type",
            type: CT.TYPE_SELECT,
            cellClass: get_cell_class,
            headerName: TC.DATASET_TYPE,
            params: { values: DS.DATASETS.type },
        },
        {
            field: "unit",
            type: CT.TYPE_SELECT,
            cellClass: get_cell_class,
            headerName: TC.DATASET_UNIT,
            params: { getValues: options.unit, typeahead: { allowNewOption: true, onAddOption: options.add_unit } },
        },
        { field: "cumulated", headerName: TC.DATASET_CUMULATED, type: CT.TYPE_CHECKBOX, cellClass: get_cell_class, params: { distinctFalseFromEmpty: true } },
        {
            field: "tr_agg",
            type: CT.TYPE_SELECT,
            cellClass: get_cell_class,
            headerName: TC.DATASET_AGGREGATION,
            params: { values: DS.DATASETS.aggregate },
        },
        {
            type: CT.TYPE_SELECT,
            field: "tr_normalized",
            cellClass: get_cell_class,
            headerName: TC.DATASET_NORMALIZED,
            params: { values: DS.DATASETS.normalized },
        },
    ], [options, infos.elements, get_cell_class]);

    const events = React.useMemo(() => ({
        change_field: (params => {
            let row = params.data;
            let field = params.colDef.field as keyof typeof rows[number];
            // This field can't be edited
            if (field === "tag_name") M.Alerts.updateError();
            // Manage the desired
            else {
                let prop: keyof Row;
                // Set the right prop
                if (field === "tr_agg") prop = "aggregate";
                else if (field === "tr_type") prop = "type";
                else if (field === "origin_name") prop = "origin";
                else if (field === "tr_normalized") prop = "normalized";
                else prop = field as typeof prop;
                // Other changes might happen, depending on which prop was changed
                let changes = { [prop]: params.newValue } as Partial<Row>;
                // If the type of value was changed, the unit might have to change too
                if (prop === "type") {
                    let units = options.unit({ ...row, type: params.newValue });
                    // The current unit is no longer accepted
                    if (!units.some(u => u.value === row.unit)) changes.unit = units[0]?.value || "";
                }
                // If the origin is changed, fetch the origin name
                else if (prop === "origin") {
                    let origin_name = infos.elements.filter(e => e.value === params.newValue)[0]?.label;
                    changes.origin_name = origin_name;
                }
                // Populate some default values to a row
                if (!row.aggregate && prop !== "aggregate") changes.aggregate = "sum";
                if (!row.normalized && prop !== "normalized") changes.normalized = "false";
                if (typeof row.cumulated !== "boolean" && prop !== "cumulated") changes.cumulated = false;
                // Update the errors
                let updated_props = Object.keys(changes).concat(field);
                errors.current[row.tag_id] = (errors.current[row.tag_id] || []).filter(p => !updated_props.includes(p));
                // Update the rows
                set_infos(p => ({ ...p, tags: p.tags.map(tag => tag.tag_id === row.tag_id ? { ...tag, ...changes } : tag) }));
            }
        }) as TableProps["onValueChange"],
        validate: () => {
            // The list of datasets
            let datasets: T.DataSet[] = [];
            // Check content of each row
            for (let row of infos.tags) {
                let invalid_prop = [] as typeof errors.current[string];
                // Test 6 properties
                if (!row.type) invalid_prop.push("tr_type");
                if (!row.aggregate) invalid_prop.push("tr_agg");
                if (!row.normalized) invalid_prop.push("tr_normalized");
                if (!TB.validString(row.name)) invalid_prop.push("name");
                if (!TB.mongoIdValidator(row.origin)) invalid_prop.push("origin_name");
                if (typeof row.cumulated !== "boolean") invalid_prop.push("cumulated");
                // There was some errors, but it is not an empty line (6 errors)
                if (invalid_prop.length !== 0 && invalid_prop.length !== 6) errors.current[row.tag_id] = invalid_prop;
                // There was no errors, or it is an empty line
                else {
                    // Remove possible former errors
                    errors.current[row.tag_id] = [];
                    // If not empty line, add the dataset to the list of dataset to create
                    if (invalid_prop.length === 0) datasets.push({
                        _id: row._id,
                        name: row.name,
                        unit: row.unit,
                        type: row.type,
                        origin: row.origin,
                        global: row.global,
                        cumulated: row.cumulated,
                        aggregate: row.aggregate,
                        normalized: row.normalized,
                        src: row.src || "automatic",
                        tag: { id: row.tag_id, station: parseInt(props.station) },
                    });
                }
            }
            // Check if some data was missing
            let active_errors_nb_rows = Object.entries(errors.current).filter(([key, props]) => props.length > 0).length;
            // There was no errors
            if (active_errors_nb_rows === 0) {
                is_saving.setTrue();
                // Create / update the datasets
                S.tagMapperValidate(datasets)
                    .then(({ data }) => on_validate?.(data))
                    .catch(M.Alerts.updateError)
                    .finally(is_saving.setFalse);
            }
            // Redraw the table, to color the cells
            else {
                table.current?.grid?.api?.redrawRows?.();
                M.renderAlert({ type: "warning", message: TC.BR_ERROR_BUILD_DETAILS });
            }
        },
        reset_row: (row: Row) => {
            // Reset the error
            errors.current[row.tag_id] = [];
            // Reset the row
            set_infos(p => ({
                ...p,
                tags: p.tags.map(tag => {
                    if (tag.tag_id === row.tag_id) return { tag_name: tag.tag_name, tag_id: tag.tag_id };
                    else return tag;
                })
            }));
        },
        context_menu: (event => {
            let row = event.node?.data;
            let items = [] as ReturnType<TableProps["getContextMenuItems"]>;
            // Reset a new dataset
            if (row && !TB.mongoIdValidator(row._id)) items.push({
                icon: "<i class='fa fa-times'></i>",
                action: () => events.reset_row(row),
                name: lg.getStaticText(TC.TAG_MAPPER_RESET_ROW),
            });
            return items;
        }) as TableProps["getContextMenuItems"],
    }), [options, infos, is_saving, lg, set_infos, on_validate, props.station]);

    const footer = React.useMemo(() => <C.Flex
        alignItems="center"
        justifyContent="end"
        children={<C.Button
            text={TC.GLOBAL_SAVE}
            onClick={events.validate}
            disabled={is_saving.value}
            icon={{ icon: "save", spin: is_saving.value, spinIcon: "spinner" }}
        />}
    />, [events.validate, is_saving.value]);

    return React.createElement(
        props.popup ? BlankModal : "div",
        props.popup ? {
            ...props.modal,
            footer,
            onQuit: props.quit,
            disableEscapeKeyDown: true,
            title: props.modal?.title || TC.TAG_MAPPER_TITLE,
            isFullScreen: typeof props.modal?.isFullScreen === "boolean" ? props.modal?.isFullScreen : true,
        } as StyleModalProps : null,
        <C.Spinner status={status} min_load_size="500px">
            <G.Table<Row>
                rows={rows}
                ref={table}
                noBottomPadding
                columns={columns}
                columns_base="all"
                adaptableId="tag_mapper"
                sideBar="filters_columns"
                onValueChange={events.change_field}
                getContextMenuItems={events.context_menu}
                autoFit={React.useMemo<(keyof typeof rows[number])[]>(() => ["tag_name", "cumulated", "unit"], [])}
            />
            {!props.popup && footer}
        </C.Spinner>
    );
}

export default TagMap;