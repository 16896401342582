import _ from "lodash";
import * as S from "../../services";
import { TypeAhead } from "../../Common";
import { T, TB, TC } from "../../Constants";
import { Button, InputGroup } from "react-bootstrap";
import React, { FC, useCallback, useMemo } from "react";
import { askConfirm, renderAlert, renderRegDoc } from "../Modal";
import moment from "moment";

//#region Types
type RegSelectProps = {
    action: string;
    element: string;
    selected?: string;
    options: T.RegDoc[];
    popUpContainer?: string;
    actions: T.Submission<T.RegAction>[];
    onEdited?: (doc: T.RegDoc) => void;
    onCreated?: (doc: T.RegDoc) => void;
    onDeleted?: (docId: string) => void;
};
//#endregion

const RegSelect: FC<RegSelectProps> = ({ selected, popUpContainer, action, actions, element, options, onEdited, onCreated, onDeleted, ...props }) => {

    //#region Options
    const vOptions = useMemo(() => {
        let actionsNames = TB.getArray(actions)
            .filter(a => TB.mongoIdValidator(a?._id) && TB.validString(a?.data?.name))
            .map(a => [a._id, a.data.name]);

        let actionNamesObj = Object.fromEntries(actionsNames);

        return TB.getArray(options)
            .filter(rd => TB.ObjectIdStringify(actionNamesObj[rd.regAction]) && rd.regAction === action)
            .map(rd => ({ label: `${actionNamesObj[rd.regAction]} (${moment(rd.last_control).format("DD/MM/YY")})`, value: rd._id }))
    }, [options, actions, action]);

    const selectedOptions = useMemo(() => vOptions.filter(o => o.value === selected), [vOptions, selected]);
    //#endregion

    //#region Callbacks
    const changeDoc = useCallback((create = false) => {

        let params = { action, elemId: element } as Parameters<typeof renderRegDoc>[0];
        // Create new doc
        if (create) params.forceNewDoc = true;
        // Edit current doc
        else params.regFormId = selected;

        renderRegDoc(params).then(results => {
            if (results !== null) {
                if (results.action === "deleted") onDeleted?.(results.doc?._id);
                else (create || results.doc._id !== selected ? onCreated : onEdited)?.(results.doc);
            }
        });
    }, [action, element, selected, onEdited, onCreated, onDeleted]);

    const deleteDoc = useCallback(() => {
        askConfirm().then(confirmed => {
            if (confirmed) S.removeRegForm(selected).then(() => onDeleted?.(selected))
                .catch(() => renderAlert({ type: "error", message: TC.GLOBAL_ERROR_DELETE }));
        });
    }, [selected, onDeleted]);

    const onSelectDoc = useCallback((docId?: string) => {
        if (!TB.mongoIdValidator(docId)) onDeleted?.(selected);
        else {
            let doc = _.find(options, o => o._id === docId);
            if (doc) onEdited?.(doc);
        }
    }, [onDeleted, onEdited, options, selected]);
    //#endregion

    return <InputGroup>
        <TypeAhead
            options={vOptions}
            selectedItems={selected}
            onChange={opt => onSelectDoc(opt[0]?.value)}
        />

        {selectedOptions.length > 0 && <>
            <Button onClick={() => changeDoc()} size="sm" variant="info">
                <i className="fa fa-edit"></i>
            </Button>
            <Button onClick={deleteDoc} size="sm" variant="danger">
                <i className="fa fa-times"></i>
            </Button>
        </>}
        {selectedOptions.length === 0 && <Button onClick={() => changeDoc(true)} size="sm">
            <i className="fa fa-plus"></i>
        </Button>}
    </InputGroup>
}

export default RegSelect;