import React from "react";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";

export type EquipmentIndicatorsProps = {
    /** The id of the asset evaluated */
    root: string;
    /** The id of the current mission */
    mission?: string;
    /** Show an extra graph */
    show_extra_graph?: boolean;
}

const EquipmentIndicators: React.FC<EquipmentIndicatorsProps> = props => {
    const lg = H.useLanguage();
    const [html, set_html, status] = H.useAsyncState("");

    React.useEffect(() => {
        let isSubscribed = true;
        let extra_graph = props.show_extra_graph ? "extra" : "";

        S.html_report(({ template: "CDM", params: { asset: props.root, mission: props.mission, lang: lg.prop, extra_graph } }))
            .then(({ data }) => isSubscribed && set_html(data, "done"))
            .catch(() => isSubscribed && set_html("", "error"));
        return () => {
            isSubscribed = false;
            set_html("", "load");
        }
    }, [set_html, props.root, props.mission, lg.prop, props.show_extra_graph]);

    return <C.Spinner status={status} min_load_size="20vh">
        <div className="bg-white p-2 rounded">
            <C.HtmlText html={html} />
        </div>
    </C.Spinner>;
};

export default EquipmentIndicators;