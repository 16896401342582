import _ from 'lodash';
import { T, TB, TC } from "../../Constants";
import { useDark, useFavorite, useLanguage, useOnClickOutside } from '../../hooks';
import React, { FC, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";

//#region Types
export type SearchModuleApi = {
    clearSearches: () => void;
}

type SearchModuleProps = {
    origin?: string;
    itemList: any[];
    testArray?: boolean;
    minimalView?: boolean;
    allowGrouping?: boolean;
    allowFavorites?: boolean;
    searchList: SearchList[];
    onGrouping?: (groups: string[]) => void;
    refObj?: MutableRefObject<SearchModuleApi>;
    onSelect?: (ids: string[], obj: NormalizedValueObj[]) => void;
}

type NormalizedValueObj = Record<string, string | string[] | null>;
export type SearchList = { label: string, property: string, noGroup?: boolean, enterSearch?: boolean };
export type AppliedFilters = { isFilter?: boolean, property: string, label: string, search?: string, isStrict?: boolean };
//#endregion

//#region Constants
const normalizeObject = (i: T.AnyObject, lg: number, useArray?: boolean): Record<string, string | string[] | null> => {
    let tmp = Object.entries(i).map(([key, val]) => [key, (key === "id" ? val : TB.normalizedString(val, lg, true, null, useArray))]);
    return Object.fromEntries(tmp);
}

const TEXT_CODES = [TC.GLOBAL_SEARCH_PLACEHOLDER, TC.GLOBAL_NO_DATA_AVAILABLE, TC.SM_LOOK_IN];
const isSearchList = (sl: any): sl is SearchList => TB.validString(sl?.property) && typeof sl?.label === "string" && sl.property !== "id";
//#endregion

const SearchModule: FC<SearchModuleProps> = ({
    refObj, searchList, itemList, onSelect, onGrouping, origin,
    testArray = false, minimalView = false, allowGrouping = false, allowFavorites = true
}) => {
    const isDark = useDark();
    const [openMenu, setOpenMenu] = useState(false);
    const [searchString, setSearchString] = useState('');
    const [openedCat, setOpenedCat] = useState<string[]>([]);
    const containerRef = useRef<null | HTMLDivElement>(null);
    useOnClickOutside(containerRef, () => setOpenMenu(false));
    const [appliedFilters, setAppliedFilters] = useState<AppliedFilters[]>([]);
    const { getStaticElem, getStaticText, fetchStaticTranslations, language } = useLanguage(TEXT_CODES);
    const [{ dropdown }, { setFilters }] = useFavorite<AppliedFilters[]>({ origin: origin || "", applyFav: setAppliedFilters });

    //#region Normalization
    const normalizedSearch = useMemo(() => TB.getString(TB.normalizedString(searchString, language)), [searchString, language]);
    const checkSimilar = useCallback((str1: string, str2: string) => [str1, str2].every(TB.validString) && TB.checkStringSimilarity(str1, str2) >= 0.5, []);
    const checkIncludes = useCallback((str1: string, str2: string) => [str1, str2].every(TB.validString) && str2.toLowerCase().includes(str1.toLowerCase()), []);
    //#endregion

    //#region Data memo
    const filterNoGrouping = useMemo(() => appliedFilters.filter(af => af.isFilter), [appliedFilters]);
    const purgedSearchList = useMemo(() => TB.getArray(searchList).filter(isSearchList), [searchList]);
    const normalizedValues = useMemo(() => TB.getArray(itemList).filter(TB.validObject).map(i => normalizeObject(i, language, testArray)), [itemList, testArray, language]);

    const usableValues = useMemo(() => {
        if (filterNoGrouping.length === 0) return normalizedValues;
        let filteredValues = [...normalizedValues];

        filterNoGrouping.forEach(f => filteredValues = filteredValues.filter(val => {
            let testVal = val?.[f.property] || "";

            const fnArray = f.isStrict ? [checkIncludes] : [checkIncludes, checkSimilar];
            const checker = (str: string) => fnArray.some(fn => fn(TB.getString(f.search), str));

            if (Array.isArray(testVal)) return testVal.some(str => checker(str));
            else return checker(testVal);
        }));

        return filteredValues;
    }, [normalizedValues, filterNoGrouping, checkIncludes, checkSimilar]);
    //#endregion

    //#region Languages
    useEffect(() => {
        let labels = purgedSearchList.map(sl => sl.label).filter(TB.isTextCode);
        if (labels.length > 0) fetchStaticTranslations(labels);
    }, [fetchStaticTranslations, purgedSearchList])
    //#endregion

    //#region Selection
    useEffect(() => onSelect?.(usableValues.map(v => TB.getString(v.id)), usableValues), [usableValues, onSelect]);
    //#endregion

    //#region Grouping
    const groupAbleProperties = useMemo(() => purgedSearchList.filter(sl => !sl.noGroup), [purgedSearchList]);
    const appliedGrouping = useMemo(() => appliedFilters.filter(af => !af.isFilter).map(af => af.property), [appliedFilters]);
    const groupButtonClass = useMemo(() => `dropdown-toggle btn ${minimalView ? "w-100 btn-outline" : "btn"}-secondary`, [minimalView]);
    const onApplyGrouping = useCallback((sl: SearchList) => setAppliedFilters(p => p.concat({ label: sl.label, property: sl.property, isFilter: false })), []);

    useEffect(() => onGrouping?.(appliedGrouping), [appliedGrouping, onGrouping]);

    const groupingButton = useMemo(() => allowGrouping && <div className={`dropdown ${minimalView ? "flex-grow-1" : ""}`}>
        <a title="GROUPING" className={groupButtonClass} role="button" href='/#' id="groupingButton" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fa fa-bars"></i>
        </a>
        <ul className="dropdown-menu" aria-labelledby="groupingButton">
            {groupAbleProperties.filter(g => !appliedGrouping.includes(g.property)).map(g => <li key={g.property}>
                <button onClick={() => onApplyGrouping(g)} className="dropdown-item">{g.label}</button>
            </li>)}
        </ul>
    </div>, [groupAbleProperties, appliedGrouping, groupButtonClass, onApplyGrouping, allowGrouping, minimalView]);
    //#endregion

    //#region Tree based on the search
    const emptyVal = useMemo(() => <span className="search" style={{ fontStyle: "italic" }}>none</span>, []);
    const emptySearchList = useMemo(() => purgedSearchList.length === 0 && <div className="noData"><span>{getStaticElem(TC.GLOBAL_NO_DATA_AVAILABLE)}</span></div>, [getStaticElem, purgedSearchList]);

    const sortByName = useCallback((a: { value: string }, b: { value: string }) => {
        if (a.value > b.value) return 1;
        if (b.value > a.value) return -1;
        return 0;
    }, []);

    const filteredValues = useMemo(() => purgedSearchList.map(({ label, property }) => {
        // Keep only the data that match the search
        let values = usableValues.map(({ id, ...nv }) => ({ value: nv?.[property] || "", id }))
            .filter(({ value }) => {
                if (TB.validString(value)) return value.length > 0 && [checkIncludes, checkSimilar].some(f => f(normalizedSearch, value))
                if (Array.isArray(value)) return value.filter(v => TB.validString(v) && [checkIncludes, checkSimilar].some(f => f(normalizedSearch, v)));
                return false;
            })
            .map(({ value, ...r }) => {
                if (Array.isArray(value)) return { ...r, value: value.filter(v => TB.validString(v) && [checkIncludes, checkSimilar].some(f => f(normalizedSearch, v))) };
                return { value, ...r };
            })
            .filter(({ value }) => Array.isArray(value) ? value.length > 0 : true);

        // Group the values to avoid duplicates
        let groupedValues: { value: string, ids: string[] }[] = [];

        if (testArray && values.some(({ value }) => Array.isArray(value))) {
            let valueObj: Record<string, string[]> = {};
            values.forEach(({ value, id }) => {
                let strId = TB.getString(id);
                if (!Array.isArray(value)) value = [value];
                value.forEach(v => {
                    if (!Array.isArray(valueObj[v])) valueObj[v] = [strId];
                    else valueObj[v].push(strId);
                });
            });
            groupedValues = Object.entries(valueObj).map(([value, ids]) => ({ value, ids: _.uniq(ids) })).sort(sortByName);
        }
        else groupedValues = Object.entries(
            _.groupBy(values, "value")).map(([value, array]) => ({ value, ids: array.map(v => TB.getString(v.id)) })
            ).sort(sortByName);

        return { label, property, values: groupedValues };
    }), [checkIncludes, checkSimilar, sortByName, usableValues, purgedSearchList, normalizedSearch, testArray]);

    const toggleCategory = useCallback((property: string) => setOpenedCat(p => {
        if (p.includes(property)) return p.filter(prop => prop !== property);
        else return p.concat(property);
    }), []);

    const applyFilter = useCallback((label: string, property: string, search: string, isStrict = false) => {
        setAppliedFilters(p => p.concat({ label, property, search, isFilter: true, isStrict }));
        // Empty the search and close the category
        setOpenedCat([]);
        setSearchString('');
    }, []);

    const searchListTree = useMemo(() => {
        if ([searchString.length, filteredValues.length].some(l => l === 0)) return null;
        let is_sole_cat = filteredValues.length === 1;

        return filteredValues.map(({ label, property, values }) => <React.Fragment key={property}>
            <div className="propertyContainer">
                {!is_sole_cat && <div className="identifier d-flex">
                    <span className="toggler position-absolute ml-2" style={{ "color": isDark ? "grey" : "" }} onClick={() => toggleCategory(property)}>
                        {values.length > 5 && <i className={`fa fa-angle-double-${openedCat.includes(property) ? "down" : "right"}`}></i>}
                        {values.length < 5 && values.length > 0 && <i className={`fa fa-caret-${openedCat.includes(property) ? "down" : "right"}`}></i>}
                    </span>
                    <span className="px-card pt-0 pb-2 fw-medium dropdown-header pointer" onClick={() => applyFilter(label, property, searchString)}>
                        {getStaticElem(TC.SM_LOOK_IN)} : <em className='fw-bold'>{getStaticElem(label)}</em>
                    </span>
                </div>}
                {(openedCat.includes(property) || is_sole_cat) && <div className="valueList">
                    <ul>
                        {values.length > 0 && values.map(({ value }, i) => <li style={{ color: isDark ? "grey" : "" }} key={i}>
                            <span className="valueItem pointer" style={{ color: "rgba(23, 141, 226, 0.705)" }} onClick={() => applyFilter(label, property, value, true)}>
                                {value.length === 0 ? emptyVal : value}
                            </span>
                        </li>)}
                        {values.length === 0 && <li>
                            <span className="valueItem"><em>{getStaticElem(TC.GLOBAL_NO_DATA_AVAILABLE)}</em></span>
                        </li>}
                    </ul>
                </div>}
            </div>
        </React.Fragment>);
    }, [searchString, filteredValues, isDark, emptyVal, openedCat, getStaticElem, toggleCategory, applyFilter]);
    //#endregion

    //#region Clear Search
    const removeFilter = useCallback((index: number) => setAppliedFilters(p => p.filter((f, i) => i !== index)), []);

    const clearSearches = useCallback(() => {
        setOpenedCat([]);
        setSearchString("");
        setAppliedFilters([]);
    }, []);

    useEffect(() => {
        if (TB.validObject(refObj?.current)) refObj.current.clearSearches = clearSearches;
    }, [refObj, clearSearches]);
    //#endregion

    //#region Preferences
    useEffect(() => setFilters(appliedFilters), [appliedFilters, setFilters]);
    const showFavorites = useMemo(() => TB.validString(origin) && allowFavorites, [origin, allowFavorites]);
    //#endregion

    //#region Enter Press
    const enterCategory = useMemo(() => purgedSearchList.filter(s => s.enterSearch)[0] || null, [purgedSearchList]);

    const onPressEnter = useCallback<React.KeyboardEventHandler<HTMLInputElement>>(event => {
        if ((event.key === "Enter" || event.code === "Enter") && enterCategory) applyFilter(enterCategory.label, enterCategory.property, event.currentTarget.value);
    }, [enterCategory, applyFilter]);
    //#endregion

    //#region Display items
    const renderSearchItem = useCallback((item: AppliedFilters, i: number) => {
        let search = TB.getString(item.search);

        if (item.isFilter) return <div key={i} className="filterContainer" style={{ backgroundColor: "none" }} >
            <span><i style={{ color: "white" }} className="fa fa-filter"></i></span>
            <span className="label" >{TB.shortenString(getStaticText(item.label), 15)}</span>
            {search.length === 0 ? emptyVal : <span className="search">{TB.shortenString(search, 10)}</span>}
            <span className="remover" onClick={() => removeFilter(i)}><i className="fa fa-times"></i></span>
        </div>;

        return <div key={i} className="filterContainer" style={{ backgroundColor: "none" }}>
            <span><i style={{ color: "white" }} className="fa fa-bars"></i></span>
            <span className="px-card pt-0 pb-2 label dropdown-header">{TB.shortenString(getStaticText(item.label), 15)}</span>
            <span className="remover" onClick={() => removeFilter(i)}><i className="fa fa-times"></i></span>
        </div>
    }, [emptyVal, removeFilter, getStaticText]);

    /* @ts-ignore */
    const input = useMemo(() => <div className="inputSearch  form-control" style={{ "--custom-background-color": isDark ? "black" : "white" }} onClick={() => setOpenMenu(true)}>
        <div className="filtersList">
            {appliedFilters.map(renderSearchItem)}
            <input
                type="text"
                value={searchString}
                className="searchInputFlex "
                onChange={e => setSearchString(e.target.value)}
                onKeyDown={onPressEnter}
                placeholder={getStaticText(TC.GLOBAL_SEARCH_PLACEHOLDER) + '...'}
                /* @ts-ignore */
                style={{ fontFamily: 'Mulish', "--custom-color": isDark ? "whitesmoke" : "#4c5054" }}
            />
        </div>
        <div className="clearSearches">
            <button onClick={clearSearches}><i className="fa fa-times" style={{ color: "grey" }}></i></button>
        </div>
    </div>, [clearSearches, renderSearchItem, getStaticText, onPressEnter, searchString, appliedFilters, isDark]);
    //#endregion

    //#region View
    const inputView = useMemo(() => {
        if (minimalView) return <div>
            <div className="btn-group w-100">
                {groupingButton}
                {showFavorites && dropdown}
            </div>
            {input}
        </div>

        return <div className={"inputGroup " + (allowGrouping ? "on" : "") + (showFavorites ? " fav" : "")}>
            {input}
            {groupingButton}
            {showFavorites && dropdown}
        </div>
    }, [allowGrouping, dropdown, groupingButton, input, minimalView, showFavorites]);
    //#endregion

    return <div ref={containerRef} className="searchModule">
        {inputView}
        <div hidden={!openMenu || searchString.length === 0} className="dropdown-menu show w-100" onClick={() => setOpenMenu(oldState => oldState)}>
            {searchListTree}
            {emptySearchList}
        </div>
    </div>
}

export default SearchModule;