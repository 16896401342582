import React from "react";
import moment from "moment";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { ButtonGroup } from "react-bootstrap";
import { RIGHTS, T, TB, TC } from "../../../Constants";

//#region Types
type DataEntry = T.API.Utils.Energy.DataEntry;

export type DataChartProps = {
    /** The dataset to get data from */
    dataset: T.DataSet;
    /** Show the form in a popup */
    popup?: boolean;
    /** Props for the modal */
    modalProps?: Omit<M.BlankModalProps, "footer" | "showHeader" | "children">;
}

type Config = {
    /** The time to get data from and to */
    time: T.NRJ.TimeSelection;
}
//#endregion

//#region Constants
const TEXT_CODES = [TC.GLOBAL_CONFIRM_PARAMS, TC.DATASET_COL_TIME, TC.DATASET_COL_VALUE];
//#endregion

const DataChart: React.FC<DataChartProps> = props => {
    const rights = H.useRights();
    const [copy] = H.useClipBoard();
    const [{ userId }] = H.useAuth();
    const lg = H.useLanguage(TEXT_CODES);
    const [reload, set_reload] = React.useState(1);
    const gridRef = React.useRef<C.DataGridRef>(null);
    const [html, setHtml, htmlStatus] = H.useAsyncState("");
    const [items, setItems, status] = H.useAsyncState<DataEntry[]>([]);
    const [config, setConfig] = React.useState<Config>({ time: { interval: "15 DAY" } });
    const [{ dropdown, currentFilters }, { setFilters }] = H.useFavorite<Config>({ origin: "datasets_charts", variant: "info" });

    //#region Load items
    const time = React.useMemo(() => TB.getFromToUnix(config.time), [config.time]);

    const strTime = React.useMemo(() => ({
        to: new Date(time.to).toISOString(),
        from: new Date(time.from).toISOString(),
    }), [time])

    React.useEffect(() => {
        let isSubscribed = true;
        if (props.dataset.src === "calculated") setItems([], "done");
        else {
            // Set the items as loading
            setItems([], "load");
            S.getEntries({ dataset: props.dataset._id, unix: time })
                .then(({ data }) => isSubscribed && setItems(data, "done"))
                .catch(() => isSubscribed && setItems([], "error"));
        }
        return () => { isSubscribed = false };
    }, [setItems, props.dataset.src, props.dataset._id, reload, time]);

    React.useEffect(() => {
        let isSubscribed = true;

        let params = {
            ...strTime,
            dataGraph: 1,
            indexGraph: 0,
            lang: lg.prop,
            heatmapGraph: 1,
            entity: props.dataset._id,
        };

        setHtml("", "load");
        S.html_report({ template: "CompteurOK", params })
            .then(({ data }) => isSubscribed && setHtml(data, "done"))
            .catch(() => isSubscribed && setHtml("", "error"));

        return () => { isSubscribed = false };
    }, [setHtml, strTime, lg.prop, props.dataset._id, reload]);
    //#endregion

    //#region Favorite
    React.useEffect(() => setFilters(config), [config, setFilters]);
    React.useEffect(() => currentFilters && setConfig(currentFilters), [currentFilters]);
    //#endregion

    //#region rights & Current User
    const allowed = React.useMemo(() => ({
        own: props.dataset.src !== "calculated" && rights.isRightAllowed(RIGHTS.NRJ.WRITE_OWN_DATA, props.dataset?.origin),
        anon: props.dataset.src !== "calculated" && rights.isRightAllowed(RIGHTS.NRJ.WRITE_ANON_DATA, props.dataset?.origin),
        other: props.dataset.src !== "calculated" && rights.isRightAllowed(RIGHTS.NRJ.WRITE_OTHER_DATA, props.dataset?.origin),
    }), [rights, props.dataset?.origin, props.dataset?.src]);

    const canEditRow = React.useCallback((row: typeof items[number]) => {
        // Cannot edit calculated data
        if (props.dataset.src === "calculated") return false;
        let isAnon = !TB.mongoIdValidator(row.owner);
        let isOwn = TB.mongoIdValidator(row.owner) && row.owner === userId;
        return (isOwn && allowed.own) || (isAnon && allowed.anon) || (!isOwn && allowed.other);
    }, [allowed, userId, props.dataset.src]);
    //#endregion

    //#region Config
    const configBar = React.useMemo(() => <C.Flex alignItems="center" justifyContent="between">
        <div>
            <C.TimeSelector
                to={config.time.to}
                from={config.time.from}
                interval={config.time.interval}
                onChangeDatePicker={time => setConfig(p => ({ ...p, time }))}
                onChangeInterval={interval => setConfig(p => ({ ...p, time: { interval } }))}
            />
        </div>
        <div>
            {dropdown}
        </div>
    </C.Flex>, [config, dropdown]);
    //#endregion

    //#region Charts
    const charts = React.useMemo(() => <div className="p-2 border rounded my-2">
        <C.Spinner status={htmlStatus}>
            <C.HtmlText html={html} />
        </C.Spinner>
    </div>, [htmlStatus, html]);
    //#endregion

    //#region Table
    const importManualData = React.useCallback(() => {
        const params: Parameters<typeof M.renderExcelMapper>[0] = {
            raw: true,
            invert: true,
            allowIgnoring: true,
            allowSwapping: false,
            title: lg.getStaticText(TC.EXCEL_DATA_IMPORT_WITH_UNIT, props.dataset.unit || TC.EXCEL_DATA_IMPORT_NO_UNIT),
            columnFormat: {
                time: {
                    type: "date",
                    required: true,
                    no_convert_date: true,
                    label: lg.getStaticText(TC.DATASET_COL_TIME),
                },
                value: {
                    type: "number",
                    required: true,
                    label: lg.getStaticText(TC.DATASET_COL_VALUE),
                }
            },
        };

        M.renderExcelMapper(params).then(results => {
            if (Array.isArray(results)) {
                let entries = results as Record<"time" | "value", string>[];
                let times = entries.map(e => e.time);

                if (entries.length === 0) M.renderAlert({ type: "warning", message: TC.DATASET_WARNING_EMPTY_IMPORT });
                else {
                    let format_promise = new Promise<"cancel" | { value: number, time: string }[]>(resolve => {
                        if (times.some((t: any) => !(t instanceof Date))) M.askTimeFormat({ examples: times }).then(format => {
                            if (format) {
                                let parsedEntries = entries.map(e => ({
                                    value: TB.getNumber(e.value),
                                    time: TB.parseDate(e.time, format.format, format.timezone, format.timezone_data)?.toISOString?.(),
                                }));
                                resolve(parsedEntries);
                            }
                            else resolve("cancel");
                        });
                        else resolve(entries.map(e => ({ value: TB.getNumber(e.value), time: (e.time as any)?.toISOString?.() || null })));
                    });

                    format_promise.then(parsedEntries => {
                        if (parsedEntries !== "cancel") {
                            // Start the grid loading
                            gridRef.current?.loading?.setTrue?.();

                            S.importRecords({ dataset: props.dataset._id, entries: parsedEntries })
                                .then(({ data }) => {
                                    // Tell the user some errors occurred
                                    if (data.failed.length > 0) {
                                        // Show Error message
                                        M.renderAlert({ type: "warning", message: TC.FAIL_DATA_IMPORT });
                                        // User download a file of the values that were not imported
                                        TB.jsonToExcel(data.failed, "IMPORT_FAIL", "sheet");
                                    }
                                    // Tell the users data was inserted, but there were some duplicates
                                    if (data.duplicates.length > 0) {
                                        copy(data.duplicates.join("\n"));
                                        M.renderAlert({ type: "warning", message: TC.FAIL_DATA_IMPORT_DUPLICATE });
                                        M.renderBlankModal({
                                            size: "md",
                                            title: TC.DATA_INSERT_DUPLICATES,
                                            children: <>{data.duplicates.map((d, i) => <div key={i}>{d}</div>)}</>,
                                        });
                                    }
                                    if (data.results.length > 0) setItems(p => p.concat(data.results));
                                })
                                .catch(M.Alerts.updateError)
                                // Stop the grid loading
                                .finally(gridRef.current?.loading?.setFalse);
                        }
                    });
                }
            }
        });
    }, [props.dataset._id, props.dataset.unit, lg, setItems, copy]);

    const auto_correct = React.useMemo(() => ({
        allow: props.dataset.src === "automatic" && allowed.anon && props.dataset?.tag?.id && props.dataset?.tag?.station,
        execute: () => {
            let pairs = [{ station: props.dataset.tag.station, tag: props.dataset.tag?.id }];
            M.askDataCorrect({ dataset_name: props.dataset.name, pairs: { id: props.dataset.tag.id, station: props.dataset.tag.station }, dataset_id: props.dataset._id }).then(response => {
                let params: Parameters<typeof S.assessCorrections>[0] = null;
                if (response === "accept") params = { pairs, state: "accept", dataset: props.dataset._id };
                else params = { pairs, state: "reject", dataset: props.dataset._id };

                let unmount = M.renderLoader();
                S.assessCorrections(params).then(() => {
                    // Force the reload of the data
                    if (params.state !== "reject") set_reload(p => p + 1);
                })
                    .catch(M.Alerts.updateError)
                    .finally(unmount);
            });
        }
    }), [allowed.anon, props.dataset]);

    const entries = React.useMemo(() => ({
        export: () => {
            let to_export = items.map(i => ({ value: i.new_value, time: moment(i.time).format("DD/MM/YY HH:mm:SS") }));
            TB.jsonToExcel(to_export, "Data", "Data");
        },
        delete: (row: typeof items[number]) => {
            M.askConfirm().then(confirmed => {
                if (confirmed) {
                    // Start the grid loading
                    gridRef.current?.loading?.setTrue?.();
                    S.removeDataEntry({ dataset: props.dataset._id, time: row.time })
                        .then(({ data }) => {
                            if (data === "not_found") M.renderAlert({ type: "warning", message: TC.DATA_CHARTS_FAILED_DELETE })
                            else setItems(p => p.map(i => i.id !== row.id ? i : data))
                        })
                        .catch(M.Alerts.deleteError)
                        // Stop the grid loading
                        .finally(gridRef.current?.loading?.setFalse);
                }
            });
        },
        restore: (row: typeof items[number]) => {
            M.askConfirm({ text: TC.DATA_CHARTS_RESTORE_LABEL, title: TC.DATA_CHARTS_RESTORE_TITLE }).then(confirmed => {
                if (confirmed) {
                    // Start the grid loading
                    gridRef.current?.loading?.setTrue?.();
                    S.restoreDataEntry({ dataset: props.dataset._id, time: row.time })
                        .then(({ data }) => {
                            if (data === "overwritten") M.renderAlert({ type: "warning", message: TC.DATASET_WARNING_OVERWRITTEN });
                            else setItems(p => p.map(i => i.id !== row.id ? i : data));
                        })
                        .catch(M.Alerts.updateError)
                        // Stop the grid loading
                        .finally(gridRef.current?.loading?.setFalse);
                }
            });
        },
        add: () => {
            M.renderEntryFormModal({ askDate: true, dataset: props.dataset._id }).then(entry => {
                if (entry) {
                    // Start the grid loading
                    gridRef.current?.loading?.setTrue?.();
                    // Send the update
                    S.addManualEntry(entry as any)
                        .then(({ data }) => {
                            if (data === "duplicate") M.renderAlert({ type: "warning", message: TC.DATASET_WARNING_DUPLICATE });
                            else setItems(p => p.concat(data));
                        })
                        // Error happened
                        .catch(M.Alerts.updateError)
                        // Stop the grid loading
                        .finally(gridRef.current?.loading?.setFalse);
                }
            });
        },
        cancel_period: (row: typeof items[number]) => {
            let time_str = moment(row.period_data.from).format("DD/MM/YY HH:mm:SS") + " - " + moment(row.period_data.to).format("DD/MM/YY HH:mm:SS");
            let message = lg.getStaticText(TC.DATA_CORRECT_CANCEL_PERIOD, time_str);

            M.askConfirm({ text: message }).then(confirmed => {
                if (confirmed) {
                    // Start the grid loading
                    gridRef.current?.loading?.setTrue?.();
                    // Send the update
                    S.cancelPeriodUpdate({ dataset: props.dataset._id, period: row.period_data })
                        .then(({ data }) => {
                            if (data === "not_found") M.Alerts.loadError();
                            else S.getEntries({ dataset: props.dataset._id, unix: time })
                                .then(({ data }) => setItems(data, "done"))
                                .catch(() => setItems([], "error"))
                                .finally(gridRef.current?.loading?.setFalse);
                        })
                        // Error happened
                        .catch(e => {
                            M.Alerts.updateError(e);
                            gridRef.current?.loading?.setFalse?.();
                        });
                }
            })
        },
        picture: (picture: string) => M.renderCarousel({ images: [{ src: picture, title: " " }] }),
    }), [setItems, props.dataset._id, items, time, lg]);

    const renderButtons = React.useCallback((params: C.DataGridRenderCellParams<typeof items[number]>) => {
        let row = params.row as typeof items[number];
        let canEdit = canEditRow(row);

        if (!canEdit) return null;
        // Restore a period
        else if (row.period && !row.delete_date) return <C.Button size="sm" icon="trash-restore" variant="info" onClick={() => entries.cancel_period(params.row)} />;
        else if (row.delete_date) return <C.Button size="sm" icon="trash-restore" variant="info" onClick={() => entries.restore(params.row)} />;
        else return <C.Button size="sm" icon="times" variant="danger" onClick={() => entries.delete(params.row)} />;
    }, [canEditRow, entries]);

    const renderImg = React.useCallback((params: C.DataGridRenderCellParams<typeof items[number]>) => {
        if (!params.row.picture) return null;
        return <C.Flex className="w-100 pointer" onClick={() => entries.picture(params.row.picture)} justifyContent="center">
            <img src={params.row.picture} alt="" style={{ width: 15, height: 15, objectFit: "scale-down" }} />
        </C.Flex>;
    }, [entries]);

    const processRowUpdate = React.useCallback<C.DataGridProps<typeof items[number]>["processRowUpdate"]>((newRow, oldRow) => new Promise((resolve, reject) => {
        // Changed the "new_value" field
        if (newRow.new_value !== oldRow.new_value) {
            // Don't inside a period
            if (newRow.period) reject(TC.DATA_CHARTS_EDIT_PERIOD);
            // Don't edit a deleted row
            else if (newRow.delete_date) reject(TC.DATA_CHARTS_EDIT_DELETE);
            // Check user can edit the row
            else if (canEditRow(oldRow)) M.askConfirm({ title: TC.DATA_ENTRY_ASK_NOTE_TITLE, text: TC.DATA_ENTRY_ASK_NOTE_MSG, yesText: TC.GLOBAL_YES, noText: TC.GLOBAL_NO }).then(confirmed => {
                if (confirmed) M.renderEntryFormModal({ dataset: props.dataset._id, value: { value: newRow.new_value } }).then(entry => {
                    if (entry) {
                        // Start the grid loading
                        gridRef.current?.loading?.setTrue?.();
                        S.editRecordEntry({ time: oldRow.time, value: entry.value, old_value: oldRow.new_value, dataset: props.dataset._id, note: entry.note, picture: entry.picture })
                            .then(({ data }) => {
                                setItems(p => p.map(i => i.id === data.id ? data : i));
                                resolve(data);
                            })
                            .catch(error => {
                                M.Alerts.updateError(error);
                                resolve(oldRow);
                            })
                            // Stop the grid loading
                            .finally(gridRef.current?.loading?.setFalse);
                    }
                });
                else {
                    // Start the grid loading
                    gridRef.current?.loading?.setTrue?.();
                    S.editRecordEntry({ time: oldRow.time, value: newRow.new_value, old_value: oldRow.new_value, dataset: props.dataset._id })
                        .then(({ data }) => {
                            setItems(p => p.map(i => i.id === data.id ? data : i));
                            resolve(data);
                        })
                        .catch(error => {
                            M.Alerts.updateError(error);
                            resolve(oldRow);
                        })
                        // Stop the grid loading
                        .finally(gridRef.current?.loading?.setFalse);
                }
            });
            else resolve(oldRow);
        }
        else resolve(oldRow);
    }), [setItems, canEditRow, props.dataset._id]);

    const columns = React.useMemo<C.DataGridProps["columns"]>(() => [
        {
            flex: 1.1,
            field: "time",
            type: "dateTime",
            headerName: TC.DATASET_COL_TIME,
            editable: props.dataset.src === "manual",
            valueGetter: (v: any) => TB.getDate(v.value),
        },
        {
            flex: 1,
            type: "number",
            sortable: false,
            field: "old_value",
            headerName: TC.DATASET_COL_OLD_VAL,
        },
        {
            flex: 1,
            type: "number",
            field: "new_value",
            headerName: TC.DATASET_COL_NEW_VAL,
            editable: props.dataset.src !== "calculated",
        },
        {
            flex: 1,
            editable: false,
            field: "aggregate_label",
            headerName: TC.DATASET_AGGREGATION,
        },
        {
            flex: 1,
            sortable: false,
            type: "dateTime",
            field: "edit_date",
            headerName: TC.DATASET_COL_EDIT_DATE,
            valueGetter: (v: any) => TB.getDate(v.value),
        },
        {
            flex: 1,
            sortable: false,
            field: "edit_user",
            headerName: TC.DATASET_COL_EDIT_USER,
        },
        {
            flex: 1,
            sortable: false,
            type: "dateTime",
            field: "delete_date",
            headerName: TC.DATASET_COL_DELETE_DATE,
            valueGetter: (v: any) => TB.getDate(v.value),
        },
        {
            flex: 1,
            sortable: false,
            field: "delete_user",
            headerName: TC.DATASET_COL_DELETE_USER,
        },
        {
            flex: 0.5,
            sortable: false,
            field: "note",
            headerName: TC.DATA_ENTRY_NOTE,
        },
        {
            flex: 0.5,
            sortable: false,
            field: "picture",
            renderCell: renderImg,
            headerName: TC.DATA_ENTRY_PICTURE,
        },

        {
            flex: 0.5,
            headerName: "",
            sortable: false,
            align: "center",
            field: "buttons",
            renderCell: renderButtons
        },
    ], [renderButtons, renderImg, props.dataset.src]);

    const grid_header = React.useMemo<C.DataGridProps["header"]>(() => <ButtonGroup className="p-2">
        {props.dataset.src !== "calculated" && allowed.own && <>
            <C.Button icon="file-import" size="sm" onClick={importManualData} text={TC.DATASET_IMPORT_DATA} />
            <C.Button icon="plus" size="sm" variant="info" onClick={entries.add} text={TC.DATASET_CREATE_DATA} />
        </>}
        <C.Button variant="secondary" size="sm" onClick={entries.export} text={TC.EXPORT} icon="file-excel" />
        {auto_correct.allow && <C.Button icon="robot" size="sm" onClick={auto_correct.execute} text={TC.AUTO_CORRECT_DATA} />}
    </ButtonGroup>, [auto_correct, allowed.own, entries.add, entries.export, importManualData, props.dataset.src]);

    const table = React.useMemo(() => props.dataset.src !== "calculated" && <C.Spinner error={status === "error"}>
        <C.Flex direction="column" style={{ minHeight: "50vh" }}>
            <C.DataGrid
                rows={items}
                ref={gridRef}
                columns={columns}
                header={grid_header}
                loading={status === "load"}
                processRowUpdate={processRowUpdate}
                initialState={{ sorting: { sortModel: [{ field: "time", sort: "desc" }] } }}
            />
        </C.Flex>
    </C.Spinner>, [processRowUpdate, grid_header, columns, items, status, props.dataset.src]);
    //#endregion

    //#region Content
    const content = React.useMemo(() => {
        if (!TB.validObject(props.dataset)) return <C.ErrorBanner type="danger" textCode={TC.DATASET_ERROR_NO_DATASET} />;
        return <>
            {configBar}
            {charts}
            {table}
        </>;
    }, [props.dataset, configBar, charts, table]);
    //#endregion

    return React.createElement(
        props.popup ? M.BlankModal : React.Fragment,
        props.popup ? {
            ...props.modalProps,
            disableEscapeKeyDown: true,
            size: props.modalProps?.size || "md",
            title: props.modalProps?.title || TC.DATASET_CHART_MODAL_TITLE,
            maxBodyHeight: props.modalProps?.isFullScreen ? "" : props.modalProps?.maxBodyHeight || "80vh",
        } as M.BlankModalProps : null,
        content
    );
}

export default DataChart;