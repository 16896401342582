import _ from "lodash";
// import EquipTech from "./EquipTech";
import GenTab, { GenTabApi } from "./GenTab";
import { ErrorBanner, Flex } from "../../Common";
import { useSearchParams } from "react-router-dom";
import EquipArchitecture from "./EquipArchitecture";
import { Tab, Tabs, Button } from "react-bootstrap";
import BuildStruct, { StructTabApi } from "./BuildStruct";
import { SiteBuildRegActions, RegTabAPI } from "../RegDoc";
import { TB, TC, FP, LT, T, RIGHTS } from "../../Constants";
import { uploadNewFile } from "../../Constants/FileToolBox";
import { Loader, renderLoader, renderAlert, askConfirm } from "../Modal";
import { useMemo, useEffect, useState, useCallback, useRef, FC } from "react";
import { useAuth, useCrumbs, useHideUnavailable, useLanguage, useRights } from "../../hooks";
import { createSubmission, deleteLocalFile, getFullResources, removeSubmission, replaceLinkApi, updateSubmission } from "../../services/user.service";

type BuildEditorResource = {
    site?: T.SiteType;
    forms: T.FormType[];
    building?: T.BuildingType;
    icons: T.ReducedFileOptions[];
    countries: { label: string, value: string }[];
    status: "loading" | "loadError" | "ready" | "noRoot" | "invalidRoot" | "invalidBuilding";
}

const TEXT_CODES = [
    TC.CREAT_BUILDING, TC.GLOBAL_ARCHITECTURE, TC.BUILD_EDIT_LABEL, TC.BUILD_EDIT_MISC, TC.BUILD_EDIT_GENERAL, TC.BUILD_EDIT_LOCAL_FLOOR, TC.BUILD_EDIT_ELEM_TECH,
    TC.GLOBAL_SAVE, TC.BR_CREATE_BUILD, TC.BUILD_EDIT_CREATE_FIRST, TC.BUILD_EDIT_NO_ROOT, TC.BUILD_EDIT_INVALID_ROOT, TC.BUILD_EDIT_INVALID_BUILD, TC.GLOBAL_FAILED_LOAD,
    TC.ERR_IMG_UPLOAD, TC.BR_NOTIF_ERROR_CREATION, TC.GLOBAL_ERROR_UPDATE, TC.SCH_SAVE_BEFORE_QUIT, TC.CFQ_TITLE_SAVE_NOTIF, TC.GLOBAL_NO, TC.GLOBAL_YES, TC.CAN_SAVE,
    FP.EQUIPEMENT_FORM,
];

const BuildEditor: FC<{}> = ({ ...props }) => {
    const rights = useRights();
    const [{ user }] = useAuth();
    useCrumbs(TC.BUILD_EDIT_LABEL);
    const hide = useHideUnavailable();
    const regApi = useRef<RegTabAPI>({});
    const genTabApi = useRef<GenTabApi>({});
    const structApi = useRef<StructTabApi>({});
    const { getStaticText } = useLanguage(TEXT_CODES);
    const [activeKey, setActiveKey] = useState("general");
    const [openedTabs, setOpenedTabs] = useState(["general"]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [{ icons, status, forms, building, countries }, setResource] = useState<BuildEditorResource>({ icons: [], status: "loading", forms: [], countries: [] });

    //#region Roots & Resources
    const { buildingId, rootParent } = useMemo(() => {
        let [rootParent, buildingId] = ["root", "building"].map(prop => {
            let val = searchParams.get(prop);
            return TB.mongoIdValidator(val) ? val : null;
        });

        return { rootParent, buildingId };
    }, [searchParams]);

    useEffect(() => {
        let isSubscribed = true;

        if (!TB.mongoIdValidator(rootParent) && !TB.mongoIdValidator(buildingId)) setResource(p => ({ ...p, status: "noRoot" }));
        else getFullResources(FP.BUILDING_FORM, { editor: true, rootParent, buildingId }).then(({ data }) => {
            if (isSubscribed) {
                if (data?.hasFailed || !TB.validString(data?.status)) setResource(p => ({ ...p, status: "loadError" }));
                else setResource(data);
            }
        });

        return () => { isSubscribed = false; }
    }, [rootParent, buildingId]);
    //#endregion

    //#region Forms
    const formArray = useMemo(() => TB.getArray(forms).filter(TB.isValidForm), [forms]);

    const buildingFormId = useMemo(() => formArray.filter(({ path }) => path === FP.BUILDING_FORM)[0]?._id, [formArray]);
    //#endregion

    //#region Action Button
    const handleFiles = useCallback((id: string, toAdd: T.File | null, toDelete: string | null) => new Promise<null | string>((resolve, reject) => {
        console.log({ id, toAdd, toDelete });
        if (toDelete) deleteLocalFile(toDelete);
        if (TB.isFile(toAdd)) uploadNewFile(toAdd.name, toAdd.url, id).then(val => {
            if (TB.validObject(val) && TB.validString(val.url)) {
                let newPic = toAdd;
                newPic.url = val.url;

                const onError = () => {
                    deleteLocalFile(val.url);
                    renderAlert({ type: "error", message: TC.ERR_IMG_UPLOAD });
                    reject(null);
                }

                updateSubmission(id, { "data.picture.0": newPic })
                    .then(({ data }) => {
                        if (!TB.mongoIdValidator(data?._id)) onError();
                        else resolve(val.url);
                    })
                    .catch(onError);
            }
        })
            .catch(() => {
                renderAlert({ type: "error", message: TC.ERR_IMG_UPLOAD });
                reject(null);
            })
        else resolve(null);
    }), []);

    const createBuilding = useCallback(() => {
        if (TB.mongoIdValidator(buildingFormId) && TB.isUser(user)) {
            let resp = genTabApi.current.saveData?.();

            if (resp) {
                let unmount = renderLoader();

                let newBuilding = TB.blankSub(user?._id, buildingFormId);
                newBuilding.data = _.omit(resp.data, "picture");
                /* @ts-ignore */
                newBuilding.data.picture = [];

                createSubmission(newBuilding).then(({ data }) => {
                    let building = data;

                    if (TB.validSubmission(building)) replaceLinkApi(rootParent, building._id, LT.LINK_TYPE_OWN, true).then(({ data }) => {
                        if (data?.insert) {
                            handleFiles(building._id, resp?.toAdd ?? null, resp?.toDelete ?? null)
                                .finally(() => {
                                    unmount();
                                    setSearchParams({ building: building._id }, { replace: true });
                                });
                        }
                        else {
                            removeSubmission(building._id);
                            renderAlert({ type: "error", message: TC.BR_NOTIF_ERROR_CREATION });
                            unmount();
                            setSearchParams({ building: building._id }, { replace: true });
                        }
                    })
                    else {
                        unmount();
                        renderAlert({ type: "error", message: TC.BR_NOTIF_ERROR_CREATION });
                    }
                })
            }
        }
    }, [buildingFormId, rootParent, user, setSearchParams, handleFiles]);

    const updateBuilding = useCallback(() => {
        if (activeKey === "general") {
            if (TB.isBuilding(building)) {
                let resp = genTabApi.current.saveData?.();
                if (resp) {
                    let unmount = renderLoader();
                    let dataBuilding = { ...resp.data };
                    if (resp.toDelete || resp.toAdd) dataBuilding.picture = [];

                    updateSubmission(building?._id, { data: dataBuilding }).then(({ data }) => {
                        if (TB.mongoIdValidator(data?._id)) handleFiles(building._id, resp?.toAdd ?? null, resp?.toDelete ?? null)
                            .then((url) => {
                                if (url !== null && resp && Array.isArray(resp.data.picture)) resp.data.picture[0].url = url;
                            })
                            .finally(() => {
                                unmount();
                                let dataObj = TB.validObject(resp) && TB.validObject(resp.data) ? resp.data : (TB.isBuilding(building) ? building.data : null);
                                setResource(p => ({ ...p, building: dataObj === null || !TB.isBuilding(p.building) ? p.building : { ...p.building, data: dataObj } }));
                            })
                        else {
                            unmount();
                            renderAlert({ type: "error", message: TC.GLOBAL_ERROR_UPDATE });
                        }
                    })
                }
            }
        }
        else if (activeKey === "reg") regApi.current.saveData().catch((error?: string) => TB.isTextCode(error) && renderAlert({ type: "error", message: TC.GLOBAL_ERROR_UPDATE }));
    }, [building, activeKey, handleFiles]);

    const [saveLabel, saveFn] = useMemo(() => {
        if (activeKey === "struct") return [TC.CAN_SAVE, () => structApi.current?.saveData()]
        if (!TB.mongoIdValidator(building?._id)) return [TC.BR_CREATE_BUILD, createBuilding];
        return [TC.GLOBAL_SAVE, updateBuilding];
    }, [building?._id, createBuilding, updateBuilding, activeKey]);

    const dropdownActions = useMemo(() => <Button onClick={saveFn}>
        {getStaticText(saveLabel)}
    </Button>, [saveLabel, getStaticText, saveFn]);
    //#endregion

    //#region Tabs
    const showRegTab = useMemo(() => rights.isRightAllowed(RIGHTS.REG.READ_ELEMENT_REG, building?._id), [rights, building?._id]);

    const disableNonGenTab = useMemo(() => !TB.mongoIdValidator(building?._id), [building?._id]);
    const warningNoBuild = useMemo(() => disableNonGenTab && <ErrorBanner type="info" textCode={TC.BUILD_EDIT_CREATE_FIRST} />, [disableNonGenTab]);

    const tabList = useMemo(() => [
        { title: TC.BUILD_EDIT_GENERAL, eventKey: "general", content: <GenTab api={genTabApi} icons={icons} data={building?.data} countries={countries} />, disabled: false },
        { title: TC.BUILD_EDIT_LOCAL_FLOOR, eventKey: "struct", content: <BuildStruct api={structApi} icons={icons} buildingId={building?._id} />, disabled: disableNonGenTab },
        { title: FP.EQUIPEMENT_FORM/* TC.GLOBAL_ARCHITECTURE */, eventKey: "arch", content: <EquipArchitecture buildingId={building?._id} />, disabled: disableNonGenTab },
        // { title: TC.BUILD_EDIT_ELEM_TECH, eventKey: "tech", content: <EquipTech buildingId={building?._id} />, disabled: disableNonGenTab },
        { title: TC.CRUMB_REG, eventKey: "reg", content: <SiteBuildRegActions api={regApi} roots={building?._id} />, disabled: disableNonGenTab, noAccess: !showRegTab },
    ], [icons, countries, building, disableNonGenTab, showRegTab]);

    useEffect(() => {
        if (!openedTabs.includes(activeKey)) setOpenedTabs(p => p.concat(activeKey));
    }, [openedTabs, activeKey]);

    const onChangeKey = useCallback((key: string | null) => {
        const setKey = () => setActiveKey(TB.validString(key) ? key : "general");

        let confirmProps = { noText: getStaticText(TC.GLOBAL_NO), yesText: getStaticText(TC.GLOBAL_YES), text: getStaticText(TC.SCH_SAVE_BEFORE_QUIT), title: getStaticText(TC.CFQ_TITLE_SAVE_NOTIF) };

        if (key !== null && key !== activeKey) {
            if (activeKey === "general" && genTabApi.current.changesMade?.()) askConfirm(confirmProps).then(confirm => {
                if (confirm) saveFn();
                setKey();
            });
            else if (activeKey === "reg" && regApi.current.changesMade?.()) askConfirm(confirmProps).then(confirm => {
                if (confirm && typeof regApi.current.saveData === "function") regApi.current.saveData()
                    .then((a) => setKey())
                    .catch((error?: string) => TB.isTextCode(error) && renderAlert({ type: "error", message: TC.GLOBAL_ERROR_UPDATE }));
                else {
                    setKey();
                    regApi.current.reset?.();
                }
            });
            else if (activeKey === "struct" && structApi.current.changesMade?.()) askConfirm(confirmProps).then(confirm => {
                if (confirm && typeof structApi.current.saveData === "function") structApi.current.saveData()
                    .then(val => val && setKey())
                    .catch(setKey);
            })
            else setKey();
        }
        else setKey();
    }, [activeKey, getStaticText, saveFn]);

    const tabsProps = useMemo(() => ({ fill: true, activeKey, onSelect: onChangeKey }), [activeKey, onChangeKey]);
    //#endregion

    //#region Error Banner
    const errorBanner = useMemo(() => {
        let code: (string | null) = null;

        if (status === "noRoot") code = TC.BUILD_EDIT_NO_ROOT;
        else if (status === "loadError") code = TC.GLOBAL_FAILED_LOAD;
        else if (status === "invalidRoot") code = TC.BUILD_EDIT_INVALID_ROOT;
        else if (status === "invalidBuilding") code = TC.BUILD_EDIT_INVALID_BUILD;

        if (code !== null) return <ErrorBanner type="danger" textCode={code} />;
        return null;
    }, [status]);
    //#endregion

    return <div className="w-100 flex-grow-1 pb-3">
        {status === "loading" && <Loader />}
        {errorBanner}
        {status === "ready" && <div className="h-100">
            <Flex justifyContent="end" direction="row" alignItems="center" className="mb-2">
                {dropdownActions}
            </Flex>
            <Flex direction="column" className="h-100">
                <Tabs className="build_edit_tabs" {...tabsProps}>
                    {/* @ts-ignore */}
                    {tabList.map(t => !(t.noAccess && hide) && <Tab
                        {...t}
                        key={t.eventKey}
                        className="h-100"
                        eventKey={t.eventKey}
                        title={getStaticText(t.title)}
                        disabled={t.disabled || t.noAccess}
                        tabClassName="bg-white border-x border-top h-100"
                    >
                        {warningNoBuild}
                        {openedTabs.includes(t.eventKey) && t.content}
                    </Tab>)}


                    {/* {tabList.map(({ content, title, eventKey, ...props }) => <Tab
                        className="h-100"
                        tabClassName="bg-white border-x border-top h-100" {...props} title={getStaticText(title)} key={eventKey} eventKey={eventKey}>
                    </Tab>)} */}
                </Tabs>
            </Flex>
        </div>}
    </div>
}

export default BuildEditor;