import { LanguageProps } from "../Types";

//#region Indexes
export const FR = 0;
export const EN = 1;
export const DU = 2;
export const IT = 3;
export const SP = 4;
export const DE = 5;
export const FR_BE = 6;
export const EN_US = 7;
//#endregion

//#region Prop
export const DU_PROP = "nl";
export const IT_PROP = "it";
export const SP_PROP = "es";
export const AR_PROP = "ar";
export const DE_PROP = "de";
export const FR_PROP = "fr_fr";
export const EN_PROP = "en_us";
export const FR_BE_PROP = "fr_be";
export const EN_UK_PROP = "en_uk";

export const ALL_PROPS: LanguageProps[] = [FR_PROP, FR_BE_PROP, EN_UK_PROP, EN_PROP, DU_PROP, DE_PROP, SP_PROP, IT_PROP];
//#endregion

export const MOMENT_LOCAL = (language = 0) => {
    switch (language) {
        case FR: return 'fr';
        case FR_BE: return 'fr';
        case IT: return 'it';
        case DU: return 'nl';
        case SP: return 'es';
        case DE: return 'de';
        case EN: return 'en-gb';
        default: return 'en-gb';
    }
};

export const getHtmlLanguageCode = (language?: LanguageProps | number) => {
    if (typeof language === "number") {
        switch (language) {
            case FR: return 'fr';
            case FR_BE: return 'fr';
            case IT: return 'it';
            case DU: return 'nl';
            case DE: return 'de';
            case SP: return 'es';
            case EN: return 'en';
            default: return 'en';
        }
    }
    else {
        switch (language) {
            case "nl": return "nl";
            case "it": return "it";
            case "es": return "es";
            case "de": return "de";
            case "fr_fr": return "fr";
            case "en_us": return "en";
            case "fr_be": return "fr";
            case "en_uk": return "en";
            default: return "en";
        }
    }
};

export const get_lang_label = (prop: LanguageProps) => ALL_LANGUAGES.filter(l => l.prop === prop)[0]?.lg || "";

export const ALL_LANGUAGES = [
    { lg: "Français (FR)", code: "FR", index: FR, prop: FR_PROP },
    { lg: "Français (BE)", code: "FR-BE", index: FR_BE, prop: FR_BE_PROP },
    { lg: "English", code: "EN", index: EN, prop: EN_UK_PROP },
    { lg: "English (USA)", code: "EN-US", index: EN_US, prop: EN_PROP },
    { lg: "Nederlands", code: "DU", index: DU, prop: DU_PROP },
    { lg: "Italiano", code: "IT", index: IT, prop: IT_PROP },
    { lg: "Español", code: "SP", index: SP, prop: SP_PROP },
    { lg: "Deutsch", code: "DE", index: DE, prop: DE_PROP },
];

export const LANGUAGES_INDEXES = ALL_LANGUAGES.map(l => l.index);