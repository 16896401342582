import _ from "lodash";
import React from "react";
import * as H from "../../hooks";
import { renderLoader } from "./Loader";
import { Form, Button } from "../../Common";
import renderInContainer from "./getContainer";
import BlankModalReduxWrapper from "./BlankModal";
import { TC, T, FP, TB, RIGHTS, LT } from "../../Constants";
import { getFullResources } from "../../services/user.service";

//#region Types
type Resources = {
    forms: T.FormType[];
    linkTypes: T.LinkType[];
    objTypes: T.ObjResourceType[];
}

type ObjTypePickerFnProps = {
    parentForm: string;
    entities?: { name: string, id: string }[];
    /** Restrict to show only the own link type if it's an option */
    only_own?: boolean;
}

type ResultParams = { form: string, path?: string, linkType: string, linkCode?: string, entity?: string };

interface ObjTypePickerProps extends ObjTypePickerFnProps {
    onValidate?: (params: ResultParams) => void;
}
//#endregion

const ObjTypePicker: React.FC<ObjTypePickerProps> = ({ parentForm, entities, onValidate, ...props }) => {
    const rights = H.useRights();
    const formObj = H.useFormIds()[1];
    const [selectedForm, setSelectedForm] = React.useState<string | null>(null);
    const [selectedLink, setSelectedLink] = React.useState<string | null>(null);
    const [{ linkTypes, objTypes, forms }, setResources, loadState] = H.useAsyncState<Resources>({ forms: [], linkTypes: [], objTypes: [] });

    //#region Resources
    React.useEffect(() => {
        let isSubscribed = true;

        getFullResources(FP.OBJ_TYPE_RESOURCE_FORM).then(({ data }) => {
            if (isSubscribed) {
                if (!data?.hasFailed) setResources(data, "done");
                else setResources({ forms: [], linkTypes: [], objTypes: [] }, "error");
            }
        });

        return () => { isSubscribed = false; }
    }, [setResources]);

    React.useEffect(() => {
        if (loadState === "load") {
            let unmount = renderLoader();
            return () => { unmount() };
        }
    }, [loadState]);
    //#endregion

    //#region Filters
    const has_rights = React.useCallback((form: string) => {
        let path = formObj[form];
        let right = RIGHTS.TECH.WRITE_OTHERS;

        if (path === FP.SITE_FORM) right = RIGHTS.TECH.CREATE_SITE;
        else if (path === FP.BUILDING_FORM) right = RIGHTS.TECH.CREATE_BUILDING;
        else if (path === FP.EQUIPEMENT_FORM) right = RIGHTS.TECH.CREATE_EQUIPMENT;
        else if (path === FP.EMPLACEMENT_FORM) right = RIGHTS.TECH.CREATE_EMPLACEMENT;

        return rights.isRightAllowed(right);
    }, [formObj, rights]);

    const own_type_id = React.useMemo(() => linkTypes.filter(lt => lt.data.type === LT.LINK_TYPE_OWN)[0]?._id, [linkTypes]);

    const { possibleOutputs, possibleLinkTypes } = React.useMemo(() => {
        let outputsTypeFilter = parentForm,
            linkTypeFilter = selectedForm || "";

        let outputsAoA = linkTypes.map(lt => ({ _id: lt._id, outputs: lt.data.pairs.filter(p => p.input === outputsTypeFilter).map(lt => lt.output) }));
        let flatArray = _.flatten(outputsAoA).filter(({ outputs }) => outputs.length > 0);
        let possibleOutputs = _.uniq(_.flatten(flatArray.map(({ outputs }) => outputs)).filter(TB.mongoIdValidator))
            .filter(has_rights);

        let possibleLinkTypes = flatArray
            .filter(({ outputs }) => selectedForm === null ? false : outputs.includes(linkTypeFilter))
            .map(({ _id }) => _id).filter(TB.mongoIdValidator);

        let is_own_option = possibleLinkTypes.filter(lt => lt === own_type_id)[0] !== undefined;
        if (props.only_own && is_own_option) possibleLinkTypes = [own_type_id];

        return { possibleOutputs, possibleLinkTypes };
    }, [has_rights, linkTypes, selectedForm, own_type_id, parentForm, props.only_own]);

    React.useEffect(() => {
        //Select a default Form
        if (selectedForm === null && TB.mongoIdValidator(possibleOutputs[0])) setSelectedForm(possibleOutputs[0]);
    }, [selectedForm, possibleOutputs]);

    React.useEffect(() => {
        /* Select a default link type */
        if ((selectedLink === null || !possibleLinkTypes.includes(selectedLink))) {
            if (TB.mongoIdValidator(possibleLinkTypes[0])) setSelectedLink(possibleLinkTypes[0]);
            else setSelectedLink(null);
        }
    }, [selectedLink, possibleLinkTypes]);
    //#endregion

    //#region Options
    const formOptions = React.useMemo(() => objTypes.filter(({ data }) => possibleOutputs.includes(data.form)).map(({ data }) => ({ value: data.form, label: data.type })), [objTypes, possibleOutputs]);
    const linkOptions = React.useMemo(() => linkTypes.filter(({ _id }) => possibleLinkTypes.includes(_id)).map(({ data, _id }) => ({ value: _id, label: data.type, prop: "type" })), [linkTypes, possibleLinkTypes]);
    //#endregion

    //#region Validation
    const allowValidate = React.useMemo(() => TB.multiMongoIdValidator([selectedForm, selectedLink]), [selectedForm, selectedLink]);

    const validate = React.useCallback(() => {
        if (!TB.mongoIdValidator(selectedForm) || !TB.mongoIdValidator(selectedLink)) return;

        let linkCode = _.find(linkTypes, ({ _id }) => _id === selectedLink)?.data?.type;
        let path = _.find(forms, ({ _id }) => _id === selectedForm)?.path;

        onValidate?.({ form: selectedForm, linkType: selectedLink, path, linkCode });
    }, [forms, linkTypes, selectedForm, selectedLink, onValidate]);
    //#endregion

    return <div>
        <Form.Select
            positionFixed
            value={selectedForm}
            options={formOptions}
            label={TC.DIA_OBJ_TYPES}
            onChange={val => setSelectedForm(val || null)}
        />
        <Form.Select
            positionFixed
            value={selectedLink}
            options={linkOptions}
            label={FP.LINK_TYPE_FORM}
            onChange={val => setSelectedLink(val || null)}
        />
        <Button disabled={!allowValidate} onClick={validate} text={TC.GLOBAL_CONFIRM} />
    </div>
}

export default ObjTypePicker;

export const askObjTypePicker = (params?: ObjTypePickerFnProps) => new Promise<ResultParams | null>(resolve => {
    if (TB.validObject(params) && TB.mongoIdValidator(params.parentForm)) {
        const [render, dismount] = renderInContainer();
        if (render && dismount) {
            let onQuit = () => dismount(() => resolve(null));
            let onValidate = (confirmed: ResultParams) => dismount(() => resolve(confirmed));

            render(<BlankModalReduxWrapper title={TC.TREE_CREATE_DESCENDANT} onQuit={onQuit}>
                <ObjTypePicker {...params} onValidate={onValidate} />
            </BlankModalReduxWrapper>);
        }
        else resolve(null);
    }
    else resolve(null);
});