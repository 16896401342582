import React from "react";
import * as I from "../types";

export type Props<A = Record<string, any>> = {
    /** A function that will render the content of the cell */
    render?: (data: A, field: string) => React.ReactElement;
};

export const type = "agCustomFreeRender";

export const Cell = React.forwardRef<{}, I.CellProps<Props>>((props, ref) => {
    return props.colDef?.params?.render?.(props.data, props.colDef?.field) || null;
});