import _ from "lodash";
import moment from "moment";
import { Loader } from "../Modal";
import * as S from "../../services";
import { useSelector } from "react-redux";
import { Row, Col, Card } from "react-bootstrap";
import { TB, T, TABS, TC, FP } from "../../Constants";
import { useAuth, useCrumbs, useLanguage } from "../../hooks";
import { getFullResources } from "../../services/user.service";
import React, { FC, useMemo, useState, useEffect, useCallback } from "react";
import { ErrorBanner, TypeAhead, ContextGrid, Flex, DoughnutChart, BarChart, TimeSelector } from "../../Common";

//#region Types
type Resource = {
    users: { value: string, label: string }[];
    status: "loading" | "ready" | "error" | "noContext";
    locOptions: T.FullOptionsLocationsTyped<T.BuildingType>[];
};

type TimePick = { timeInterval?: string, dateFrom?: string, dateTo?: string };
//#endregion

//#region Constants
const DF_INIT: Resource = { status: "loading", users: [], locOptions: [] };
const TEXT_CODES = [TC.GP_GROUP_DAY, TC.GP_GROUP_WEEK, TC.GP_GROUP_MONTH, TC.GP_GROUP_YEAR];
const getTimeGroup = (g: any): T.API.Graph.TimeGroups => ["day", "week", "month", "year"].includes(g) ? g : "day";
//#endregion

const TicketDashboard: FC<{ rootId?: string | string[], portfolioId?: string }> = ({ rootId, portfolioId }) => {
    useAuth({ tabName: TABS.ACTION_DASHBOARD });
    const { resetCrumbs } = useCrumbs(TC.TAB_DASHBOARD_GMAO);
    const [currentBuild, setCurrentBuild] = useState<string>();
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const { dataContext } = useSelector((redux: T.ReduxSelector) => redux);
    const [timeGroup, setTimeGroup] = useState<T.API.Graph.TimeGroups>("day");
    const { getStaticText, fetchStaticTranslations } = useLanguage(TEXT_CODES);
    const [{ status, users, locOptions }, setResource] = useState<Resource>(DF_INIT);
    const [graphData, setGraph] = useState<T.API.Graph.TicketGraphResults | null | undefined>();
    const [{ timeInterval, dateFrom, dateTo }, setTime] = useState<TimePick>({ timeInterval: "15 DAY" });

    //#region Context & Data Fetch
    const rootsPortfolio = useMemo(() => {
        if (TB.mongoIdValidator(portfolioId)) return { portfolio: portfolioId };
        else if (TB.mongoIdValidator(rootId)) return { roots: [rootId] };
        else if (Array.isArray(rootId) && rootId.length > 0 && TB.multiMongoIdValidator(rootId)) return { roots: rootId };
        else if (TB.mongoIdValidator(dataContext.selectedPortfolio)) return { portfolio: dataContext.selectedPortfolio };
        else return { roots: dataContext.selectedItems };
    }, [dataContext.selectedItems, dataContext.selectedPortfolio, portfolioId, rootId]);

    useEffect(() => {
        let isSubscribed = true;
        const onError = () => setResource({ ...DF_INIT, status: "error" });

        getFullResources(FP.TICKET_FORM, { dashboard: true, ...rootsPortfolio })
            .then(({ data }) => {
                if (isSubscribed) {
                    if (data?.hasFailed) onError();
                    else if (data.locOptions.length === 0) setResource({ ...DF_INIT, status: "noContext" });
                    else {
                        let { contextName, ...resources } = data;
                        setResource({ ...resources, status: "ready" });
                        setCurrentBuild(data.locOptions[0].submission._id);
                    }
                }
            })
            .catch(onError);

        return () => {
            isSubscribed = false;
            resetCrumbs();
        }
    }, [resetCrumbs, rootsPortfolio]);
    //#endregion

    //#region Time
    const timeSelector = useMemo(() => <TimeSelector
        to={dateTo}
        from={dateFrom}
        interval={timeInterval}
        onChangeInterval={timeInterval => setTime({ timeInterval })}
        onChangeDatePicker={({ from, to }) => setTime({ dateFrom: from, dateTo: to })}
    />, [dateFrom, dateTo, timeInterval]);

    const translatedInterval = useMemo(() => {
        if (typeof timeInterval !== "string") return null;
        let [number, time] = timeInterval.split(/\s/g);
        return { number: parseInt(number), time: time.toLowerCase() + 's' };
    }, [timeInterval]);

    const ISOTime = useMemo(() => {
        if ([dateFrom, dateTo].every(date => !isNaN(Date.parse(date || "")))) return { from: dateFrom, to: dateTo };
        if (translatedInterval !== null) return {
            /* @ts-ignore */
            from: moment().subtract(translatedInterval.number, translatedInterval.time).toISOString(),
            to: new Date().toISOString(),
        }
        // Default 2 weeks
        return {
            from: moment().subtract(2, 'w').toISOString(),
            to: new Date().toISOString(),
        }
    }, [translatedInterval, dateFrom, dateTo]);
    //#endregion

    //#region Fetch Graph Data
    useEffect(() => {
        let codes = [];
        if (graphData) codes = _.flatten(
            Object.entries(graphData).map(([key, v]) => {
                let rowLabel = v.graph.map(g => g.label);
                return rowLabel.concat(v.label);
            })
        ).filter(TB.isTextCode);
        if (codes.length > 0) fetchStaticTranslations(codes);
    }, [graphData, fetchStaticTranslations]);

    useEffect(() => {
        let isSubscribed = true;
        if (TB.mongoIdValidator(currentBuild)) S.getTicketsGraph({ roots: currentBuild, ...ISOTime, groupBy: timeGroup, employees: selectedUsers })
            .then(({ data }) => isSubscribed && setGraph(data))
            .catch(() => isSubscribed && setGraph(null));

        return () => { isSubscribed = false };
    }, [currentBuild, ISOTime, timeGroup, selectedUsers]);
    //#endregion

    //#region Buildings
    const buildOptions = useMemo(() => locOptions.map(lo => ({
        value: lo.submission._id,
        label: lo.submission.data.name,
    })), [locOptions]);

    const buildGrid = useMemo(() => <ContextGrid
        roots={buildOptions}
        selected={currentBuild}
        changeSelection={buildId => setCurrentBuild(buildId)}
    />, [buildOptions, currentBuild]);
    //#endregion

    //#region Users
    const userSelect = useMemo(() => <TypeAhead
        multiple
        options={users}
        selectedItems={selectedUsers}
        onChange={opt => setSelectedUsers(opt.map(o => o.value))}
    />, [users, selectedUsers]);
    //#endregion

    //#region TimeGroup
    const timeGroupOptions = useMemo<{ label: string, value: T.API.Graph.TimeGroups }[]>(() => [
        { label: getStaticText(TC.GP_GROUP_DAY), value: "day" },
        { label: getStaticText(TC.GP_GROUP_WEEK), value: "week" },
        { label: getStaticText(TC.GP_GROUP_MONTH), value: "month" },
        { label: getStaticText(TC.GP_GROUP_YEAR), value: "year" },
    ], [getStaticText]);

    const timeGroupSelect = useMemo(() => <TypeAhead
        selectedItems={timeGroup}
        options={timeGroupOptions}
        onChange={opt => setTimeGroup(getTimeGroup(opt[0]?.value))}
    />, [timeGroupOptions, timeGroup]);
    //#endregion

    //#region Error & Loading
    const errorBanner = useMemo(() => {
        if (graphData === undefined || status === "loading") return <Loader />;
        if (graphData === null || status === "error") return <ErrorBanner type="danger" textCode={''} />;
        if (status === "noContext") return <ErrorBanner type="warning" textCode={""} />;
        return null
    }, [status, graphData]);
    //#endregion

    //#region GraphData
    const formatData = useCallback((dataArray: T.API.Graph.GraphData<number>) => TB.getArray(dataArray).map(d => ({
        value: d.data,
        color: d?.color,
        name: getStaticText(d.label),
    })), [getStaticText]);
    //#endregion

    return <div className="w-100">
        {errorBanner}
        {status === "ready" && <>
            {buildGrid}
            <Flex className="my-3 align-items-center">
                <div>{timeSelector}</div>
                <div className="mx-2 flex-grow-1">{userSelect}</div>
                <div>{timeGroupSelect}</div>
            </Flex>
            <Row className="mb-2 g-2">
                <Col md={3}>
                    <Card>
                        <Card.Header>
                            <Card.Title>{getStaticText(graphData?.ticketState?.label)}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <DoughnutChart valuedLegend legend tooltip data={formatData(graphData?.ticketState?.graph)} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card>
                        <Card.Header>
                            <Card.Title>{getStaticText(graphData?.inTimeClosed?.label)}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <DoughnutChart valuedLegend legend tooltip data={formatData(graphData?.inTimeClosed?.graph)} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card>
                        <Card.Header>
                            <Card.Title>{getStaticText(graphData?.closePerUser?.label)}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <DoughnutChart valuedLegend legend tooltip data={formatData(graphData?.closePerUser?.graph)} />
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card>
                        <Card.Header>
                            <Card.Title>{getStaticText(graphData?.openPerUser?.label)}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <DoughnutChart valuedLegend legend tooltip data={formatData(graphData?.openPerUser?.graph)} />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mb-2 g-1">
                <Col>
                    <Card>
                        <Card.Header>
                            <Card.Title>{getStaticText(graphData?.evolution?.label)}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <BarChart
                                legend
                                tooltip
                                categories={graphData?.evolution?.categories}
                                data={TB.getArray(graphData?.evolution?.graph).map(g => ({ name: getStaticText(g.label), values: g.data, color: g.color }))}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>}
    </div>;
}

export default TicketDashboard;