import _ from "lodash";
import React from "react";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as ES from "./ElemStyles";
import * as S from "../../services";
import { FamDiagram } from 'basicprimitivesreact';
import { FP, LT, T, TB, TC } from "../../Constants";
import * as BP from "./PropsPrimitiveDiagrams.json";

//#region Types
export type LightTreeProps = {
    /** The root of the tree to display */
    root: string,
    /** Pre-select a node */
    selection?: string;
    /** A list of submission type to not show in the tree */
    hideTypes?: T.AllowArray<string>;
    /** Show the light tree in a modal ? */
    modal?: boolean;
    /** Modal style props */
    style?: M.StyleModalProps,
    /** Use only own link types in the tree */
    restrictOwnLinks?: boolean,
    /** Callback for a node's selection */
    onValidate?: (node: string) => void,
    /** Callback for the cancelation / modal exit */
    onClose?: () => void,
    /** The height of the tree, in pixels. Default is 650 */
    height?: number;
    /** Type of submission (id or path) the selection must be, takes priority over 'linkRestriction' */
    limitedForms?: T.AllowArray<string>;
    /** Restrictions on the node that can be selected, priority given to 'limitedForms' */
    linkRestriction?: {
        /** Do not allow to select a child of the pre-selected node */
        no_children?: boolean;
        /** Nodes ids to automatically reject */
        excludeIds?: T.AllowArray<string>;
        /** The type of the other submission in the link, to filter the valid pairs */
        objForm?: T.AllowArray<string>;
        /** The type(s) of links (ids or paths) expected to be created */
        linkType?: T.AllowArray<string>;
        /** Will the selected node be an input or an output, used for link verifications */
        isInput?: boolean;
    };
};

export type LightTreeRef = {};
type Dia = Awaited<ReturnType<typeof S.getDia>>["data"];
//#endregion

//#region Constants
const TEMPLATE = ES.TemplatesOptions.Slide;
const INIT: Dia = { contextName: "", nodes: [], links: [], linkTypes: [], roots: [] };

const CONFIG = {
    linesWidth: 2,
    name: "Diagram",
    dotLevelShift: 20,
    lineLevelShift: 20,
    dotItemsInterval: 30,
    normalLevelShift: 35,
    normalItemsInterval: 10,
    showExtraArrows: false,
    minimumVisibleLevels: 1,
    linesColor: BP.Colors.Black,
    hideGrandParentsConnectors: false,
    minimalVisibility: BP.Visibility.Dot,
    pageFitMode: BP.PageFitMode.FitToPage,
    hasSelectorCheckbox: BP.Enabled.False,
    orientationType: BP.OrientationType.Top,
    arrowsDirection: BP.GroupByType.Children,
    labelPlacement: BP.PlacementType.BottomLeft,
    selectionPathMode: BP.SelectionPathMode.FullStack,
    labelOrientation: BP.TextOrientationType.RotateLeft,
}
//#endregion

/** 
 * A simplified version of the main tree, whose purpose is to pick a node and return it's _id 
 */
const LightTree = React.forwardRef<LightTreeRef, LightTreeProps>(({ onClose, onValidate, ...props }, ref) => {
    const lg = H.useLanguage();
    const [, reverseForms] = H.useFormIds();
    const [search, set_search] = React.useState("");
    const [set_search_size, search_size] = H.useElementSize();
    const [tree, setTree, status] = H.useAsyncState<Dia>(INIT);
    const [selection, setSelection] = React.useState<{ selected: string, errors: Record<string, string> }>({ selected: props.selection || "", errors: {} });

    //#region Load Nodes & Links
    React.useEffect(() => {
        let isSubscribed = true;
        if (!TB.mongoIdValidator(props.root)) setTree(INIT, "error");
        else S.getDia({ roots: props.root })
            .then(({ data }) => isSubscribed && setTree(data, "done"))
            .catch(() => isSubscribed && setTree(INIT, "error"));
        return () => {
            isSubscribed = false;
            setTree(INIT, "done");
        };
    }, [setTree, props.root]);
    //#endregion

    //#region Languages
    React.useEffect(() => {
        let nodesIds = tree.nodes.map(n => n.id);
        let typesIds = tree.linkTypes.map(lt => lt._id);
        let allIds = nodesIds.concat(typesIds).filter(TB.mongoIdValidator);
        if (allIds.length > 0) lg.fetchObjectTranslations(allIds);
    }, [lg, tree.nodes, tree.linkTypes]);
    //#endregion

    //#region Templates
    const templates = React.useMemo(() => ES.Templates.map(t => ({
        name: t.NAME,
        realName: t.NAME,
        itemSize: t.ITEM_SIZE,
        minimizedItemLineType: BP.LineType.Dashed,
        onItemRender: (params: T.DiaRendererArg) => <t.Node {...params} noMenu node={params.context} />,
    })), []);
    //#endregion

    //#region Formatting & Filtering
    const linksColor = React.useMemo(() => Object.fromEntries(tree.linkTypes.map(lt => [lt._id, lt.color])), [tree.linkTypes]);
    const height = React.useMemo(() => (TB.getNumber(props.height, 650) - search_size.height) + "px", [props.height, search_size.height]);

    const getFamily = React.useCallback((id: string, searchDown: boolean, direct = false) => {
        let idList: string[] = [], hasExecuted = false;

        const recursive = (ids: string | string[]) => {
            if (direct && hasExecuted) return;
            if (!hasExecuted) hasExecuted = true;
            let idsArray = TB.arrayWrapper(ids).filter(TB.mongoIdValidator);
            if (idsArray.length === 0) return;

            let familyIds = [];
            if (searchDown) familyIds = tree.links.filter(l => ids.includes(l.input) && !idList.includes(l.output)).map(l => l.output);
            else familyIds = tree.links.filter(l => ids.includes(l.output) && !idList.includes(l.input)).map(l => l.input);
            idList.push(...familyIds);
            if (familyIds.length === 0) return;
            return recursive(familyIds);
        }

        recursive(id);
        return tree.nodes.filter(n => idList.includes(n.id));
    }, [tree]);

    const [hideForms, otherElemForms, limitedForms] = React.useMemo(() => [props.hideTypes, props.linkRestriction?.objForm, props.limitedForms].map(array => {
        array = TB.arrayWrapper(array);
        // Separate ids and paths
        let [ids, paths] = _.partition(array, TB.mongoIdValidator);
        // Check each paths
        paths = paths.filter(p => FP.RESOURCE_FORMS.includes(p));

        for (let id of ids) {
            let path = reverseForms[id];
            if (FP.RESOURCE_FORMS.includes(path) && !paths.includes(path)) paths.push(path);
        }

        return paths;
    }), [reverseForms, props.hideTypes, props.linkRestriction?.objForm, props.limitedForms]);

    const filtered = React.useMemo(() => {
        let nodes = tree.nodes, links = tree.links;

        // Remove the nodes that have been set as hidden based on their types
        let not_hidden_nodes = nodes;
        if (hideForms.length > 0) not_hidden_nodes = nodes.filter(n => !hideForms.includes(n.path));
        // Remove the links based on type and the nodes that still appears
        let not_hidden_links = links;
        let ownLinkType = tree.linkTypes.filter(lt => lt.type === LT.LINK_TYPE_OWN)[0]?._id;
        if (props.restrictOwnLinks) not_hidden_links = links.filter(l => l.type === ownLinkType);

        // Apply the search
        if (search.length > 0) {
            let str_match_nodes = not_hidden_nodes.filter(n => TB.areStringSimilar(search, n.title));
            // Show the parents of the nodes that match the search
            let ids = str_match_nodes.map(n => n.id);
            let all_parents = ids.map(id => getFamily(id, false, false));
            let all = all_parents.flat().map(n => n.id).concat(ids);
            not_hidden_nodes = not_hidden_nodes.filter(n => all.includes(n.id));
        }

        // The ids of the nodes still in play
        let still_displayed = not_hidden_nodes.map(n => n.id);
        // Keep only the link if both end are still in play
        not_hidden_links = not_hidden_links.filter(l => still_displayed.includes(l.input) && still_displayed.includes(l.output));

        let temp_roots = [] as string[];
        let vLinks: T.Link[] = [], searchLinks: string[] = [];

        const recursive = (ids: string[]) => {
            // Keep Links that are children of the ids given & not searched already
            let childrenLinks = not_hidden_links.filter(l => ids.includes(l.input) && !searchLinks.includes(l._id) && l.type === ownLinkType);
            // Keep Links that are parents of the ids given, that are parentSearched & not searched already
            let reverseParentLinks = not_hidden_links.filter(l => ids.includes(l.output) && !searchLinks.includes(l._id) && l.type === ownLinkType);
            let allLinks = childrenLinks.concat(reverseParentLinks);
            if (allLinks.length > 0) {
                vLinks.push(...allLinks);
                searchLinks.push(...allLinks.map(l => l._id));
                recursive(childrenLinks.map(l => l.output));
                recursive(reverseParentLinks.map(l => l.input));
            }
        }

        // The og roots are not showed. The new temporary roots are the elements without parent
        if (!not_hidden_nodes.some(n => n.id === props.root)) temp_roots = not_hidden_nodes
            .filter(n => not_hidden_links.filter(l => l.output === n.id).length === 0)
            .map(n => n.id);
        else temp_roots = [props.root];

        recursive(temp_roots);

        let nodesIds = vLinks.map(l => l.input).concat(vLinks.map(l => l.output));
        let vNodes = not_hidden_nodes.filter(n => nodesIds.includes(n.id) || temp_roots.includes(n.id));

        return { links: vLinks, nodes: vNodes };
    }, [hideForms, search, tree.nodes, tree.links, tree.linkTypes, props.restrictOwnLinks, props.root, getFamily]);

    const nodes = React.useMemo(() => filtered.nodes.map(n => ({
        ...n,
        rights: [],
        templateName: TEMPLATE.NAME,
        image: TB.iconIdToUrl(n.icon),
        label: lg.getTextObj(n.id, "name", n.title),
        placementType: BP.AdviserPlacementType.Left,
        parents: filtered.links.filter(l => l.output === n.id).map(l => l.input),
    })), [filtered, lg]);

    const links = React.useMemo(() => filtered.links.map(l => ({
        id: l._id,
        lineWidth: 7,
        opacity: 0.5,
        showArrows: false,
        items: [l.input, l.output],
        color: linksColor[l.type] || "#000000",
        annotationType: BP.AnnotationType.HighlightPath,
    })), [linksColor, filtered.links]);
    //#endregion

    //#region Handle selection
    const acceptedLinkTypes = React.useMemo(() => {
        let array = TB.arrayWrapper(props.linkRestriction?.linkType);
        // Separate ids and paths
        let [ids, types] = _.partition(array, TB.mongoIdValidator);
        // Check each paths
        types = types.filter(t => LT.ALL_LINK_TYPES.includes(t));

        for (let id of ids) {
            let type = tree.linkTypes.filter(lt => lt._id = id)[0]?.type;
            if (LT.ALL_LINK_TYPES.includes(type) && !types.includes(type)) types.push(type);
        }

        return types;
    }, [props.linkRestriction?.linkType, tree.linkTypes]);

    const restrictions = React.useMemo(() => ({
        linkType: acceptedLinkTypes,
        excludeIds: TB.arrayWrapper(props.linkRestriction?.excludeIds).filter(TB.mongoIdValidator),
        isInput: typeof props.linkRestriction?.isInput === "boolean" ? props.linkRestriction.isInput : true,
    }), [props.linkRestriction, acceptedLinkTypes]);

    const onSelection = React.useCallback((id: string, error?: string) => setSelection(p => ({ selected: id, errors: { ...p.errors, [id]: error } })), []);

    const onClick = React.useCallback((node: typeof nodes[number]) => {
        // Is node specifically told to reject ?
        if (restrictions.excludeIds.includes(node.id)) onSelection(node.id, TC.LIGHT_TREE_ERR_NODE_REJECTED);
        // Is the type of submission accepted ?
        else if (limitedForms.includes(node.path)) onSelection(node.id, TC.LIGHT_TREE_ERR_TYPE_INVALID);
        // Check if the node is a child of the pre-selected node
        else if (props.selection && props.linkRestriction?.no_children && getFamily(props.selection, true, false).some(n => n.id === node.id)) onSelection(node.id, TC.LIGHT_TREE_ERR_CHILDREN_REJECTED);
        // Check selection against provided link restrictions
        else if (restrictions.linkType.length > 0) {
            // List of valid sub types, based on the types of link wanted, and if the selection will be input or not, and the type of the other element
            let types = tree.linkTypes.filter(lt => restrictions.linkType.includes(lt.type));
            let allPairs = _.flatten(types.map(t => t.pair));

            let foundPair = false;
            let elemProp = restrictions.isInput ? "input" : "output";
            let otherElemProp = restrictions.isInput ? "output" : "input";

            // We do not have info on the type of the other element of the link
            if (otherElemForms.length === 0) foundPair = allPairs.some(p => p[elemProp] === node.path);
            // We know the type(s) of the others elements of the link
            else foundPair = allPairs.some(p => p[elemProp] === node.path && otherElemForms.includes(p[otherElemProp]));

            if (foundPair) onSelection(node.id);
            else onSelection(node.id, TC.LIGHT_TREE_ERR_LINK_NO_MATCH);
        }
        // No restrictions
        else onSelection(node.id);
    }, [onSelection, getFamily, props.linkRestriction?.no_children, props.selection, limitedForms, restrictions, otherElemForms, tree.linkTypes]);
    //#endregion

    //#region Footer
    const disableValidation = React.useMemo(() => {
        if (!TB.mongoIdValidator(selection.selected) || restrictions.excludeIds.includes(selection.selected)) return true;
        return !!selection.errors[selection.selected];
    }, [selection, restrictions.excludeIds]);

    const footer = React.useMemo(() => <C.Flex justifyContent="end">
        <C.Flex direction="column" alignItems="end">
            <div>
                {!props.modal && <C.Button
                    icon="times"
                    className="me-2"
                    variant="danger"
                    text={TC.GLOBAL_CANCEL}
                    onClick={() => onClose?.()}
                />}
                <C.Button
                    icon="check"
                    text={TC.GLOBAL_CONFIRM}
                    disabled={disableValidation}
                    onClick={() => onValidate?.(selection.selected)}
                />
            </div>
            <div>
                <C.Feedback text={selection.errors[selection.selected]} />
            </div>
        </C.Flex>
    </C.Flex>, [props.modal, onValidate, onClose, disableValidation, selection]);
    //#endregion

    return React.createElement(
        props.modal ? M.BlankModal : "div",
        props.modal ? {
            ...props.style,
            footer,
            maxBodyHeight: "850px",
            onQuit: () => onClose?.(),
            size: props.style?.size || "lg",
            title: props.style?.title || tree.contextName || TC.LT_LIGHT_TREE_TITLE,
        } : { className: "h-100 w-100 flex-grow-1" },
        <>
            <C.Spinner status={status}>
                <C.Flex ref={set_search_size} justifyContent="end">
                    <C.Form.TextField
                        uncontrolled
                        value={search}
                        noBottomMargin
                        customClass="w-50"
                        onChange={set_search}
                        placeholder={TC.GLOBAL_SEARCH_PLACEHOLDER}
                    />
                </C.Flex>
                <div style={{ height }}>
                    <FamDiagram
                        centerOnCursor
                        onCursorChanged={(e, { context }) => onClick(context)}
                        config={{
                            ...CONFIG,
                            items: nodes,
                            annotations: links,
                            templates: templates,
                            enablePanning: false,
                            cursorItem: selection?.selected,
                        }} />
                </div>
            </C.Spinner>
            {!props.modal && <div className="mt-1">{footer}</div>}
        </>
    );
});

const LightTreeWrapper = React.forwardRef<LightTreeRef, LightTreeProps>((props, ref) => <C.ReduxWrapper>
    <LightTree {...props} ref={ref} />
</C.ReduxWrapper>);

export default LightTreeWrapper;