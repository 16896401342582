import * as C from "../../../Common";
import * as S from "../../../services";
import { InputGroup } from "react-bootstrap";
import { FP, LT, T, TB, TC } from "../../../Constants";
import { useAsyncState, useDark, useLanguage } from "../../../hooks";
import React, { useCallback, useEffect, useMemo } from "react";
import { Alerts, askConfirm, askLink, renderDataFormModal } from "../../Modal";

//#region Types
export type DataOrganizerProps = {
    /** The point of origin */
    origin?: string;
    /** The context of origin */
    context?: T.ContextParams;
};

type EnergyLinksData = T.API.Utils.Datasets.EnergyLinksData;

type Row = EnergyLinksData["links"][number] & {
    /** Options for input datasets */
    input_datasets: T.Option[];
    /** Options for output datasets */
    output_datasets: T.Option[];
}

type TextCodeDic = {
    /** Contains text code for the types of energy */
    type: Record<T.DataSet["type"], string>;
    /** Contains text code for the source of values */
    src: Record<T.DataSet["src"], string>;
}

export const TEXT_CODE_DIC: TextCodeDic = {
    type: {
        GAS: TC.GP_GAS,
        ELEC: TC.GP_ELEC,
        FUEL: TC.GP_FUEL,
        THFR: TC.GP_THFR,
        THCH: TC.GP_THCH,
        OTHER: TC.GP_OTHER,
        WATER: TC.GP_WATER,
        MONEY: TC.NRJ_MONEY,
        ELEC_PPV: TC.GP_ELEC_PPV,

        GAS_HEAD: TC.NRJ_HEAD_GAS,
        ELEC_HEAD: TC.NRJ_HEAD_ELEC,
        THFR_HEAD: TC.NRJ_HEAD_THFR,
        THCH_HEAD: TC.NRJ_HEAD_THCH,
        FUEL_HEAD: TC.NRJ_HEAD_FUEL,
        WATER_HEAD: TC.NRJ_HEAD_WATER,
        OTHER_HEAD: TC.NRJ_HEAD_OTHER,
        ELEC_PPV_HEAD: TC.NRJ_HEAD_ELEC_PPV,

        NB_START: TC.NRJ_NB_START,
        HOURS_WORK: TC.NRJ_WORK_HOURS,
        PRESSURE: TC.NRJ_PRESSURE,
        TEMPERATURE: TC.NRJ_TEMPERATURE,
        CONDUCTIVITY: TC.NRJ_CONDUCTIVITY,
        CO2: TC.NRJ_CO2,
        WASTE: TC.NRJ_WASTE,
    },
    src: {
        manual: TC.DATASET_SRC_MANUAL,
        automatic: TC.DATASET_SRC_AUTO,
        prediction: TC.DATASET_SRC_PRED,
        calculated: TC.DATASET_SRC_CALCULATED,
    }
};
//#endregion

const DataOrganizer: React.FC<DataOrganizerProps> = props => {
    const lg = useLanguage();
    const isDark = useDark();
    const [items, setItems, itemStatus] = useAsyncState<EnergyLinksData>({ links: [], datasets: [] });

    //#region Rights
    const allowLinkEdit = useMemo(() => {
        // Restricts modifications to links when opened from the tree
        return !TB.mongoIdValidator(props.origin);
    }, [props.origin]);
    //#endregion

    //#region Load Items
    useEffect(() => {
        let isSubscribed = true;

        if (TB.mongoIdValidator(props.origin)) S.findEnergyLinksOrigin(props.origin)
            .then(({ data }) => isSubscribed && setItems(data, "done"))
            .catch(() => isSubscribed && setItems({ links: [], datasets: [] }, "error"));
        else if (props.context) S.findEnergyLinksContext(props.context)
            .then(({ data }) => isSubscribed && setItems(data, "done"))
            .catch(() => isSubscribed && setItems({ links: [], datasets: [] }, "error"));
        else setItems({ links: [], datasets: [] }, "done");

        return () => { isSubscribed = false };
    }, [props.context, props.origin, setItems]);
    //#endregion

    //#region Render Datasets
    const datasets = useMemo(() => ({
        create: (origin: T.DataSet["origin"]) => {
            renderDataFormModal({ origin }).then(dataset => {
                if (dataset) setItems(p => ({ ...p, datasets: p.datasets.concat(dataset) }));
            });
        },
        edit: (id: T.DataSet["_id"]) => {
            let dataset = items.datasets.filter(d => d._id === id)[0];
            if (dataset) renderDataFormModal({ dataset, origin: dataset.origin }).then(dataset => {
                if (dataset) setItems(p => ({ ...p, datasets: p.datasets.map(d => d._id === id ? dataset : d) }));
            });
        },
        change: (link: Row["id"], isInput?: boolean, dataset?: T.DataSet["_id"]) => {
            // Update link in db
            S.assignDataset({ link, dataset, flow: isInput ? "in" : "out" }).then(() => {
                // Update link in local
                setItems(p => ({
                    ...p,
                    links: p.links.map(l => {
                        if (l.id !== link) return l;
                        return {
                            ...l,
                            dataset_in: isInput ? dataset : l.dataset_in,
                            dataset_out: isInput ? l.dataset_out : dataset,
                        }
                    })
                }));
            }).catch(Alerts.updateError);
        }
    }), [setItems, items.datasets]);

    const DatasetRender = useCallback<React.FC<C.DataGridRenderCellParams<Row>>>(props => {
        let isInput = props.field === "dataset_input";
        let origin = isInput ? props.row.input : props.row.output;
        let value = isInput ? props.row.dataset_in : props.row.dataset_out;
        let options = isInput ? props.row.input_datasets : props.row.output_datasets;

        let hasValue = options.filter(o => o.value === value).length > 0;

        return <InputGroup className="w-100">
            <C.Form.Select
                positionFixed
                value={value}
                noBottomMargin
                options={options}
                customClass="flex-grow-1"
                onChange={dataset => datasets.change(props.row.id, isInput, dataset)}
            />
            {hasValue
                ? <>
                    <C.Button size="sm" variant="info" onClick={() => datasets.edit(value)}>
                        <i className="fa fa-edit"></i>
                    </C.Button>
                    <C.Button size="sm" variant="danger" onClick={() => datasets.change(props.row.id, isInput)}>
                        <i className="fa fa-times"></i>
                    </C.Button>
                </>
                : <C.Button size="sm" variant="success" onClick={() => datasets.create(origin)}>
                    <i className="fa fa-plus"></i>
                </C.Button>}
        </InputGroup>
    }, [datasets]);
    //#endregion

    //#region Languages
    useEffect(() => lg.fetchStaticTranslations(LT.GROUP_ENERGY_NON_PRIMARY), [lg]);
    //#endregion

    //#region Columns
    const columns = useMemo<C.DataGridProps["columns"]>(() => [
        { field: "code_type", headerName: TC.DATASET_LINK_TYPE, flex: 0.55, renderCell: LinkTypeRender },
        { field: "input_label", headerName: TC.DATASET_LINK_INPUT, flex: 0.55 },
        { field: "dataset_input", headerName: TC.DATASET_LINK_INPUT_D, flex: 1, renderCell: DatasetRender },
        { field: "output_label", headerName: TC.DATASET_LINK_OUTPUT, flex: 0.55 },
        { field: "dataset_output", headerName: TC.DATASET_LINK_OUTPUT_D, flex: 1, renderCell: DatasetRender },
    ], [DatasetRender]);
    //#endregion

    //#region Rows
    const findAvailableDatasets = useCallback((isInput: boolean, item: typeof items["links"][number]): T.Option[] => {
        let origin = isInput ? item.input : item.output;
        let datasetsOrigin = items.datasets.filter(d => d.origin === origin);
        return datasetsOrigin.map(d => ({ label: d.name, value: d._id }));
    }, [items.datasets]);

    const rows = useMemo<Row[]>(() => items.links.map(l => ({
        ...l,
        input_datasets: findAvailableDatasets(true, l),
        output_datasets: findAvailableDatasets(false, l),
    })), [items.links, findAvailableDatasets]);
    //#endregion

    //#region Context Menu
    const links = useMemo(() => ({
        create: (link?: Partial<T.Link>) => {
            askLink({ link, types: LT.GROUP_ENERGY_NON_PRIMARY, paths: FP.GROUP_DATASETS }).then(link => {
                if (link) S.findEnergyLinksOrigin([link.input, link.output]).then(({ data }) => {
                    setItems(p => {
                        let existingLinks = p.links.map(l => l.id);
                        let existingDatasets = p.datasets.map(d => d._id);

                        return {
                            links: p.links.concat(data.links.filter(l => !existingLinks.includes(l.id))),
                            datasets: p.datasets.concat(data.datasets.filter(d => !existingDatasets.includes(d._id))),
                        }
                    });
                }).catch(Alerts.loadError);
            });
        },
        delete: (link: string) => {
            askConfirm().then(confirmed => {
                if (confirmed) S.removeLinks(link)
                    .then(() => setItems(p => ({ ...p, links: p.links.filter(l => l.id !== link) })))
                    .catch(Alerts.deleteError);
            });
        }
    }), [setItems]);

    const getContextMenu = useCallback<C.DataGridProps<Row>["getContextMenu"]>(row => {
        if (!row) return [];
        let actions: ReturnType<C.DataGridProps["getContextMenu"]> = [
            { name: TC.DATASET_LINK_ADD, icon: "plus", action: () => links.create() },
            { name: TC.DATASET_LINK_ADD_SAME_IN, icon: "plus", action: () => links.create({ input: row.input }) },
            { name: TC.DATASET_LINK_ADD_SAME_OUT, icon: "plus", action: () => links.create({ output: row.output }) },
            "separator",
            { name: TC.DATASET_LINK_REMOVE, icon: "trash", action: () => links.delete(row.id) },
        ];

        return actions;
    }, [links]);
    //#endregion

    return <C.Spinner error={itemStatus === "error"}>
        <div className={`mb-3 h-100 w-100 ${isDark ? "text-light" : "bg-white"}`}>
            <C.DataGrid
                rows={rows}
                columns={columns}
                loading={itemStatus === "load"}
                getContextMenu={allowLinkEdit && getContextMenu}
                footer={pagination => allowLinkEdit && <C.Flex alignItems="center" justifyContent="center">
                    <div className="flex-grow-1 p-2">
                        <C.Button icon={{ icon: "plus" }} onClick={() => links.create()}>
                            {TC.DATASET_LINK_ADD}
                        </C.Button>
                    </div>
                    {pagination}
                </C.Flex>}
            />
        </div>
    </C.Spinner>;
}

export default DataOrganizer;

const LinkTypeRender: React.FC<C.DataGridRenderCellParams<Row>> = props => {
    const lg = useLanguage();
    const textWhite = useMemo(() => TB.isColorWhite(props.row.type_color), [props.row.type_color]);
    return <div className="p-2 m-1 w-100 text-center" style={{ backgroundColor: props.row.type_color, color: !textWhite && "white" }}>
        {lg.getStaticElem(props.value)}
    </div>
}