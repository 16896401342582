import _ from "lodash";
import React from "react";
import moment from "moment";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as S from "../../../services";
import * as PM from "../../../PurposeModal";
import { Table, TableProps } from "../Grid";
import { CellsTypes as CT } from "../AgGridDefs";
import { DS, FP, T, TABS, TB, TC } from "../../../Constants";
import { Spinner, TimeSelector, Flex } from "../../../Common";

const AlarmOptions = DS.ALARMS;

//#region Types
export type AlertTableProps = {
    /** The context to load the alarms from */
    context?: T.ContextParams;
    /** The dataset to load the alarms from, overrule the context */
    dataset?: string;
    /** The property to save the states under */
    origin?: string;
    /** ClassName for the main div */
    className?: string;
}

type Row = ReturnType<T.API.Utils.Energy.GetAlertsRows>[number];
//#endregion

const TEXT_CODES = [TC.GLOBAL_EDIT, TC.GLOBAL_NEW, TC.GLOBAL_DELETE];

export const Alert: React.FC<AlertTableProps> = props => {
    const lg = H.useLanguage(TEXT_CODES);
    const [alerts, setAlerts, alert_status] = H.useAsyncState<Row[]>([]);
    const [time, set_time] = React.useState<T.NRJ.TimeSelection>({ interval: "7 DAY" });

    //#region Misc
    const is_error = React.useMemo(() => alert_status === "error", [alert_status]);

    const cols_props = React.useMemo<TableProps<{}>["columns_base"]>(() => [
        "grouped",
        "sortable",
        "filterable",
    ], []);
    //#endregion

    //#region Load data
    React.useEffect(() => {
        let isSubscribed = true;
        let unix_times = TB.getFromToUnix(time);

        if (TB.mongoIdValidator(props.dataset)) S.getAlertsRowsDataset({ dataset: props.dataset, ...unix_times })
            .then(({ data }) => isSubscribed && setAlerts(data, "done"))
            .catch(() => isSubscribed && setAlerts([], "error"));
        else if (props.context) S.getAlertsRows({ context: props.context, ...unix_times })
            .then(({ data }) => isSubscribed && setAlerts(data, "done"))
            .catch(() => isSubscribed && setAlerts([], "error"));
        else setAlerts([], "error");
        return () => {
            isSubscribed = false;
            setAlerts([], "load");
        };
    }, [time, props.dataset, props.context, setAlerts]);
    //#endregion

    //#region Language
    React.useEffect(() => lg.getOptionsStatic(AlarmOptions.type), [lg]);
    React.useEffect(() => lg.getOptionsStatic(AlarmOptions.aggregate), [lg]);
    //#endregion

    //#region Translated rows
    const alarms_tr = React.useMemo(() => alerts.map(a => {
        let t_status = a.status || "",
            type_translated = a.alarm.type || "",
            group_agg_tr = a.alarm.data_group_aggregate || "";

        let type = AlarmOptions.type.filter(o => o.value === a.alarm.type)[0];
        if (type) type_translated = lg.getStaticText(type.label);

        let gr_agg = AlarmOptions.aggregate.filter(o => o.value === a.alarm.data_group_aggregate)[0];
        if (gr_agg) group_agg_tr = lg.getStaticText(gr_agg.label);

        let status = AlarmOptions.status.filter(o => o.value === t_status)[0];
        if (status) t_status = lg.getStaticText(status.label);

        return { ...a, t_status, alarm: { ...a.alarm, type_translated, group_agg_tr } };
    }), [lg, alerts]);
    //#endregion

    //#region Events
    const show_graphs = React.useCallback((row: Row) => {
        PM.renderBuildingSelect({ context: { roots: row.alarm.origin }, isRequired: true }).then(building => {
            if (building) {
                let period = TB.splitFrequency(row.alarm.period);
                type Params = Parameters<typeof PM.renderEnergyDashboard>[0];
                let group_period = TB.splitFrequency(row.alarm.data_group_period);

                let group: Params["group"],
                    end_date: Params["times"]["to"],
                    start_date: Params["times"]["from"];

                if (period) {
                    end_date = row.alarm_start_date as any;
                    start_date = moment(row.alarm_start_date)
                        .subtract(period.num, period.unit)
                        .subtract(1, "d")
                        .toISOString();
                }

                if (group_period) {
                    if (group_period.unit === "M") group = "month";
                    else if (group_period.unit === "d") group = "day";
                    else if (group_period.unit === "h") group = "hour";
                    else if (group_period.unit === "w") group = "week";
                    else if (group_period.unit === "y") group = "year";
                    else if (group_period.unit === "m") group = "minute";
                }

                PM.renderEnergyDashboard({
                    group,
                    building,
                    tab: "datasets",
                    isFullScreen: true,
                    datasets: row.alarm.datasets_ids,
                    times: { from: start_date, to: end_date },
                });
            }
        });
    }, []);

    const delete_alerts = React.useCallback((row: Row) => {
        M.askConfirm().then(confirmed => {
            if (confirmed) S.removeAlarmsAlerts(row.alarm_id)
                .then(() => setAlerts(p => p.filter(a => a.alarm_id !== row.alarm_id)))
                .catch(M.Alerts.deleteError);
        });
    }, [setAlerts]);

    const onValueChange = React.useCallback<TableProps<Row>["onValueChange"]>(params => {
        let row = params.data,
            old_value = params.oldValue,
            field = params.colDef.field as keyof typeof alarms_tr[number];

        let prop: keyof T.pSQL.Alert;
        // Set the right prop
        if (field === "t_status") {
            prop = "status";
            old_value = row.status;
        }
        else prop = field as typeof prop;

        const updatePromise = new Promise<Row[]>((resolve, reject) => {
            let api_params = {
                id: row.id,
                field: prop,
                old_value: old_value,
                new_value: params.newValue,
            } as Parameters<typeof S.editAlertField>[0];

            S.editAlertField(api_params).then(({ data }) => {
                if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                    if (confirmed) S.editAlertField({ ...api_params, force_update: true }).then(({ data }) => {
                        if (data === "changed") reject("Error");
                        else resolve(data);
                    }).catch(reject);
                });
                else resolve(data);
            }).catch(reject);
        });

        updatePromise.then(rows => {
            setAlerts(p => {
                let existing_ids = p.map(r => r.id);
                let [to_update, to_add] = _.partition(rows, r => existing_ids.includes(r.id));
                return p.map(r => to_update.filter(row => row.id === r.id)[0] || r).concat(to_add);
            });
        }).catch(M.Alerts.updateError);
    }, [setAlerts]);

    const context_menu = React.useCallback<TableProps<Row>["getContextMenuItems"]>(event => {
        let row = event.node?.data;
        let items = [] as ReturnType<TableProps<Row>["getContextMenuItems"]>;

        if (row) items.push({
            action: () => delete_alerts(row),
            icon: "<i class='fa fa-times text-danger'></i>",
            name: lg.getStaticText(TC.ALERTS_DELETE_ALERTS_FROM_ALARMS),
        });
        if (items.length > 0) items.push("separator");
        return items;
    }, [lg, delete_alerts]);
    //#endregion

    //#region Columns
    const columns = React.useMemo<TableProps<Row>["columns"]>(() => [
        {
            headerName: " ",
            field: "to_chart",
            type: CT.TYPE_ACTION_BUTTON,
            params: {
                action: show_graphs,
                content: <i className="fa fa-chart-line"></i>,
                buttonProps: { variant: "secondary", size: "sm" },
            }
        },
        {
            headerName: TC.ALERT_TRIGGER,
            children: [
                { field: "value_num", headerName: TC.ALERT_VALUE, type: CT.TYPE_NUMBER },
                { field: "alarm.unit", headerName: TC.DATASET_UNIT, type: CT.TYPE_NUMBER },
                { field: "alarm_begin_date", headerName: TC.ALERT_BEGIN_DATE, type: CT.TYPE_DATE, params: { format: "DD/MM/YY HH:mm" } },
                { field: "alarm_start_date", headerName: TC.ALERT_TRIGGER_DATE, type: CT.TYPE_DATE, params: { format: "DD/MM/YY HH:mm" } },
                { field: "alarm_end_date", headerName: TC.ALERT_END_DATE, type: CT.TYPE_DATE, params: { format: "DD/MM/YY HH:mm" } },
                { field: "notes", editable: true, headerName: TC.ALARM_NOTES },
                { field: "t_status", editable: true, headerName: TC.ALARM_STATUS, type: CT.TYPE_SELECT, params: { values: AlarmOptions.status } },
            ]
        },
        {
            headerName: TC.ALERT_ALARM,
            children: [
                { field: "alarm.name", headerName: TC.GLOBAL_NAME },
                { field: "alarm.type_translated", headerName: TC.ALARM_TYPE },
                { field: "alarm.start_date", headerName: TC.ALARM_START_DATE, type: CT.TYPE_DATE, params: { format: "DD/MM/YY HH:mm" } },
                { field: "alarm.period", headerName: TC.ALARM_PERIOD, type: CT.TYPE_FREQUENCY },
                { field: "alarm.active", headerName: TC.ALARM_ACTIVE, type: CT.TYPE_CHECKBOX },
            ]
        },
        {
            headerName: TC.DATASET_ORIGIN,
            children: [
                { headerName: TC.DATASET_FORM, field: "alarm.dataset" },
                { headerName: TC.DATASET_ELEMENT, field: "alarm.origin_name" },
                { headerName: FP.SITE_FORM, field: "alarm.site.name" },
                { headerName: FP.BUILDING_FORM, field: "alarm.building.name" },
                { headerName: FP.EMPLACEMENT_FORM, field: "alarm.emplacement.name" },
                { headerName: TC.GLOBAL_LOCAL, field: "alarm.local.name" },
            ]
        },
    ], [show_graphs]);
    //#endregion

    return <div className={TB.getString(props.className) + " w-100"}>
        <Spinner error={is_error}>
            <Flex direction="column" className="h-100">
                <div className="mb-1">
                    <TimeSelector
                        to={time.to}
                        from={time.from}
                        interval={time.interval}
                        onChangeDatePicker={time => set_time(time)}
                        onChangeInterval={interval => set_time({ interval })}
                    />
                </div>
                <Table<Row>
                    sideBar
                    rows={alarms_tr}
                    columns={columns}
                    status={alert_status}
                    export_dashboard_button
                    columns_base={cols_props}
                    onValueChange={onValueChange}
                    getContextMenuItems={context_menu}
                    autoFit={React.useMemo(() => ["to_chart"], [])}
                    adaptableId={props.origin || TABS.NRJ_ALERT_TABLE}
                />
            </Flex>
        </Spinner>
    </div>;
}

export const AlertContext: React.FC = props => {
    H.useCrumbs(TC.CPT_ALARMS);
    const [roots] = H.useRoots();
    H.useAuth({ tabName: TABS.NRJ_ALERT_TABLE });

    return <Alert context={roots} origin={TABS.NRJ_ALERT_TABLE} />;
}