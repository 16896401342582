import { Alerts } from "../MiscModal";
import { T, TB, TC } from "../../../Constants";
import { createLink, newLinkOptions } from "../../../services";
import { useAsyncState, useBoolean, useRoots } from "../../../hooks";
import { Button, Form, ReduxWrapper, Spinner } from "../../../Common";
import { StyleModalProps, default as BlankModal } from "../BlankModal";
import React, { useCallback, useEffect, useMemo, useState } from "react";

export type LinkModalProps = {
    /** Callback for closing the modal */
    onClose?: () => void;
    /** The default link values */
    link?: Partial<T.Link>;
    /** Callback after creating the link */
    onCreate?: (link: T.Link) => void;
    /** The context to search the elements from, default is selected context */
    context?: T.ContextParams;
    /** Restriction on what kind of links is expected */
    types?: T.AllowArray<string>;
    /** Extra params to change the modal */
    modal?: StyleModalProps;
    /** Restriction on what types of elements is expected */
    paths?: T.AllowArray<string>;
}

type Options = ReturnType<T.API.Utils.Link.NewLinkOptions>;

const LinkModal: React.FC<LinkModalProps> = ({ onCreate, ...props }) => {
    const isSaving = useBoolean(false);
    const [context] = useRoots(props.context);
    const [link, setLink] = useState(props.link || {});
    const [errors, setErrors] = useState<T.Errors<T.Link>>({});
    const [options, setOptions, status] = useAsyncState<Options>({ nodes: [], types: [] });

    //#region Fetch options
    useEffect(() => {
        let isSubscribed = true;
        newLinkOptions({ context, types: props.types, paths: props.paths })
            .then(({ data }) => isSubscribed && setOptions(data, "done"))
            .catch(() => isSubscribed && setOptions({ nodes: [], types: [] }, "error"));
        return () => { isSubscribed = false };
    }, [setOptions, context, props.types, props.paths]);
    //#endregion

    //#region Save
    const onSave = useCallback(() => {
        let newErrors: typeof errors = {};

        if (!TB.mongoIdValidator(link.type)) newErrors.type = TC.GLOBAL_REQUIRED_FIELD;
        if (!TB.mongoIdValidator(link.input)) newErrors.input = TC.GLOBAL_REQUIRED_FIELD;
        if (!TB.mongoIdValidator(link.output)) newErrors.output = TC.GLOBAL_REQUIRED_FIELD;

        // Has the 3 fields required
        if (Object.keys(newErrors).length === 0) {
            // Check that input and output are not the same
            if (link.input === link.output) newErrors.output = TC.LINK_ERROR_SAME_IN_OUT;
            else {
                // The pair input/output is valid for the link type
                let pairs = options.types.filter(t => t.value === link.type)[0]?.pairs;
                if (!Array.isArray(pairs)) newErrors.type = TC.LINK_ERROR_VALUE_NOT_OPTION;
                else {
                    let input = options.nodes.filter(n => n.value === link.input)[0]?.form;
                    let output = options.nodes.filter(n => n.value === link.output)[0]?.form;

                    if (input && output) {
                        let hasPair = pairs.some(p => p.input === input && p.output === output);
                        if (!hasPair) newErrors.type = TC.LINK_ERROR_PAIR_INVALID;
                    }
                    else {
                        if (!input) newErrors.input = TC.LINK_ERROR_VALUE_NOT_OPTION;
                        if (!output) newErrors.output = TC.LINK_ERROR_VALUE_NOT_OPTION;
                    }
                }
            }
        }

        if (Object.keys(newErrors).length > 0) setErrors(newErrors);
        else {
            isSaving.setTrue();

            createLink(link as T.MakeRequired<typeof link, "input" | "output" | "type">).then(newLink => {
                if (newLink.data === "duplicate") Alerts.linkDupeWarning({ delay: -1 });
                else onCreate?.(newLink.data);
            })
                .catch(Alerts.updateError)
                .finally(isSaving.setFalse);
        }
    }, [isSaving, link, options, onCreate]);

    const footer = useMemo(() => <Button
        onClick={onSave}
        icon={{ icon: "save", spinIcon: "spinner", spin: isSaving.value }}
    >
        {TC.GLOBAL_SAVE}
    </Button>, [onSave, isSaving.value]);
    //#endregion

    //#region Disabled
    const disabled = useMemo(() => ({
        type: TB.mongoIdValidator(props.link?.type),
        input: TB.mongoIdValidator(props.link?.input),
        output: TB.mongoIdValidator(props.link?.output),
    }), [props.link]);
    //#endregion

    return <BlankModal
        {...props.modal}
        footer={footer}
        onQuit={props.onClose}
        size={props.modal?.size || "md"}
        title={props.modal?.title || TC.DATASET_LINK_ADD}
    >
        <Spinner status={status} >
            <>
                <Form.Select
                    positionFixed
                    value={link.input}
                    options={options.nodes}
                    disabled={disabled.input}
                    label={TC.DATASET_LINK_INPUT}
                    error={{ code: errors.input }}
                    onChange={input => setLink(l => ({ ...l, input }))}
                />

                <Form.Select
                    positionFixed
                    value={link.output}
                    options={options.nodes}
                    disabled={disabled.output}
                    label={TC.DATASET_LINK_OUTPUT}
                    error={{ code: errors.output }}
                    onChange={output => setLink(l => ({ ...l, output }))}
                />

                <Form.Select
                    positionFixed
                    value={link.type}
                    options={options.types}
                    disabled={disabled.type}
                    label={TC.DATASET_LINK_TYPE}
                    error={{ code: errors.type }}
                    onChange={type => setLink(l => ({ ...l, type }))}
                />
            </>
        </Spinner>
    </BlankModal >;
}

const WrappedLinkModal: React.FC<LinkModalProps> = props => <ReduxWrapper>
    <LinkModal {...props} />
</ReduxWrapper>;

export default WrappedLinkModal;