import React from "react";
import * as G from "../Grid";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { CellsTypes as CT } from "../AgGridDefs";
import { T, TC, TABS, TB, FP, TICKET } from "../../../Constants";

//#region Types
export type CapexProps<A = {}> = {
    /** The context to load the actions from */
    context: T.ContextParams;
    /** The _id of the table */
    origin?: string;
    /** Disable edits */
    read_only?: boolean;
    /** Show the "group" ticket or only the normal tickets */
    group_tickets_state?: "group" | "single_actions" | "both";
    /** Hide some of the default buttons */
    hide_buttons?: T.AllowArray<"toggle" | "group" | "toggle_ticket_group">;
    /** Extra columns */
    extra_columns?: G.TableProps<Row<A>>["columns"];
    /** Add extra data to the rows */
    process_rows?: (rows: Row[]) => Row<A>[];
    /** Callback for when the grid is ready */
    onReadyGrid?: G.TableProps<Row<A>>["onReadyGrid"];
    /** Buttons to show in the toolbar */
    buttons?: G.TableProps<Row<A>>["extra_buttons"];
    /** Callback for inline edits */
    onValueChange?: (params: Parameters<G.TableProps<Row<A>>["onValueChange"]>[0]) => "processed" | "to_process";
}

export type CapexRef<A = {}> = {
    /** The table Grid Ref */
    table: React.MutableRefObject<G.TableRef<Row<A>>>
    /** The loaded rows */
    rows: Row<A>[];
    /** The unprocessed rows, as fetched from the server */
    actions: Row[];
};

const OPTIONS = TICKET.CAPEX;
type Row<A = {}> = (ReturnType<T.API.Utils.Tables.GetCapexRows>[number]) & A;
//#endregion

//#region Constants
const TIME_RANGES = {
    under_2: 0,
    under_5: 1,
    under_10: 2,
    over_10: 3,
    unknown: 4,
} as Record<Row["time_range"], number>;

const time_range_comparator: G.ColDef<Row>["comparator"] = (v1, v2, data1, data2) => {
    let tr_v1 = data1?.data?.time_range;
    if (data1.group) tr_v1 = data1?.allLeafChildren?.[0]?.data?.time_range;
    let tr_v2 = data2?.data?.time_range;
    if (data2.group) tr_v2 = data2?.allLeafChildren?.[0]?.data?.time_range;
    return TIME_RANGES[tr_v1 || "unknown"] - TIME_RANGES[tr_v2 || "unknown"];
}
//#endregion

const RenderCapex = <A extends {},>({ process_rows, onValueChange, onReadyGrid, ...props }: CapexProps<A>, ref: React.ForwardedRef<CapexRef<A>>) => {
    const lg = H.useLanguage();
    const loading = H.useBoolean(false);
    const grid_ready = H.useBoolean(false);
    const first_grouping = React.useRef(true);
    const group_by_years = H.useBoolean(false);
    const show_suggestions = H.useBoolean(true);
    const show_group_tickets = H.useBoolean(false);
    const table = React.useRef<G.TableRef<Row<A>>>(null);
    const [actions, set_actions, status] = H.useAsyncState<Row[]>([]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getCapexRows(props.context)
            .then(({ data }) => isSubscribed && set_actions(data, "done"))
            .catch(() => isSubscribed && set_actions([], "error"));
        return () => {
            isSubscribed = false;
            set_actions([], "load");
        }
    }, [props.context, set_actions]);

    const events = React.useMemo(() => ({
        create_replacement: (row: Row) => {
            // Pre-fill the data
            let partial_sub: Partial<T.TicketData> = {
                cost: row.cost,
                capex: row.capex,
                title: row.title,
                type: "replacement",
                start: row.start_date,
                equipment: row.element_id,
            };
            M.renderFormModal<T.TicketData>({ forcedSubmission: TB.submissionToArrayUpdate(partial_sub, "type"), path: FP.TICKET_FORM }).then(ticket => {
                if (ticket) {
                    loading.setTrue();
                    // Load the newly created row, Remove the old row & Add the new ones
                    S.getCapexRowsByIds(ticket._id)
                        .then(({ data }) => set_actions(p => p.filter(r => r._id !== row._id).concat(data)))
                        .catch(M.Alerts.loadError)
                        .finally(loading.setFalse);
                }
            });
        },
    }), [loading, set_actions]);

    const col_options = React.useMemo(() => ({
        render_origin: (row => {
            if (!row || row.context_origin === "other") return null;
            else {
                let variant: T.ColorTypes = "primary";
                if (row.context_origin === "auto_reg") variant = "info";
                else if (row.context_origin === "from_rem") variant = "danger";
                else if (row.context_origin === "auto_maintenance") variant = "warning";
                else if (row.context_origin === "suggestion_lifespan_audit") variant = "success"
                else if (row.context_origin === "suggestion_lifespan_th") variant = "tiny-success";
                let text = lg.getStaticText(OPTIONS.context_origin[row.context_origin], row.element_name);
                return <C.Flex alignItems="center" justifyContent="center" className={`w-100 h-100 fs-85 text-light bg-${variant}`} children={text} />;
            }
        }) as G.ColDefParams<Row>["render"],
        replacement_button: {
            action: events.create_replacement,
            content: TC.CAPEX_TABLE_CREATE_ACTION,
            buttonProps: { size: "sm", variant: "success", icon: "plus" },
            isHidden: row => !row || (row.context_origin !== "suggestion_lifespan_audit" && row.context_origin !== "suggestion_lifespan_th"),
        } as G.ColDefParams<Row>,
    }), [lg, events]);

    const columns = React.useMemo<G.TableProps<Row<A>>["columns"]>(() => {
        let columns = [
            { field: "context_origin", headerName: TC.CAPEX_TABLE_ORIGIN, filter: false, type: CT.TYPE_FREE_RENDER, params: { render: col_options.render_origin } },
            { field: "tr_intervention_type", headerName: TC.CAPEX_TABLE_TYPE },
            { field: "title", headerName: TC.CAPEX_TABLE_TITLE },
            { field: "description", headerName: TC.CAPEX_TABLE_DESCRIPTION },
            { field: "element_name", headerName: TC.CAPEX_TABLE_ELEMENT },
            { field: "cost", headerName: TC.CAPEX_TABLE_COST, editable: !props.read_only, type: CT.TYPE_NUMBER, params: { formatted: "money", show_grouped: true } },
            { field: "start_date", headerName: TC.CAPEX_TABLE_START_DATE, editable: !props.read_only, type: CT.TYPE_DATE },
            { field: "capex", headerName: TC.CAPEX_TABLE_CAPEX, type: CT.TYPE_CHECKBOX },
            { field: "tags_names", headerName: TC.CAPEX_TABLE_TAGS },
            { field: "owner_name", headerName: TC.CAPEX_TABLE_OWNER },
            { field: "site", headerName: FP.SITE_FORM },
            { field: "building", headerName: FP.BUILDING_FORM },
            { field: "floor", headerName: TC.GLOBAL_FLOOR },
            { field: "local", headerName: TC.GLOBAL_LOCAL },
            { field: "tr_time_range", headerName: TC.CAPEX_TABLE_TIME_RANGE, hide: true, rowGroup: true, comparator: time_range_comparator },
            { field: "year", headerName: TC.CAPEX_TABLE_YEAR, hide: true, sort: "asc" },
        ] as G.TableProps<Row<A>>["columns"];
        // Create action button
        if (!props.read_only) columns.push({ field: "replacement_action", headerName: " ", pinned: "right", type: CT.TYPE_ACTION_BUTTON, params: col_options.replacement_button });
        if (props.extra_columns) columns.push(...props.extra_columns);
        return columns;
    }, [col_options, props.extra_columns, props.read_only]);

    React.useEffect(() => {
        if (grid_ready.value) {
            let current_group = (table.current?.grid?.columnApi?.getRowGroupColumns?.() || [])
                .map(c => c.getColDef?.()?.field);

            if (group_by_years.value && !current_group.includes("year")) table.current?.grid?.columnApi?.setRowGroupColumns?.(["year"]);
            else if (!group_by_years.value && !current_group.includes("tr_time_range")) table.current?.grid?.columnApi?.setRowGroupColumns?.(["tr_time_range"]);
            // Expand all groups
            table.current?.grid?.api?.expandAll?.();
            // Delay the sorting, otherwise it doesn't stick
            setTimeout(() => table.current?.grid?.columnApi?.applyColumnState?.({
                state: [{ colId: group_by_years.value ? "year" : "tr_time_range", sort: "asc" }],
                defaultState: { sort: null },
                // Needs a longer delay to work on first rendering
            }), first_grouping.current ? 2500 : 1500);
            // Flag that the first rendering is done
            first_grouping.current = false;
        }
    }, [group_by_years.value, grid_ready.value, columns]);

    const rows = React.useMemo<Row<A>[]>(() => {
        let actions_rows = actions.map(a => ({
            ...a,
            tr_intervention_type: lg.getStaticText(a.intervention_type),
            tr_time_range: lg.getStaticText(OPTIONS.time_range[a.time_range || "unknown"]),
        }));

        if (show_group_tickets.value || props.group_tickets_state === "group") {
            let group_tickets_children = actions_rows.filter(a => a.ticket_type === "group").map(a => a.sub_tickets).flat();
            actions_rows = actions_rows.filter(a => !group_tickets_children.includes(a._id));
        }
        else if (props.group_tickets_state !== "both") actions_rows = actions_rows.filter(a => a.ticket_type !== "group");
        if (!show_suggestions.value) actions_rows = actions_rows.filter(a => a.context_origin !== "suggestion_lifespan_th" && a.context_origin !== "suggestion_lifespan_audit");

        if (typeof process_rows === "function") return process_rows(actions_rows);
        else return actions_rows as any;
    }, [actions, lg, show_suggestions.value, show_group_tickets, process_rows, props.group_tickets_state]);

    const extra_buttons = React.useMemo<G.TableProps<Row<A>>["extra_buttons"]>(() => {
        let hide_buttons = TB.arrayWrapper(props.hide_buttons);
        let buttons = [] as T.OnlyArray<G.TableProps<Row<A>>["extra_buttons"]>;
        if (!hide_buttons.includes("toggle")) buttons.push({
            onClick: show_suggestions.toggle,
            label: lg.getStaticText(show_suggestions.value ? TC.CAPEX_TABLE_SUGGESTIONS_HIDE : TC.CAPEX_TABLE_SUGGESTIONS_SHOW),
        });
        if (!hide_buttons.includes("toggle_ticket_group")) buttons.push({
            onClick: show_group_tickets.toggle,
            icon: { element: "<i class='fa fa-eye me-2'></i>" },
            label: lg.getStaticText(TC.CAPEX_TABLE_TOGGLE_TICKET_GROUP),
        });
        if (!hide_buttons.includes("group")) buttons.push({
            onClick: group_by_years.toggle,
            icon: { element: "<i class='fa fa-clock me-2'></i>" },
            label: lg.getStaticText(group_by_years.value ? TC.CAPEX_TABLE_GROUP_RANGE : TC.CAPEX_TABLE_GROUP_YEAR),
        });
        if (Array.isArray(props.buttons)) buttons.push(...props.buttons);
        else if (props.buttons) buttons.push(props.buttons);
        return buttons;
    }, [lg, show_suggestions, group_by_years, show_group_tickets, props.buttons, props.hide_buttons]);

    const on_edit_table = React.useCallback<G.TableProps<Row<A>>["onValueChange"]>(params => {
        if (props.read_only) return;
        let change = onValueChange?.(params) || "to_process";
        if (change === "to_process") {
            let field = params.colDef.field as keyof Row;
            let date_as_years = null;
            if (field === "start_date") date_as_years = new Date(params.newValue)?.getFullYear?.();

            if (field === "cost" || field === "start_date") {

                const updatePromise = new Promise<"canceled" | Row[]>((resolve, reject) => {
                    loading.setTrue();
                    // A ticket was edited
                    if (TB.mongoIdValidator(params.data._id)) {
                        let api_params = {
                            _id: params.data._id,
                            old_value: params.oldValue,
                            new_value: params.newValue,
                            field: field === "cost" ? "cost" : "start",
                        } as Parameters<typeof S.update_ticket_field>[0];
                        // Reset the end date
                        if (field === "start_date") api_params.extra_update = { end: "" };
                        S.update_ticket_field(api_params).then(({ data }) => {
                            if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                                if (confirmed) S.update_ticket_field({ ...api_params, force_update: true }).then(({ data }) => {
                                    if (data === "changed") reject("Error");
                                    else resolve(data);
                                }).catch(reject);
                                else resolve("canceled");
                            });
                            else resolve(data);
                        }).catch(reject);
                    }
                    else {
                        let api_params = {
                            old_value: params.oldValue,
                            _id: params.data.element_id,
                            force_update: field === "start_date",
                            new_value: field === "cost" ? params.newValue : date_as_years,
                            field: field === "cost" ? "replacement_cost" : "estimated_end_of_life",
                        } as Parameters<typeof S.update_equipment_field>[0];

                        S.update_equipment_field(api_params).then(({ data }) => {
                            if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                                if (confirmed) S.update_equipment_field({ ...api_params, force_update: true }).then(({ data }) => {
                                    if (data === "changed") reject("Error");
                                    else S.getCapexRows({ roots: api_params._id }).then(({ data }) => resolve(data)).catch(reject);
                                }).catch(reject);
                            });
                            else S.getCapexRows({ roots: api_params._id }).then(({ data }) => resolve(data)).catch(reject);
                        }).catch(reject);
                    }
                });

                updatePromise
                    .then(rows => {
                        console.log(params.data, rows);
                        if (rows !== "canceled") set_actions(p => p.map(row => {
                            let is_replacement = !TB.mongoIdValidator(row._id);
                            let new_row = rows.find(r => is_replacement ? r.element_id === row.element_id && !TB.mongoIdValidator(r._id) : r._id === row._id);
                            if (!new_row) return row;
                            else if (is_replacement) return { ...new_row, _id: row._id };
                            else return new_row;
                        }))
                    })
                    .catch(M.Alerts.updateError)
                    .finally(loading.setFalse);
            }
        }
    }, [loading, set_actions, onValueChange, props.read_only]);

    const set_grid_ready = React.useCallback<G.TableProps<Row<A>>["onReadyGrid"]>(grid => {
        onReadyGrid?.(grid);
        grid_ready.setTrue();
    }, [grid_ready, onReadyGrid]);

    React.useImperativeHandle(ref, () => ({ table, rows, actions }), [rows, actions]);

    return <div className="w-100">
        <C.Spinner status={status}>
            <G.Table<Row<A>>
                sideBar
                rows={rows}
                ref={table}
                status={status}
                columns={columns}
                columns_base="all_but_edit"
                onReadyGrid={set_grid_ready}
                onValueChange={on_edit_table}
                extra_buttons={extra_buttons}
                loading={status === "load" || loading.value}
                adaptableId={props.origin || TABS.CAPEX_TABLE}
                autoFit={React.useMemo(() => ["context_origin", "element_name", "replacement_action"], [])}
            />
        </C.Spinner>
    </div>;
};

export const Capex = React.forwardRef(RenderCapex) as <A>(props: CapexProps<A> & Partial<Record<"ref", React.ForwardedRef<CapexRef<A>>>>) => React.ReactElement;

export const CapexContext: React.FC = () => {
    const [roots] = H.useRoots();
    H.useCrumbs(TC.TAB_CAPEX_PLAN);
    H.useAuth({ tabName: TABS.CAPEX_TABLE });
    return <Capex context={roots} origin={TABS.CAPEX_TABLE} />;
}