import _ from 'lodash';
import { Flex } from '../../Common';
import { BlankModal } from '../Modal';
import { NoteManager, Form } from '../Form';
import { useLanguage } from '../../hooks';
import { Tab, Tabs } from '@material-ui/core';
import { TC, REGEX, TB, FP } from "../../Constants";
import { Accordion, Button } from 'react-bootstrap';
import { useState, useCallback, useMemo, useRef } from 'react';

const formPanelCode = "form";
const notePanelCode = "note";
const remarquesPanel = "rem";

const TEXT_CODES = [TC.GLOBAL_EDIT, TC.GLOBAL_CANCEL];

const PopUpForms = ({
    form,
    title,
    idForm,
    pathForm,
    override,
    noteInfos,
    extraData,
    remarques,
    updateRem,
    noDbSaving,
    handleForm,
    submission,
    extraPanels,
    idSubmission,
    startLoading,
    hideForm = false,
    readOnly = false,
    showNotes = false,
    sendBackFormId = true,
    sendBackFullObj = false,
    defaultKey = formPanelCode,
    ...props
}) => {
    const submitButtonRef = useRef();
    const lg = useLanguage(TEXT_CODES);
    const [editRem, setEditRem] = useState({ current: null, updated: [] });
    const [activePanel, setActivePanel] = useState(defaultKey || formPanelCode);

    //#region Note Panel
    const noteOrigin = useMemo(() => noteInfos?._id, [noteInfos]);
    const showNotePanel = useMemo(() => (readOnly || showNotes) && TB.mongoIdValidator(noteOrigin), [noteOrigin, readOnly, showNotes]);
    const noteContent = useMemo(() => showNotePanel && <NoteManager origin={noteOrigin} />, [showNotePanel, noteOrigin]);
    //#endregion

    //#region Form Panel
    const submitForm = useCallback(() => submitButtonRef?.current?.save?.(), []);

    const formClosed = useCallback(() => {
        updateRem?.(editRem.updated);
        handleForm?.({}, 2/* CLOSE_FORM */)
    }, [handleForm, updateRem, editRem]);

    const formSubmitted = useCallback((formData) => {
        if (typeof startLoading === "function") startLoading?.();
        updateRem?.(editRem.updated);
        if (sendBackFullObj) handleForm?.(formData);
        else {
            let data = formData.data;
            //Add the _id to the data send by the form if there is one
            if (formData._id !== undefined) data._id = formData._id;
            //Add the formId
            if (sendBackFormId) data.form = formData.form;
            handleForm?.(data);
        }
    }, [handleForm, sendBackFormId, sendBackFullObj, startLoading, updateRem, editRem]);

    const { formPath, formId, submissionId } = useMemo(() => {
        if ([idForm, pathForm].some(TB.validString)) return { formPath: pathForm, formId: idForm, submissionId: idSubmission }

        if (!TB.validString(form)) return {};
        let ids = form.match(REGEX.MONGO_ID_REGEX_LOOSE);

        if (Array.isArray(ids) && ids.length === 2) return { formId: ids[0], submissionId: ids[1] };

        let paths = FP.ALL_OBJECT_TYPES.filter(path => form.includes(path));
        if (paths.length > 0) {
            // The path is the one that is the longest, cause it matched the more
            let closestPath = _.maxBy(paths, p => p.length);

            if (Array.isArray(ids) && ids.length === 1) return { formPath: closestPath, submissionId: ids[0] };
            return { formPath: closestPath };
        }
        else if (Array.isArray(ids) && ids.length === 1 && paths.length === 0) return { formId: ids[0] };
        return {};
    }, [form, idForm, idSubmission, pathForm]);

    const formPanelRegular = useMemo(() => <Form
        _id={formId}
        path={formPath}
        readOnly={readOnly}
        extraData={extraData}
        api={submitButtonRef}
        onSave={formSubmitted}
        noDbSaving={noDbSaving}
        submissionId={submissionId}
        forcedSubmission={TB.submissionToArrayUpdate(submission)}
    />, [formPath, formId, submissionId, formSubmitted, extraData, noDbSaving, submission, readOnly]);

    const formPanel = useMemo(() => formPanelRegular, [formPanelRegular]);
    //#endregion

    //#region Remarques Panel
    const vRemarques = useMemo(() => {
        return TB.getArray(remarques)
            .filter(TB.validSubmission)
            .map(s => editRem.updated.filter(e => e._id === s._id)[0] || s);
    }, [remarques, editRem]);

    const getRemButton = useCallback(id => {
        if (editRem.current === id) return <Button variant="danger" onClick={(e) => { e.preventDefault(); setEditRem(p => ({ ...p, current: null })) }}>
            <i className='fa fa-times me-2'></i>
            {lg.getStaticElem(TC.GLOBAL_CANCEL)}
        </Button>
        return <Button onClick={(e) => { e.preventDefault(); setEditRem(p => ({ ...p, current: id })) }}>
            <i className='fa fa-pencil-alt me-2'></i>
            {lg.getStaticElem(TC.GLOBAL_EDIT)}
        </Button>
    }, [editRem, lg]);

    const onSubmitDone = useCallback((sub) => setEditRem(p => {
        let edited = p.updated.filter(s => s._id !== sub._id).concat(sub);
        return { current: null, updated: edited };
    }), []);

    const remPanel = useMemo(() => <Accordion className='mt-3'>
        {vRemarques.map(r => <Accordion.Item key={r._id} eventKey={r._id}>
            <Accordion.Header>{r.data.description}</Accordion.Header>
            <Accordion.Body>
                <Flex className='w-100 me-3' alignItems='center' justifyContent='end'>
                    {getRemButton(r._id)}
                </Flex>

                <Form
                    path={FP.REMARQUES_DEFAULTS}
                    readOnly={editRem.current !== r._id}
                    onSave={onSubmitDone}
                    submissionId={r._id}
                />
            </Accordion.Body>
        </Accordion.Item>)}
    </Accordion>, [vRemarques, editRem, getRemButton, onSubmitDone]);

    const showRems = useMemo(() => vRemarques.length > 0, [vRemarques.length]);
    //#endregion

    //#region Panels
    const vExtraPanels = useMemo(() => {
        let p = TB.arrayWrapper(extraPanels)
            .filter(p => TB.validObject(p) && TB.validString(p.code) && TB.validString(p.label) && p.content)
            .map(p => ({ ...p, show: true }));

        return p;
    }, [extraPanels]);

    const panels = useMemo(() => [
        { code: formPanelCode, label: "Formulaire", content: formPanel, show: !hideForm },
        { code: notePanelCode, label: "Notes", content: noteContent, show: showNotePanel },
        { code: remarquesPanel, label: "Remarques & Defaults", content: remPanel, show: showRems },
        ...vExtraPanels,
    ], [showNotePanel, hideForm, formPanel, noteContent, vExtraPanels, showRems, remPanel]);

    const visiblePanels = useMemo(() => panels.filter(({ show }) => show), [panels]);

    const panelBar = useMemo(() => {
        if (panels.filter(p => p.show).length <= 1) return null;
        return <Tabs value={activePanel} onChange={(e, val) => setActivePanel(val)}>
            {panels.map(p => p.show && <Tab key={p.code} label={p.label} value={p.code} />)}
        </Tabs>
    }, [panels, activePanel]);

    const content = useMemo(() => {
        if (visiblePanels.length === 1) return visiblePanels[0].content;
        return visiblePanels.filter(({ code }) => code === activePanel)[0]?.content;
    }, [visiblePanels, activePanel]);

    const contentContainer = useMemo(() => <div style={{ minHeight: "70vh" }}>{content}</div>, [content]);
    //#endregion

    //#region Modal
    const showSaveButton = useMemo(() => !readOnly && activePanel === formPanelCode, [activePanel, readOnly]);

    const modalFooter = useMemo(() => !showSaveButton ? undefined : <div>
        <button onClick={submitForm} className='btn btn-primary'>
            <i className='fa fa-save mr-2'></i>
            {"Sauvergarder"}
        </button>
    </div>, [showSaveButton, submitForm]);

    const modalProps = useMemo(() => ({
        size: "lg",
        onQuit: formClosed,
        footer: modalFooter,
        maxBodyHeight: "75vh",
        title: TB.validString(title) ? title : TB.isTextCode(formPath) ? formPath : TC.FORMS,
    }), [modalFooter, formPath, formClosed, title]);
    //#endregion

    return <BlankModal {...modalProps}>
        {panelBar}
        {contentContainer}
    </BlankModal>
}

export default PopUpForms;