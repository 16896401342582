import './modal.scss';
import { useLanguage } from '../../hooks';
import { ReduxWrapper } from '../../Common';
import renderInContainer from './getContainer';
import React, { FC, useEffect, useMemo } from "react";
import { TB } from "../../Constants";

type LoaderProps = {
    isPopUp?: boolean;
    message?: string | null;
    lowerZIndex?: boolean;
}

const Loader: FC<LoaderProps> = ({ message = null, isPopUp = true, lowerZIndex = false, ...props }) => {
    const { getStaticText, fetchStaticTranslations } = useLanguage();

    //#region Message
    const isMessageCode = useMemo(() => TB.isTextCode(message), [message]);
    const translatedMessage = useMemo(() => TB.validString(message) ? (isMessageCode ? getStaticText(message) : message) : null, [message, getStaticText, isMessageCode]);
    const validMessage = useMemo(() => TB.validString(translatedMessage) ? translatedMessage : null, [translatedMessage]);

    useEffect(() => {
        if (TB.isTextCode(message)) fetchStaticTranslations([message]);
    }, [message, isMessageCode, fetchStaticTranslations]);

    const messageClassName = useMemo(() => "loader_message" + (isPopUp ? "" : "_inline"), [isPopUp])
    const messageSpan = useMemo(() => validMessage && <span className={messageClassName}>{validMessage}</span>, [validMessage, messageClassName]);
    //#endregion

    //#region Pop Up + inline Loader
    const modalLoaderStyle = useMemo(() => lowerZIndex ? { zIndex: 1000 } : undefined, [lowerZIndex]);

    const popUpLoader = useMemo(() => <div className="loader_bg_modal" style={modalLoaderStyle}>
        {messageSpan}
        <div className="loader"></div>
    </div>, [messageSpan, modalLoaderStyle]);

    const inlineLoader = useMemo(() => <div className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: "5rem" }}>
        {messageSpan}
        <div className="loader inline-loader"></div>
    </div>, [messageSpan]);
    //#endregion

    return isPopUp ? popUpLoader : inlineLoader;
}

const WrappedLoader: FC<LoaderProps> = ({ ...props }) => <ReduxWrapper><Loader {...props} /></ReduxWrapper>;
export default WrappedLoader;

export const renderLoader = (message?: string) => {
    let [render, dismount] = renderInContainer(undefined, 1004);
    if (render && dismount) {
        render(<WrappedLoader message={message} />);
        return dismount;
    }
}