import React from "react";
import * as Table from "../../Gestion";

export type RemarquesProps = {
    /** The _id of the asset */
    asset: string;
    /** Do not allow any edits */
    read_only: boolean;
}

const Remarques: React.FC<RemarquesProps> = props => <Table.Remarques read_only={props.read_only} context={{ roots: props.asset }} />;

export default Remarques;