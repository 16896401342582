import "./Slide.scss";
import React from "react";
import * as M from "../../../Modal";
import * as BS from "react-bootstrap";
import * as H from "../../../../hooks";
import * as C from "../../../../Common";
import { T, TB } from "../../../../Constants";

type SlideMenuProps = T.DiaMenuProps & { open: boolean, onClose: (closed: boolean) => void };

const Menu: React.FC<T.MakeRequired<SlideMenuProps, "onPick">> = ({ open, node, onClose, onPick, ...props }) => {
    const lg = H.useLanguage();

    //#region Colors
    const { color, isWhite } = React.useMemo(() => ({ color: TB.getColor(node.color) || "", isWhite: TB.isColorWhite(node.color) }), [node.color]);
    //#endregion

    //#region Image
    const icon = React.useMemo(() => TB.iconIdToUrl(node.icon), [node.icon]);

    const popUpImg = React.useCallback(() => {
        if (node.picture) M.renderCarousel({ images: [{ title: node.title, src: node.picture }] });
    }, [node.picture, node.title]);
    //#endregion

    //#region Render Button
    const renderButton = React.useCallback((right: T.DiaRight) => <C.Button
        key={right.code}
        className="singleButton"
        title={lg.getStaticText(right.code)}
        onClick={() => onPick?.(right.code, node)}
        children={<i className={"fa fa-" + right.icon} />}
        variant={"outline-" + (isWhite ? "dark" : "light")}
    />, [lg, onPick, isWhite, node]);
    //#endregion

    return <BS.Offcanvas show={open} onHide={() => onClose(false)} placement="end" backdropClassName=''>
        <BS.Offcanvas.Header closeButton closeVariant={isWhite ? undefined : "white"} style={{ background: color }}>
            <C.Flex direction="column">
                <BS.Offcanvas.Title className={"fs-180 fw-bold " + (isWhite ? "light" : "dark")}>{node.title}</BS.Offcanvas.Title>
                {TB.isTextCode(node.path) && <BS.Offcanvas.Title className={isWhite ? "light" : "dark"}>({lg.getStaticText(node.path)})</BS.Offcanvas.Title>}
            </C.Flex>
        </BS.Offcanvas.Header>
        <BS.Offcanvas.Body style={{ background: color }}>
            {node.picture && <div className="buildImgWrapper">
                <img className={`buildingImg ${isWhite ? "dark" : ""}`} src={node.picture} alt="" title={node.title} onClick={popUpImg} />
            </div>}
            <div className="menuBody">
                {icon && <img className="background-img" src={icon} alt="" />}
                <div className="buttonGroups">
                    {node.rights.map(renderButton)}
                </div>
            </div>
        </BS.Offcanvas.Body>
    </BS.Offcanvas>
}

export default Menu;