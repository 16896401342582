import React from "react";
import * as I from "../types";
import * as C from "../../../../Common";

export type Props<A = Record<string, any>> = {
    /** Callback when the button is pressed */
    action?: (data: A) => void;
    /** Should the button be disabled ? */
    isDisabled?: boolean | ((data: A) => boolean);
    /** Should the button be shown ? */
    isHidden?: boolean | ((data: A) => boolean);
    /** Props for the button component */
    buttonProps?: C.ButtonProps | ((data: A) => C.ButtonProps);
    /** The content of the button */
    content?: C.ButtonProps["children"] | ((data: A) => C.ButtonProps["children"]);
}

export const type = "agCustomActionButton";

export const Cell = React.forwardRef<{}, I.CellProps<Props>>((props, ref) => {

    const elements = React.useMemo(() => {
        let disabled: boolean;
        let content: Props["content"];
        let hidden: Props["isHidden"];
        let button_props: Props["buttonProps"];
        let params = props.colDef?.params || {};

        if (typeof params.content === "function") content = params.content(props.data);
        else content = params.content;
        if (typeof params.isDisabled === "function") disabled = params.isDisabled(props.data);
        else disabled = params.isDisabled || false;
        if (typeof params.isHidden === "function") hidden = params.isHidden(props.data);
        else hidden = params.isHidden;
        if (typeof params.buttonProps === "function") button_props = params.buttonProps(props.data);
        else button_props = params.buttonProps;

        return { content, disabled, hidden, button_props };
    }, [props.colDef?.params, props.data]);

    if (elements.hidden || props.node.group) return null;
    return <div className="w-100 text-center m-0">
        <C.Button
            {...elements.button_props}
            children={elements.content}
            disabled={elements.disabled}
            onClick={() => props.colDef?.params?.action?.(props.data)}
        />
    </div>;
});