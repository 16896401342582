import _ from "lodash";
import React from "react";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as N from "../Navigator";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import * as US from "../../services/user.service";
import { RESOURCE, URL, T, TB, TC } from "../../Constants";

export type PortfolioFormProps = {
    /** An existing selection, or a default on */
    submission?: T.Portfolio;
    /** Callback after the selection was edited or created */
    onSubmit: (portfolio: T.Portfolio) => void | any;
};

type TempImage = { b64?: string; picName?: string; };
type ToggleTypes = (array: string[], id: string) => string[];

const PortfolioForm: React.FC<PortfolioFormProps> = ({ submission, onSubmit, ...props }) => {
    const [{ user }] = H.useAuth();
    const miss_name = H.useBoolean(false);
    const ogImageLink = React.useRef<string>("");
    const [{ b64, picName }, setTempImage] = React.useState<TempImage>({});
    const [portfolio, setPortfolio] = React.useState<T.Portfolio>(submission || { name: "", user: "", buildings: [], sites: [], clients: [] });

    //#region User
    React.useEffect(() => TB.mongoIdValidator(user?._id) ? setPortfolio(prev => ({ ...prev, user: user._id })) : undefined, [user]);
    //#endregion

    //#region Media
    React.useEffect(() => {
        if (TB.validString(submission?.image)) ogImageLink.current = submission.image;
    }, [submission]);

    const mediaSrc = React.useMemo(() => {
        if (TB.validString(b64)) return b64;
        if (TB.validString(portfolio.image)) return portfolio.image;
        return "";
    }, [b64, portfolio]);

    const removeImage = React.useCallback(() => {
        setTempImage({});
        setPortfolio(prev => ({ ...prev, image: "" }));
    }, []);

    const onErrorImage = React.useCallback(event => TB.validObject(event.target) ? event.target.src = `${URL.API_URL}resources/img/${RESOURCE.NO_IMG_FOUND}` : undefined, []);

    const importNewImg = React.useCallback(() => {
        M.askFilePicker({ image: true, fileMaxSize: "15MB", modal: { disableEscapeKeyDown: true } }).then(pictures => {
            if (pictures) {
                let picture = pictures[0];
                if (picture.url) setTempImage({ b64: picture.url, picName: picture.name });
            }
        });
    }, []);
    //#endregion

    //#region Save
    const onSave = React.useCallback(async () => {
        if (!TB.validString(portfolio.name)) miss_name.setTrue();
        else {
            let { image, _id, ...data } = portfolio;

            // Update/Create the portfolio
            let bulk = [];

            if (TB.mongoIdValidator(_id)) bulk.push({
                updateOne: {
                    filter: { _id: TB.validString(_id) ? _id : "" },
                    update: data,
                    upsert: true,
                }
            });
            else bulk.push({ insertOne: { document: data } });

            let replyBulk = await US.portfoliosBulk(bulk);
            const showAlert = (message = "SAVE FAILED") => M.renderAlert({ type: "error", message, delay: 10 });

            if (replyBulk.data?.ok !== 1) showAlert();
            else if (!TB.mongoIdValidator(_id) && !TB.mongoIdValidator(replyBulk?.data?.insertedIds?.[0]?._id)) showAlert();
            else {
                if (!TB.mongoIdValidator(_id)) _id = replyBulk.data.insertedIds[0]._id;
                let failedUpdatePic = false, url = "";
                let deletedAndUnreplacedPic = TB.validString(ogImageLink.current) && !TB.validString(image);

                // Handle the image
                if (TB.validString(picName)) S.moveTempFile({ name: picName, id: _id })
                    .then(() => US.updatePortfolios({ _id }, { image: URL.CRAFT_FILE_URL(_id, picName) }));
                // Remove old image 
                else {
                    if (deletedAndUnreplacedPic) US.deleteLocalFile(ogImageLink.current);
                    M.Alerts.success_alert();
                }

                let newPortfolio = { ...portfolio };
                newPortfolio._id = _id;
                if (deletedAndUnreplacedPic || failedUpdatePic) newPortfolio.image = "";
                else if (TB.validString(url)) newPortfolio.image = url;
                if (typeof onSubmit === "function") onSubmit(newPortfolio);
                else setPortfolio(newPortfolio);
            }
        }
    }, [portfolio, picName, miss_name, onSubmit]);
    //#endregion

    //#region Sites & Buildings options
    const toggleItem = React.useCallback<N.SearchContextProps["onSelect"]>((id, name, type) => setPortfolio(prev => {
        if (!TB.mongoIdValidator(id)) return prev;
        const removeItem: ToggleTypes = (array, id) => Array.isArray(array) ? array.filter(item => item !== id) : [];
        const addItem: ToggleTypes = (array, id) => Array.isArray(array) ? _.uniq(array.concat(id)) : [];

        let isChecked = false;
        if (type === "site") isChecked = prev.sites.includes(id);
        else if (type === "client") isChecked = prev.clients.includes(id);
        else if (type === "building") isChecked = prev.buildings.includes(id);
        const toggle: ToggleTypes = (array, id) => (isChecked ? removeItem : addItem)(array, id);

        return {
            ...prev,
            sites: type === "site" ? toggle(prev.sites, id) : prev.sites,
            clients: type === "client" ? toggle(prev.clients, id) : prev.clients,
            buildings: type === "building" ? toggle(prev.buildings, id) : prev.buildings,
        }
    }), []);

    const select_many_items = React.useCallback<N.SearchContextProps["select_many"]>((items, type) => setPortfolio(prev => {
        let sites = [], clients = [], buildings = [];
        console.log(items);
        if (type === "building") buildings = items.map(i => i.id);
        else if (type === "client") clients = items.map(i => i.id);
        else if (type === "site") sites = items.map(i => i.id);
        return { ...prev, sites, buildings, clients };
    }), []);
    //#endregion

    return <C.Flex direction="column" className="h-100">
        <BS.Row>
            <BS.Col md={2}>
                <div className="position-relative w-100">
                    <img className="w-100" style={{ maxHeight: "10rem", objectFit: "contain" }} src={mediaSrc} onError={onErrorImage} alt="" />
                    <div className="position-absolute top-0 start-0 p-1">
                        <button onClick={removeImage} className="btn fs-85 btn-sm btn-danger mr-1"><i className="fa fa-times"></i></button>
                        <button onClick={importNewImg} className="btn fs-85 btn-sm btn-primary"><i className="fa fa-pencil-alt"></i></button>
                    </div>
                </div>
            </BS.Col>
            <BS.Col md={4}>
                <C.Form.TextField
                    required
                    label={TC.GLOBAL_NAME}
                    value={portfolio.name}
                    placeholder={TC.GLOBAL_NAME}
                    onChange={name => setPortfolio(prev => ({ ...prev, name }))}
                    error={miss_name.value ? { code: TC.GLOBAL_REQUIRED_FIELD } : undefined}
                />
            </BS.Col>
        </BS.Row>

        <BS.Row className="flex-grow-1">
            <BS.Col md={12}>
                <N.SearchContext
                    portfolio
                    onSelect={toggleItem}
                    select_many={select_many_items}
                    selected={React.useMemo(() => ({
                        site: portfolio.sites,
                        client: portfolio.clients,
                        building: portfolio.buildings,
                    }), [portfolio.buildings, portfolio.clients, portfolio.sites])}
                />
            </BS.Col>
        </BS.Row>

        <div className="position-fixed p-2 border w-100 bottom-0 start-0 bg-white">
            <C.Button
                icon="save"
                onClick={onSave}
                text={TC.GLOBAL_SAVE}
                style={{ float: "right" }}
            />
        </div>
    </C.Flex>
}

export default PortfolioForm;