import React from "react";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import { FP, T, TB, TC } from "../../../Constants";

//#region Types
export type RentProps = {
    /** The id of the cell (emplacement) */
    origin: string;
    /** Should the form be in a popup ? */
    popup?: boolean;
    /** Extra modal style params */
    modal?: M.StyleModalProps;
    /** Callback for quitting */
    onQuit?: () => void;
}

type Data = ReturnType<T.API.Utils.Rent.GetRentInfo>;
//#endregion

const Rent: React.FC<RentProps> = props => {
    const [forms_ids] = H.useFormIds();
    const was_edited = H.useBoolean(false);
    const [data, set_data, status] = H.useAsyncState<Data>(null);

    //#region Load data
    React.useEffect(() => {
        let isSubscribed = true;
        if (props.origin) S.getRentInfo(props.origin)
            .then(({ data }) => isSubscribed && set_data(data, "done"))
            .catch(() => isSubscribed && set_data(null, "error"));
        else set_data(null, "error");
        return () => { isSubscribed = false };
    }, [props.origin, set_data]);
    //#endregion

    //#region Misc & Events
    const forms = React.useMemo(() => ({
        bail: forms_ids[FP.BAIL_FORM],
        emp: forms_ids[FP.EMPLACEMENT_FORM],
    }), [forms_ids]);

    const renter = React.useMemo(() => {
        if (!data?.enterprise_options || !data?.bail?.renter) return null;
        return data.enterprise_options.filter(e => e.value === data.bail.renter)[0] || null;
    }, [data?.enterprise_options, data?.bail?.renter]);

    const update = React.useMemo(() => ({
        field: (field: keyof Data["emplacement"], value: any) => {
            let params = {
                field,
                new_value: value,
                _id: props.origin,
                force_update: true,
                old_value: data.emplacement[field],
            } as Parameters<typeof S.update_emplacement_field>[0];

            // Cell is no longer rentable, must delete the bail (if there is one)
            if (field === "isRentable" && !value && data.bail) M.askConfirm({ text: TC.BAIL_MANAGER_NO_LONGER_RENTABLE }).then(() => {
                S.removeBail({ bail: data.bail._id, renter: data.bail.renter, enseigne: data.bail.enseigne }).then(() => {
                    set_data(p => ({ ...p, bail: undefined, emplacement: { ...p.emplacement, isRentable: false } }));
                    was_edited.setTrue();
                }).catch(M.Alerts.deleteError);
            });
            else S.update_emplacement_field(params).then(({ data }) => {
                if (data !== "changed") {
                    set_data(p => ({ ...p, emplacement: { ...p.emplacement, [field]: value } }));
                    was_edited.setTrue();
                }
            }).catch(M.Alerts.updateError);
        },
        delete_bail: () => M.askConfirm().then(confirmed => {
            if (confirmed) S.removeBail({ bail: data.bail._id, renter: data.bail.renter, enseigne: data.bail.enseigne }).then(() => {
                set_data(p => ({ ...p, bail: undefined, emplacement: { ...p.emplacement, isRentable: false } }));
                was_edited.setTrue();
            }).catch(M.Alerts.deleteError);
        }),
        update_bail_field: (field: keyof T.BailData, value: any) => {
            if (data?.bail?._id) S.update_bail_field({
                field,
                new_value: value,
                _id: data.bail._id,
                force_update: true,
                old_value: null,
            })
                .then(() => {
                    set_data(p => ({ ...p, bail: { ...p.bail, [field]: value } }));
                    was_edited.setTrue();
                }).catch(M.Alerts.updateError);
        },
        create_bail: () => {
            const renter_params = {
                isRequired: true,
                label: TC.BAIL_MANAGER_RENTER_LABEL,
                title: TC.BAIL_MANAGER_RENTER_LABEL,
                options: data.enterprise_options.filter(e => e.available),
                create_option: (text, resolve) => {
                    M.renderFormModal<T.ClientData>({ forcedSubmission: TB.submissionToArrayUpdate({ name: text }), path: FP.CLIENT_FORM })
                        .then(client => {
                            if (client) set_data(p => ({
                                ...p,
                                enterprise_options: p.enterprise_options.concat({
                                    available: true,
                                    value: client._id,
                                    label: client.data.name,
                                    franchised: client.data.isFranchise,
                                }),
                            }));
                            resolve(client?._id || null)
                        });
                },
            } as Parameters<typeof M.askSelect>[0];

            // Select the renter
            M.askSelect(renter_params).then(renter => {
                if (renter) new Promise<string>(resolve => {
                    const enseigne_params = {
                        label: TC.BAIL_MANAGER_ENSEIGNE_LABEL,
                        title: TC.BAIL_MANAGER_ENSEIGNE_LABEL,
                        options: data.enseigne_options.filter(e => e.enterprises.includes(renter)),
                    } as Parameters<typeof M.askSelect>[0];

                    if (enseigne_params.options.length === 0) resolve(undefined);
                    else M.askSelect(enseigne_params).then(resolve);
                }).then(enseigne => {
                    // User quit the modal
                    if (enseigne !== null) M.renderFormModal<T.BailData>({ path: FP.BAIL_FORM }).then(bail => {
                        if (bail) S.linkBail({ bail: bail._id, renter, enseigne, cell: props.origin })
                            .then(() => {
                                set_data(p => ({ ...p, bail: { ...bail.data, _id: bail._id, renter, enseigne } }));
                                was_edited.setTrue();
                            }).catch(M.Alerts.updateError);
                    });
                });
            });
        },
        update_client_franchised: (value: boolean) => {
            S.updateRenterFranchised({ renter: data.bail.renter, franchised: value }).then(() => {
                set_data(p => ({
                    ...p,
                    enterprise_options: p.enterprise_options.map(e => e.value === data.bail.renter ? { ...e, franchised: !!value } : e)
                }));
                was_edited.setTrue();
            }).catch(M.Alerts.updateError);
        },
        renter: (renter: string) => {
            S.updateBailRenter({ bail: data.bail._id, old_renter: data.bail.renter, new_renter: renter, enseigne: data.bail.enseigne })
                .then(() => {
                    set_data(p => ({ ...p, bail: { ...p.bail, renter, enseigne: undefined } }));
                    was_edited.setTrue();
                }).catch(M.Alerts.updateError);
        },
        enseigne: (enseigne: string) => {
            S.updateBailEnseigne({ renter: data.bail.renter, new_enseigne: enseigne, old_enseigne: data.bail.enseigne })
                .then(() => {
                    set_data(p => ({ ...p, bail: { ...p.bail, enseigne } }));
                    was_edited.setTrue();
                }).catch(M.Alerts.updateError);
        },
    }), [data, props.origin, set_data, was_edited]);
    //#endregion

    //#region Content
    const content = React.useMemo(() => <C.Spinner status={status}>
        <BS.Row>
            <BS.Col md={4}>
                <C.Form.TextField
                    value={data?.emplacement?.name}
                    label={{ prop: "name", _id: forms.emp }}
                    onChange={v => update.field("name", v)}
                />
            </BS.Col>
            <BS.Col md={4}>
                <C.Form.RadioBool
                    labelPosition="top"
                    value={data?.emplacement?.isRentable}
                    tooltip={TC.BAIL_MANAGER_MUST_BE_RENTABLE}
                    onChange={v => update.field("isRentable", v)}
                    label={{ prop: "isRentable", _id: forms.emp }}
                />
            </BS.Col>
        </BS.Row>
        {/* Creates a new Bail */}
        {!data?.bail && <BS.Row>
            <BS.Col>
                <C.Button
                    icon="plus"
                    onClick={update.create_bail}
                    text={TC.BAIL_MANAGER_CREATE_BAIL}
                    disabled={!data?.emplacement?.isRentable}
                />
            </BS.Col>
        </BS.Row>}
        {/* Show the existing bail */}
        {data?.bail && <>
            <BS.Row>
                <BS.Col md={12}>
                    <C.Flex className="mb-3" justifyContent="end">
                        <C.Button
                            size="sm"
                            icon="times"
                            variant="danger"
                            onClick={update.delete_bail}
                            text={TC.BAIL_MANAGER_REMOVE_BAIL}
                        />
                    </C.Flex>
                </BS.Col>
            </BS.Row>
            <BS.Row>
                <BS.Col>
                    <C.Form.Select
                        value={data.bail.renter}
                        onChange={r => update.renter(r)}
                        options={data.enterprise_options}
                        label={TC.BAIL_MANAGER_RENTER_LABEL}
                        typeahead={{ hideClearButton: true }}
                    />
                </BS.Col>
                <BS.Col>
                    <C.Form.RadioBool
                        labelPosition="top"
                        value={renter?.franchised}
                        label={TC.BAIL_MANAGER_IS_FRANCHISED}
                        onChange={v => update.update_client_franchised(v)}
                    />
                </BS.Col>
                <BS.Col>
                    <C.Form.Select
                        value={data.bail.enseigne}
                        options={data.enseigne_options}
                        onChange={e => update.enseigne(e)}
                        label={TC.BAIL_MANAGER_ENSEIGNE_LABEL}
                        typeahead={{
                            allowNewOption: true,
                            onAddOption: text => M.renderFormModal<T.EnseigneData>({ path: FP.ENSEIGNE_FORM, forcedSubmission: TB.submissionToArrayUpdate({ name: text }) })
                                .then(enseigne => {
                                    if (enseigne) {
                                        set_data(p => ({
                                            ...p,
                                            enseigne_options: p.enseigne_options.concat({
                                                value: enseigne._id,
                                                label: enseigne.data.name,
                                                enterprises: [data.bail.renter],
                                            })
                                        }));
                                        update.enseigne(enseigne._id);
                                    }
                                }),
                        }}
                    />
                </BS.Col>
            </BS.Row>
            <BS.Row>
                <BS.Col>
                    <C.Form.DateTime
                        value={data.bail.start}
                        label={{ prop: "start", _id: forms.bail }}
                        onChange={v => update.update_bail_field("start", v)}
                    />
                </BS.Col>
                <BS.Col>
                    <C.Form.DateTime
                        value={data.bail.end}
                        label={{ prop: "end", _id: forms.bail }}
                        onChange={v => update.update_bail_field("end", v)}
                    />
                </BS.Col>
                <BS.Col>
                    <C.Form.NumField
                        value={data.bail.break}
                        label={{ prop: "break", _id: forms.bail }}
                        onChange={v => update.update_bail_field("break", v)}
                    />
                </BS.Col>
                <BS.Col>
                    <C.Form.TextField
                        value={data.bail.loadDistribution}
                        label={{ prop: "loadDistribution", _id: forms.bail }}
                        onChange={v => update.update_bail_field("loadDistribution", v)}
                    />
                </BS.Col>
            </BS.Row>
        </>}
    </C.Spinner>, [forms, data, update, status, renter, set_data]);
    //#endregion

    if (props.popup) return <M.BlankModal
        {...props.modal}
        children={content}
        onQuit={props.onQuit}
        size={props.modal?.size || "md"}
        title={props.modal?.title || TC.BAIL_MANAGER}
    />
    return <>
        {content}
    </>;
}

export default Rent;