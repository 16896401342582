import { TB } from "../../Constants";
import { useLanguage } from "../../hooks";
import { FormControl } from "react-bootstrap";
import React, { FC, useEffect, useMemo } from "react";

//#region Types
type FeedbackProps = {
    /** The text to display in the feedback, no feedback if no text */
    text?: string;
    /** The type of feedback */
    isValid?: boolean;
    /** The size of the text */
    isTextSmall?: boolean;
}
//endregion

const Feedback: FC<FeedbackProps> = props => {
    const lg = useLanguage();

    //#region Text
    useEffect(() => {
        if (TB.isTextCode(props.text)) lg.fetchStaticTranslations([props.text]);
    }, [lg, props.text]);

    const validText = useMemo(() => {
        if (TB.isTextCode(props.text)) return lg.getStaticElem(props.text);
        if (TB.validString(props.text)) return props.text;
        return null;
    }, [lg, props.text]);
    //#endregion

    const type = useMemo(() => props.isValid ? "valid" : "invalid", [props.isValid]);

    return validText && <FormControl.Feedback type={type} className={`${props.isTextSmall ? "fs-85" : ""}`} style={{ display: "initial" }}>
        {validText}
    </FormControl.Feedback>
}

export default Feedback;