import React from "react";
import moment from "moment";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import { TB, T, TC } from "../../Constants";
import { EntityDataTable } from "../Gestion";
import { useSearchParams } from "react-router-dom";

//#region Types
type Params = {
    to?: Date;
    from?: Date;
    interval?: string;
    group: Parameters<T.API.Utils.Energy.EntityDataCsv>[0]["group"];
}

type Datasets = ReturnType<T.API.Utils.Datasets.GetDatasetItemTable>;

export type AlertDashboardsProps = {
    /** The list of datasets to display */
    datasets: string[];
}
//#endregion

//#region Constants
const TIME_GROUPS = [
    { label: TC.GP_GROUP_MIN, value: "minute" },
    { label: TC.GP_GROUP_HOUR, value: "hour" },
    { label: TC.GP_GROUP_DAY, value: "day" },
    { label: TC.GP_GROUP_WEEK, value: "week" },
    { label: TC.GP_GROUP_MONTH, value: "month" },
    { label: TC.GP_GROUP_QUARTER, value: "quarter" },
    { label: TC.GP_GROUP_YEAR, value: "year" },
] as T.Option<null, Params["group"]>[];
//#endregion

export const AlertDashboards: React.FC<AlertDashboardsProps> = props => {
    const lg = H.useLanguage();
    const exporting = H.useBoolean(false);
    const [html, set_html, status] = H.useAsyncState("");
    const [full_dataset, set_full_datasets] = H.useAsyncState<Datasets>([]);
    const datasets = React.useMemo(() => props.datasets || [], [props.datasets]);
    const [params, set_params] = React.useState<Params>({ group: "hour", interval: "15 DAY" });

    React.useEffect(() => {
        let isSubscribed = true;
        S.getDatasetItemTable(datasets)
            .then(({ data }) => isSubscribed && set_full_datasets(data, "done"))
            .catch(() => isSubscribed && set_full_datasets([], "error"));
        return () => {
            isSubscribed = false;
            set_full_datasets([], "load");
        }
    }, [datasets, set_full_datasets]);

    //#region Data
    React.useEffect(() => {
        let isSubscribed = true;
        let time = TB.getFromTo({
            interval: params.interval,
            to: params.to?.toISOString?.(),
            from: params.from?.toISOString?.(),
        });

        let ok_params = { ...time, lang: lg.prop, group: params.group, entity: datasets };

        S.html_report({ template: "Compteur", params: ok_params })
            .then(({ data }) => isSubscribed && set_html(data, "done"))
            .catch(() => isSubscribed && set_html("", "error"));
        return () => {
            isSubscribed = false;
            set_html("", "load");
        }
    }, [datasets, params, set_html, lg.prop]);

    const table_data = React.useMemo(() => {
        let data = [];
        let dataMatch = html.match(/%%%.*%%%/g);
        if (dataMatch !== null) try {
            let str = TB.replaceStringPart(dataMatch[0], "%%%", "").trim();
            data = JSON.parse(str);
        }
            catch (error) { data = [] };
        return data;
    }, [html]);
    //#endregion

    //#region Export
    const export_data = React.useCallback(() => {
        let time = TB.getFromTo({
            interval: params.interval,
            to: params.to?.toISOString?.(),
            from: params.from?.toISOString?.(),
        });
        // Set the state as exporting the data
        exporting.setTrue();
        S.entityDataCsv({ ...time, entities: datasets, group: params.group })
            .then(({ data }) => TB.aoaToExcel(data, moment().format("YYYYMMDD_HHmmss"), "Data"))
            .catch(() => M.Alerts.loadError({ message: TC.GLOBAL_FAIL_GEN_FILE }))
            .finally(exporting.setFalse);
    }, [datasets, params, exporting]);

    const export_button = React.useMemo(() => <C.Button
        onClick={export_data}
        text={TC.EXPORT}
        icon={{
            spinIcon: "sync",
            icon: "file-download",
            spin: exporting.value,
        }}
    />, [export_data, exporting.value]);
    //#endregion

    //#region Tools
    const group_time = React.useMemo(() => <C.Form.Select
        value={params.group}
        options={TIME_GROUPS}
        label={TC.GLOBAL_TIME_GROUP}
        onChange={group => set_params(p => ({ ...p, group }))}
    />, [params.group]);

    const timeSelector = React.useMemo(() => <C.TimeSelector
        interval={params.interval}
        min_relative_range="12 HOUR"
        to={params.to?.toISOString?.()}
        from={params.from?.toISOString?.()}
        onChangeInterval={interval => set_params(p => ({ ...p, interval, to: undefined, from: undefined }))}
        onChangeDatePicker={dates => set_params(p => ({ ...p, to: new Date(dates.to), from: new Date(dates.from), interval: undefined }))}
    />, [params.from, params.to, params.interval]);
    //#endregion

    return <C.Flex direction="column" className="w-100 px-4">

        {full_dataset.length > 0 && <C.Title className="my-2" level={3} children={full_dataset.map(d => d.name).join(", ")} />}

        <C.Flex alignItems="center" justifyContent="between" className="border border-1 bg-light rounded p-3 my-2">
            <div>{timeSelector}</div>
            <div className="w-50">{group_time}</div>
            <div>{export_button}</div>
        </C.Flex>

        <C.Spinner min_load_size="25vh" status={status}>
            <C.HtmlText html={html} no_full_height />
            <div style={{ height: "85vh" }}>
                <C.ReduxWrapper>
                    <EntityDataTable data={table_data} isFullHeight />
                </C.ReduxWrapper>
            </div>
        </C.Spinner>
    </C.Flex>;
}

export const AlertDashboardsStandalone: React.FC = () => {
    const [url_params] = useSearchParams();
    const datasets = React.useMemo(() => TB.findIds(url_params.get("datasets")), [url_params]);
    return <AlertDashboards datasets={datasets} />;
}