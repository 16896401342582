import React from 'react';
import * as M from "../../Modal";
import * as H from '../../../hooks';
import * as C from '../../../Common';
import * as BS from 'react-bootstrap';
import * as S from "../../../services";
import { DS, FP, RIGHTS, T, TB, TC } from "../../../Constants";

//#region Types
type DatasetFilter = { path?: string, nrj?: T.DataSet["type"], src?: T.DataSet["src"] };
type OptionsDatasets = T.Awaited<ReturnType<typeof S.getContextDatasetsOptions>>["data"];

export type AlarmFormProps = {
    /** The default dataset */
    alarm?: T.Alarm;
    /** Show the form in a popup */
    popup?: boolean;
    /** The ids of the datasets pre-selected */
    datasets: string[];
    /** The context to load datasets from */
    context: T.ContextParams;
    /** Props for the modal */
    modalProps?: M.StyleModalProps;
    /** Save callback */
    onSave?: (alarm: T.Alarm) => void;
    /** Only read the dataset, no edit */
    readOnly?: boolean;
};
//#endregion

//#region Constants
const DS_Options = DS.DATASETS;
const TEXT_CODES = [TC.ALARM_DATA_INCLUDE];

const getAlarm = (dataset = [], alarm?: T.Alarm): T.Alarm => {
    if (alarm && alarm?.id) {
        if (alarm.next_schedule_timestamp) return {
            ...alarm,
            next_schedule_timestamp: null,
            start_date: alarm.next_schedule_timestamp,
            /* To replace the previous values that were "threshold" */
            type: (alarm.type as any) === "threshold" ? "custom" : alarm.type,
        };
        else return alarm;
    }
    return {
        id: null,
        name: "",
        user: "",
        tags: [],
        period: "",
        active: true,
        type: "custom",
        considered_range: "",
        datasets_ids: dataset,
        data_group_period: "",
        data_group_aggregate: "sum",
        end_of_considered_range: "",
        start_date: new Date().toISOString(),
        data_include: [{ from: '00:00', to: "23:59", days: [0, 1, 2, 3, 4, 5, 6, 7] }],
    };
}
//#endregion

const AlarmForm: React.FC<AlarmFormProps> = ({ onSave, ...props }) => {
    const rights = H.useRights();
    const [{ userId }] = H.useAuth();
    const drawer = H.useBoolean(false);
    const lg = H.useLanguage(TEXT_CODES);
    const isSaving = H.useBoolean(false);
    const [errors, setErrors] = React.useState<T.Errors<T.Alarm>>({});
    const [dataset_filter, set_dataset_filter] = React.useState<DatasetFilter>({});
    const [alarm, setAlarm] = React.useState(getAlarm(props.datasets, props.alarm));
    const [note_tags, set_note_tags, note_tags_status] = H.useAsyncState<T.Option[]>([]);
    const [datasets, setDatasets, datasets_status] = H.useAsyncState<OptionsDatasets>([]);

    //#region Load datasets
    React.useEffect(() => {
        let isSubscribed = true;
        S.getContextDatasetsOptions(props.context)
            .then(({ data }) => isSubscribed && setDatasets(data, "done"))
            .catch(() => isSubscribed && setDatasets([], "error"));
        return () => { isSubscribed = false };
    }, [props.context, setDatasets]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (props.context) S.getNoteTags({ context: props.context, user: userId, type: "alarm" })
            .then(({ data }) => isSubscribed && set_note_tags(data, 'done'))
            .catch(() => isSubscribed && set_note_tags([], "error"));
        else set_note_tags([], "done");
        return () => {
            isSubscribed = false;
            set_note_tags([], "load");
        };
    }, [userId, props.context, set_note_tags]);
    //#endregion

    //#region Datasets Options
    const datasets_options = React.useMemo(() => {
        let options = datasets.map(d => ({ ...d, label: d.label + " - " + d.origin, name: d.label }));

        if (dataset_filter.src) options = options.filter(o => {
            if (alarm.datasets_ids.includes(o.value)) return true;
            return o.src === dataset_filter.src;
        });
        if (dataset_filter.path) options = options.filter(o => {
            if (alarm.datasets_ids.includes(o.value)) return true;
            return o.origin_type === dataset_filter.path;
        });
        if (dataset_filter.nrj) options = options.filter(o => {
            if (alarm.datasets_ids.includes(o.value)) return true;
            else if (dataset_filter.nrj === "GAS") return o.nrj === "GAS" || o.nrj === "GAS_HEAD";
            else if (dataset_filter.nrj === "ELEC") return o.nrj === "ELEC" || o.nrj === "ELEC_HEAD";
            else if (dataset_filter.nrj === "WATER") return o.nrj === "WATER" || o.nrj === "WATER_HEAD";
            else if (dataset_filter.nrj === "THCH") return o.nrj === "THCH" || o.nrj === "THCH_HEAD";
            else if (dataset_filter.nrj === "THFR") return o.nrj === "THFR" || o.nrj === "THFR_HEAD";
            else if (dataset_filter.nrj === "FUEL") return o.nrj === "FUEL" || o.nrj === "FUEL_HEAD";
            else if (dataset_filter.nrj === "ELEC_PPV") return o.nrj === "GAS" || o.nrj === "GAS_HEAD";
            else return true;
        });
        if (options.length === 0) return [];
        return [{ label: TC.GLOBAL_ALL, value: "all", origin_type: "", src: "manual" } as typeof options[number]].concat(options);
    }, [alarm.datasets_ids, dataset_filter, datasets]);

    const render_dataset_option = React.useCallback((option: typeof datasets_options[number]) => {
        if (option.value === "all") return <div>{option.label}</div>;

        let icon = "question", src_icon = "database";
        if (option.origin_type === FP.SITE_FORM) icon = "city";
        else if (option.origin_type === FP.EQUIPEMENT_FORM) icon = "cog";
        else if (option.origin_type === FP.BUILDING_FORM) icon = "building";
        else if (option.origin_type === FP.EMPLACEMENT_FORM) icon = "door-closed";

        if (option.src === "calculated") src_icon = "calculator";
        else if (option.src === "manual") src_icon = "hand-paper";

        return <div>
            <span>
                <i className={`fa fa-${icon} me-2`}></i>
                <i className={`fa fa-${src_icon} me-2`}></i>
            </span>
            <span>{option.name} - </span>
            <span className='fst-italic'>{option.origin}</span>
        </div>;
    }, []);
    //#endregion

    //#region Access
    const allowedEdit = React.useMemo(() => rights.isRightAllowed(RIGHTS.NRJ.WRITE_NRJ_TAGS), [rights]);
    const readOnly = React.useMemo(() => props.readOnly || !allowedEdit, [allowedEdit, props.readOnly]);

    const noAccessBanner = React.useMemo(() => !allowedEdit && !props.readOnly && <C.ErrorBanner
        canHide
        type="info"
        textCode={TC.ALARM_EDIT_NOT_ALLOWED}
    />, [allowedEdit, props.readOnly]);
    //#endregion

    //#region Reset Errors
    React.useEffect(() => {
        if (errors.name && alarm.name) setErrors(p => ({ ...p, name: undefined }));
    }, [errors.name, alarm.name]);
    //#endregion

    //#region Base form
    const add_tag = React.useCallback((text: string) => new Promise((resolve, reject) => {
        let submission = { name: text, users: [userId], sites: [], type: "alarm" } as T.NoteTag;
        S.createSubmission({ submission, path: FP.NOTE_TAG_FORM }).then(({ data }) => {
            let new_tag = data.submissions[0] as T.Submission<T.NoteTag>;
            if (new_tag) {
                let new_option = { label: new_tag.data.name, value: new_tag._id } as T.Option;
                set_note_tags(p => p.concat(new_option));
                setAlarm(p => ({ ...p, tags: p.tags.concat(new_option.value) }));
                resolve(new_option);
            }
            else resolve(null);
        }).catch(reject);
    }), [set_note_tags, userId]);

    const onChangeType = React.useCallback((type: T.Alarm["type"]) => {
        let preset = DS.PRESET_ALARMS[type];
        if (preset) {
            let time: Pick<T.Alarm, "end_of_considered_range" | "start_date">;
            if (type === "custom") time = { end_of_considered_range: "", start_date: "" };
            else {
                let now = new Date();
                // End of the range today at 23:59
                let end_range = new Date(now.getTime());
                // Set the time to 23:59
                end_range.setHours(23, 59, 59, 0);

                // Next schedule execution at 7am, the next day or the same day, whichever's closest
                let start_date = new Date();
                // Set the target time to 7AM
                start_date.setHours(7, 0, 0, 0);
                // We are past 7am today, so set it to tomorrow
                if (now.getTime() > start_date.getTime()) start_date = new Date(start_date.getTime() + (1000 * 60 * 60 * 24));
                time = {
                    start_date: start_date.toISOString(),
                    end_of_considered_range: end_range.toISOString(),
                };
            }
            setAlarm(p => ({ ...p, type, ...preset, ...time, name: lg.getStaticText(preset.name) }));
        }
    }, [lg]);

    const onChangeSchedule = React.useCallback((schedule: T.Schedule[]) => {
        setAlarm(p => ({ ...p, data_include: schedule }));
        drawer.setFalse();
    }, [drawer]);

    const options = React.useMemo(() => ({
        paths: [{ label: TC.GLOBAL_ALL, value: "all" }].concat(DS.ALARMS.paths),
        sources: [{ label: TC.GLOBAL_ALL, value: "all" }].concat(DS_Options.src),
        energies: [{ label: TC.GLOBAL_ALL, value: "all" }].concat(DS.ALARMS.energies),
    }), []);
    //#endregion

    //#region Saving
    const checkErrors = React.useCallback(() => {
        let new_errors: typeof errors = {};

        let period = TB.splitFrequency(alarm.period);
        let start_date = TB.getDate(alarm.start_date);
        let range = TB.splitFrequency(alarm.considered_range);
        let end_date = TB.getDate(alarm.end_of_considered_range);
        let threshold_value = TB.getNumber(alarm.threshold_value);
        let group_period = TB.splitFrequency(alarm.data_group_period);

        if (!alarm.type) new_errors.type = TC.GLOBAL_REQUIRED_FIELD;
        if (!alarm.name) new_errors.name = TC.GLOBAL_REQUIRED_FIELD;
        if (period === null) new_errors.period = TC.GLOBAL_REQUIRED_FIELD;
        if (!start_date) new_errors.start_date = TC.GLOBAL_REQUIRED_FIELD;
        if (range === null) new_errors.considered_range = TC.GLOBAL_REQUIRED_FIELD;
        if (!end_date) new_errors.end_of_considered_range = TC.GLOBAL_REQUIRED_FIELD;
        if (!alarm.threshold_func) new_errors.threshold_func = TC.GLOBAL_REQUIRED_FIELD;
        if (!alarm.threshold_group) new_errors.threshold_group = TC.GLOBAL_REQUIRED_FIELD;
        if (isNaN(threshold_value)) new_errors.threshold_value = TC.GLOBAL_REQUIRED_FIELD;
        if (group_period === null) new_errors.data_group_period = TC.GLOBAL_REQUIRED_FIELD;
        if (alarm.datasets_ids.length === 0) new_errors.datasets_ids = TC.GLOBAL_REQUIRED_FIELD;
        if (!alarm.data_group_aggregate) new_errors.data_group_aggregate = TC.GLOBAL_REQUIRED_FIELD;

        if (start_date && end_date) {
            if (start_date.getTime() <= end_date.getTime()) new_errors.start_date = TC.ALARM_START_DATE_ERROR;
        }

        return new_errors;
    }, [alarm]);

    const onSaveLocal = React.useCallback(() => {
        let errors = checkErrors();
        if (Object.keys(errors).length > 0) setErrors(errors);
        else {
            isSaving.setTrue();
            S.updateAlarm({ ...alarm, active: alarm.active || false })
                .then(({ data }) => onSave?.(data))
                .catch(M.Alerts.updateError)
                .finally(isSaving.setFalse);
        }
    }, [checkErrors, isSaving, alarm, onSave]);

    const saveButton = React.useMemo(() => !readOnly && <C.Flex className='mb-2' alignItems='center' justifyContent='end'>
        <C.Button
            onClick={onSaveLocal}
            text={TC.GLOBAL_SAVE}
            icon={{ icon: "save", spinIcon: "spinner", spin: isSaving.value }}
            disabled={!Array.isArray(alarm.datasets_ids) || alarm.datasets_ids.length === 0}
        />
    </C.Flex>, [readOnly, alarm.datasets_ids, isSaving.value, onSaveLocal]);
    //#endregion

    return React.createElement(
        props.popup ? M.BlankModal : React.Fragment,
        props.popup ? {
            ...props.modalProps,
            autofocus: false,
            footer: saveButton,
            disableEnforceFocus: true,
            size: props.modalProps?.size || "md",
            title: props.modalProps?.title || TC.ALARM_TITLE,
            maxBodyHeight: props.modalProps?.maxBodyHeight || "55vh",
        } : null,
        <C.Spinner>
            {noAccessBanner}

            <BS.Row>
                <BS.Col md={12}>
                    <div>{lg.getStaticText(TC.DATASETS_FILTERS_SELECTION)}</div>
                    <BS.Row className='border border-1 bg-light rounded p-1 m-1'>
                        <BS.Col>
                            <C.Form.Select
                                disabled={readOnly}
                                options={options.paths}
                                value={dataset_filter.path || "all"}
                                label={TC.TREE_CONFIG_HIDE_SUBS}
                                onChange={path => set_dataset_filter(p => ({ ...p, path: path === "all" ? undefined : path }))}
                            />
                        </BS.Col>
                        <BS.Col>
                            <C.Form.Select
                                disabled={readOnly}
                                label={TC.GLOBAL_ENERGY}
                                options={options.energies}
                                value={dataset_filter.nrj || "all"}
                                onChange={nrj => set_dataset_filter(p => ({ ...p, nrj: nrj === "all" ? undefined : nrj }))}
                            />
                        </BS.Col>
                        <BS.Col>
                            <C.Form.Select
                                disabled={readOnly}
                                label={TC.DATASET_SRC}
                                options={options.sources}
                                value={dataset_filter.src || "all"}
                                onChange={src => set_dataset_filter(p => ({ ...p, src: src === "all" ? undefined : src }))}
                            />
                        </BS.Col>
                    </BS.Row>
                </BS.Col>
                <BS.Col md={12}>
                    <C.Form.Select
                        multiple
                        required
                        disabled={readOnly}
                        options={datasets_options}
                        value={alarm.datasets_ids}
                        label={TC.DATASET_PANEL_TAB_DATA}
                        loading={datasets_status === "load"}
                        error={{ code: errors.datasets_ids || "" }}
                        typeahead={{ renderItem: render_dataset_option, select_all_on_enter: true, height: "md" }}
                        onChange={datasets_ids => setAlarm(p => ({ ...p, datasets_ids: datasets_ids.includes("all") ? datasets_options.map(o => o.value) : datasets_ids }))}
                    />
                </BS.Col>
            </BS.Row>
            <BS.Row>
                <BS.Col>
                    <C.Form.TextField
                        required
                        value={alarm.name}
                        disabled={readOnly}
                        label={TC.GLOBAL_NAME}
                        error={{ code: errors.name || "" }}
                        onChange={name => setAlarm(p => ({ ...p, name }))}
                    />
                </BS.Col>
                <BS.Col>
                    <C.Form.Select
                        required
                        value={alarm.type}
                        disabled={readOnly}
                        label={TC.ALARM_TYPE}
                        onChange={onChangeType}
                        options={DS.ALARMS.type}
                        error={{ code: errors.type || "" }}
                        typeahead={{ hideClearButton: true }}
                    />
                </BS.Col>
            </BS.Row>
            <BS.Row>
                <BS.Col>
                    <C.Form.Frequency
                        noReset
                        required
                        disabled={readOnly}
                        value={alarm.period}
                        extraUnit={["h", "m"]}
                        label={TC.ALARM_PERIOD}
                        error={{ code: errors.period || "" }}
                        onChange={period => setAlarm(p => ({ ...p, period }))}
                    />
                </BS.Col>
                <BS.Col>
                    <C.Form.DateTime
                        required
                        enableTime
                        disabled={readOnly}
                        value={alarm.start_date}
                        label={TC.ALARM_START_DATE}
                        error={{ code: errors.start_date || "" }}
                        onChange={start_date => setAlarm(p => ({ ...p, start_date }))}
                    />
                </BS.Col>
            </BS.Row>
            <BS.Row>
                <BS.Col>
                    <C.Form.Frequency
                        noReset
                        required
                        disabled={readOnly}
                        extraUnit={["h", "m"]}
                        value={alarm.considered_range}
                        label={TC.ALARM_CONSIDERED_RANGE}
                        error={{ code: errors.considered_range || "" }}
                        onChange={considered_range => setAlarm(p => ({ ...p, considered_range }))}
                    />
                </BS.Col>
                <BS.Col>
                    <C.Form.DateTime
                        required
                        enableTime
                        disabled={readOnly}
                        label={TC.ALARM_END_RANGE}
                        value={alarm.end_of_considered_range}
                        error={{ code: errors.end_of_considered_range || "" }}
                        onChange={end_of_considered_range => setAlarm(p => ({ ...p, end_of_considered_range }))}
                    />
                </BS.Col>
            </BS.Row>
            <BS.Row>
                <BS.Col>
                    <C.Form.Frequency
                        noReset
                        required
                        disabled={readOnly}
                        extraUnit={["h", "m"]}
                        label={TC.ALARM_GROUP_PERIOD}
                        value={alarm.data_group_period}
                        error={{ code: errors.data_group_period || "" }}
                        onChange={data_group_period => setAlarm(p => ({ ...p, data_group_period }))}
                    />
                </BS.Col>
                <BS.Col>
                    <C.Form.Select
                        required
                        disabled={readOnly}
                        label={TC.ALARM_GROUP_AGG}
                        options={DS.ALARMS.aggregate}
                        value={alarm.data_group_aggregate}
                        typeahead={{ hideClearButton: true }}
                        error={{ code: errors.data_group_aggregate || "" }}
                        onChange={data_group_aggregate => setAlarm(p => ({ ...p, data_group_aggregate }))}
                    />
                </BS.Col>
            </BS.Row>
            <BS.Row>
                <BS.Col md={6}>
                    <C.Flex alignItems='center' justifyContent='between' className='mb-3'>
                        <div>{lg.getStaticText(TC.ALARM_DATA_INCLUDE)}</div>
                        <C.Button onClick={drawer.setTrue} icon="calendar-alt" variant="secondary" />
                    </C.Flex>
                    <BS.Offcanvas
                        placement='end'
                        autoFocus={false}
                        show={drawer.value}
                        enforceFocus={false}
                        onHide={drawer.setFalse}
                        backdropClassName='z-index-higher-modal'
                        bsPrefix='w-50 z-index-higher-modal offcanvas'
                    >
                        <BS.Offcanvas.Header closeButton />
                        <BS.Offcanvas.Body>
                            <C.Schedule context={props.context} value={alarm.data_include} onSave={onChangeSchedule} />
                        </BS.Offcanvas.Body>
                    </BS.Offcanvas>
                </BS.Col>
            </BS.Row>
            <BS.Row className='g-1'>
                <BS.Col>
                    <C.Form.Select
                        disabled={readOnly}
                        label={TC.ALARM_T_GROUP}
                        value={alarm.threshold_group}
                        options={DS.ALARMS.threshold_group}
                        typeahead={{ hideClearButton: true }}
                        error={{ code: errors.threshold_group || "" }}
                        onChange={threshold_group => setAlarm(p => ({ ...p, threshold_group }))}
                    />
                </BS.Col>
                <BS.Col>
                    <C.Form.Select
                        disabled={readOnly}
                        label={TC.ALARM_T_FUNC}
                        value={alarm.threshold_func}
                        options={DS.ALARMS.threshold_func}
                        typeahead={{ hideClearButton: true }}
                        error={{ code: errors.threshold_func || "" }}
                        onChange={threshold_func => setAlarm(p => ({ ...p, threshold_func }))}
                    />
                </BS.Col>
                <BS.Col>
                    <C.Form.NumField
                        disabled={readOnly}
                        label={TC.ALARM_T_VALUE}
                        value={alarm.threshold_value as any}
                        error={{ code: errors.threshold_value || "" }}
                        onChange={threshold_value => setAlarm(p => ({ ...p, threshold_value: TB.getString(threshold_value) }))}
                    />
                </BS.Col>
            </BS.Row>
            <BS.Row>
                <BS.Col>
                    <C.Form.RadioBool
                        required
                        disabled={readOnly}
                        name="radio_active"
                        value={!!alarm.active}
                        label={TC.ALARM_ACTIVE}
                        error={{ code: errors.active || "" }}
                        onChange={active => setAlarm(p => typeof active === "boolean" ? { ...p, active } : p)}
                    />
                </BS.Col>
            </BS.Row>
            <BS.Row>
                <BS.Col>
                    <C.Form.Select
                        multiple
                        disabled={readOnly}
                        options={note_tags}
                        value={alarm.tags}
                        label={TC.DATASET_TAGS}
                        loading={note_tags_status === "load"}
                        onChange={tags => setAlarm(p => ({ ...p, tags }))}
                        typeahead={{ allowNewOption: true, onAddOption: add_tag }}
                    />
                </BS.Col>
            </BS.Row>

            {!props.popup && saveButton}
        </C.Spinner>
    );
}

export default AlarmForm;