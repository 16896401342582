import axios from "axios";
import jwt from "jsonwebtoken";
import { LANG, T, TB } from "../Constants";

//#region Auto Set Headers
axios.interceptors.request.use(config => {
    if (config.url === "https://atmdata.be/auth") return config;

    const userStr = localStorage.getItem("formioUser");
    try {
        const user = JSON.parse(userStr);
        // Load the current mission_id
        const mission_id = localStorage.getItem("mission_id");
        // FR_FR because it is default options on login page when nothing selected
        const lang = localStorage.getItem("lang") || LANG.FR_PROP;
        const token = localStorage.getItem("formioToken");
        let decodedStrToken = jwt.decode(token);

        if (TB.validString(decodedStrToken)) decodedStrToken = {};
        let decodedToken = TB.getObject<T.API.Token>(decodedStrToken, { isAdmin: false });

        let headers = TB.getObject(config.headers);

        headers.lang = lang;
        if (user) headers.user_id = user?._id;
        headers.is_admin = decodedToken?.isAdmin;
        if (token) headers["x-access-token"] = token;
        if (mission_id) headers.mission_id = mission_id;
        if (!TB.validString(headers.request_type)) headers.request_type = "READ";

        config.headers = headers;
        return config;
    }
    catch (error) { return config };
});

axios.interceptors.response.use(config => {
    let strToken = config.headers?.token_update;
    if (TB.validString(strToken)) localStorage.setItem("formioToken", strToken);
    return config;
});
//#endregion

export * from "./Utils";