import React from "react";
import { InputProps, ComponentWrapper } from "../Input";
import { FormCheck, FormCheckProps } from "react-bootstrap";

export type CheckBoxProps = InputProps & {
    /** Type of checkbox */
    check_type?: FormCheckProps["type"];
};

export type CheckBoxRef = {
    /** The html input ref element */
    input: HTMLInputElement;
}

const CheckBox = React.forwardRef<CheckBoxRef, CheckBoxProps>(({ onChange, disabled, noOverride, ...props }, ref) => {
    const check = React.useRef<HTMLInputElement>(null);

    React.useImperativeHandle(ref, () => ({ input: check.current }), []);
    const isDisabled = React.useMemo(() => disabled || noOverride, [disabled, noOverride]);
    const labelFullWidth = React.useMemo(() => props.labelPosition === "left", [props.labelPosition]);

    return <ComponentWrapper {...props} type="checkbox" labelFullWidth={labelFullWidth} labelPosition={props.labelPosition || "left"} disabled={isDisabled}>
        <FormCheck
            ref={check}
            disabled={isDisabled}
            checked={!!props.value}
            type={props.check_type}
            onChange={e => onChange?.(e.target.checked)}
        />
    </ComponentWrapper>
});

export default CheckBox;