import React from "react";
import * as M from "../Modal";
import * as P from "./Panels";
import * as H from "../../hooks";
import * as C from "../../Common";
import { DataPanel } from "./Data";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import NotesManager from "./NoteManager";
import { ActionRegUpdater } from "../RegDoc";
import Form, { FormApi, FormProps } from "./form";
import { FP, RIGHTS, T, TB, TC } from "../../Constants";

//#region Types
type RegTabData = Partial<ReturnType<T.API.Reg.GetElemRegUpdaterData>>;
type FormPropsEdited<A> = Omit<FormProps<A>, "modal" | "modalProps" | "onSave" | "hideSubmit" | "api">;
type FormPanel = { code: string, content: React.ReactNode, show: boolean, label: string, footer?: React.ReactNode, size?: T.ModalSizes };
type EditedRemarques = Record<Parameters<P.RemarquesPanelProps["onChange"]>[0], Parameters<P.RemarquesPanelProps["onChange"]>[1][]>;

export type PopUpFormProps<A> = FormPropsEdited<A> & {
    /** Popup's title */
    title?: string;
    /** Do not show the form */
    hideForm?: boolean;
    /** The default tab open, can be "form", "note", "rem", "reg", "kpi" or "data" */
    defaultKey?: string;
    /** Callback after validation of the form */
    handleForm?: FormProps<A>["onSave"];
    /** Callback for the edition of remarques */
    updateRem?: (edits: EditedRemarques) => void;
};
//#endregion

//#region Constants
const DATA_PATHS = [FP.SITE_FORM, FP.BUILDING_FORM, FP.EMPLACEMENT_FORM, FP.EQUIPEMENT_FORM];
const PANELS_CODES = { form: "form", note: "note", remarques: "rem", reg: "reg", data: "data", kpi: "kpi" };

const TEXT_CODES = [
    TC.GLOBAL_SAVE, FP.NOTES_PATH, TC.POP_UP_FORM_PROP_PANEL, TC.DATASET_DATA,
    FP.REMARQUES_DEFAULTS, TC.GLOBAL_CANCEL, TC.GLOBAL_EDIT, FP.ACTION_REG_FORM,
];
//#endregion

const PopUpForms = <A,>({ defaultKey, hideForm, handleForm, updateRem, ...props }: PopUpFormProps<A>) => {
    const rights = H.useRights();
    const [forms] = H.useFormIds();
    const lg = H.useLanguage(TEXT_CODES);
    const disable_save = H.useBoolean(false);
    const formRef = React.useRef<FormApi>({});
    const [tabKey, setTabKey] = React.useState(defaultKey || PANELS_CODES.form);
    const [regTabData, setRegTabData, regTabStatus] = H.useAsyncState<RegTabData>({});
    const [editedRemarques, setEditedRemarques] = React.useState<EditedRemarques>({ edit: [], delete: [], add: [] });

    //#region Close
    const closeForm = React.useCallback<typeof handleForm>(submission => {
        // Fire the remarques callback if there was some edits
        if (Object.values(editedRemarques).some(a => a.length > 0)) updateRem?.(editedRemarques);
        handleForm?.(submission);
    }, [handleForm, updateRem, editedRemarques]);
    //#endregion

    //#region Form
    const displayForm = React.useMemo(() => !hideForm, [hideForm]);

    const formContent = React.useMemo(() => <Form<A>
        {...props}
        hideSubmit
        api={formRef}
        onSave={closeForm}
        on_saving_status_change={disable_save.setValue}
    />, [closeForm, disable_save, props]);

    const formFooter = React.useMemo(() => !props.readOnly && <C.Flex alignItems="center" justifyContent="end">
        <C.Button onClick={() => formRef.current.save?.()} disabled={disable_save.value} text={TC.GLOBAL_SAVE} icon="save" />
    </C.Flex>, [props.readOnly, disable_save.value]);

    const onQuit = React.useCallback(() => {
        if (formRef.current?.has_changed?.()) M.Confirms.quit_no_save()
            .then(confirmed => confirmed && closeForm());
        else closeForm();
    }, [closeForm]);
    //#endregion

    //#region Notes
    const displayNotes = React.useMemo(() => {
        if (!TB.mongoIdValidator(props.submissionId)) return false;
        let is_note_form = props.path === FP.NOTES_PATH || props._id === forms[FP.NOTES_PATH];
        return !is_note_form;
    }, [props.submissionId, props.path, props._id, forms]);
    const notesContent = React.useMemo(() => <NotesManager origin={props.submissionId} />, [props.submissionId]);
    //#endregion

    //#region Remarques
    const displayRemarques = React.useMemo(() => {
        // Has user access to remarques
        if (!rights.isRightAllowed(RIGHTS.MISC.WRITE_OWN_REMARQUES)) return false;
        // Must be an existing submission;
        if (!TB.mongoIdValidator(props.submissionId)) return false;
        // Must be an equipment
        if (props._id === forms[FP.EQUIPEMENT_FORM] || props.path === FP.EQUIPEMENT_FORM) return true;
        // Or must be an emplacement
        else if (props._id === forms[FP.EMPLACEMENT_FORM] || props.path === FP.EMPLACEMENT_FORM) return true;
        // If Other do not show
        else return false;
    }, [props.submissionId, props._id, props.path, forms, rights]);

    const remarquesContent = React.useMemo(() => ({
        show: displayRemarques,
        panel: <P.RemarquesPanel
            _id={props.submissionId || ''}
            onChange={(type, remarque) => setEditedRemarques(p => {
                if (type === 'delete') return {
                    delete: p.delete.concat(remarque),
                    add: p.add.filter(r => r._id !== remarque._id),
                    edit: p.edit.filter(r => r._id !== remarque._id),
                };
                else if (type === "add") return {
                    ...p,
                    add: p.add.concat(remarque),
                }
                else return {
                    ...p,
                    add: p.add.map(r => r._id === remarque._id ? remarque : r),
                    edit: p.edit.map(r => r._id === remarque._id ? remarque : r),
                }
            })}
        />
    }), [displayRemarques, props.submissionId]);
    //#endregion

    //#region Reg
    const showRegTab = React.useMemo(() => {
        let hasId = TB.mongoIdValidator(props.submissionId);
        let hasRight = rights.isRightAllowed(RIGHTS.REG.READ_ELEMENT_REG, props.submissionId);
        let isEquip = props.path === FP.EQUIPEMENT_FORM || props._id === forms[FP.EQUIPEMENT_FORM];
        return hasId && hasRight && isEquip;
    }, [forms, props._id, props.path, props.submissionId, rights]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (showRegTab) S.getElemRegUpdaterData(props.submissionId)
            .then(({ data }) => isSubscribed && setRegTabData(data, "done"))
            .catch(() => isSubscribed && setRegTabData({}, "error"));
        else setRegTabData({}, "done");

        return () => { isSubscribed = false };
    }, [props.submissionId, showRegTab, setRegTabData]);

    const regTabContent = React.useMemo(() => {
        if (regTabStatus === "load") return <M.Loader isPopUp={false} />;
        else if (regTabStatus === "error") return <C.ErrorBanner type="danger" message={TC.GLOBAL_FAILED_LOAD} />;
        else return <div className="p-3">
            <ActionRegUpdater
                roots={regTabData.site}
                disabled={props.readOnly}
                label={FP.ACTION_REG_FORM}
                category={regTabData.category}
                elementId={props.submissionId}
                itemActions={regTabData.reglementations}
            />
        </div>;
    }, [props.readOnly, props.submissionId, regTabStatus, regTabData]);
    //#endregion

    //#region Data
    const showDataTab = React.useMemo(() => {
        // Existing submission
        if (TB.mongoIdValidator(props.submissionId)) {
            // Has the right to read datasets
            if (rights.isRightAllowed(RIGHTS.NRJ.READ_NRJ_TAGS)) {
                // Valid type of submission
                if (DATA_PATHS.includes(props.path)) return true;
                if (DATA_PATHS.map(path => forms[path]).includes(props._id)) return true;
            }
        }
        return false;
    }, [forms, rights, props.submissionId, props._id, props.path]);

    const dataTab = React.useMemo(() => <div style={{ height: "50vh" }}>
        <DataPanel origin={props.submissionId} readOnly={props.readOnly} />
    </div>, [props.submissionId, props.readOnly]);
    //#endregion

    //#region KPI
    const show_kpi_tab = React.useMemo(() => {
        // Existing submission
        if (TB.mongoIdValidator(props.submissionId)) {
            // Is a building
            if (props.path === FP.BUILDING_FORM || props._id === forms[FP.BUILDING_FORM]) return true;
        }
        return false;
    }, [forms, props._id, props.path, props.submissionId]);

    const kpi_panel = React.useMemo(() => <P.KPIPanel building={props.submissionId} />, [props.submissionId]);
    //#endregion

    //#region BS.Tabs
    const title = React.useMemo(() => TB.getString(props.title, TC.FORMS), [props.title]);

    const panels = React.useMemo<FormPanel[]>(() => [
        { code: PANELS_CODES.form, label: TC.POP_UP_FORM_PROP_PANEL, content: formContent, show: displayForm, footer: formFooter },
        { code: PANELS_CODES.note, label: FP.NOTES_PATH, content: notesContent, show: displayNotes },
        { code: PANELS_CODES.remarques, label: FP.REMARQUES_DEFAULTS, content: remarquesContent.panel, show: remarquesContent.show },
        { code: PANELS_CODES.reg, label: FP.ACTION_REG_FORM, content: regTabContent, show: showRegTab, size: "lg" },
        { code: PANELS_CODES.data, label: TC.DATASET_DATA, content: dataTab, show: showDataTab },
        { code: PANELS_CODES.kpi, label: TC.POP_UP_FORM_PANEL_KPI, content: kpi_panel, show: show_kpi_tab },
    ], [displayForm, displayNotes, formFooter, dataTab, showDataTab, regTabContent, formContent, kpi_panel, show_kpi_tab, showRegTab, notesContent, remarquesContent]);

    const footer = React.useMemo(() => panels.filter(p => p.code === tabKey && p.show)[0]?.footer || null, [panels, tabKey]);

    const panelBar = React.useMemo(() => {
        let showPanels = panels.filter(p => p.show);
        if (showPanels.length === 0) return null;
        else if (showPanels.length === 1) return showPanels[0].content;
        return <BS.Tabs activeKey={tabKey} className="nav-fill" mountOnEnter onSelect={setTabKey}>
            {showPanels.map(p => <BS.Tab key={p.code} eventKey={p.code} title={lg.getStaticText(p.label)}>
                <div className="mt-3">
                    {p.content}
                </div>
            </BS.Tab>)}
        </BS.Tabs>
    }, [tabKey, panels, lg]);
    //#endregion

    const size = React.useMemo(() => panels.filter(p => p.code === tabKey)[0]?.size || "md", [panels, tabKey])

    return <M.BlankModal onQuit={onQuit} title={title || props.path} size={size} maxBodyHeight="70vh" footer={footer}>
        {panelBar}
    </M.BlankModal>;
}

export default PopUpForms;