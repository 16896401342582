import React from "react";
import CRVA from "./CRVA";
import * as F from "../../Form";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import Alignment from "./Alignment";
import * as C from "../../../Common";
import * as BS from "react-bootstrap";
import * as S from "../../../services";
import { TC, T } from "../../../Constants";

type Tabs = T.Option<{}, "mission" | "main" | "crva">;
type MissionResumeEuTaxonomy = ReturnType<T.API.Utils.Missions.MissionResumeEuTaxonomy>;

const TABS: Tabs[] = [
    { label: TC.CERT_HISTORY_MISSION, value: "mission" },
    { label: TC.TAXO_MISSION_ALIGNMENT_TITLE, value: "main" },
    { label: TC.MISSION_TASK_CRVA, value: "crva" },
];

const Main: React.FC = () => {
    const [, mission_id] = H.useRoots();
    const updated_mission = H.useBoolean(false);
    const mission_ref = React.useRef<F.Missions.MissionFormRef>(null);
    const { updateCrumbs, resetCrumbs } = H.useCrumbs(TC.MISSION_TYPE_EU_TAXONOMY);
    const [current_tab, set_current_tab] = React.useState<Tabs["value"]>("mission");
    const [loaded_tabs, set_loaded_tabs] = React.useState<Tabs["value"][]>(["mission"]);
    const [mission_state, set_mission_state, status] = H.useAsyncState<MissionResumeEuTaxonomy>({ asset_id: "", mission_id: "", read_only: true, has_crva: false, site: null, building: null, infos: null, taxonomy: null });

    //#region Tabs & Crumbs
    React.useEffect(() => {
        let tab = TABS.filter(t => t.value === current_tab)[0];
        if (tab) updateCrumbs({ label: tab.label }, 1);
        return () => { resetCrumbs() };
    }, [updateCrumbs, resetCrumbs, current_tab]);

    const change_tab = React.useCallback((tab: typeof current_tab) => {
        const tab_setter = (keep_mission_loaded = true) => {
            // Update the current tab
            set_current_tab(tab);
            // Update the list of loaded tab
            set_loaded_tabs(p => {
                let new_loaded = p.includes(tab) ? p : p.concat(tab);
                if (!keep_mission_loaded) return new_loaded.filter(t => t !== "mission");
                else return new_loaded;
            });
        }

        // Ask to confirm the changes made in the mission
        if (current_tab === "mission" && updated_mission.value) M.askConfirm({ title: TC.MISSION_WIZARD_AUTO_SAVE_MISSION, text: TC.MISSION_WIZARD_AUTO_SAVE_MISSION_TEXT }).then(confirmed => {
            if (confirmed) {
                let result = mission_ref.current?.save?.();
                let mission = mission_ref.current?.mission;

                console.log(mission);

                if (result && result !== "errors") result.then(success => {
                    if (success) {
                        tab_setter();
                        updated_mission.setFalse();


                        console.log("oooooo");

                        // Update the mission state
                        set_mission_state(p => ({ ...p, has_crva: mission?.tasks?.some?.(t => t.type === "crva") }));
                    }
                });
            }
            else tab_setter(false);
        });
        else tab_setter();
    }, [current_tab, updated_mission, set_mission_state]);

    const on_save_form_mission = React.useCallback<F.Missions.MissionFormProps["onSave"]>(mission => {
        if (mission !== "deleted" && mission !== "error") {
            updated_mission.setFalse();
            set_mission_state(p => ({ ...p, has_crva: mission.tasks.some(t => t.type === "crva") }));
        }
    }, [updated_mission, set_mission_state]);

    // eslint-disable-next-line react-hooks/exhaustive-deps -- Dependency is not necessary, and I don't want the warning to show up
    React.useEffect(() => updated_mission.setFalse(), [mission_id]);
    //#endregion

    //#region Load Data
    React.useEffect(() => {
        let isSubscribed = true;
        S.missionResumeEuTaxonomy()
            .then(({ data }) => isSubscribed && set_mission_state(data, "done"))
            .catch(() => isSubscribed && set_mission_state({ asset_id: "", mission_id: "", read_only: true, has_crva: false, site: null, building: null, infos: null, taxonomy: null }, "error"));
        return () => {
            isSubscribed = false;
            set_current_tab("mission");
            set_loaded_tabs(["mission"]);
            set_mission_state({ asset_id: "", mission_id: "", read_only: true, has_crva: false, site: null, building: null, infos: null, taxonomy: null }, "load");
        }
    }, [set_mission_state]);

    const reload_data = React.useCallback(() => {
        set_mission_state({ asset_id: "", mission_id: "", read_only: true, has_crva: false, site: null, building: null, infos: null, taxonomy: null }, "load");
        S.missionResumeEuTaxonomy()
            .then(({ data }) => set_mission_state(data, "done"))
            .catch(() => set_mission_state({ asset_id: "", mission_id: "", read_only: true, has_crva: false, site: null, building: null, infos: null, taxonomy: null }, "error"));
    }, [set_mission_state]);
    //#endregion

    return <C.Flex direction="column" className="flex-grow-1">
        <C.Spinner status={status}>
            {/* Tabs */}
            <BS.Row className="g-1">
                {TABS.map(tab => (tab.value !== "crva" || mission_state.has_crva) && <BS.Col key={tab.value}>
                    <C.Button
                        size="sm"
                        text={tab.label}
                        className="w-100"
                        onClick={() => change_tab(tab.value)}
                        variant={current_tab === tab.value ? "primary" : "link"}
                    />
                </BS.Col>)}
            </BS.Row>

            {/* Wizards */}
            {loaded_tabs.includes("mission") && <div className="flex-grow-1 my-3" hidden={current_tab !== "mission"}>
                <F.Missions.MissionForm
                    no_delete
                    ref={mission_ref}
                    onSave={on_save_form_mission}
                    asset={mission_state.asset_id}
                    onChange={updated_mission.setTrue}
                    mission_id={mission_state.mission_id}
                />
            </div>}

            {loaded_tabs.includes("main") && <div className="flex-grow-1 my-3" hidden={current_tab !== "main"}>
                <Alignment data={mission_state} update={set_mission_state} read_only={mission_state.read_only} reload={reload_data} />
            </div>}

            {loaded_tabs.includes("crva") && <div className="flex-grow-1 my-3" hidden={current_tab !== "crva"} children={<CRVA />} />}
        </C.Spinner>
    </C.Flex>
};

export default Main;