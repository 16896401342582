import React from "react";
import * as I from "../Input";
import { Select } from "../Components";
import { T, TB } from "../../../Constants";

export type GammeSelectProps = I.InputProps;
type GammeOption = ReturnType<T.API.Utils.Gammes.GetGammesOptions>[number];
type Option = GammeOption & GammeSelectProps["options"][number];

const GammeSelect: React.FC<GammeSelectProps> = ({ onChange, ...props }) => {

    const vOptions = React.useMemo(() => TB.getArray(props.options) as Option[], [props.options]);
    const isDisabled = React.useMemo(() => props.disabled || props.noOverride, [props.disabled, props.noOverride]);

    const gammesTree = React.useMemo(() => {
        let currentGamme = vOptions.filter(o => o.value === props.value)[0];
        if (!currentGamme) return [];

        let tree: typeof vOptions = [];
        const getParent = (option: Option) => {
            let parent = vOptions.filter(o => o.value === option.parent)[0];
            if (parent) {
                tree.push(parent);
                getParent(parent);
            }
        }

        getParent(currentGamme);
        return tree.reverse().concat(currentGamme);
    }, [props.value, vOptions]);

    const getOptions = React.useCallback((value: string) => {
        let currentValue = vOptions.filter(o => o.value === value)[0];
        if (!currentValue) return vOptions;

        const findDescendants = (ids: T.AllowArray<string>): typeof vOptions => {
            ids = TB.arrayWrapper(ids);
            let descendants = vOptions.filter(o => ids.includes(o.parent));

            if (descendants.length > 0) return descendants
                .concat(findDescendants(descendants.map(d => d.value)));
            return [];
        }

        let options = findDescendants(value);
        return options;
    }, [vOptions]);

    const updateValue = React.useCallback((id: string) => onChange?.(id), [onChange]);

    const currentHasChildren = React.useMemo(() => {
        let current = vOptions.filter(o => o.value === props.value)[0];
        if (!current) return true;
        return vOptions.some(o => o.parent === props.value);
    }, [props.value, vOptions]);

    return <I.ComponentWrapper {...props} disabled={isDisabled}>
        {gammesTree.map(g => <Select
            key={g.value}
            positionFixed
            value={g.value}
            disabled={isDisabled}
            options={getOptions(g.parent)}
            onChange={id => updateValue(id || g.parent)}
            typeahead={{ extra_search_props: "omniclass" }}
        />)}

        {currentHasChildren && <Select
            positionFixed
            key={props.value}
            disabled={isDisabled}
            onChange={updateValue}
            options={getOptions(props.value)}
            typeahead={{ extra_search_props: "omniclass" }}
        />}

    </I.ComponentWrapper>
};

export default GammeSelect;