import _ from "lodash";
import React from "react";
import * as M from "../Modal";
import * as C from "../../Common";
import * as BS from "react-bootstrap";
import { massDelete } from "../../helpers";
import FlatLabelInput from "./FlatLabelInput";
import { renderMagicModal } from "./MagicModals";
import * as US from "../../services/user.service";
import { useAuth, useLanguage, useRights } from "../../hooks";
import { TC, FP, LT, TB, T, ICONS, RIGHTS } from "../../Constants";
import { AccordionSelectCallback } from "react-bootstrap/esm/AccordionContext";

//#region Types
export type StructTabApi = {
    changesMade?: () => boolean;
    saveData?: () => Promise<boolean | null>;
}

type BuildStructProps = {
    buildingId?: string;
    icons: T.ReducedFileOptions[];
    api?: React.RefObject<StructTabApi | null>;
}

type Resource = {
    links: T.Link[];
    forms: T.StringObject;
    linkTypes: T.StringObject;
    nodes: T.EmplacementType[];
    status: "ready" | "loadError" | "noBuilding" | "loading";
}
//#endregion

const TEXT_CODES = [
    TC.GLOBAL_NO_EMPLACEMENTS, TC.GLOBAL_NO_SELECTION, TC.GLOBAL_PROPERTIES_LABEL, TC.TYPE, TC.GLOBAL_NAME, TC.BR_B_AREA, TC.GLOBAL_FLOOR,
    TC.GLOBAL_LOCAL, TC.GLOBAL_ZONE, TC.GLOBAL_REQUIRED_FIELD, TC.GLOBAL_NUM_SUP_0, TC.BUILD_EDIT_ICON, TC.BUILD_EDIT_COLOR, TC.CREAT_NEW,
    TC.GLOBAL_EMP_NAME, TC.GLOBAL_ERROR_UPDATE, TC.BR_B_AUTO_CREATE, TC.GLOBAL_SHOW_MINI_TREE, TC.AG_DUPLICATE, TC.GLOBAL_DELETE
];

const BuildStruct: React.FC<BuildStructProps> = ({ buildingId, icons, api, ...props }) => {
    const empRef = React.useRef<T.EmplacementType | null>(null);

    const rights = useRights();
    const [{ user }] = useAuth();
    const [updated, setUpdated] = React.useState(false);
    const { getStaticText } = useLanguage(TEXT_CODES);
    const [isEmpClosed, setIsEmpClosed] = React.useState(false);
    const [errors, setErrors] = React.useState<T.StringObject>({});
    const [{ activeTab, allClosed }, setActiveTab] = React.useState<{ activeTab: string | null, allClosed: boolean }>({ activeTab: null, allClosed: false });
    const [{ links, linkTypes, forms, nodes, status }, setResource] = React.useState<Resource>({ links: [], forms: {}, linkTypes: {}, nodes: [], status: "loading" });

    //#region Load Data
    React.useEffect(() => {
        let isSubscribed = true;
        if (TB.mongoIdValidator(buildingId)) US.listItemsAndLinks(buildingId, FP.EMPLACEMENT_FORM, LT.LINK_TYPE_OWN).then(({ data }) => {
            if (isSubscribed) {
                if (data?.hasFailed) setResource(p => ({ ...p, status: "loadError" }));
                else {
                    let defaultNode = data?.nodes?.[0];
                    let defaultTab = defaultNode?._id;
                    if (TB.mongoIdValidator(defaultTab)) setActiveTab(p => ({ ...p, activeTab: defaultTab }));
                    /* @ts-ignore */
                    if (TB.validObject(defaultNode)) empRef.current = _.cloneDeep(defaultNode);

                    setResource({ ...data, status: "ready" });
                }
            }
        })
        else setResource(p => ({ ...p, status: "noBuilding" }));
        return () => { isSubscribed = false; }
    }, [buildingId]);
    //#endregion

    //#region Forms & Links
    const ownLinkType = React.useMemo(() => linkTypes[LT.LINK_TYPE_OWN], [linkTypes])
    const emplacementForm = React.useMemo(() => forms[FP.EMPLACEMENT_FORM], [forms]);
    //#endregion

    //#region Error Banner
    const errorBanner = React.useMemo(() => {
        let code: (string | null) = null;

        if (status === "noBuilding") code = TC.BUILD_EDIT_NO_ROOT;
        else if (status === "loadError") code = TC.GLOBAL_FAILED_LOAD;

        if (code !== null) return <C.ErrorBanner type="danger" textCode={code} />;
        return null;
    }, [status]);
    //#endregion

    //#region Links & Nodes operations
    const findRelativeNodes = React.useCallback((id: string, isInput?: boolean) => {
        if (!TB.mongoIdValidator(id)) return [];
        if (typeof isInput !== "boolean") isInput = true;

        return links.map(link => {
            if (isInput && link.input === id) return link.output;
            if (!isInput && link.output === id) return link.input;
            return null;
        })
            .filter(TB.mongoIdValidator)
            .map(id => _.find(nodes, ({ _id }) => _id === id))
            .filter(TB.isEmplacement);
    }, [links, nodes]);

    const findNode = React.useCallback((id: string | null) => TB.mongoIdValidator(id) ? _.find(nodes, ({ _id }) => _id === id) ?? null : null, [nodes]);
    //#endregion

    //#region Selected Location
    const updateEmpItem = React.useCallback((prop: string, val: any, id = activeTab) => {
        if (!TB.mongoIdValidator(id)) return;
        setResource(p => ({ ...p, nodes: p.nodes.map(emp => emp._id !== id ? emp : { ...emp, data: _.set({ ...emp.data }, prop, val) }) }));
    }, [activeTab]);

    const onChangeValue = React.useCallback((prop: string, value?: string, type?: string) => {
        let strValue = TB.getString(value), okVal: any = strValue;
        if (type === "number") {
            okVal = TB.getNumber(strValue);
            if (isNaN(okVal)) okVal = "";
        }

        setUpdated(true);
        updateEmpItem(prop, okVal);
    }, [updateEmpItem]);

    const typeOptions = React.useMemo(() => [
        { value: "floor", label: getStaticText(TC.GLOBAL_FLOOR) },
        { value: "local", label: getStaticText(TC.GLOBAL_LOCAL) },
        { value: "zone", label: getStaticText(TC.GLOBAL_ZONE) },
        { value: "parking", label: getStaticText(TC.GLOBAL_LABEL_PARKING) },
    ], [getStaticText]);

    const selectedItem = React.useMemo(() => findNode(activeTab), [findNode, activeTab]);
    const noItemPanel = React.useMemo(() => selectedItem === null && <C.ErrorBanner type="info" textCode={TC.GLOBAL_NO_SELECTION} />, [selectedItem]);

    const fields = React.useMemo(() => [
        // {label:TC.TYPE, prop:"type"},
        { label: TC.GLOBAL_NAME, prop: "name" },
        { label: TC.BR_B_AREA, prop: "area", type: "number" },
    ], []);

    const canEdit = React.useMemo(() => rights.isRightAllowed(RIGHTS.TECH.EDIT_EMPLACEMENT, selectedItem?._id), [rights, selectedItem?._id]);

    const renderRegularField = React.useCallback(({ prop, label, ...props }) => {
        let hasError = TB.validString(errors[prop]);

        return <FlatLabelInput label={getStaticText(label)}>
            <BS.Form.Control value={selectedItem?.data?.[prop] ?? ""} disabled={!canEdit} {...props} isInvalid={hasError} />
            {hasError && <span className="invalid-feedback shown">{getStaticText(errors?.[prop])}</span>}
        </FlatLabelInput>
    }, [errors, selectedItem, canEdit, getStaticText]);

    const iconOptions = React.useMemo(() => (Array.isArray(icons) ? icons : []).map(({ label, url, ...r }) => ({ title: label, src: url, ...r })), [icons]);
    const defaultImg = React.useMemo(() => iconOptions.filter(({ title }) => title === ICONS.DEFAULT_FLOOR)[0]?.value, [iconOptions]);

    const iconSelect = React.useMemo(() => <BS.Col md={4}>
        <BS.Form.Label>{getStaticText(TC.BUILD_EDIT_ICON)}</BS.Form.Label>
        <C.ImgSelect
            disabled={!canEdit}
            options={iconOptions}
            defaultValue={defaultImg}
            addNewImage={() => alert("todo")}
            onSelect={a => onChangeValue("selectIcon", a)}
            selected={selectedItem?.data?.selectIcon ?? ""}
        />
    </BS.Col>, [getStaticText, onChangeValue, iconOptions, canEdit, defaultImg, selectedItem?.data?.selectIcon]);

    const colorSelect = React.useMemo(() => <BS.Col md={4}>
        <BS.Form.Label>{getStaticText(TC.BUILD_EDIT_COLOR)}</BS.Form.Label>
        <C.ColorSelect defaultColor="#ffffff" disabled={!canEdit} selected={selectedItem?.data?.color} onSelect={c => onChangeValue("color", c)} />
    </BS.Col>, [getStaticText, onChangeValue, canEdit, selectedItem?.data?.color]);

    const itemPanelForm = React.useMemo(() => <BS.Row className="border-start">
        {fields.map(props => <BS.Col md={6} key={props.prop}>
            {renderRegularField({ ...props, onChange: e => onChangeValue(props.prop, e.target.value, props.type) })}
        </BS.Col>)}
        <BS.Col md={6}>
            <FlatLabelInput label={getStaticText(TC.TYPE)}>
                <C.TypeAhead
                    disabled={!canEdit}
                    options={typeOptions}
                    invalid={TB.validString(errors.type)}
                    selectedItems={selectedItem?.data?.type}
                    onChange={optArray => onChangeValue("type", optArray?.[0]?.value)}
                />
                {TB.validString(errors.type) && <span className="invalid-feedback shown">{getStaticText(errors.type)}</span>}
            </FlatLabelInput>
        </BS.Col>
    </BS.Row>, [fields, typeOptions, errors, canEdit, selectedItem, renderRegularField, onChangeValue, getStaticText]);

    const itemPanel = React.useMemo(() => selectedItem !== null && <div className="h-100">
        <C.Flex alignItems="center" className="mb-2">
            <span className="h5">{getStaticText(TC.GLOBAL_PROPERTIES_LABEL)}</span>
            <i className={`ms-2 pointer fa fa-chevron-${isEmpClosed ? "up" : "down"}`} onClick={() => setIsEmpClosed(p => !p)}></i>
        </C.Flex>
        <BS.Fade in={!isEmpClosed}>
            <div>
                {itemPanelForm}
                <BS.Row>
                    {iconSelect}
                    {colorSelect}
                </BS.Row>
            </div>
        </BS.Fade>
    </div>, [selectedItem, isEmpClosed, iconSelect, colorSelect, itemPanelForm, getStaticText]);

    const saveEmp = React.useCallback(() => new Promise<boolean | null>(resolve => {
        if (selectedItem !== null) {
            let newErrors: T.StringObject = {};
            if (selectedItem.data.area! < 0) newErrors.area = TC.GLOBAL_NUM_SUP_0;
            if (!TB.validString(selectedItem.data.name)) newErrors.name = TC.GLOBAL_REQUIRED_FIELD;
            if (!typeOptions.map(t => t.value).includes(selectedItem.data.type)) newErrors.type = TC.GLOBAL_REQUIRED_FIELD;

            if (Object.keys(newErrors).length > 0) {
                setErrors(newErrors);
                resolve(null);
            }
            else {
                let oldRef = empRef.current?.data;
                let update = Object.entries(selectedItem.data)
                    .filter(([key, val]) => !_.isEqual(val, oldRef?.[key]))
                    .map(([key, val]) => ["data." + key, val]);

                const onError = () => {
                    M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_UPDATE });
                    if (TB.isEmplacement(empRef.current)) setResource(p => ({ ...p, nodes: p.nodes.map(emp => emp._id === empRef.current?._id ? empRef.current : emp) }));
                    resolve(false);
                }

                if (update.length > 0) US.updateSubmission(selectedItem._id, Object.fromEntries(update)).then(({ data }) => {
                    if (TB.mongoIdValidator(data?._id)) {
                        setUpdated(false);
                        resolve(true);
                    }
                    else onError();
                }).catch(onError);
                else resolve(true);
            }
        }
        else resolve(true);
    }), [selectedItem, typeOptions]);
    //#endregion

    //#region Create New Emplacement
    const createEmplacement = React.useCallback((parent: string) => {
        if (!TB.isUser(user) || !TB.mongoIdValidator(parent) || !TB.mongoIdValidator(emplacementForm)) return;
        M.askPrompt({ title: getStaticText(TC.GLOBAL_EMP_NAME), isRequired: true }).then(name => {
            if (TB.validString(name)) {
                let newSub = TB.blankSub(user._id, emplacementForm);
                newSub.data = { name, type: "local", selectIcon: defaultImg, color: "#FFFFFF" };

                const onError = () => M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_UPDATE });

                US.createSubmission(newSub).then(({ data }) => {
                    if (TB.isEmplacement(data)) {
                        US.replaceLinkApi(parent, data._id, LT.LINK_TYPE_OWN).then(replyLink => {
                            if (TB.validLink(replyLink?.data?.insert)) setResource(p => ({
                                ...p,
                                links: p.links.concat(replyLink.data.insert),
                                nodes: p.nodes.concat(data),
                            }))
                            else onError();
                        }).catch(() => onError());
                    }
                    else onError();
                }).catch(() => onError());
            }
        });
    }, [user, emplacementForm, defaultImg, getStaticText]);
    //#endregion

    //#region Bulk Creation
    type searchNameParentFn = (id: string | undefined, parentsName: string[]) => string[];

    const emplacementList = React.useMemo(() => {
        const searchNamesParent: searchNameParentFn = (id, parentsName = []) => {
            if (!TB.mongoIdValidator(id)) return parentsName;
            let node = findNode(id);
            if (node === null) return parentsName;
            let parentId = findRelativeNodes(id, false)[0]?._id;
            return searchNamesParent(parentId, parentsName.concat(node.data.name));
        }

        return nodes.map(node => ({ value: node._id, names: searchNamesParent(node._id, []) }))
            .map(({ names, ...opt }) => ({ ...opt, label: `${names[0]} ${names.length > 1 ? `(${_.drop(names, 1).join(" - ")})` : ""}` }));
    }, [findNode, findRelativeNodes, nodes]);

    const getEmplacementList = React.useCallback((id: string) => emplacementList.filter(({ value }) => value !== id), [emplacementList]);

    const bulkFloors = React.useCallback(() => {
        const alertFailure = () => M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_DELETE });

        if (TB.isUser(user) && TB.multiMongoIdValidator([emplacementForm, ownLinkType, buildingId])) renderMagicModal({ type: "floor", icons: iconOptions, defaultIcon: defaultImg })
            .then(empData => {
                if (empData !== null && empData.length > 0) {
                    let submissions = empData.map(data => ({ ...TB.blankSub(user?._id, emplacementForm), data }));
                    US.createManySubmissions(submissions).then(({ data }) => {
                        if (Array.isArray(data)) {
                            const onError = () => {
                                US.removerManySubmissionsFromFilter({ _id: data.map(({ _id }) => _id) });
                                alertFailure();
                            }

                            let links = data.map(({ _id }) => ({ input: buildingId, output: _id, type: ownLinkType }));
                            US.createManyLinks(links).then(linkReply => {
                                if (Array.isArray(linkReply.data)) setResource(p => ({
                                    ...p,
                                    nodes: p.nodes.concat(data),
                                    links: p.links.concat(linkReply.data)
                                }))
                                else onError()
                            }).catch(onError);
                        }
                        else alertFailure();
                    }).catch(alertFailure);
                }
            })
    }, [iconOptions, defaultImg, user, emplacementForm, ownLinkType, buildingId]);

    const duplicate = React.useCallback(() => {
        /* TODO */
        // Proposer de dupliquer a plusieurs / tous les autres (niveaux)
        // Proposer de dupliquer la descendance avec
        console.log("todo");
    }, []);

    const showTree = React.useCallback((id: string) => M.renderLightTree({ root: id }), []);

    const askReplacement = React.useCallback((id: string) => new Promise<boolean | null>((resolve, reject) => {
        US.hasDescendance([id]).then(({ data }) => {
            const alertFailure = () => M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_DELETE });

            let selectProps = {
                options: getEmplacementList(id),
                title: TC.BUILD_EDIT_FLOOR_REPLACEMENT,
                noSelectionText: TC.BUILD_EDIT_FLOOR_MASS_DELETE,
                description: TC.BUILD_EDIT_FLOOR_DELETE_SELECT_LABEL,
                extraButton: <BS.Button onClick={() => showTree(id)} >{getStaticText(TC.GLOBAL_SHOW_MINI_TREE)}</BS.Button>
            }

            if (data?.hasFailed || !TB.validObject(data)) reject(null);
            /* Node has descendance, ask for the replacement / Mass Delete AND SHOW CURRENT TREE */
            else if (data[id]) M.askSelect(selectProps).then(newParent => {
                /* User chose new parent, so update links */
                if (TB.mongoIdValidator(newParent)) {
                    const onError = () => {
                        alertFailure();
                        resolve(null);
                    }

                    US.updateManyLinks({ input: id }, { input: newParent }).then(({ data }) => {
                        if (data?.ok) {
                            setResource(p => ({
                                ...p,
                                links: p.links.map(link => link.input === id ? { ...link, input: newParent } : link)
                            }));
                            resolve(true);
                        }
                        else onError();
                    }).catch(onError);
                    resolve(true);
                }
                /* User canceled */
                else if (newParent === null) resolve(null);
                /* User chose to delete the whole subtree */
                else M.askConfirm({}).then(confirmed => {
                    const failedDelete = () => {
                        alertFailure();
                        resolve(null);
                    }

                    if (confirmed) massDelete(id, true).then(deletedIds => {
                        if (Array.isArray(deletedIds)) {
                            setResource(p => ({
                                ...p,
                                nodes: p.nodes.filter(({ _id }) => !deletedIds.includes(_id)),
                                links: p.links.filter(({ input, output }) => !deletedIds.includes(input) && !deletedIds.includes(output))
                            }));
                            resolve(false);
                        }
                        else failedDelete();
                    }).catch(failedDelete)
                    else resolve(null);
                });
            })
            /* No Descendance, so ask for confirmation of the deletion */
            else M.askConfirm({}).then(confirmed => resolve(confirmed ? true : null));
        })
    }), [getEmplacementList, getStaticText, showTree]);

    const removeNode = React.useCallback((id: string) => {
        const alertFailure = () => M.renderAlert({ type: "error", message: TC.GLOBAL_ERROR_DELETE });

        if (TB.mongoIdValidator(id)) askReplacement(id).then(async hasReplaced => {
            /* Is replacement has been picked and done or if no replacement was necessary */
            if (typeof hasReplaced === "boolean") {
                if (hasReplaced) US.deleteSubmissionsAndRelatives([id]).then(({ data }) => {
                    if (data?.ok === 1) setResource(p => ({
                        ...p,
                        nodes: p.nodes.filter(node => node._id !== id),
                        links: p.links.filter(link => link.input !== id && link.output !== id),
                    }));
                    else alertFailure();
                })
            }
        }).catch(alertFailure);
    }, [askReplacement]);
    //#endregion

    //#region navBar
    const onSelectTab = React.useCallback<AccordionSelectCallback>(tabKey => {
        if (TB.mongoIdValidator(tabKey) && tabKey !== activeTab) saveEmp().then(isValid => {
            if (isValid) {
                let emp = _.find(nodes, ({ _id }) => _id === tabKey) ?? null;
                empRef.current = emp === null ? null : _.cloneDeep(emp);
                setErrors({});
                setActiveTab({ activeTab: tabKey, allClosed: false });
            }
        });
        else if (tabKey === activeTab) setActiveTab(p => ({ ...p, allClosed: !p.allClosed }));
    }, [nodes, activeTab, saveEmp]);

    const renderNavAccordion = React.useCallback((nodes: T.EmplacementType[]) => {
        const render = (nodes: T.EmplacementType[]) => {
            if (nodes.length === 0) return null;
            return nodes.map(({ _id, data }) => {
                let children = findRelativeNodes(_id);
                return <BS.Accordion.Item key={_id} eventKey={_id}>
                    <BS.Accordion.Header className="smaller_buttons">{data.name}</BS.Accordion.Header>
                    <BS.Accordion.Body className="text-center p-2 ps-1">
                        <BS.Collapse in={activeTab === _id}>
                            <div className="my-2">
                                <BS.ButtonGroup className="w-100">
                                    {rights.isRightAllowed(RIGHTS.TECH.CREATE_EMPLACEMENT) && <BS.Button size="sm" title={getStaticText(TC.CREAT_NEW)} onClick={() => createEmplacement(_id)} >
                                        <i className="fa fa-plus"></i>
                                    </BS.Button>}
                                    {rights.isRightAllowed(RIGHTS.TECH.CREATE_EMPLACEMENT) && <BS.Button size="sm" disabled onClick={duplicate} title={getStaticText(TC.AG_DUPLICATE)} variant="info">
                                        <i className="fa fa-copy"></i>
                                    </BS.Button>}
                                    {rights.isRightAllowed(RIGHTS.TECH.DELETE_EMPLACEMENT, _id) && <BS.Button size="sm" onClick={() => removeNode(_id)} title={getStaticText(TC.GLOBAL_DELETE)} variant="danger">
                                        <i className="fa fa-times"></i>
                                    </BS.Button>}
                                </BS.ButtonGroup>
                            </div>
                        </BS.Collapse>
                        {children.length === 0 && <span className="text-muted">{getStaticText(TC.GLOBAL_NO_EMPLACEMENTS)}</span>}
                        {children.length > 0 && render(children)}
                    </BS.Accordion.Body>
                </BS.Accordion.Item>
            })
        };

        return render(nodes);
    }, [findRelativeNodes, getStaticText, createEmplacement, duplicate, removeNode, activeTab, rights]);

    const reverseOrder = React.useMemo(() => {
        const findParents = (id: string, children: string[]) => {
            let input = _.find(links, ({ output }) => output === id)?.input;
            if (TB.mongoIdValidator(input)) return findParents(input, children.concat(input));
            else return children;
        }
        let order: string[] = _.reverse(findParents(activeTab ?? "", [activeTab ?? ""]));
        return allClosed ? _.dropRight(order, 1) : order;
    }, [links, activeTab, allClosed]);

    const navBar = React.useMemo(() => {
        if (TB.mongoIdValidator(buildingId)) return <BS.Accordion flush onSelect={onSelectTab} activeKey={reverseOrder} style={{ maxHeight: "500px", overflow: "scroll" }}>
            {renderNavAccordion(findRelativeNodes(buildingId))}
        </BS.Accordion>
        return null;
    }, [buildingId, reverseOrder, findRelativeNodes, onSelectTab, renderNavAccordion]);
    //#endregion

    //#region API
    React.useEffect(() => {
        if (TB.validObject(api) && TB.validObject(api.current)) {
            api.current.saveData = saveEmp;
            api.current.changesMade = () => updated;
        }
    }, [api, saveEmp, updated]);
    //#endregion

    return <div className="mt-3">
        {status === "loading" && <M.Loader />}
        {errorBanner}
        {status === "ready" && <BS.Row className="ps-2">
            <BS.Col md={4} className="border p-2 bg-white rounded">
                <div className="w-100 mb-2 mx-1">
                    {rights.isRightAllowed(RIGHTS.TECH.CREATE_EMPLACEMENT) && <BS.Button className="w-100" onClick={bulkFloors}>
                        <i className="fa fa-magic me-2"></i>
                        {getStaticText(TC.BR_B_AUTO_CREATE)}
                    </BS.Button>}
                </div>
                {navBar}
            </BS.Col>
            <BS.Col md={8}>
                {noItemPanel}
                {itemPanel}
            </BS.Col>
        </BS.Row>}
    </div>
}

export default BuildStruct;