import _ from "lodash";
import { v4 as uuid } from "uuid";
import * as S from "../../services";
import { RegSelect } from "../RegDoc";
import { T, TC, URL, TB } from "../../Constants";
import * as US from "../../services/user.service";
import { useAuth, useLanguage } from "../../hooks";
import { Button, ListGroup } from "react-bootstrap";
import SignaturePad from "react-signature-pad-wrapper";
import FileUploadComp from "../Formio/Components/Premium/FileUploadComp";
import { BlankModal, renderAlert, renderInContainer, renderLoader } from "../Modal";
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

//#region Types
export type TicketSignerProps = {
    popUp?: boolean;
    docList?: Docs[];
    onClose?: () => void;
    tickets: T.TicketType[];
    actions?: T.Submission<T.RegAction>[];
    onSubmit: (tickets: T.TicketType[]) => void;
    setDocList?: React.Dispatch<React.SetStateAction<Docs[]>>;
}

type Docs = {
    regDoc: T.RegDoc;
    tickets: string[];
};
//#endregion

const TEXT_CODES = [TC.ER_SIGNATURE, TC.P_CLOSE_TICKETS, TC.GT_CLOSE_TICKET, TC.GLOBAL_AFTER_PIC];

const TicketSigner: FC<TicketSignerProps> = ({ tickets, actions, onSubmit, docList = [], setDocList, ...props }) => {
    const preFnRefs = useRef();
    const postFnRefs = useRef();
    const hasSignedRef = useRef<{ signed: boolean, docs: Docs[] }>({ signed: false, docs: [] });

    const [{ user }] = useAuth();
    const { getStaticText } = useLanguage(TEXT_CODES);
    const [signature, setSignature] = useState<string>();
    const [validTickets, setTickets] = useState<T.TicketType[]>([]);

    useEffect(() => setTickets(TB.getArray(tickets).filter(TB.isTicket)), [tickets]);

    const pictureSetter = useCallback((id, after) => setTickets(p => p.map(ticket => {
        if (ticket._id !== id) return ticket;
        return { ...ticket, data: { ...ticket.data, after } }
    })), []);

    //#region RegSelect Callback
    const onChangeRegDoc = useCallback((regDoc: T.RegDoc, ticket: T.TicketType) => {
        setDocList?.(p => {
            let docIds = p.map(l => l.regDoc._id);
            if (!docIds.includes(regDoc._id)) return p.concat({ regDoc, tickets: [ticket._id] });
            return p.map(l => {
                if (l.regDoc._id === regDoc._id) return {
                    regDoc,
                    tickets: l.tickets.includes(ticket._id) ? l.tickets : l.tickets.concat(ticket._id)
                }
                return l;
            })
        });
    }, [setDocList]);

    const onRemoveRegDoc = useCallback((regDoc: string, ticket: T.TicketType) => {
        setDocList?.(p => p.map(l => {
            if (l.regDoc._id === regDoc) return { ...l, tickets: l.tickets.filter(id => id !== ticket._id) }
            return l;
        }))
    }, [setDocList]);
    //#endregion

    //#region Render
    const renderTicket = useCallback((ticket: T.TicketType) => <ListGroup.Item key={ticket._id}>
        <span className="h5" onClick={() => console.log(ticket)}>{ticket.data.title}</span>
        <div>
            {/* @ts-ignore */}
            {ticket.data.type !== "reg" && <FileUploadComp
                image
                webcam
                multiple
                newStorage="local"
                fileMaxSize="15mb"
                postFunc={postFnRefs}
                value={ticket.data.after}
                preEditDataFunc={preFnRefs}
                label={getStaticText(TC.GLOBAL_AFTER_PIC)}
                setter={val => pictureSetter(ticket._id, val)}
            />}

            {ticket.data.type === "reg" && <div className="my-2">
                <RegSelect
                    actions={actions}
                    popUpContainer="regDocContainer"
                    action={ticket.data.regAction}
                    element={ticket.data.equipment}
                    options={docList.map(l => l.regDoc)}
                    onEdited={doc => onChangeRegDoc(doc, ticket)}
                    onCreated={doc => onChangeRegDoc(doc, ticket)}
                    onDeleted={docId => onRemoveRegDoc(docId, ticket)}
                    selected={_.find(docList, l => l.tickets.includes(ticket._id))?.regDoc?._id}
                />
            </div>}
        </div>
    </ListGroup.Item>, [getStaticText, pictureSetter, onChangeRegDoc, onRemoveRegDoc, actions, docList]);
    //#endregion

    //#region Submit
    useEffect(() => {
        hasSignedRef.current.docs = docList;
    }, [docList]);

    useEffect(() => {
        // Delete the unsaved docs if user quits before saving
        let ref = hasSignedRef.current;
        return () => {
            if (!ref.signed && ref.docs.length > 0) S.clearRegDocs(ref.docs.map(d => d.regDoc._id));
        }
    }, []);

    const submit = useCallback(() => {
        let sign = TB.getString(signature);

        let saveFilePromises = _.flatten(
            validTickets.map(({ data, _id }) => {
                if (!Array.isArray(data.after)) return null;
                return data.after.map((file, index) => new Promise<{ _id: string, fileName: string }>(resolve => {
                    let formData = new FormData();
                    formData.append("file", file.url);
                    US.uploadLocalFile(_id, file.name, formData)
                        .then(() => resolve({ _id, fileName: file.name }))
                        .catch(() => resolve({ _id: null, fileName: null }));
                }));
            })
        ).filter(p => p instanceof Promise);

        let uploadedSignature = new Promise(resolve => {
            let _id = validTickets[0]._id;
            let fileName = uuid() + "_signature.png";
            let formData = new FormData();
            formData.append("file", sign);
            US.uploadLocalFile(_id, fileName, formData).then(() => resolve(URL.CRAFT_FILE_URL(_id, fileName)));
        });

        let dismountLoader = renderLoader();

        Promise.all(saveFilePromises).then(replies => {
            uploadedSignature.then((signatureUrl: string) => {
                let filesNames = replies.map(r => r.fileName);
                let ticketsIds = validTickets.map(t => t._id);
                let emptyDoc = docList.filter(dl => dl.tickets.length === 0).map(dl => dl.regDoc._id);
                let update = { signature: signatureUrl, signedBy: user?._id, dateSignature: new Date().toISOString() };

                let updatedTickets = validTickets.map(ticket => {
                    let afterPicsName = replies.filter(r => r._id === ticket._id).map(r => r.fileName);
                    let vFiles = TB.getArray(ticket.data.after);

                    if (afterPicsName.length === 0) return { ...ticket, data: { ...ticket.data, ...update } };
                    return {
                        ...ticket,
                        data: {
                            ...ticket.data,
                            ...update,
                            after: vFiles.map(f => {
                                if (afterPicsName.includes(f.name)) return { ..._.omit(f, "isB64", "isAdded"), storage: "local", url: URL.CRAFT_FILE_URL(ticket._id, f.name) }
                                return f;
                            })
                        }
                    };
                }).filter(TB.isTicket);

                let files = _.flatten(validTickets.map(t => TB.getArray(t.data.after)))
                    .filter(f => filesNames.includes(f.name))
                    .map(f => {
                        let _id = _.find(replies, r => r.fileName === f.name)?._id;
                        let file = { ..._.omit(f, "isAdded", "isB64", "url"), url: URL.CRAFT_FILE_URL(_id, f.name) };
                        return { _id, file };
                    });

                S.closeTickets(ticketsIds, signatureUrl, files, emptyDoc)
                    .then(() => {
                        hasSignedRef.current.signed = true;
                        onSubmit?.(updatedTickets);
                    })
                    .catch(() => renderAlert({ type: "error", message: TC.GLOBAL_ERROR_UPDATE }))
                    .finally(() => dismountLoader());
            });
        });
    }, [validTickets, signature, docList, user, onSubmit]);
    //#endregion

    //#region Misc
    const validSignature = useMemo(() => TB.validString(signature), [signature]);
    const closeTicketLabel = useMemo(() => validTickets.length > 1 ? TC.P_CLOSE_TICKETS : TC.GT_CLOSE_TICKET, [validTickets]);

    const allowedSubmit = useMemo(() => {
        let docTickets = _.flatten(docList.map(dl => dl.tickets));
        let regTickets = validTickets.filter(t => t.data.type === "reg").map(t => t._id);
        return validSignature && regTickets.every(id => docTickets.includes(id));
    }, [validSignature, validTickets, docList]);
    //#endregion


    const content = useMemo(() => <div>
        <div id="regDocContainer"></div>
        <ListGroup className="my-3">
            {validTickets.map(renderTicket)}
        </ListGroup>

        {/* @ts-ignore */}
        <Signature value={signature} setter={setSignature} label={getStaticText(TC.ER_SIGNATURE)} />

        <Button className="mt-3" onClick={submit} disabled={!allowedSubmit}>
            <i className="fa fa-signature"></i>
            <span className="ms-2">{getStaticText(closeTicketLabel)}</span>
        </Button>
    </div>, [allowedSubmit, closeTicketLabel, getStaticText, renderTicket, signature, submit, validTickets]);

    if (props.popUp) return <BlankModal size="sm" onQuit={props.onClose}>
        {content}
    </BlankModal>
    return content;
}

export default TicketSigner;

//#region Signature Component
const Signature = ({
    //#region Formio Props
    width,
    footer,
    hidden,
    hideLabel,
    maxWidth,
    minWidth,
    height = "150px",
    penColor = "black",
    label = "Signature",
    backgroundColor = "rgb(245,245,235)",
    //#endregion

    //#region Custom Props
    setter,
    value,
    //#endregion

    ...props
}) => {
    const padRef = useRef<any>();

    //#region Setter
    const onEndStroke = useCallback(() => setter?.(padRef.current?.toDataURL?.() ?? ""), [setter]);
    //#endregion

    //#region value
    const clearValue = useCallback(() => {
        padRef.current?.clear?.()
        setter?.();
    }, [setter]);
    //#endregion

    useEffect(() => TB.validString(value) ? padRef.current?.fromDataURL?.(value) : undefined, [value]);
    useEffect(() => padRef?.current?.signaturePad?.addEventListener?.("endStroke", onEndStroke), [onEndStroke]);

    //#region Footer
    const footerElem = useMemo(() => !TB.validString(footer) ? undefined : <div className="p-1 mb-1 text-center">{footer}</div>, [footer]);
    //#endregion

    return !hidden && <div className="w-100 position-relative">
        <button className="position-absolute top-0 start-0 border border-1" onClick={clearValue}>
            <i className="fa fa-times py-1"></i>
        </button>
        {/* @ts-ignore */}
        <SignaturePad ref={padRef} options={{ penColor, backgroundColor }} redrawOnResize />
        {footerElem}
    </div>
}
//#endregion

//#region Render Modal
type TicketSignerPropsFn = Omit<TicketSignerProps, "onSubmit" | "onClose">;

export const renderTicketSigner = (params: TicketSignerPropsFn) => new Promise<T.TicketType[] | null>(resolve => {
    const [render, dismount] = renderInContainer();
    if (render && dismount) {
        if (!TB.validObject(params)) params = { tickets: [] };

        const close = () => dismount(() => resolve(null));
        const submit: TicketSignerProps["onSubmit"] = tickets => dismount(() => resolve(tickets));

        render(<TicketSigner {...params} popUp onClose={close} onSubmit={submit} />);
    }
    else resolve(null);
});
//#endregion