import _ from "lodash";
import React from "react";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import { Skeleton } from "@mui/material";
import { ExtraParams } from "../../Common/Form";
import { T, TB, TC, URL } from "../../Constants";

//#region Types
type SubmissionResource<D> = Partial<T.API.Form.GetSubmissionFormResults<D>>;
type ModalProps = Pick<M.BlankModalProps, "autofocus" | "isFullScreen" | "size">;

export type FormApi = {
    /** Call to save the form */
    save?: () => void;
    /** Has there been any manual changes done ? */
    has_changed?: () => boolean;
}

export type FormProps<Data extends Record<string, any> = {}> = {
    _id?: string;
    path?: string;
    title?: string;
    modal?: boolean;
    readOnly?: boolean;
    hideSubmit?: boolean;
    noDbSaving?: boolean;
    submissionId?: string;
    modalProps?: ModalProps;
    extraData?: ExtraParams;
    api?: React.MutableRefObject<FormApi>;
    onChangeData?: (data: Data) => void;
    forcedSubmission?: T.Sockets.Form.ForcedUpdate[];
    onSave?: (submission?: T.Submission<Data>) => void;
    /** Callback when the saving is enabled / disabled */
    on_saving_status_change?: (status: boolean) => void;
}
//#endregion

//#region Constants
const TEXT_CODES = [TC.GLOBAL_SAVE];
const FORM_INIT: ReturnType<T.API.Form.GetFormComponents> = { _id: "", components: [], path: "" };
//#endregion

const Form = <D,>({ _id, title, path, submissionId, extraData, forcedSubmission, modalProps, onSave, onChangeData, on_saving_status_change, ...props }: FormProps<D>) => {
    const isMounted = H.useIsMounted();
    const isSaved = H.useBoolean(false);
    const isError = H.useBoolean(false);
    const lg = H.useLanguage(TEXT_CODES);
    const isSaving = H.useBoolean(false);
    const isLocked = H.useBoolean(false);
    const isKicked = H.useBoolean(false);
    const lock_submit = H.useBoolean(false);
    const [{ user, isAdmin }] = H.useAuth();
    const isRegistered = H.useBoolean(false);
    const [context, mission_id] = H.useRoots();
    const contextHasData = H.useBoolean(false);
    const refDbSub = React.useRef<Partial<D> | null>({});
    const [socket] = H.useSingleSocket(URL.FORM_SOCKET);
    const [subData, setSubData] = React.useState<Partial<D>>({});
    const [contextKey, changes, updateContext, fullData] = H.useFormProvider();
    const [form, setForm, formStatus, setFormStatus] = H.useAsyncState(FORM_INIT);
    const [forcedUpdate, setForcedUpdates] = React.useState<T.Sockets.Form.ForcedUpdate[]>([]);
    const [submission, setSubmission, subStatus, setSubStatus] = H.useAsyncState<SubmissionResource<D>>({});

    //#region Fetch Forms
    React.useEffect(() => {
        let isSubscribed = true;
        S.getFormComponents({ id: _id, path })
            .then(({ data }) => isSubscribed && setForm(data, "done"))
            .catch(() => isSubscribed && setForm(FORM_INIT, "error"));
        return () => { isSubscribed = false };
    }, [_id, path, setForm]);
    //#endregion

    //#region Fetch Submission
    const fetchSubmission = React.useCallback((id: string) => {
        if (TB.mongoIdValidator(id)) S.getSubmissionAndCertifications<D>({ id: _id, path: path, submission: id })
            .then(({ data }) => {
                if (isMounted()) {
                    setSubmission(data, "done");
                    if (data.status === "success") {
                        // Save the original Data, for comparison
                        refDbSub.current = data.submission?.data || null;
                        setSubData(data.submission?.data || {});
                    }
                    else {
                        refDbSub.current = null;
                        setSubData({});
                    }
                };
            })
            .catch(() => isMounted() && setSubmission({}, "error"));
        else setSubmission({}, "done");
    }, [isMounted, setSubmission, _id, path]);

    React.useEffect(() => {
        fetchSubmission(submissionId);
    }, [submissionId, fetchSubmission]);
    //#endregion

    //#region Fetch Languages
    React.useEffect(() => {
        let ids = [_id, submissionId, form._id].filter(TB.mongoIdValidator);
        if (ids.length > 0) lg.fetchObjectTranslations(ids);
    }, [_id, submissionId, form._id, lg]);

    React.useEffect(() => {
        let paths = [path, form.path].filter(TB.isTextCode);
        if (paths.length > 0) lg.fetchStaticTranslations(paths);
    }, [path, form.path, lg]);
    //#endregion

    //#region Error & Skeleton
    const allLoaded = React.useMemo(() => {
        if (!isRegistered.value) return false;
        if (isLocked.value || isKicked.value) return false;
        if (formStatus !== "done" || subStatus !== "done") return false;
        return !submission.status || submission.status === "success";
    }, [formStatus, isRegistered, subStatus, submission.status, isLocked.value, isKicked.value]);

    const errorBanner = React.useMemo(() => {
        let error: string, warning: string, success: string;
        if (isLocked.value) warning = TC.FORM_LOCKED;
        else if (isError.value) error = TC.FORM_ERROR;
        else if (isKicked.value) error = TC.FORM_KICKED;
        else if (isSaved.value) success = TC.FORM_SUCCESS;
        else if (subStatus === "error") error = TC.FORM_ERR_LOAD_SUB;
        else if (formStatus === "error") error = TC.FORM_ERR_LOAD_FORM;
        else if (submission.status === "failed") error = submission.reason;

        if (!TB.validString(error) && !TB.validString(warning)) return null;
        return <C.ErrorBanner type={error ? "danger" : (warning ? "warning" : "success")} textCode={error || warning || success} />;
    }, [formStatus, subStatus, submission, isLocked.value, isKicked.value, isError.value, isSaved.value]);

    const skeleton = React.useMemo(() => {
        if (errorBanner || allLoaded) return null;
        return [...new Array(5)].map((x, i) => <div key={i} className="mb-3">
            <Skeleton animation="wave" variant="text" width={"33%"} />
            <Skeleton animation="wave" variant="rectangular" height={"3rem"} width={"100%"} />
        </div>);
    }, [allLoaded, errorBanner]);
    //#endregion

    //#region Save / Update
    const has_had_changes = React.useCallback(() => {
        return changes.updates.some(u => u.type === "manual");
    }, [changes.updates]);

    const onSubmit = React.useCallback(() => {
        isSaving.setTrue();
        socket.emit("check_sub_data");
    }, [isSaving, socket]);

    const onSubmitChecked = React.useCallback((errors: T.Sockets.Form.DataChecked_R) => {
        if (Object.values(errors).length > 0) {
            isSaving.setFalse();
            updateContext({ errors });
        }
        else socket.emit("form_submit_request", { noDbSaving: props.noDbSaving });
    }, [isSaving, socket, updateContext, props.noDbSaving]);

    const onSubmitDone = React.useCallback((submission: T.Submission<D>) => {
        isSaving.setFalse();
        if (submission) {
            isSaved.setTrue();
            onSave?.(submission);
        }
        else isError.setTrue();
    }, [isSaving, isSaved, isError, onSave]);

    const onQuit = React.useCallback(() => {
        if (has_had_changes()) M.Confirms.quit_no_save()
            .then(confirmed => confirmed && onSave?.());
        else onSave?.();
    }, [onSave, has_had_changes]);
    //#endregion

    //#region Api
    React.useEffect(() => {
        if (props.api && props.api.current) {
            props.api.current.save = onSubmit;
            props.api.current.has_changed = has_had_changes;
        }
    }, [props.api, onSubmit, has_had_changes]);

    /* @ts-ignore */
    React.useEffect(() => onChangeData?.(fullData), [onChangeData, fullData]);

    React.useEffect(() => {
        on_saving_status_change?.(lock_submit.value)
    }, [on_saving_status_change, lock_submit.value]);
    //#endregion

    //#region Socket
    const reloadSubmission = React.useCallback((id: string) => {
        setSubStatus("load");
        fetchSubmission(id);
        isLocked.setFalse();
    }, [fetchSubmission, setSubStatus, isLocked]);

    const stateUpdates = React.useCallback((params: T.Sockets.Form.UpdateState_R) => {
        if (contextHasData.value) updateContext(params);
        else {
            contextHasData.setTrue();
            updateContext({ ...params, data: subData });
        }
    }, [updateContext, subData, contextHasData]);

    const registered = React.useCallback((params?: T.Sockets.Form.UpdateState_R) => {
        isRegistered.setTrue();
        if (params) stateUpdates(params);
    }, [isRegistered, stateUpdates]);

    React.useEffect(() => {
        let hasLocked = false;

        // Register user
        if (formStatus === "done" && subStatus === "done" && TB.isSub(user)) {
            let sub = submission.status === "success" ? submission.submission._id : null;
            let params: T.Sockets.Form.LockForm_E = {
                isAdmin,
                context,
                extraData,
                lang: lg.prop,
                user: user._id,
                form: form.path,
                submission: sub,
                mission_id: mission_id,
                readonly: props.readOnly,
            };
            hasLocked = true;
            socket.emit("lock_form", params);
        }

        return () => {
            // Unregister user
            if (hasLocked) socket.emit("unlock_form");
        }
    }, [socket, formStatus, subStatus, context, user, mission_id, form.path, submission, isAdmin, props.readOnly, lg.prop, extraData]);

    React.useEffect(() => socket.addOneCallback("form_error", isError.setTrue), [socket, isError]);
    React.useEffect(() => socket.addOneCallback("sub_updates", stateUpdates), [socket, stateUpdates]);
    React.useEffect(() => socket.addOneCallback("form_registered", registered), [socket, registered]);
    React.useEffect(() => socket.addOneCallback("locked_form", isLocked.setTrue), [socket, isLocked]);
    React.useEffect(() => socket.addOneCallback("form_kick_out", isKicked.setTrue), [socket, isKicked]);
    React.useEffect(() => socket.addOneCallback("form_submitted", onSubmitDone), [socket, onSubmitDone]);
    React.useEffect(() => socket.addOneCallback("unlocked_form", reloadSubmission), [socket, reloadSubmission]);
    React.useEffect(() => socket.addOneCallback("sub_data_checked", onSubmitChecked), [socket, onSubmitChecked]);
    React.useEffect(() => socket.addOneCallback("load_sub_err", () => setSubStatus("error")), [socket, setSubStatus]);
    React.useEffect(() => socket.addOneCallback("load_form_err", () => setFormStatus("error")), [socket, setFormStatus]);
    //#endregion

    //#region Components
    const onChange = React.useCallback((prop: string, value: unknown) => {
        if (isSaving.value) return;

        // Make sure the value has changed
        let instance = _.find(changes.updates, c => c.prop === prop);
        if (instance && _.isEqual(instance.value, value)) socket.emit("register_form_activity");
        else {
            // Check if a recently created certification for the prop doesn't have the same value anymore
            let obsolete_certification = changes.partial_certif.some(c => c.prop === prop && c.value !== TB.valueToString(value));

            let updated_context: Partial<typeof changes> = {};
            if (TB.validString(changes.errors[prop])) updated_context.errors = _.omit(changes.errors, prop);
            if (obsolete_certification) updated_context.partial_certif = changes.partial_certif.filter(c => c.prop !== prop);

            let params: T.Sockets.Form.ChangeData_E = { prop, value, type: 'manual' };
            if (Object.keys(updated_context).length > 0) updateContext({ ...changes, ...updated_context });
            socket.emit("form_data_change", params);
        }
    }, [socket, isSaving.value, changes, updateContext]);

    const add_cert = React.useCallback((cert: T.Certification) => {
        if (isSaving.value) return;
        let updated_certifications = changes.partial_certif
            // Remove potential other certification for the same prop
            .filter(c => c.prop !== cert.prop)
            // Add the new certification
            .concat(cert);
        updateContext({ ...changes, partial_certif: updated_certifications });
        socket.emit("upd_certif", updated_certifications);
    }, [socket, changes, isSaving.value, updateContext]);

    const onMultipleChangeData = React.useCallback((updates: { value: any, prop: string }[]) => {
        if (isSaving.value) return;
        let tempUpdates = TB.arrayWrapper(updates).filter(c => TB.validString(c?.prop));
        let vUpdates: T.Sockets.Form.SubUpdate[] = tempUpdates.map(c => ({ ...c, type: "manual" }));

        let copyErrors = _.cloneDeep(changes.errors), hasChanged = false;
        for (let update of vUpdates) {
            if (TB.validString(copyErrors[update.prop])) {
                hasChanged = true;
                copyErrors = _.omit(copyErrors, update.prop);
            }
        }

        if (hasChanged) updateContext({ ...changes, errors: copyErrors });
        socket.emit("form_data_change", vUpdates);
    }, [socket, updateContext, changes, isSaving.value]);

    const components = React.useMemo(() => {
        if (errorBanner || skeleton) return null;
        return form.components.map(c => <C.Input
            {...c}
            prop={c.key}
            formId={form?._id}
            extraData={extraData}
            contextKey={contextKey}
            onAddCertification={add_cert}
            lock_form={lock_submit.setTrue}
            onChanges={onMultipleChangeData}
            unlock_form={lock_submit.setFalse}
            onChange={(v, key) => onChange(TB.getString(key, c.key), v)}
            submissionId={submission?.status === "success" ? submission?.submission?._id : undefined}
        />)
    }, [errorBanner, contextKey, form._id, lock_submit, submission, skeleton, onChange, add_cert, onMultipleChangeData, form.components, extraData]);
    //#endregion

    //#region Default Submission Updates
    const preForcedUpdates = React.useMemo(() => TB.getArray(forcedSubmission).filter(u => TB.validString(u?.prop)), [forcedSubmission]);

    React.useEffect(() => {
        let newUpdates = preForcedUpdates
            .filter(u => !_.find(forcedUpdate, fu => fu.prop === u.prop && _.isEqual(fu.value, u.value)));

        if (isRegistered.value && newUpdates.length > 0) {
            onMultipleChangeData(newUpdates);
            setForcedUpdates(p => {
                let newProps = newUpdates.map(u => u.prop);
                return p.filter(u => !newProps.includes(u.prop)).concat(newUpdates);
            });
        }
    }, [onMultipleChangeData, preForcedUpdates, forcedUpdate, isRegistered.value]);
    //#endregion

    //#region Footer
    const nbErrors = React.useMemo(() => Object.values(changes.errors).length, [changes.errors]);
    const saveButtonIcon = React.useMemo(() => isSaving.value ? "spinner fa-spin" : "save", [isSaving.value]);
    const hideFooter = React.useMemo(() => props.hideSubmit || props.readOnly || !allLoaded, [props.hideSubmit, props.readOnly, allLoaded]);

    const footer = React.useMemo(() => !hideFooter && <C.Flex alignItems="center" justifyContent="end">
        <C.Button
            onClick={onSubmit}
            icon={saveButtonIcon}
            text={TC.GLOBAL_SAVE}
            disabled={nbErrors > 0 || lock_submit.value}
        />
    </C.Flex>, [saveButtonIcon, hideFooter, nbErrors, lock_submit.value, onSubmit]);
    //#endregion

    return React.createElement(
        props.modal ? M.BlankModal : React.Fragment,
        props.modal ? {
            ...modalProps,
            footer,
            onQuit,
            maxBodyHeight: "70vh",
            title: title || form.path,
            size: modalProps?.size || "md",
        } : null,
        <>
            {errorBanner}
            {skeleton}
            {components}
            {!props.modal && <div children={footer} className="my-3" />}
        </>
    );
}

export default Form;