import React from "react";
import * as H from "../../../hooks";
import { TB, TC } from "../../../Constants";
import { StyleModalProps, default as Modal } from "../BlankModal";
import { Button, ButtonProps, Flex, ReduxWrapper } from "../../../Common";

export type ConfirmProps = {
    /** Text to be displayed in the modal */
    text?: string | React.ReactElement;
    /** Title of the modal */
    title?: string;
    /** Text displayed in the cancel button */
    noText?: string;
    /** Text displayed in the confirm button */
    yesText?: string;
    /** Color of the cancel button */
    noVariant?: ButtonProps["variant"];
    /** Do not show any button */
    no_buttons?: boolean;
    /** Other elements to display in the footer */
    extraOptions?: React.ReactElement | ((confirm: (value: any) => void) => React.ReactElement);
    /** Callback for cancelation */
    onQuit?: () => void;
    /** Callback for confirmation */
    onValidate?: (confirmed: boolean) => void;
    /** Extra params to change the modal */
    modal?: StyleModalProps;
}

const Confirm: React.FC<ConfirmProps> = ({ onValidate, extraOptions, ...props }) => {
    const lg = H.useLanguage([TC.GLOBAL_CONFIRM_DELETE]);

    //#region Language
    React.useEffect(() => {
        if (TB.isTextCode(props.text)) lg.fetchStaticTranslations(props.text);
    }, [props.text, lg]);
    //#endregion

    //#region Text
    const text = React.useMemo(() => {
        if (typeof props.text === "string") return lg.getStaticElem(props.text);
        else if (props.text) return props.text;
        else return lg.getStaticElem(TC.GLOBAL_CONFIRM_DELETE)
    }, [lg, props.text]);
    //#endregion

    //#region Footer
    const extraFooter = React.useMemo(() => {
        if (typeof extraOptions === "function") return extraOptions(v => onValidate?.(v));
        else return extraOptions;
    }, [extraOptions, onValidate]);

    const footer = React.useMemo(() => !props.no_buttons && <Flex alignItems="end">
        {extraFooter}
        <Button onClick={() => onValidate?.(false)} className="mx-2" variant={props.noVariant || "danger"}>
            {props.noText || TC.GLOBAL_CANCEL}
        </Button>
        <Button onClick={() => onValidate?.(true)}>
            {props.yesText || TC.GLOBAL_CONFIRM}
        </Button>
    </Flex>, [props.noText, props.yesText, props.noVariant, props.no_buttons, extraFooter, onValidate]);
    //#endregion

    return <Modal
        {...props.modal}
        footer={footer}
        children={text}
        onQuit={props.onQuit}
        size={props.modal?.size || "sm"}
        title={props.title || props.modal?.title || TC.GLOBAL_DELETE}
    />;
}

const WrappedConfirm: React.FC<ConfirmProps> = props => <ReduxWrapper children={<Confirm {...props} />} />;
export default WrappedConfirm;