import React from "react";
import { useLanguage } from "../../hooks";
import { FP, T, TC } from "../../Constants";
import { Table, TableProps, TableRef } from "../Gestion/Grid";
import { CellsTypes as CT } from "../Gestion/AgGridDefs";

type Row = ReturnType<T.API.Utils.Navigator.NavTableClients>[number];

export type ClientTableProps = {
    /** The rows to be shown in the table */
    rows: Row[];
    /** The current status of the loading */
    status: T.AsyncStates;
    /** The type of selection */
    selection: "checkbox" | "button";
    /** The items currently selected */
    selected?: string[];
    /** Callback for an item's selection */
    onSelect?: (id: string) => void;
    /** Callback for loading the heavy format of the table */
    load_heavy: () => void;
    /** Do the rows contain all the data */
    is_heavy?: boolean;
}

export type ClientTableRef = {
    /** Callback to select all active rows in the grid */
    select_all: () => string[];
}

const ClientTable = React.forwardRef<ClientTableRef, ClientTableProps>((props, ref) => {
    const lg = useLanguage();
    const table = React.useRef<TableRef<Row>>(null);

    const rows = React.useMemo(() => props.rows.map(r => ({
        ...r,
        selected: props.selected?.includes?.(r.id),
        tr_affectations: r.affectations.map(a => lg.getStaticText(a)),
        tr_sub_affectations: r.sub_affectations.map(a => lg.getStaticText(a)),
        tr_regions: (r.regions_ids||[]).map(id => lg.getTextObj(id, "name", r.regions?.[id] || "")),
    })), [props.rows, props.selected, lg]);

    const columns = React.useMemo<TableProps<Row>["columns"]>(() => {
        let cols = [] as TableProps<Row>["columns"];

        if (props.selection === "checkbox") cols.push({
            pinned: "left",
            headerName: " ",
            field: "selected",
            type: CT.TYPE_ACTION_BUTTON,
            params: {
                action: data => data && props.onSelect?.(data.id),
                buttonProps: (data: typeof rows[number]) => ({
                    size: "sm",
                    icon: data.selected ? "check-circle" : "check",
                    variant: data.selected ? "success" : "primary",
                    text: data.selected ? TC.NAV_TABLE_ITEM_SELECTED : TC.NAV_TABLE_ITEM_SELECT,
                }),
            }
        });
        else cols.push({
            pinned: "left",
            headerName: " ",
            field: "select",
            type: CT.TYPE_ACTION_BUTTON,
            params: {
                action: row => props.onSelect?.(row.id),
                buttonProps: { size: "sm", icon: "check" },
            }
        });

        cols.push(
            { field: "picture_link", headerName: TC.GLOBAL_PICTURE, type: CT.TYPE_PICTURES, params: { layout: "profile" } },
            { field: "name", headerName: TC.GLOBAL_NAME },
            { field: "enseignes", headerName: FP.ENSEIGNE_FORM, hide: false },
            (props.is_heavy ? { field: "sites_names", headerName: TC.SITE_PLURAL_SINGLE } : null),
            (props.is_heavy ? { field: "buildings_names", headerName: TC.BUILDING_PLURAL_SINGLE } : null),
            (props.is_heavy ? { field: "countries", headerName: TC.COUNTRY } : null),
            (props.is_heavy ? { field: "tr_regions", headerName: FP.REGION_FORM } : null),
            (props.is_heavy ? { field: "tr_affectations", headerName: TC.GLOBAL_AFFECTATION_PRINCIPAL } : null),
            (props.is_heavy ? { field: "tr_sub_affectations", headerName: TC.NAV_TABLE_SUB_AFFECTATION } : null),
            (props.is_heavy ? { field: "surfaces", headerName: TC.GLOBAL_SURFACE_BHS, type: CT.TYPE_NUMBER } : null),
        );
        return cols.filter(x => x !== null);
    }, [props]);

    //#region Ref
    const select_all = React.useCallback<ClientTableRef["select_all"]>(() => {
        let ids = [] as string[];
        table.current?.grid?.api?.forEachNode?.(n => n.displayed && ids.push(n.data.id));
        return ids;
    }, []);

    React.useImperativeHandle(ref, () => ({ select_all }), [select_all]);
    //#endregion

    return <Table<Row>
        count
        ref={table}
        rows={rows}
        columns={columns}
        status={props.status}
        adaptableId="nav_sites"
        sideBar="filters_columns"
        autoFit={["selected", "select", "picture_link"]}
        columns_base={["grouped", "filterable", "sortable"]}
        extra_buttons={{
            onClick: props.load_heavy,
            label: lg.getStaticText(TC.NAVIGATOR_LOAD_HEAVY),
            icon: { element: "<i class='fa fa-database me-2'></i>" },
        }}
    />
});

export default ClientTable;