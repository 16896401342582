import * as T from "../Types";
import * as R from "./Rights";
import * as TC from "../textCode";
import * as FP from "./FormPaths";

export const RoleIntervenantsOptions = [
    { label: TC.MISSION_INTERV_CONSULTANT, value: "advisor" },
    { label: TC.MISSION_INTERV_FACILITY, value: "facility_mg" },
    { label: TC.MISSION_INTERV_EXPLOITER, value: "maintenance" },
    { label: TC.MISSION_INTERV_OTHER, value: "other" },
    { label: TC.MISSION_INTERV_MANAGER, value: "owner" },
    { label: TC.MISSION_INTERV_PROPERTY, value: "property_mg" },
] as T.Option<{}, T.Mission_Intervenant["role"]>[];

export const MissionTypes = [
    { label: TC.MISSION_TYPES_CDM, value: "cdm", allowed_asset: [FP.SITE_FORM, FP.BUILDING_FORM], right: R.MISSION.ALLOW_CDM_MISSIONS, report_right: R.MISSION.ALLOW_CDM_MISSIONS_REPORT },
    { label: TC.MISSION_TYPES_NRJ, value: "nrj", allowed_asset: [FP.BUILDING_FORM], right: R.MISSION.ALLOW_NRJ_MISSIONS, report_right: R.MISSION.ALLOW_NRJ_MISSIONS_REPORT },
    { label: TC.MISSION_TYPES_EDL, value: "edl_tech", allowed_asset: [FP.SITE_FORM, FP.BUILDING_FORM], right: R.MISSION.ALLOW_EDL_MISSIONS, report_right: R.MISSION.ALLOW_EDL_MISSIONS_REPORT },
    { label: TC.MISSION_TYPES_RED_FLAG, value: "red_flag", allowed_asset: [FP.SITE_FORM, FP.BUILDING_FORM], right: R.MISSION.ALLOW_RED_FLAG_MISSIONS, report_right: R.MISSION.ALLOW_RED_FLAG_MISSIONS_REPORT },
    { label: TC.MISSION_TYPES_REG, value: "edl_reg", allowed_asset: [FP.SITE_FORM, FP.BUILDING_FORM], right: R.MISSION.ALLOW_REG_MISSIONS, report_right: R.MISSION.ALLOW_REG_MISSIONS_REPORT },
    { label: TC.MISSION_TYPE_EU_TAXONOMY, value: "eu_taxonomy", allowed_asset: [FP.BUILDING_FORM], right: R.MISSION.ALLOW_EU_TAXONOMY_MISSIONS, report_right: R.MISSION.ALLOW_EU_TAXONOMY_MISSIONS_REPORT },
    { label: TC.MISSION_TYPE_RSC01, value: "rsc01", allowed_asset: [FP.SITE_FORM, FP.BUILDING_FORM], right: R.MISSION.ALLOW_RSC01_MISSIONS, report_right: R.MISSION.ALLOW_RSC01_MISSIONS_REPORT },
] as T.Option<Record<"allowed_asset", string[]> & Record<"right" | "report_right", string>, T.Mission["type"]>[];