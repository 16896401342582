import "./modal.scss";
import { Form } from "react-bootstrap";
import { useLanguage } from "../../hooks";
import { Dialog } from "@material-ui/core";
import { TB, TC, T } from "../../Constants";
import renderInContainer from "./getContainer";
import { ReduxWrapper, TypeAhead, TypeAheadProps } from "../../Common";
import React, { FC, isValidElement, ReactNode, useEffect, useMemo, useState } from "react";

//#region Types
export type Option<A extends {} = T.AnyObject> = A & { label: string, value: string };
type TypeAheadPropsUpdated = Omit<TypeAheadProps, "options" | "onChange" | "onDropdownChange" | "selectedItems" | "noDropOverSize">;

export type ModalSelectProps = {
    title?: string;
    label?: string;
    options?: Option[];
    onQuit?: () => void;
    defaultVal?: string;
    isRequired?: boolean;
    confirmText?: string;
    description?: string;
    extraButton?: ReactNode;
    noSelectionText?: string;
    selectProps?: TypeAheadPropsUpdated;
    size?: "xs" | "sm" | "md" | "lg" | "xl";
    onValidate?: (selection?: string) => void;
    modalClass?: string;
}

export interface RenderModalSelectProps extends Omit<ModalSelectProps, 'onValidate' | 'onQuit' | 'extraButton' | 'create_option'> {
    renderExtraButton?: (resolve: (param: any) => void) => ReactNode;
    /** Callback for creating a new option */
    create_option?: (text: string, resolve: (option: string) => void) => void;
};
//#endregion

const TEXT_CODES = [TC.GLOBAL_CONFIRM, TC.GLOBAL_SELECT, TC.GLOBAL_NO_OPTIONS_SELECT, TC.GLOBAL_NO_SELECTION];

const ModalSelect: FC<ModalSelectProps> = ({ onValidate, onQuit, size, selectProps, description, extraButton, isRequired, defaultVal, options, title, noSelectionText, confirmText, label, ...props }) => {
    const [selection, setSelection] = useState<string | undefined>();
    const { getStaticText, fetchStaticTranslations } = useLanguage(TEXT_CODES);

    //#region Texts
    const isTitleCode = useMemo(() => TB.isTextCode(title), [title]);
    const isLabelCode = useMemo(() => TB.isTextCode(label), [label]);
    const isDescCode = useMemo(() => TB.isTextCode(description), [description]);
    const isConfirmTextCode = useMemo(() => TB.isTextCode(confirmText), [confirmText]);
    const isNoTextCode = useMemo(() => TB.isTextCode(noSelectionText), [noSelectionText]);

    const translatedTitle = useMemo(() => TB.validString(title) ? (isTitleCode ? getStaticText(title) : title) : null, [title, getStaticText, isTitleCode]);
    const translatedLabel = useMemo(() => TB.validString(label) ? (isLabelCode ? getStaticText(label) : label) : null, [label, getStaticText, isLabelCode]);
    const translatedDesc = useMemo(() => TB.validString(description) ? (isDescCode ? getStaticText(description) : description) : null, [description, getStaticText, isDescCode]);
    const translatedConfirm = useMemo(() => TB.validString(confirmText) ? (isConfirmTextCode ? getStaticText(confirmText) : confirmText) : null, [confirmText, getStaticText, isConfirmTextCode]);
    const translatedNoText = useMemo(() => TB.validString(noSelectionText) ? (isNoTextCode ? getStaticText(noSelectionText) : noSelectionText) : null, [noSelectionText, getStaticText, isNoTextCode]);

    const validDesc = useMemo(() => TB.validString(translatedDesc) ? translatedDesc : null, [translatedDesc]);
    const validLabel = useMemo(() => TB.validString(translatedLabel) ? translatedLabel : null, [translatedLabel]);
    const validTitle = useMemo(() => TB.validString(translatedTitle) ? translatedTitle : getStaticText(TC.GLOBAL_SELECT), [translatedTitle, getStaticText]);
    const validNoSelection = useMemo(() => TB.validString(translatedNoText) ? translatedNoText : getStaticText(TC.GLOBAL_NO_SELECTION), [translatedNoText, getStaticText]);
    const validConfirmText = useMemo(() => TB.validString(translatedConfirm) ? translatedConfirm : getStaticText(TC.GLOBAL_CONFIRM), [translatedConfirm, getStaticText]);

    useEffect(() => {
        let textCodes = [title, noSelectionText, confirmText, label, description].filter(TB.isTextCode);
        if (textCodes.length > 0) fetchStaticTranslations(textCodes);
    }, [title, noSelectionText, description, confirmText, label, fetchStaticTranslations]);
    //#endregion

    //#region Options
    const validOptions = useMemo(() => {
        if (!Array.isArray(options)) return [];
        return options.filter(({ label, value }) => [label, value].every(TB.validString))
            .concat(isRequired ? [] : [{ label: "", value: "" }]);
    }, [options, isRequired]);
    //#endregion

    //#region Default Value
    const values = useMemo(() => validOptions.map(({ value }) => value), [validOptions]);

    useEffect(() => {
        if (TB.validString(defaultVal) && values.includes(defaultVal)) setSelection(defaultVal);
        else if (isRequired && validOptions.length > 0) setSelection(validOptions[0].value);
    }, [values, validOptions, isRequired, defaultVal]);
    //#endregion

    //#region Select
    const hasValues = useMemo(() => validOptions.length > 0, [validOptions]);

    const select = useMemo(() => hasValues && <>
        {validLabel && <Form.Label>{validLabel}</Form.Label>}
        <TypeAhead
            {...selectProps}
            options={validOptions}
            selectedItems={selection}
            onChange={opt => setSelection(opt?.[0]?.value)}
        />
    </>, [validLabel, hasValues, validOptions, selection, selectProps]);

    const noValues = useMemo(() => hasValues ? undefined : <div className="alert alert-danger alert-imaged">
        {getStaticText(TC.GLOBAL_NO_OPTIONS_SELECT)}
        <i className="fa fa-exclamation-triangle"></i>
    </div>, [hasValues, getStaticText]);

    const selectNoValueButton = useMemo(() => isRequired ? undefined : <button className="btn btn-secondary" onClick={() => onValidate?.()}>
        {validNoSelection}
    </button>, [validNoSelection, isRequired, onValidate])
    //#endregion

    //#region Modal Parts
    const modalHeader = useMemo(() => <div className="modal-header">
        <h5 className="modal-title">{validTitle}</h5>
        <button className="btn-close" onClick={onQuit}></button>
    </div>, [validTitle, onQuit]);

    const modalBody = useMemo(() => <div className="modal-body">
        {select}
        {validDesc && <div className="text-muted mt-2">{validDesc}</div>}
        {noValues}
    </div>, [select, noValues, validDesc]);

    const modalFooter = useMemo(() => <div className="modal-footer d-md-flex justify-content-md-end">
        {isValidElement(extraButton) && extraButton}
        {selectNoValueButton}
        <button disabled={!hasValues} className="btn btn-primary ms-2" onClick={() => onValidate?.(selection)}>{validConfirmText}</button>
    </div>, [selection, validConfirmText, selectNoValueButton, hasValues, onValidate, extraButton]);
    //#endregion

    return <Dialog className={props.modalClass} open={true} maxWidth={size || "sm"} fullWidth={true} onClose={onQuit} PaperProps={{ className: "animated_scale_in" }}>
        <div className="modal-content">
            {modalHeader}
            {modalBody}
            {modalFooter}
        </div>
    </Dialog>
}

const WrappedModalSelect: FC<ModalSelectProps> = ({ ...props }) => <ReduxWrapper><ModalSelect {...props} /></ReduxWrapper>;
export default WrappedModalSelect;

export const askSelect = (params: RenderModalSelectProps) => new Promise<null | undefined | string>(resolve => {
    let [render, dismount] = renderInContainer();
    if (!TB.validObject(params)) params = {};
    if (render && dismount) {
        let selectProps = params?.selectProps || {};
        let onQuit = () => dismount(() => resolve(null));
        let onValidate = (confirmed?: string) => dismount(() => resolve(confirmed));

        if (params?.create_option) {
            selectProps.allowNewOption = true;
            selectProps.onAddOption = text => dismount(() => params.create_option(text, resolve));
        };
        render(<WrappedModalSelect {...params} selectProps={selectProps} onQuit={onQuit} onValidate={onValidate} />);
    }
    else resolve(null);
});