import React from "react";
import * as ID from "uuid";
import moment from "moment";
import * as M from "../../Modal";
import * as DnD from "react-dnd";
import DataSelect from "./DataDnD";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import * as S from "../../../services";
import { TC, T, TB, DS } from "../../../Constants";
import { HTML5Backend } from "react-dnd-html5-backend";
import _ from "lodash";

//#region Types
export type DataProps = {
    /** The current mission's structure */
    structure?: T.Mission["structure"];
    /** Callback when the structure changes */
    onChange?: (structure: T.Mission["structure"]) => void;
    /** Force a reload of the data */
    reload_data?: boolean;
    /** Callback to notify that the data was reloaded */
    on_data_reloaded: () => void;
    /** Do not allow any edits */
    read_only: boolean;
};

type ColumnRowProps = {
    /** Data from the current column */
    column: ColumnEditProps["columns"][number];
    /** The current row's position */
    index: number;
    /** Wether to show or not the "new_row" button */
    last: boolean;
    /** The errors to display */
    errors: T.Errors<Record<"from" | "to" | "name", string>>;
    /** Callback to edit a value */
    edit: (col: Partial<ColumnEditProps["columns"][number]>) => void;
    /** Callback to remove the row */
    remove: () => void;
    /** Callback to add a new row */
    new_row: () => void;
    /** Callback to move the column to a new position */
    moveCard: (dragIndex: number, hoverIndex: number) => void;
}

type ColumnEditProps = {
    /** The pre-existing columns */
    columns?: T.Mission["structure"]["columns"];
    /** Callback to handle the changes in the columns */
    onChange: (columns: T.Mission["structure"]["columns"]) => void;
};

type DatasetPickProps = {
    /** A list of datasets found in the mission's context */
    datasets?: ReturnType<T.API.Utils.Missions.GetMissionDatasets>;
    /** The pre-existing categories */
    categories?: T.Mission["structure"]["categories"];
    /** Callback to handle the changes in the categories */
    onChange: (categories: T.Mission["structure"]["categories"]) => void;
};

type CategoryEditProps = {
    /** The pre-existing categories */
    categories?: T.Mission["structure"]["categories"];
    /** Callback to handle the changes in the categories */
    onChange: (categories: T.Mission["structure"]["categories"]) => void;
};
//#endregion

//#region Constants
const to_date = (date: string) => moment(date).format("DD-MM-YY");
//#endregion

const Data: React.FC<DataProps> = ({ on_data_reloaded, ...props }) => {
    const lg = H.useLanguage();
    const [loading_dataset, set_loading_dataset] = React.useState("");
    const [elements, set_elements, elem_status] = H.useAsyncState<ReturnType<T.API.Utils.Missions.GetMissionDatasets>>([]);
    const [structures, set_structures, struct_status] = H.useAsyncState<ReturnType<T.API.Utils.Missions.GetPastStructures>>([]);
    const [data, set_data, data_status] = H.useAsyncState<ReturnType<T.API.Utils.Missions.GetValueForMissionStructure>>({ index: {}, delta: {} });

    //#region Load data
    React.useEffect(() => {
        let isSubscribed = true;
        S.getPastStructures()
            .then(({ data }) => isSubscribed && set_structures(data, "done"))
            .catch(() => isSubscribed && set_structures([], "error"));
        return () => { isSubscribed = false };
    }, [set_structures]);

    React.useEffect(() => {
        let isSubscribed = true;
        if (props.reload_data) S.getMissionDatasets()
            .then(({ data }) => {
                if (isSubscribed) {
                    on_data_reloaded();
                    set_elements(data, "done");
                }
            })
            .catch(() => isSubscribed && set_elements([], "error"));
        return () => {
            isSubscribed = false;
            // set_elements([], "load")
        };
    }, [set_elements, props.reload_data, on_data_reloaded]);

    React.useEffect(() => {
        let isSubscribed = true;
        S.getValueForMissionStructure(props.structure)
            .then(({ data }) => isSubscribed && set_data(data, "done"))
            .catch(() => isSubscribed && set_data({ index: {}, delta: {} }, "error"));
        return () => set_data({ index: {}, delta: {} }, "load");
    }, [set_data, props.structure]);
    //#endregion

    //#region Value Formatter
    const get_element = React.useCallback((elem_id: string) => elements.filter(e => e.id === elem_id)[0]?.name || "N/A", [elements]);

    const get_value = React.useCallback((col_id: string, dataset_id: string, type: "index" | "delta") => {
        if (data_status === "load" || loading_dataset === dataset_id) return <i className="fa fa-spin fa-spinner"></i>;
        if (data_status === "error") return <i className="fa fa-exclamation-triangle text-danger"></i>;
        let num_value = data[type]?.[col_id]?.[dataset_id];
        if (typeof num_value !== "number" || isNaN(num_value)) return "N/A";
        else return num_value;
    }, [data_status, data, loading_dataset]);

    const get_datasets = React.useCallback((params: typeof structures[number]["structure"]["categories"][number]["elements"][number]) => {
        let params_datasets_ids = params.datasets.map(d => d._id);
        let elements_datasets = elements.filter(e => e.id === params.id)[0]?.datasets || [];
        let active_datasets = elements_datasets.filter(d => params_datasets_ids.includes(d.id));
        let show_datasets = [] as (typeof active_datasets[number] & Record<"type_label", string> & Record<"type", "index" | "delta">)[];

        for (let active of active_datasets) {
            let value_type = params.datasets.filter(d => d._id === active.id)[0]?.value_type;
            if (value_type === "delta") show_datasets.push({ ...active, type: "delta", type_label: lg.getStaticText(TC.CDM_DATA_DATASET_DELTA) || "" });
            else if (value_type === "index") show_datasets.push({ ...active, type: "index", type_label: "index" });
            else if (value_type === "index_delta") show_datasets.push(
                { ...active, type: "index", type_label: "index" },
                { ...active, type: "delta", type_label: lg.getStaticText(TC.CDM_DATA_DATASET_DELTA) || "" },
            );
        }

        return show_datasets;
    }, [elements, lg]);
    //#endregion

    //#region Events
    const events = React.useMemo(() => ({
        edit_columns: () => {
            render_edit<ColumnEditProps>({ type: "column", columns: props.structure?.columns })
                .then(columns => columns && props.onChange?.({ columns, categories: props.structure?.categories || [] }));
        },
        edit_categories: () => {
            render_edit<CategoryEditProps>({ type: "category", categories: props.structure?.categories })
                .then(categories => categories && props.onChange?.({ columns: props.structure?.columns || [], categories }));
        },
        edit_selection: () => {
            render_edit<DatasetPickProps>({ type: "dataset", datasets: elements, categories: props.structure?.categories }).then(categories => {
                if (categories) props.onChange?.({ columns: props.structure?.columns || [], categories });
            });
        },
        set_preset_struct: (id: string) => {
            let structure = structures.filter(s => s.mission_id === id)[0];
            if (structure) props.onChange?.(structure.structure);
        },
        edit_dataset: (dataset: ReturnType<typeof get_datasets>[number], origin: string) => {
            M.renderDataFormModal({ dataset_id: dataset.id, origin }).then(dataset => {
                // Update local dataset
                if (dataset) set_elements(p => p.map(e => {
                    if (e.id !== origin) return e;
                    else return {
                        ...e,
                        datasets: e.datasets.map(d => {
                            if (d.id !== dataset._id) return d;
                            return { ...d, name: dataset.name };
                        })
                    };
                }));
            });
        },
        add_data: (dataset: ReturnType<typeof get_datasets>[number]) => {
            M.renderEntryFormModal({ dataset: dataset.id, askDate: true, value: { time: new Date().getTime() } }).then(entry => {
                if (entry) S.addManualEntry(entry as any)
                    .then(({ data }) => {
                        if (data === "duplicate") M.renderAlert({ type: "warning", message: TC.DATASET_WARNING_DUPLICATE });
                        else {
                            // Notify the user of the success
                            M.Alerts.success_alert();
                            // Check the dataset as loading
                            set_loading_dataset(dataset.id);
                            // Fetch the updated data
                            S.getValueForMissionStructurePartial({ structure: props.structure, datasets: dataset.id }).then(({ data }) => {
                                set_data(p => {
                                    let new_data = _.cloneDeep(p);
                                    for (let [data_type, cols] of Object.entries(data)) {
                                        for (let [col, values] of Object.entries(cols)) {
                                            for (let [dataset_id, value] of Object.entries(values)) {
                                                new_data[data_type][col] = { ...new_data[data_type][col], [dataset_id]: value }
                                            }
                                        }
                                    }
                                    return new_data;
                                });
                            })
                                .catch(e => set_data({ index: {}, delta: {} }, "error"))
                                .finally(() => set_loading_dataset(""));
                        }
                    }).catch(M.Alerts.updateError);
            });
        },
        open_charts: (dataset: ReturnType<typeof get_datasets>[number]) => {
            S.getDataset(dataset.id)
                .then(({ data }) => M.renderDataChartModal({ dataset: data, modalProps: { isFullScreen: true, title: dataset.name } }))
                .catch(M.Alerts.loadError);
        },
        edit_element: (elem_id: string) => {
            let elem = elements.filter(e => e.id === elem_id)[0];
            if (elem) M.renderFormModal({ submissionId: elem.id, path: elem.path })
                .then(console.log);
        },
    }), [elements, structures, set_elements, set_data, props]);
    //#endregion

    //#region Pre-Defined Structures
    const structure_select = React.useMemo(() => {
        if (structures.length === 0 || props.read_only) return null;
        let struct_options = structures.map(s => ({
            variant: "primary",
            value: s.mission_id,
            label: s.mission_name,
        })) as C.ButtonSelectProps["options"];

        return <C.Spinner status={struct_status}>
            <div className="mb-3">
                <C.ButtonSelect
                    value=""
                    default_value=""
                    options={struct_options}
                    onChange={events.set_preset_struct}
                    title={TC.CDM_DATA_STRUCTURE_EXISTING}
                />
            </div>
        </C.Spinner>
    }, [structures, struct_status, events.set_preset_struct, props.read_only]);
    //#endregion

    //#region Structure
    const table_format = React.useMemo(() => props.structure || { categories: [{ name: "", value: "none", elements: [] }], columns: [], as_index: [] }, [props.structure]);
    const empty_struct = React.useMemo(() => table_format.columns.length === 0 && table_format.categories.length <= 1, [table_format]);
    //#endregion

    return <C.Spinner status={elem_status}>

        {structure_select}

        <div className="mb-3">
            {empty_struct
                ? <C.CenterMessage style={{ height: "200px !important" }} children={TC.CDM_DATA_NO_CONFIG} />
                : <table className="fs-85 table table-sm text-center align-center border">
                    <thead>
                        <tr>
                            <th className="border-0 border-end" style={table_format.columns.length > 6 ? { width: "25%" } : undefined}></th>
                            {table_format.columns.map(f => <th key={f.id} className="border-end" colSpan={2}>{f.name}</th>)}
                        </tr>
                        <tr>
                            <th className="border-0 border-end"></th>
                            {table_format.columns.map(f => <React.Fragment key={f.id}>
                                <td className="border-end" children={to_date(f.from)} />
                                <td className="border-end" children={to_date(f.to)} />
                            </React.Fragment>)}
                        </tr>
                    </thead>
                    <tbody>
                        {table_format.categories.map(cat => (cat.value !== "none" || cat.elements.length > 0) && <React.Fragment key={cat.value}>

                            <tr>
                                {/* Name of the category */}
                                <th className="text-start border-end">
                                    <span>{cat.value === "none" ? lg.getStaticText(TC.CDM_DATA_DEFAULT_CAT) : cat.name}</span>
                                </th>
                                {/* Empty Columns */}
                                {table_format.columns.map(c => <td key={c.id} className="border-end" colSpan={2}></td>)}
                            </tr>

                            {cat.elements.map(e => <React.Fragment key={e.id}>
                                <tr>
                                    {/* Name of the elements */}
                                    <td className="text-start border-end">
                                        <C.Flex justifyContent="between">
                                            <span className="ms-1">
                                                <i className="fa fa-cog me-2"></i>
                                                {get_element(e.id)}
                                            </span>
                                            <div>
                                                {!props.read_only && <C.Button onClick={() => events.edit_element(e.id)} size="sm" icon="pencil-alt" />}
                                            </div>
                                        </C.Flex>
                                    </td>
                                    {/* Empty Columns */}
                                    {table_format.columns.map(c => <td key={c.id} className="border-end" colSpan={2}></td>)}
                                </tr>

                                {get_datasets(e).map(d => <tr key={d.id + d.type}>
                                    {/* Name of the dataset */}
                                    <td className="text-start border-end">
                                        <C.Flex justifyContent="between">
                                            <span className="ms-3">
                                                <i className="fa fa-chart-line me-2"></i>
                                                <i className={`fa fa-${DS.ICONS[d.src]} me-2`}></i>
                                                [{d.type_label.toUpperCase()}] {d.name} {d.unit && `(${d.unit})`}
                                            </span>
                                            <div>
                                                <C.Button onClick={() => events.open_charts(d)} size="sm" icon="chart-line" variant="secondary" className="ms-1" />
                                                {!props.read_only && d.src !== "calculated" && <C.Button onClick={() => events.add_data(d)} size="sm" icon="plus" className="ms-1" />}
                                                {!props.read_only && <C.Button onClick={() => events.edit_dataset(d, e.id)} size="sm" icon="pencil-alt" className="ms-1" />}
                                            </div>
                                        </C.Flex>
                                    </td>
                                    {/* Value columns */}
                                    {table_format.columns.map(c => <td className="border-end" colSpan={2} key={c.id}>
                                        {get_value(c.id, d.id, d.type)}
                                    </td>)}
                                </tr>)}
                            </React.Fragment>)}
                        </React.Fragment>)}
                    </tbody>
                </table>}
        </div>

        {!props.read_only && <C.Flex className="w-100" justifyContent="around" alignItems="center">
            <C.Button icon="pencil-alt" onClick={events.edit_columns} text={TC.MISSION_CDM_DATA_COLUMNS} />
            <C.Button icon="pencil-alt" onClick={events.edit_categories} text={TC.MISSION_CDM_DATA_CATEGORIES} />
            <C.Button icon="pencil-alt" onClick={events.edit_selection} text={TC.MISSION_CDM_DATA_DATASETS} />
        </C.Flex>}
    </C.Spinner>;
}

/** Shortcut to render the edit popups */
const render_edit = <P extends Record<"onChange", (...args: any) => any>>(params: { type: "column" | "dataset" | "category" } & Omit<P, "onChange">) => new Promise<Parameters<P["onChange"]>[0]>(resolve => {
    const [render, dismount] = M.renderInContainer();
    if (render && dismount) {
        let content = null, size = "sm";
        const onQuit = () => dismount(() => resolve(null));
        const onChange: P["onChange"] = data => dismount(() => resolve(data));

        if (params.type === "column") {
            size = "md";
            content = <ColumnEdit {...params} onChange={onChange} />;
        }
        else if (params.type === "dataset") content = <DatasetEdit {...params} onChange={onChange} />;
        else if (params.type === "category") content = <CategoryEdit {...params} onChange={onChange} />;

        if (content === null) resolve(null);
        else render(<M.BlankModal title=" " onQuit={onQuit} size={size as any}>
            {content}
        </M.BlankModal>);
    }
    else resolve(null);
});

/** Row entry for a Column */
const ColumnRow: React.FC<ColumnRowProps> = ({ moveCard, new_row, remove, edit, ...props }) => {
    const ref = React.useRef<HTMLDivElement>(null);

    const [{ handlerId }, drop] = DnD.useDrop<Record<"index", number>, any, Record<"handlerId", string | symbol>>({
        accept: "column",
        collect: monitor => ({ handlerId: monitor.getHandlerId() }),
        hover: (item, monitor) => {
            if (!ref.current) return;
            const dragIndex = item.index;
            const hoverIndex = props.index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) return;
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        }
    });

    const [{ isDragging }, drag] = DnD.useDrag({
        type: "column",
        item: () => ({ id: props.column.id, index: props.index }),
        collect: monitor => ({ isDragging: monitor.isDragging() }),
    });

    const style = React.useMemo(() => {
        if (isDragging) return { opacity: 0.25, cursor: "grabbing" };
        return { opacity: 1, cursor: "grab" };
    }, [isDragging]);

    drag(drop(ref));

    return <div style={style}>
        <C.Flex key={props.column.id} className="mb-2" alignItems={props.index === 0 ? "end" : "center"}>

            <C.Flex alignItems="center" justifyContent="center" ref={ref} data-handler-id={handlerId} className="text-muted me-2 p-2">
                <i className="fa fa-arrows-alt"></i>
            </C.Flex>

            <C.Form.TextField
                value={props.column.name}
                noBottomMargin
                customClass="flex-grow-1 me-2"
                error={{ code: props.errors?.name }}
                label={props.index === 0 ? TC.GLOBAL_NAME : undefined}
                onChange={name => edit({ name })}
            />
            <C.Form.DateTime
                value={props.column.from}
                noBottomMargin
                customClass="flex-grow-1 me-2"
                error={{ code: props.errors?.from }}
                onChange={from => edit({ from })}
                label={props.index === 0 ? TC.CDM_MISSION_DATA_COL_TIME_START : undefined}
            />
            <C.Form.DateTime
                value={props.column.to}
                noBottomMargin
                customClass="flex-grow-1 me-2"
                error={{ code: props.errors?.to }}
                onChange={to => edit({ to })}
                label={props.index === 0 ? TC.CDM_MISSION_DATA_COL_TIME_END : undefined}
            />
            <div className={props.index === 0 ? "mb-1" : ""}>
                <C.Button size="sm" icon="times" variant="danger" onClick={() => remove()} />
                {props.last && <C.Button className="ms-2" size="sm" icon="plus" onClick={() => new_row()} />}
            </div>
        </C.Flex>
    </div>;
}

/** Column Edit Form Component */
const ColumnEdit: React.FC<ColumnEditProps> = props => {
    const lg = H.useLanguage();
    const [columns, set_columns] = React.useState(props.columns || []);
    const [errors, set_errors] = React.useState<ColumnRowProps["errors"][]>([]);

    const current_cols = React.useMemo(() => {
        if (columns.length === 0) return [{ from: "", to: "", name: "", id: "empty" }];
        else return columns;
    }, [columns]);

    const events = React.useMemo(() => ({
        remove: (id: string) => set_columns(p => p.filter(c => c.id !== id)),
        new: (col?: Partial<typeof columns[number]>) => set_columns(p => p.concat({ from: col?.from || "", to: col?.to || "", name: col?.name || "", id: ID.v4() })),
        edit: (id: string, col: Partial<typeof columns[number]>) => {
            if (id === "empty") events.new(col);
            else {

                if (col.to) {
                    let temp_date = new Date(col.to);
                    temp_date.setHours(23, 59, 59);
                    col.to = temp_date.toISOString();
                }


                set_columns(p => p.map(c => {
                    if (c.id !== id) return c;
                    return { from: col.from || c.from, to: col.to || c.to, name: col.name || c.name, id: c.id };
                }))
            };
        },
        move: (dragIndex: number, hoverIndex: number) => set_columns(p => {
            let dragItem = p[dragIndex];
            let copyArray = [...p];
            copyArray.splice(dragIndex, 1);
            copyArray.splice(hoverIndex, 0, dragItem);
            return copyArray;
        }),
    }), []);

    const validate = React.useCallback(() => {
        let new_errors: typeof errors = [];

        for (let i = 0; i < columns.length; i++) {
            let col = columns[i];
            let to = TB.getDate(col.to),
                from = TB.getDate(col.from),
                err = {} as typeof new_errors[number];

            if (!TB.validString(col.name)) err.name = TC.GLOBAL_REQUIRED_FIELD;
            if (!from) err.from = TC.GLOBAL_REQUIRED_FIELD;
            if (!to) err.to = TC.GLOBAL_REQUIRED_FIELD;
            if (from && to && from.getTime() >= to.getTime()) {
                err.to = TC.ERR_DATE_TO_LOWER_DATE_FROM;
                err.from = TC.ERR_DATE_FROM_HIGHER_DATE_TO;
            }

            if (Object.keys(err).length > 0) new_errors[i] = err;
        }

        if (new_errors.length > 0) set_errors(new_errors);
        else props.onChange(columns);
    }, [columns, props]);

    return <>
        <C.Flex alignItems="center" justifyContent="between">
            <h4>{lg.getStaticText(TC.CDM_MISSION_COLUMN_LABEL)}</h4>
            <C.Button size="sm" icon="save" text={TC.GLOBAL_SAVE} onClick={validate} />
        </C.Flex>

        <div className="mt-3">
            {/* @ts-ignore */}
            <DnD.DndProvider backend={HTML5Backend}>
                {current_cols.map((c, i) => <ColumnRow
                    index={i}
                    key={c.id}
                    column={c}
                    new_row={events.new}
                    moveCard={events.move}
                    errors={errors[i] || {}}
                    remove={() => events.remove(c.id)}
                    last={i === current_cols.length - 1}
                    edit={col => events.edit(c.id, col)}
                />)}
            </DnD.DndProvider>
        </div>
    </>;
}

/** Category Edit Form Component */
const CategoryEdit: React.FC<CategoryEditProps> = props => {
    const lg = H.useLanguage();
    const [cat, set_cat] = React.useState(props.categories || []);
    const [errors, set_errors] = React.useState<T.Errors<Record<number, string>>>({});

    const valid_cat = React.useMemo(() => cat.filter(c => c.value !== "none"), [cat]);

    const current_cat = React.useMemo(() => {
        if (valid_cat.length === 0) return [{ elements: [], name: "", value: "empty" }];
        else return valid_cat;
    }, [valid_cat]);

    const events = React.useMemo(() => ({
        remove: (id: string) => set_cat(p => p.filter(c => c.value !== id)),
        new: (name?: string) => set_cat(p => p.concat({ name: name || "", value: ID.v4(), elements: [] })),
        edit: (name: string, id: string) => {
            if (id === "empty") events.new(name);
            else set_cat(p => p.map(c => c.value === id ? { ...c, name } : c));
        },
    }), []);

    const validate = React.useCallback(() => {
        let new_errors: typeof errors = {};

        for (let i = 0; i < valid_cat.length; i++) {
            if (!TB.validString(valid_cat[i].name)) new_errors[i] = TC.GLOBAL_REQUIRED_FIELD;
        }

        if (Object.keys(new_errors).length > 0) set_errors(new_errors);
        else {
            // Reorder to put the "none" a the end
            let none_cat = cat.filter(c => c.value === "none")[0] || { value: "none", elements: [], name: "N/A" };
            let new_cat = cat.filter(c => c.value !== "none").concat(none_cat);
            props.onChange(new_cat);
        }
    }, [valid_cat, cat, props]);

    return <div>
        <C.Flex alignItems="center" justifyContent="between">
            <h4>{lg.getStaticText(TC.CDM_MISSION_CATEGORY_LABEL)}</h4>
            <C.Button size="sm" icon="save" text={TC.GLOBAL_SAVE} onClick={validate} />
        </C.Flex>

        <div className="mt-3">
            {current_cat.map((c, i) => <C.Flex key={c.value} className="mb-2" alignItems="center" justifyContent="between">
                <C.Form.TextField
                    value={c.name}
                    noBottomMargin
                    error={{ code: errors[i] }}
                    customClass="flex-grow-1 me-2"
                    onChange={name => events.edit(name, c.value)}
                />
                <div>
                    <C.Button size="sm" icon="times" variant="danger" onClick={() => events.remove(c.value)} />
                    {i === current_cat.length - 1 && <C.Button className="ms-2" size="sm" icon="plus" onClick={() => events.new()} />}
                </div>
            </C.Flex>)}
        </div>
    </div >;
}

/** Dataset Pick Form Component */
const DatasetEdit: React.FC<DatasetPickProps> = props => {
    const lg = H.useLanguage();
    const [categories, set_categories] = React.useState(props.categories || []);

    const validate = React.useCallback(() => props.onChange?.(categories), [props, categories]);

    return <>
        <C.Flex alignItems="center" justifyContent="between">
            <h4>{lg.getStaticText(TC.DATASET_PANEL_TAB_DATA)}</h4>
            <C.Button size="sm" icon="save" text={TC.GLOBAL_SAVE} onClick={validate} />
        </C.Flex>

        <div>
            {/* @ts-ignore */}
            <DnD.DndProvider backend={HTML5Backend}>
                <DataSelect categories={categories} elements={props.datasets || []} onChange={set_categories} />
            </DnD.DndProvider>
        </div>
    </>;
}

export default Data;