import * as M from "../Modal";
import * as C from "../../Common";
import * as S from "../../services";
import * as PM from "../../PurposeModal";
import { FP, T, TB, TC, REGIONS, LT } from "../../Constants";
import { Row, Col, Form, InputGroup, Button } from "react-bootstrap";
import { ErrorBanner, Falcon, Flex, Form as Components, TypeAhead } from "../../Common";
import { useBoolean, useLanguage, useIsMounted, useAsyncState, useFormIds } from "../../hooks";
import React, { FC, useCallback, useEffect, useMemo, useState, MutableRefObject } from "react";

//#region Types
type FieldQuestion = { type: "text" };
type RadioBoolQuestion = { type: "radioBool" };
export type UnMountRef = { save?: () => Promise<any>, askSave: boolean };
type NumQuestion = { type: "num", unit?: string, min?: number, max?: number };
type ArrayQuestion = Question<NumQuestion | RadioBoolQuestion | SelectQuestion | FieldQuestion>;
type SelectQuestion = { type: "select", options: { label: string, value: string }[], status: T.AsyncStates };
type Question<A extends {} = {}> = { prop: string, regions?: string[], onChange?: (_id: string) => void } & A;

type RegDataFillerProps = {
    resources: T.API.Reg.GetRegTabResults;
    api: MutableRefObject<UnMountRef | null>;
    save: (changes: T.DataChange[]) => Promise<any>;
    /** Do not allow any edits */
    read_only?: boolean;
    /** Callback to ask for a reload of the resources */
    reload: () => Promise<void>;
    /** THe current context */
    context?: T.ContextParams;
}
//#endregion

//#region Constants
const TEXT_CODES = [
    FP.BUILDING_FORM, TC.REG_SHOW_ONLY_OCCUPATION, FP.SITE_FORM, FP.EMPLACEMENT_FORM, TC.GLOBAL_FLOOR, TC.GLOBAL_LOCAL, TC.GLOBAL_ZONE, TC.GLOBAL_SAVE,
    TC.GLOBAL_NO_X_CONTEXT,
];
//#endregion

const RegDataFiller: FC<RegDataFillerProps> = ({ resources, save, reload, api, ...props }) => {
    const [formIds] = useFormIds();
    const banner = useBoolean(true);
    const saving = useBoolean(false);
    const isMounted = useIsMounted();
    const lg = useLanguage(TEXT_CODES);
    const showOnlyOccupation = useBoolean(true);
    const [changes, setChanges] = useState<T.DataChange[]>([]);
    const [countries, setCountries, countryStatus, setCountryStatus] = useAsyncState<T.Option[]>([], "load");
    const [regions, setRegions, regionStatus, setRegionStatus] = useAsyncState<T.API.Geo.RegionOptions[]>([], "done");
    const [affectations, setAffectations, affectationStatus] = useAsyncState<T.Option<{}, T.EmplacementData["actifType"]>[]>([]);

    //#region Language
    const relevantForms = useMemo(() => [FP.SITE_FORM, FP.BUILDING_FORM, FP.EMPLACEMENT_FORM].map(p => formIds[p]), [formIds]);

    useEffect(() => lg.fetchObjectTranslations(relevantForms), [lg, relevantForms]);
    //#endregion

    //#region Elements
    const elements = useMemo(() => TB.getArray(resources?.elements), [resources?.elements]);
    /* @ts-ignore */
    const sites = useMemo<T.SiteType[]>(() => elements.filter(e => e.path === FP.SITE_FORM).map(e => e.submission), [elements]);
    /* @ts-ignore */
    const buildings = useMemo<T.BuildingType[]>(() => elements.filter(e => e.path === FP.BUILDING_FORM).map(e => e.submission), [elements]);
    /* @ts-ignore */
    const emplacementsList = useMemo<T.EmplacementType[]>(() => elements.filter(e => e.path === FP.EMPLACEMENT_FORM).map(e => e.submission), [elements]);
    const emplacements = useMemo<T.SiteType[]>(() => showOnlyOccupation.value ? emplacementsList.filter(e => e.data.isOccupation) : emplacementsList, [emplacementsList, showOnlyOccupation]);
    //#endregion

    //#region Load Countries, regions & affects
    const currentCountry = useMemo(() => {
        let countries = sites.map(s => {
            let changedCountry = changes.filter(c => c._id === s._id && c.prop === "country")[0]?.value;
            return TB.getString(changedCountry, s.data.country);
        }).filter(TB.validString);

        return TB.mostFrequent(countries);
    }, [sites, changes]);

    const currentRegion = useMemo(() => {
        let regions = sites.map(s => {
            let changedRegion = changes.filter(c => c._id === s._id && c.prop === "region")[0]?.value;
            return TB.getString(changedRegion, s.data.region);
        }).filter(TB.mongoIdValidator);

        return TB.mostFrequent(regions);
    }, [sites, changes]);

    const activeRegionsCodes = useMemo(() => {
        let descendantsRegionsIds = regions.filter(r => r.value === currentRegion)?.[0]?.parents;
        if (!descendantsRegionsIds) return [];
        return regions.filter(r => descendantsRegionsIds.includes(r.value) && TB.validString(r.code)).map(r => r.code);
    }, [regions, currentRegion]);

    useEffect(() => {
        let isSubscribed = true;

        S.getCountries()
            .then(({ data }) => isSubscribed && setCountries(data, "done"))
            .catch(() => isSubscribed && setCountryStatus("error"));

        return () => { isSubscribed = false };
    }, [setCountries, setCountryStatus]);

    useEffect(() => {
        let isSubscribed = true;
        if (TB.validString(currentCountry)) S.getRegions(currentCountry)
            .then(({ data }) => isSubscribed && setRegions(data, "done"))
            .catch(() => isSubscribed && setRegions([], "error"));
        else setRegions([], "done");

        return () => { isSubscribed = false };
    }, [setRegions, currentCountry]);

    useEffect(() => {
        let isSubscribed = true;
        S.getEmplacementResource({ type: "emplacement_affectation" })
            .then(({ data }) => isSubscribed && setAffectations(data as typeof affectations, 'done'))
            .catch(() => isSubscribed && setAffectations([], "error"));
        return () => { isSubscribed = false };
    }, [setAffectations]);
    //#endregion

    //#region DataChange
    const onChangeData = useCallback((id: string, prop: string, value?: any) => {
        setChanges(p => {
            let exists = p.filter(c => c.prop === prop && c._id === id).length > 0

            // Update Existing entry
            if (exists) return p.map(c => c.prop === prop && c._id === id ? { ...c, value } : c);
            // Add new Entry
            else return p.concat({ _id: id, prop, value });
        })
    }, []);

    const getValue = useCallback((id: string, prop: string) => {
        let dataChange = changes.filter(c => c.prop === prop && c._id === id);

        if (dataChange.length > 0) return dataChange[0].value;

        let elem = elements.filter(e => e.submission._id === id);
        if (elem.length > 0) return elem[0].submission.data[prop];
        return;
    }, [elements, changes]);

    const onSave = useCallback(() => {
        let promise = save?.(changes);
        saving.setTrue();

        if (promise) promise
            .then(() => isMounted() && setChanges([]))
            .finally(() => isMounted() && saving.setFalse());
        return promise;
    }, [changes, saving, save, isMounted]);

    useEffect(() => {
        if (api) {
            if (api.current === null) api.current = { save: onSave, askSave: changes.length > 0 };
            else {
                api.current.save = onSave;
                api.current.askSave = changes.length > 0;
            }
        }
    }, [onSave, changes.length, api]);

    const saveIcon = useMemo(() => {
        if (saving.value) return "fa fa-circle-notch fa-spin me-2";
        return "fa fa-save me-2";
    }, [saving.value]);
    //#endregion

    //#region Question
    const renderQuestion = useCallback((q: ArrayQuestion, id: string, path: string) => {
        let htmlQuestion = null;
        if (Array.isArray(q.regions) && !q.regions.some(r => activeRegionsCodes.includes(r))) return null;

        let value = getValue(id, q.prop);

        const onChange = (value?: any) => {
            q.onChange?.(id);
            onChangeData(id, q.prop, value);
        }

        if (q.type === "radioBool") htmlQuestion = <Components.RadioBool
            value={value}
            name={id + q.prop}
            onChange={onChange}
            disabled={props.read_only}
        />;
        else if (q.type === "select") htmlQuestion = <Components.ComponentWrapper disabled={props.read_only} customClass="flex-grow-1 ml-3">
            <TypeAhead
                options={q.options}
                selectedItems={value}
                disabled={props.read_only}
                onChange={o => onChange(o?.[0]?.value)}
            />
        </Components.ComponentWrapper>
        else if (q.type === "num") htmlQuestion = <Components.ComponentWrapper disabled={props.read_only} customClass="flex-grow-1 ml-3">
            <InputGroup>
                <Form.Control disabled={props.read_only} type="number" min={q.min} max={q.max} defaultValue={value || ""} onBlur={e => onChange(parseFloat(e.target.value) || "")} />
                {q.unit && <InputGroup.Text>{q.unit}</InputGroup.Text>}
            </InputGroup>
        </Components.ComponentWrapper>
        else htmlQuestion = <Components.ComponentWrapper disabled={props.read_only} customClass="flex-grow-1 ml-3">
            <InputGroup>
                <Form.Control
                    disabled={props.read_only}
                    defaultValue={value || ""}
                    onBlur={e => onChange(e.target.value || "")}
                />
            </InputGroup>
        </Components.ComponentWrapper>

        return <Col key={q.prop} md={6}>
            <Flex className="mb-2" alignItems="center" justifyContent="between">
                <Form.Label className="mr-2 mb-0">{lg.getElemObj(formIds[path], q.prop, q.prop)}</Form.Label>
                {htmlQuestion}
            </Flex>
        </Col>
    }, [onChangeData, getValue, lg, activeRegionsCodes, formIds, props.read_only]);
    //#endregion

    //#region Sites
    const onChangeCountry = useCallback((id: string) => {
        setRegionStatus("load");
        onChangeData(id, "region", undefined);
    }, [setRegionStatus, onChangeData]);

    const siteQuestions = useMemo<ArrayQuestion[]>(() => [
        { type: "select", prop: "country", options: countries, status: countryStatus, onChange: onChangeCountry },
        { type: "select", prop: "region", options: regions, status: regionStatus },
        { type: "radioBool", prop: "requiresEtudeSol" },
        { type: "radioBool", prop: "requiresPE" },
        { type: "radioBool", prop: "requiresAvisIncendie" },
        { type: "radioBool", prop: "requiresGrosConsommateurRBC", regions: [REGIONS.BE_RBC] },
    ], [countries, regions, regionStatus, countryStatus, onChangeCountry]);

    const renderSiteForm = useCallback((site: T.SiteType) => <Row key={site._id} className="mb-3">
        <Col md={12}>
            <Falcon.Title titleTag={site.data.name} />
        </Col>
        <Col md={12}>
            <Row className="mb-2">{siteQuestions.map(q => renderQuestion(q, site._id, FP.SITE_FORM))}</Row>
        </Col>
    </Row>, [siteQuestions, renderQuestion]);
    //#endregion

    //#region Builds
    const buildQuestions = useMemo<ArrayQuestion[]>(() => [
        { type: "num", prop: "ENT_TECH_BUILD_YEAR", min: 0, max: 9999 },
        { type: "num", prop: "surfaceNFA", unit: "m²", min: 0 },
        { type: "num", prop: "surfaceHEAT", unit: "m²", min: 0 },
        { type: "select", prop: "affectation", options: affectations, status: affectationStatus },
        { type: "radioBool", prop: "publicOccupiedWA", regions: [REGIONS.BE_WAL] },
        { type: "radioBool", prop: "publicOccupiedVLA", regions: [REGIONS.BE_VLA] },
        { type: "radioBool", prop: "publicOccupiedRBC", regions: [REGIONS.BE_RBC] },
        { type: "radioBool", prop: "selling" },
    ], [affectations, affectationStatus]);

    const renderBuildForm = useCallback((build: T.BuildingType) => <Row key={build._id} className="mb-3">
        <Col md={12}>
            <Falcon.Title titleTag={build.data.name} />
        </Col>
        <Col md={12}>
            <Row className="mb-1">{buildQuestions.map(q => renderQuestion(q, build._id, FP.BUILDING_FORM))}</Row>
        </Col>
    </Row>, [buildQuestions, renderQuestion]);
    //#endregion

    //#region Cells
    const cellQuestions = useMemo<ArrayQuestion[]>(() => [
        { type: "radioBool", prop: "isOccupation" },
        { type: "radioBool", prop: "selling" },
        { type: "radioBool", prop: "employees" },
        { type: "radioBool", prop: "requiresPDERBC" },
        { type: "radioBool", prop: "grandeEntrepriseVLA", regions: [REGIONS.BE_VLA] },
        { type: "radioBool", prop: "asbestosWorksRBC", regions: [REGIONS.BE_RBC] },
        { type: "radioBool", prop: "grandeEntrepriseRBCWA", regions: [REGIONS.BE_RBC, REGIONS.BE_WAL] },
        { type: "select", prop: "actifType", options: affectations, status: affectationStatus },
        { type: "num", prop: "surfaceNFA", unit: "m²", min: 0 },
    ], [affectations, affectationStatus]);

    const getCellType = useCallback((cell: T.EmplacementType) => {
        let content = null;
        if (cell.data.type === "floor") content = TC.GLOBAL_FLOOR;
        if (cell.data.type === "local") content = TC.GLOBAL_LOCAL;
        if (cell.data.type === "zone") content = TC.GLOBAL_ZONE;
        if (cell.data.type === "parking") content = TC.GLOBAL_LABEL_PARKING;
        if (content === null) return null;
        return <span className="text-muted ms-2">({lg.getStaticElem(content)})</span>
    }, [lg]);

    const renderCellForm = useCallback((cell: T.EmplacementType) => <Row key={cell._id} className="mb-3">
        <Col md={12}>
            <Flex>
                <Falcon.Title titleTag={cell.data.name} />
                {getCellType(cell)}
            </Flex>
        </Col>
        <Col md={12}>
            <Row className="mb-1">{cellQuestions.map(q => renderQuestion(q, cell._id, FP.EMPLACEMENT_FORM))}</Row>
        </Col>
    </Row>, [cellQuestions, renderQuestion, getCellType]);

    const add_emplacement = useCallback(() => {

        PM.renderBuildingSelect({ context: props.context }).then(building => {
            M.renderLightTree({ linkRestriction: { linkType: LT.LINK_TYPE_OWN, objForm: FP.EMPLACEMENT_FORM }, root: building, selection: building }).then(parent => {
                if (parent) M.renderFormModal<T.EmplacementData>({ path: FP.EMPLACEMENT_FORM, forcedSubmission: [{ prop: "isOccupation", value: true }] }).then(emp => {
                    if (emp) {
                        saving.setTrue();
                        S.attachNode({ parent, children: emp._id, type: LT.LINK_TYPE_OWN })
                            .then(() => reload().finally(saving.setFalse))
                            .catch(e => {
                                saving.setFalse();
                                M.Alerts.updateError(e);
                            });
                    }
                });
            });
        });
    }, [props.context, reload, saving]);
    //#endregion

    //#region No Data
    const emptyElements = useMemo(() => {
        if ([sites, buildings, emplacements].every(x => x.length === 0)) return <ErrorBanner type="info" message="todo no item" />;
        return null;
    }, [sites, buildings, emplacements]);

    const getEmptyCategory = useCallback((path: string) => <Flex alignItems="center" justifyContent="center" style={{ minHeight: "100px" }}>
        {lg.getStaticElem(TC.GLOBAL_NO_X_CONTEXT, path)}
    </Flex>, [lg]);
    //#endregion

    return <div className="px-3 position-relative">
        {!props.read_only && !emptyElements && <div className="position-sticky top-0 start-100 pt-3" style={{ width: "fit-content", zIndex: 50 }}>
            <Button onClick={onSave} disabled={changes.length === 0}>
                <i className={saveIcon}></i> {lg.getStaticElem(TC.GLOBAL_SAVE)}
            </Button>
        </div>}

        {banner.value && <ErrorBanner close={banner.setFalse} type="warning" textCode={TC.REG_BANNER_WARNING} />}

        {emptyElements}

        {!emptyElements && <>
            <Row>
                <Col md={12}>
                    <h2>{lg.getStaticElem(FP.SITE_FORM)}</h2>
                </Col>
                <Col md={12}>
                    {sites.length === 0 ? getEmptyCategory(FP.SITE_FORM) : sites.map(renderSiteForm)}
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    <h2>{lg.getStaticElem(FP.BUILDING_FORM)}</h2>
                </Col>
                <Col md={12}>
                    {buildings.length === 0 ? getEmptyCategory(FP.BUILDING_FORM) : buildings.map(renderBuildForm)}
                </Col>
            </Row>

            <Row>
                <Col md={12}>
                    <Flex alignItems="center">
                        <h2>{lg.getStaticElem(FP.EMPLACEMENT_FORM)}</h2>
                        <C.Button onClick={add_emplacement} disabled={props.read_only} className="mx-2" size="sm" icon="plus" />
                        <InputGroup>
                            <Form.Check type="switch" checked={showOnlyOccupation.value} onChange={showOnlyOccupation.toggle} />
                            <Form.Label className="mb-0 ms-2">{lg.getStaticElem(TC.REG_SHOW_ONLY_OCCUPATION)}</Form.Label>
                        </InputGroup>
                    </Flex>
                </Col>
                <Col md={12}>
                    {emplacements.length === 0 ? getEmptyCategory(FP.EMPLACEMENT_FORM) : emplacements.map(renderCellForm)}
                </Col>
            </Row>
        </>}
    </div>;
}

export default RegDataFiller;