import _ from "lodash";
import React from "react";
import * as I from "../types";
import { IRowNode } from "ag-grid-community";
import { FP, T } from "../../../../Constants";
import { useBoolean } from "../../../../hooks";

//#region Types
export type Props = {};
type Role = ReturnType<T.API.Access.GetRoleDistribution>["rows"][number]["roles"][string];
type Row = Pick<ReturnType<T.API.Access.GetRoleDistribution>["rows"][number], "name" | "path">;
//#endregion

export const type = "agCustomRoleCheck";

const STATUS_PRIORITY = [
    ["in_descend", 1],
    ["granted", 2],
    ["inherited", 3],
    ["unavailable", 4],
    ["blocked", 5],
] as [Role["status"], number][];

export const sort = (a?: Role, b?: Role) => {
    let priority_a = STATUS_PRIORITY.filter(s => s[0] === a?.status)[0]?.[1] || STATUS_PRIORITY.length;
    let priority_b = STATUS_PRIORITY.filter(s => s[0] === b?.status)[0]?.[1] || STATUS_PRIORITY.length;
    let result = priority_a - priority_b;
    if (result === 0) {
        if (a?.can_edit === b?.can_edit) return 0;
        else return a?.can_edit ? -1 : 1;
    }
    else return result;
};

export const Cell = React.forwardRef<{}, I.CellProps<Props>>((props, ref) => {

    const status = React.useMemo<Role>(() => {
        const default_role: Role = { can_edit: false, status: "blocked" };
        if (!props.node.group) return props?.getValue?.() || default_role;
        else {
            let field = props.colDef.field;
            let searched_path = "", search_name = props.node.key;
            if (props.node.field === "client") searched_path = FP.CLIENT_FORM;
            else if (props.node.field === "site") searched_path = FP.SITE_FORM;
            else if (props.node.field === "building") searched_path = FP.BUILDING_FORM;

            let group_rows = props.node.allLeafChildren as IRowNode<Row>[];
            let match_row = group_rows.filter(r => r.data.name === search_name && r.data.path === searched_path)[0];

            if (!match_row) return default_role;
            else return _.get(match_row.data, field) || default_role;
        }
    }, [props]);

    const icon = React.useMemo(() => {
        let icon = { i: "ban", color: "danger" as T.ColorTypes }, opacity = "0.5";

        if (status.status === "granted") icon = { i: "check", color: "success" };
        else if (status.status === "inherited") icon = { i: "check", color: "info" };
        else if (status.status === "in_descend") icon = { i: "circle", color: "warning" };
        else if (status.status === "unavailable") icon = { i: "times", color: "danger" };
        // Update opacity if user is allowed to update the role
        if (status.can_edit && !props.node.group) opacity = "1";
        else if (props.node.group) opacity = "0.25";
        return <i className={`fa fa-${icon.i} text-${icon.color}`} style={{ opacity }}></i>
    }, [status, props.node.group]);

    return <div className="text-center fs-120">
        {icon}
    </div>;
});

export const EditCell = React.forwardRef<I.EditorRef<Role>, I.EditorProps<Props>>((props, ref) => {
    const editing = useBoolean(true);
    const [value, set_value] = React.useState<Role>(props.value || { can_edit: false, status: "blocked" });

    React.useImperativeHandle(ref, () => ({ getValue: () => value }));
    React.useEffect(() => !editing.value && props.api?.stopEditing?.(), [editing.value, props.api]);

    React.useEffect(() => {
        if (value.can_edit && value.status !== "blocked") set_value(p => ({ can_edit: p.can_edit, status: p.status === "granted" ? "unavailable" : "granted" }));
        editing.setFalse();
    }, [value, editing]);

    return <></>;
});