import Typeahead from "./TypeAhead";
import { ReduxWrapper } from "../Layout";
import { useLanguage } from "../../hooks";
import { T, TB, TC } from "../../Constants";
import { Col, Row, Button, Form, ButtonGroup } from "react-bootstrap";
import { BlankModal, renderInContainer } from "../../Components/Modal";
import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";

//#region Types
interface SelectOption extends T.AnyObject { label: string, value: string };
export type getDisabledFn = (value: any, otherValueProp: any[], valueRow: T.AnyObject) => boolean;
export type checkFn = (value: any, otherValueProp: any[], valueRow: T.AnyObject) => boolean | string;

export type RowFormat = {
    size?: number;
    propName: string;
    colLabel?: string;
    defaultValue?: any;
    placeholder?: string;
    inputProps?: T.AnyObject;
    inputType?: "text" | "number" | "select";
    renderItem?: (option: SelectOption) => ReactNode;
    filterOptions?: (option: SelectOption, text?: string) => boolean;
    getDisabled?: (value: any, otherValueProp: any[], valueRow: T.AnyObject) => boolean;
    getOptions?: (value: any, otherValueProp: any[], valueRow: T.AnyObject) => SelectOption[];
    check?: (value: any, otherValueProp: any[], valueRow: T.AnyObject, inputValue?: string) => boolean | string;
}

export type QuickInputProps = {
    /** The ma number of rows allowed to be created */
    maxRow?: number;
    forceFocus?: boolean;
    rowFormat?: RowFormat[];
    onSubmit?: (values: T.AnyObject[]) => void;
};
//#endregion

//#region Constants
const submitButtonId = "quick-insert-submit";
const isRowFormat = (rf: any): rf is RowFormat => TB.validObject(rf) && TB.validString(rf.propName);
//#endregion

const QuickInput: FC<QuickInputProps> = ({ rowFormat, maxRow, forceFocus, onSubmit, ...props }) => {
    const lastKeyRef = useRef<string>("");
    const [showErrors, setShowErrors] = useState(false);
    const [values, setValues] = useState<T.AnyObject[]>([]);
    const { fetchStaticTranslations, getStaticText, getStaticElem } = useLanguage([TC.GLOBAL_CONFIRM, TC.ADD]);

    //#region Default & Formats
    const validFormat = useMemo(() => TB.getArray(rowFormat).filter(isRowFormat), [rowFormat]);
    const isMobile = useMemo(() => [window.innerHeight, window.innerWidth].some(x => x <= 600), []);

    const newLineValue = useMemo<T.AnyObject>(() => {
        let entries = validFormat.map(({ propName, defaultValue }) => [propName, defaultValue]);
        return Object.fromEntries(entries);
    }, [validFormat]);

    useEffect(() => values.length === 0 ? setValues([{ ...newLineValue }]) : undefined, [newLineValue, values]);
    //#endregion

    //#region Texts
    useEffect(() => {
        let codes = TB.getArray(rowFormat).map(r => r?.colLabel).filter(TB.isTextCode);
        if (codes.length > 0) fetchStaticTranslations(codes);
    }, [fetchStaticTranslations, rowFormat]);
    //#endregion

    //#region Auto Focus
    useEffect(() => {
        let timeout;
        if (forceFocus && isRowFormat(validFormat[0])) {
            let attempt = 0, id = `${validFormat[0].propName}${0}`;

            let attemptFocus = () => {
                let node = document.getElementById(id);
                if (node === null) {
                    attempt++;
                    if (attempt < 15) timeout = setTimeout(attemptFocus, 75);
                }
                else node.focus();
            }

            attemptFocus();
        }

        return () => window.clearTimeout(timeout);
    }, [forceFocus, validFormat]);
    //#endregion

    //#region Submit
    const allValid = useMemo(() => values.every((val, valIndex) => {
        return validFormat.every(({ propName, inputType, check }) => {
            let everyValProp = values.filter((v, i) => i !== valIndex).map(val => val[propName]);

            let id = `${propName}${valIndex}`;
            let node = inputType === "select" ? document.querySelector(`[data-select="${id}"]`) : document.getElementById(id);
            /* @ts-ignore */
            let inputValue = node === null ? undefined : node.value;

            return typeof check !== "function" || check(val[propName], everyValProp, val, inputValue) === true
        })
    }), [values, validFormat]);

    const submitData = useCallback(() => {
        if (allValid) {
            onSubmit?.(values);
            setValues([]);
        }
        else setShowErrors(true);
    }, [allValid, values, onSubmit]);

    const submitButton = useMemo(() => <Button id={submitButtonId} variant="success" onClick={submitData}>
        <i className="me-2 fa fa-chevron-right fw-bold"></i>{getStaticElem(TC.GLOBAL_CONFIRM)}
    </Button>, [submitData, getStaticElem]);

    const addRowButton = useMemo(() => <Button onClick={() => setValues(p => p.concat({ ...newLineValue }))}>
        <i className="me-2 fa fa-plus fw-bold"></i>{getStaticElem(TC.ADD)}
    </Button>, [newLineValue, getStaticElem]);
    //#endregion

    //#region Inputs
    const onRemoveRow = useCallback((rowIndex: number) => setValues(p => p.filter((v, i) => i !== rowIndex)), []);

    const onPressKeyInput = useCallback((event: KeyboardEvent, rowIndex: number, colIndex: number, propName: string) => {
        if (["Tab", "Enter"].includes(event.key)) {
            let isLastRow = rowIndex === values.length - 1;
            let isLastCol = colIndex === validFormat.length - 1;

            if (event.shiftKey && event.key === "Enter") {
                let submitButton = document.getElementById(submitButtonId);
                if (submitButton !== null) {
                    submitButton.focus();
                    setTimeout(submitData, 150);
                }
            }
            else if (isLastCol && isLastRow && event.key === "Tab" && !event.shiftKey) setValues(p => p.concat({ ...newLineValue }));
            else if (isLastRow && event.key === "Enter") {
                let [currentId, nextId] = [...new Array(2)].map((x, i) => `${propName}${rowIndex + i}`);
                /* @ts-ignore */
                let { dataset } = event.target !== null ? event.target : {};

                let getNode = () => document.getElementById(nextId);

                // If select, no enter new line
                if (TB.validObject(dataset) && dataset.select === currentId) {
                    if (!["ArrowUp", "ArrowDown"].includes(lastKeyRef.current)) getNode = () => document.querySelector(`[data-select="${nextId}"]`);
                    else {
                        lastKeyRef.current = event.key;
                        return;
                    }
                }

                setValues(p => p.concat({ ...newLineValue }));
                let attempt = 0;

                let attemptTry = () => {
                    let node = getNode();
                    if (node !== null) node.focus();
                    else {
                        attempt++;
                        if (attempt < 150) setTimeout(attemptTry, 50);
                    }
                }
                attemptTry();
            }
        }
        lastKeyRef.current = event.key;
    }, [values, validFormat, newLineValue, submitData]);

    const onChangeInput = useCallback((index: number, value: any, prop: string) => {
        setValues(p => p.map((val, row) => {
            if (row !== index) return val;
            return { ...val, [prop]: value };
        }));
    }, []);

    const renderInput = useCallback((rowFormat: RowFormat, value: T.AnyObject, rowIndex: number) => {
        let { propName, check, inputProps, placeholder, inputType, getOptions, filterOptions, renderItem, getDisabled } = rowFormat;

        if (!TB.validObject(value)) value = {};

        let id = `${propName}${rowIndex}`;
        let props = TB.validObject(inputProps) ? inputProps : {};
        let disabled = getDisabled?.(value[propName], values.map(val => val[propName]), values[rowIndex]);

        let node = inputType === "select" ? document.querySelector(`[data-select="${id}"]`) : document.getElementById(id);
        /* @ts-ignore */
        let inputValue = node === null ? undefined : node.value;

        let isValid: string | boolean = true;
        if (showErrors && typeof check === "function") isValid = check(value[propName], values.map(val => val[propName]), values[rowIndex], inputValue);

        let propsElem = {
            ...props,
            id,
            disabled,
            isInvalid: showErrors ? (isValid === true ? undefined : true) : undefined,
        };

        let elem;
        if (inputType === "number") elem = <Form.Control
            {...propsElem} type="number" defaultValue={TB.getNumber(value[propName])} onBlur={e => onChangeInput(rowIndex, TB.getNumber(e.target.value), propName)} />
        else if (inputType === "select") {
            let options = getOptions?.(value[propName], values.map(val => val[propName]), values[rowIndex]);
            if (!Array.isArray(options)) options = [];
            options = options.filter(o => TB.validObject(o));

            elem = <Typeahead
                {...propsElem}
                options={options}
                disabled={disabled}
                className="flex-grow-1"
                renderItem={renderItem}
                filterBy={filterOptions}
                placeholder={placeholder}
                id={`${propName}${rowIndex}`}
                selectedItems={value[propName]}
                /* @ts-ignore */
                inputProps={{ "data-select": `${propName}${rowIndex}` }}
                onChange={(val: SelectOption[]) => onChangeInput(rowIndex, val?.[0]?.value, propName)}
            />
        }
        else elem = <Form.Control {...propsElem} placeholder={placeholder} defaultValue={TB.getString(value[propName])} onBlur={e => onChangeInput(rowIndex, e.target.value, propName)} />;

        return <>
            {elem}
            {TB.validString(isValid) && <Form.Control.Feedback type="invalid" className="shown">{isValid}</Form.Control.Feedback>}
        </>
    }, [onChangeInput, showErrors, values]);

    const titles = useMemo(() => <Row className="g-1 mb-3">
        {validFormat.map(({ colLabel, propName, size }) => <Col md={size} className="text-center" key={propName}><span className="h4">{getStaticText(colLabel)}</span></Col>)}
        <Col sm={1}></Col>
    </Row>, [validFormat, getStaticText]);

    const content = useMemo(() => <div className={isMobile ? "striped" : ""}>
        {values.map((val, index) => <Row className="g-1 mb-1 p-2" key={index + JSON.stringify(val)}>
            {/* @ts-ignore */}
            {validFormat.map((format, colIndex) => <Col md={isMobile ? undefined : format.size} key={format.propName} onKeyDown={e => onPressKeyInput(e, index, colIndex, format.propName)}>
                {renderInput(format, val, index)}
            </Col>)}
            <Col sm={1} className="text-center">
                <Button tabIndex={-1} onClick={() => onRemoveRow(index)} variant="danger"><i className="fa fa-times"></i></Button>
            </Col>
        </Row>)}
    </div>, [validFormat, values, isMobile, renderInput, onPressKeyInput, onRemoveRow]);
    //#endregion

    return <>
        {!isMobile && titles}
        {content}
        <ButtonGroup className="mt-3">
            {submitButton}
            {addRowButton}
        </ButtonGroup>
    </>;
}

export default QuickInput;

interface RenderInputProps extends Omit<QuickInputProps, 'onSubmit'> {
    title?: string;
    isFullScreen?: boolean;
    maxBodyHeight?: string;
    size?: "xs" | "sm" | "md" | "lg" | "xl";
}

export const renderQuickInput = (params: RenderInputProps) => new Promise<T.AnyObject[] | null>(resolve => {
    let [render, dismount] = renderInContainer();
    if (!TB.validObject(params)) params = {};
    if (render && dismount) {
        let { title, isFullScreen, maxBodyHeight, size, rowFormat, maxRow, forceFocus } = params;
        let modalProps = { title, isFullScreen, maxBodyHeight, size };
        let inputProps = { rowFormat, maxRow, forceFocus };

        let onQuit = () => dismount(() => resolve(null));
        let onSubmit = (values: T.AnyObject[]) => dismount(() => resolve(values));

        render(<ReduxWrapper>
            <BlankModal {...modalProps} onQuit={onQuit}>
                <QuickInput {...inputProps} onSubmit={onSubmit} />
            </BlankModal>
        </ReduxWrapper>);
    }
    else resolve(null);
});