//#region Resources
export const USER_FORM = "user";
export const SITE_FORM = "site";
export const TYPE_FORM = "type";
export const ICON_PATH = "icon";
export const BAIL_FORM = "bail";
export const FLAGS_FORM = "flags";
export const BRAND_FORM = "brand";
export const NOTES_PATH = "notes";
export const ADMIN_FORM = "admin";
export const GROUP_FORM = "groups";
export const ENTITY_FORM = "entity";
export const TICKET_FORM = "ticket";
export const EVENTS_FORM = "events";
export const REGION_FORM = "region";
export const PARKING_FORM = "parking";
export const TAB_MENU_FORM = "tabmenu";
export const NOTE_TAG_FORM = "notetag";
export const CONTRACT_FORM = "contrat";
export const ENSEIGNE_FORM = "enseigne";
export const CLIENT_FORM = "enterprise";
export const BUILDING_FORM = "building";
export const URL_LOGOS_FORM = "urllogos";
export const LINK_TYPE_FORM = "linktype";
export const OWNERSHIP_FORM = "ownership";
export const ACTION_REG_FORM = "regaction";
export const REMARQUES_DEFAULTS = "defaults";
export const EQUIPEMENT_FORM = "equipements";
export const CONTROL_ENTITY = "entitycontrol";
export const EMPLACEMENT_FORM = "emplacement";
export const EQUIP_STATES = "equipementstates";
export const PARCEL_FORM = "parcellecadastrale";
export const EQUIPSTORE_FORM = "storeequipement";
export const EQUIPMENT_GAMME = "gammeequipement";
export const OBJ_TYPE_RESOURCE_FORM = "typeobject";
export const EQUIP_INDICATORS = "equipmentindicator";
export const MAINTENANCE_ACTION = "maintenanceaction";
export const ACTION_RENOVATION_FORM = "actionrenovation";
export const AFFECTATION_ASSET_FORM = "assetaffectation";
//#endregion

//#region Common
export const LOGIN_FORM = "user/login";
export const VENT_DETAIL_PATH = 'ventdetail';
export const DELETE_LINK_FORM = "deletelink";
export const FORM_ACCESS_FORM = "formaccess";
export const OBJ_TYPE_FORM = "objtypeselect";
export const SIGNATURE_FORM = "signatureform";
export const SELECT_ACTION_FORM = "selectactionsubmit";
export const PERMISSIONS_EDIT_CHOICE_CHILDS = "permsedit";
export const SIGN_ALL_TICKETS_FORM = "allticketssignature";
//#endregion

//#region Group
export const NON_RESOURCE_FORMS = [
    LOGIN_FORM,
    OBJ_TYPE_FORM,
    SIGNATURE_FORM,
    VENT_DETAIL_PATH,
    DELETE_LINK_FORM,
    FORM_ACCESS_FORM,
    SELECT_ACTION_FORM,
    SIGN_ALL_TICKETS_FORM,
    PERMISSIONS_EDIT_CHOICE_CHILDS,
];

export const RESOURCE_FORMS = [
    USER_FORM,
    SITE_FORM,
    TYPE_FORM,
    ICON_PATH,
    BAIL_FORM,
    GROUP_FORM,
    FLAGS_FORM,
    BRAND_FORM,
    NOTES_PATH,
    ADMIN_FORM,
    ENTITY_FORM,
    TICKET_FORM,
    EVENTS_FORM,
    REGION_FORM,
    CLIENT_FORM,
    PARCEL_FORM,
    PARKING_FORM,
    EQUIP_STATES,
    TAB_MENU_FORM,
    NOTE_TAG_FORM,
    CONTRACT_FORM,
    ENSEIGNE_FORM,
    BUILDING_FORM,
    URL_LOGOS_FORM,
    LINK_TYPE_FORM,
    OWNERSHIP_FORM,
    CONTROL_ENTITY,
    ACTION_REG_FORM,
    EQUIPEMENT_FORM,
    EQUIPSTORE_FORM,
    EQUIPMENT_GAMME,
    EMPLACEMENT_FORM,
    EQUIP_INDICATORS,
    REMARQUES_DEFAULTS,
    MAINTENANCE_ACTION,
    OBJ_TYPE_RESOURCE_FORM,
    ACTION_RENOVATION_FORM,
    AFFECTATION_ASSET_FORM,
];

/** The type of submissions that can receive datasets */
export const GROUP_DATASETS = [SITE_FORM, BUILDING_FORM, EQUIPEMENT_FORM, EMPLACEMENT_FORM];
/** The type of submissions where tickets can apply */
export const GROUP_ACTIONS = [SITE_FORM, BUILDING_FORM, EQUIPEMENT_FORM, EMPLACEMENT_FORM];
/** The types of submissions to appear in the navigator menu */
export const GROUP_NAVIGATOR = [CLIENT_FORM, SITE_FORM, BUILDING_FORM];
/** The type of submissions that represent a user */
export const GROUP_USERS = [USER_FORM, ADMIN_FORM];
/** The type of submissions that can have a remarques attributed to them */
export const GROUP_REMARQUES = [EQUIPEMENT_FORM, EMPLACEMENT_FORM];
/** The type of submissions that can be certified */
export const GROUP_CERTIFIABLE = [
    REMARQUES_DEFAULTS, CLIENT_FORM, SITE_FORM, BUILDING_FORM, EMPLACEMENT_FORM, EQUIPEMENT_FORM,
];

export const ALL_OBJECT_TYPES = NON_RESOURCE_FORMS.concat(RESOURCE_FORMS);
//#endregion

//#region Utils
const isValidPath = (path: any, array: string[]) => array.includes(path);
export const IS_VALID_PATH = (path?: any): path is string => isValidPath(path, ALL_OBJECT_TYPES);
export const IS_VALID_RESOURCE = (path?: any): path is string => isValidPath(path, RESOURCE_FORMS);
export const IS_VALID_NON_RESOURCE = (path?: any): path is string => isValidPath(path, NON_RESOURCE_FORMS);
//#endregion