import React from 'react';
import moment from 'moment';
import { Table, TableProps } from '../Grid';
import { CellsTypes } from '../AgGridDefs';
import { TB, T } from "../../../Constants";

//#region Types
type EntityDataTableProps = {
    origin?: string;
    data?: any[];
    isFullHeight?: boolean;
    getContext?: () => T.AnyObject;
    setContext?: (context: T.AnyObject) => void;
}

type Views = "normal" | "transposed" | "indexed";

type Data = {
    /** The name of the dataset */
    target: string;
    /** The _id of the dataset */
    _id: string;
    /** The couples [value, time] */
    datapoints: [number, number][];
    /** The couples [index, time] */
    index: [number, number][];
}
//#endregion

const EntityDataTable: React.FC<EntityDataTableProps> = ({ data, origin, isFullHeight = false, getContext, setContext, ...props }) => {
    const [view, setView] = React.useState<Views>("normal");

    //#region Data
    const datasToView = React.useMemo<Data[]>(() => TB.getArray(data), [data]);

    const normal = React.useMemo(() => ({
        rows: () => {
            if (!Array.isArray(datasToView)) return [];

            let okDatas = datasToView.filter(entity => TB.validObject(entity) && Array.isArray(entity.datapoints));

            let object = {};
            okDatas.forEach(entity => {
                let { datapoints } = entity;
                datapoints.forEach(array => {
                    if (Array.isArray(array) && array.length >= 2) {
                        let [value, time] = array;
                        if (TB.validObject(object[time])) object[time][entity._id] = value;
                        else {
                            object[time] = {};
                            object[time][entity._id] = value;
                        }
                    }
                });
            });

            return Object.entries(object).map(([time, objValues]) => {
                let timeNumber = TB.getNumber(time);
                if (isNaN(timeNumber)) timeNumber = 1;

                let year = moment.unix(timeNumber / 1000).format('YYYY');
                let month = moment.unix(timeNumber / 1000).format('MM');
                let day = moment.unix(timeNumber / 1000).format('DD');
                let hour = moment.unix(timeNumber / 1000).format('HH');

                let arrayVal = Object.entries(objValues || {});
                let entries = arrayVal.map(([_id, val]) => [`n_${_id}`, val]).filter(Array.isArray);
                let entryObject = Object.fromEntries(entries);
                return { time, year, month, day, hour, ...entryObject };
            })
        },
        columns: () => {
            let columns = [] as TableProps<any>["columns"];
            // Time columns
            columns.push(
                {
                    colId: "_id",
                    field: 'time',
                    headerName: 'Date',
                    type: CellsTypes.TYPE_DATE,
                    params: { format: "YYYY/MM/DD HH:mm:ss" },
                },
                { headerName: 'Year', field: 'year', hide: true, type: CellsTypes.TYPE_NUMBER },
                { headerName: 'Month', field: 'month', hide: true, type: CellsTypes.TYPE_NUMBER },
                { headerName: 'Day', field: 'day', hide: true, type: CellsTypes.TYPE_NUMBER },
                { headerName: 'Hour', field: 'hour', hide: true, type: CellsTypes.TYPE_NUMBER },
            );

            for (let entity of datasToView) columns.push({
                hide: false,
                sortable: true,
                params: { maxDigit: 3 },
                field: `n_${entity._id}`,
                colId: `n_${entity._id}`,
                headerName: entity.target,
                type: CellsTypes.TYPE_NUMBER,
            });
            return columns;
        }
    }), [datasToView]);

    const transposed = React.useMemo(() => ({
        rows: () => {
            let rows = [];
            if (!Array.isArray(datasToView)) return [];
            let okDatas = datasToView.filter(entity => TB.validObject(entity) && Array.isArray(entity.datapoints));

            okDatas.forEach(entity => {
                if (Array.isArray(entity.datapoints)) {
                    let row = { name: entity.target };
                    for (let [value, time] of entity.datapoints) row[String(time)] = value;
                    rows.push(row);
                }
            });
            return rows;
        },
        columns: () => {
            if (!Array.isArray(datasToView)) return [];
            let okDatas = datasToView.filter(entity => TB.validObject(entity) && Array.isArray(entity.datapoints));

            let times = [];
            okDatas.forEach(entity => {
                let { datapoints } = entity;
                datapoints.forEach(array => {
                    if (Array.isArray(array) && array.length >= 2) {
                        let time = array?.[1];
                        if (!times.includes(time)) times.push(time);
                    }
                });
            });

            return [{ headerName: "Name", field: "name" }]
                .concat(
                    times.map(time => {
                        let timeNumber = TB.getNumber(time);
                        if (isNaN(timeNumber)) timeNumber = 1;
                        let time_str = moment.unix(time / 1000).format('DD/MM/YYYY HH:mm:ss');

                        return {
                            hide: false,
                            sortable: true,
                            field: String(time),
                            headerName: time_str,
                            enableRowGroup: true,
                            params: { maxDigit: 3 },
                            type: CellsTypes.TYPE_NUMBER,
                        }
                    }));

        }
    }), [datasToView]);

    const indexed = React.useMemo(() => ({
        rows: () => {
            if (!Array.isArray(datasToView)) return [];

            let okDatas = datasToView.filter(entity => TB.validObject(entity));

            let object = {};
            okDatas.forEach(entity => {
                let { index } = entity;
                if (Array.isArray(index)) index.forEach(array => {
                    if (Array.isArray(array) && array.length >= 2) {
                        let [value, time] = array;

                        if (typeof value === "number") {
                            if (TB.validObject(object[time])) object[time][entity._id] = value;
                            else {
                                object[time] = {};
                                object[time][entity._id] = value;
                            }
                        }
                    }
                });
            });

            return Object.entries(object).map(([time, objValues]) => {
                let timeNumber = TB.getNumber(time);
                if (isNaN(timeNumber)) timeNumber = 1;

                let year = moment.unix(timeNumber / 1000).format('YYYY');
                let month = moment.unix(timeNumber / 1000).format('MM');
                let day = moment.unix(timeNumber / 1000).format('DD');
                let hour = moment.unix(timeNumber / 1000).format('HH');

                let arrayVal = Object.entries(objValues || {});
                let entries = arrayVal.map(([_id, val]) => [`i_${_id}`, val]).filter(Array.isArray);
                let entryObject = Object.fromEntries(entries);
                return { time, year, month, day, hour, ...entryObject };
            })
        },
        columns: () => {
            let timeCol = {
                colId: "_id",
                field: 'time',
                headerName: 'Date',
                type: CellsTypes.TYPE_DATE,
                params: { format: "YYYY/MM/DD HH:mm:ss", isDateTime: true, saveUnix: true },
                valueFormatter: p => moment.unix(p?.value / 1000).format('DD/MM/YYYY HH:mm:ss'),
            };

            let yearCol = { headerName: 'Year', field: 'year', type: CellsTypes.TYPE_NUMBER }
            let monthCol = { headerName: 'Month', field: 'month', type: CellsTypes.TYPE_NUMBER }
            let dayCol = { headerName: 'Day', field: 'day', type: CellsTypes.TYPE_NUMBER }
            let hourCol = { headerName: 'Hour', field: 'hour', type: CellsTypes.TYPE_NUMBER }

            let colObject: any[] = [];

            if (Array.isArray(datasToView)) {

                colObject = datasToView
                    // .filter(e => Array.isArray(e.index))
                    .map(entity => Array.isArray(entity.index)
                        ? {
                            hide: false,
                            sortable: true,
                            enableValue: true,
                            params: { maxDigit: 3 },
                            field: `i_${entity._id}`,
                            headerName: entity.target,
                            type: CellsTypes.TYPE_NUMBER,
                        }
                        : null)
                    .filter(e => e !== null)
            }
            /* @ts-ignore */
            return [timeCol].concat(yearCol, monthCol, dayCol, hourCol, colObject);
        }
    }), [datasToView]);

    const table_data = React.useMemo(() => {
        if (view === "indexed") return { rows: indexed.rows(), columns: indexed.columns() };
        else if (view === "transposed") return { rows: transposed.rows(), columns: transposed.columns() };
        else return { rows: normal.rows(), columns: normal.columns() };
    }, [view, normal, indexed, transposed]);
    //#endregion

    //#region Context Menu
    const getContextMenuItems = React.useCallback((e): any[] => {
        let items: any[] = [
            {
                name: "Transpose",
                disabled: view === "transposed",
                action: () => setView("transposed"),
            },
            {
                name: "Index",
                disabled: view === "indexed",
                action: () => setView("indexed"),
            },
            {
                name: "Normal",
                disabled: view === "normal",
                action: () => setView("normal"),
            }
        ];

        let default_items = TB.getArray(e.defaultItems);
        if (default_items.length > 0) items.push("separator", ...default_items);
        return items;
    }, [view]);
    //#endregion

    //#region Loading & Errors
    const isError = React.useMemo(() => datasToView?.length === 0, [datasToView]);
    const isLoading = React.useMemo(() => !isError && datasToView === undefined, [isError, datasToView]);
    //#endregion

    const show_table = React.useMemo(() => Array.isArray(datasToView) && datasToView.length > 0, [datasToView]);

    return show_table && <div className={`w-100 ${isFullHeight ? 'h-100' : ''}`}>
        <Table
            enableCharts
            origin='Data'
            loading={isLoading}
            adaptableId='entity'
            enableRangeSelection
            rows={table_data.rows}
            remove_unknown_columns
            export_dashboard_button
            rowSelection="multiple"
            sideBar={"filters_columns"}
            columns={table_data.columns}
            isRowSelectable={() => true}
            groupDisplayType="singleColumn"
            getContextMenuItems={getContextMenuItems}
            columns_base={["filterable", "grouped", "sortable"]}
        />
    </div>;
}

export default EntityDataTable;