import React from "react";
import * as M from "../Modal";
import * as H from "../../hooks";
import * as C from "../../Common";
import * as S from "../../services";
import * as BS from "react-bootstrap";
import * as DOM from "react-router-dom";
import { T, TB, TC } from "../../Constants";
import * as US from "../../services/user.service";

type Summary = ReturnType<T.API.Form.GetFormsSummary>;

const FormManager: React.FC = () => {
    const lg = H.useLanguage();
    H.useAuth({ onlyAdmin: true });
    const [copy] = H.useClipBoard();
    const navigate = DOM.useNavigate();
    const [search, set_search] = React.useState<string>("");
    const [forms, set_forms, status] = H.useAsyncState<Summary>({ sub: [], misc: [] });

    React.useEffect(() => {
        let isSubscribed = true;
        S.getFormsSummary()
            .then(({ data }) => isSubscribed && set_forms(data, "done"))
            .catch(() => isSubscribed && set_forms({ sub: [], misc: [] }, "error"));
        return () => {
            isSubscribed = false;
            set_forms({ sub: [], misc: [] }, "load");
        }
    }, [set_forms]);

    const events = React.useMemo(() => ({
        to_subs: (_id: string) => navigate(`/forms/submission/${_id}`),
        create: (key: keyof Summary) => navigate(`forms/${key === "misc" ? "form" : "resource"}/create`),
        edit: (_id: string, key: keyof Summary) => navigate(`/forms/${key === "misc" ? "form" : "resource"}/${_id}/edit`),
        remove: (_id: string, key: keyof Summary) => M.askConfirm().then(confirmed => {
            if (confirmed) US.deleteFormFromId(_id)
                .then(({ data }) => {
                    if (data?._id) set_forms(p => ({ ...p, [key]: p[key].filter(f => f._id !== _id) }));
                    else M.Alerts.deleteError();
                })
                .catch(M.Alerts.deleteError);
        }),
    }), [navigate, set_forms]);

    const filter_forms = React.useCallback((key: keyof Summary) => {
        let forms_list = forms[key];
        if (search.length === 0) return forms_list;
        return forms_list.filter(f => TB.areStringSimilar(search, f.path)
            || TB.areStringSimilar(search, f.name)
            || TB.areStringSimilar(search, lg.getStaticText(f.path))
        );
    }, [forms, lg, search]);

    const render_tab = React.useCallback((key: keyof Summary) => {
        let props = key === "misc"
            ? { variant: "success", title: TC.RESOURCE, forms: forms.misc }
            : { variant: "primary", title: TC.FORMS, forms: forms.sub };
        return <table className="table table-sm table-striped table-hover border w-100">
            {/* Header */}
            <thead className={`bg-${props.variant} text-white text-center`} style={{ fontSize: "1.3rem" }}>
                <tr className='border-0'>
                    <th colSpan={2} className="border-0">
                        {lg.getStaticText(props.title)}
                        <span className='fw-normal fs-85 ml-2'>{`(${props.forms.length})`}</span>
                    </th>
                </tr>
            </thead>
            {/* Body */}
            <tbody className='d-block border-top-0' style={{ height: "75vh", overflowY: "scroll" }}>
                {filter_forms(key).map(f => <tr className='w-100 d-flex' key={f._id}>
                    <td className='flex-grow-1 align-middle'>
                        <C.Button variant="link" text={f.path} onClick={() => events.to_subs(f._id)} onContextMenu={e => copy(f._id, e)} />
                        <span className="fs-85 muted">({f.path})</span>
                    </td>
                    <td>
                        <div className='d-flex'>
                            <div className='m-1'>
                                <C.Button size='sm' icon="pencil-alt" onClick={() => events.edit(f._id, key)} />
                            </div>
                            <div className='m-1'>
                                <C.Button size='sm' variant='danger' icon="trash" onClick={() => events.remove(f._id, key)} />
                            </div>
                        </div>
                    </td>
                </tr>)}
            </tbody>
            {/* Footer */}
            <tfoot className={`bg-${props.variant} text-white`}>
                <tr>
                    <td colSpan={2}>
                        <C.Button variant="light" icon="plus" text={props.title} onClick={() => events.create(key)} />
                    </td>
                </tr>
            </tfoot>
        </table>;
    }, [lg, copy, events, filter_forms, forms]);

    return <C.Spinner status={status} min_load_size="800px">
        <BS.Row className="g-2">
            <BS.Col md={12}>
                <C.Form.TextField
                    uncontrolled
                    noBottomMargin
                    value={search}
                    onChange={set_search}
                    label={TC.GLOBAL_SEARCH_PLACEHOLDER}
                />
            </BS.Col>
            <BS.Col md={6}>
                {render_tab("sub")}
            </BS.Col>
            <BS.Col md={6}>
                {render_tab("misc")}
            </BS.Col>
        </BS.Row>
    </C.Spinner>;
}

export default FormManager;