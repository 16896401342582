import _ from "lodash";
import React from "react";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as S from "../../../services";
import * as PM from "../../../PurposeModal";
import { CellsTypes as CT } from "../AgGridDefs";
import { Schedule, Spinner } from "../../../Common";
import { Table, TableProps, TableRef } from "../Grid";
import { T, FP, TABS, TB, TC, RIGHTS, DS } from "../../../Constants";

//#region Types
export type AlarmTableProps = {
    /** The context to load the alarms from */
    context?: T.ContextParams;
    /** The dataset to load the alarms from, overrule the context */
    dataset?: string;
    /** The property to save the states under */
    origin?: string;
    /** ClassName for the main div */
    className?: string;
}

type Row = ReturnType<T.API.Utils.Energy.GetAlarmsRows>[number];
//#endregion

const AlarmOptions = DS.ALARMS;
const TEXT_CODES = [TC.GLOBAL_EDIT, TC.GLOBAL_NEW, TC.GLOBAL_DELETE];

export const Alarm: React.FC<AlarmTableProps> = props => {
    const rights = H.useRights();
    const [{ userId }] = H.useAuth();
    const isMounted = H.useIsMounted();
    const loading = H.useBoolean(false);
    const lg = H.useLanguage(TEXT_CODES);
    const alarm_grid = React.useRef<TableRef<Row>>(null);
    const [alarms, setAlarms, alarm_status] = H.useAsyncState<Row[]>([]);

    //#region Misc
    const is_error = React.useMemo(() => alarm_status === "error", [alarm_status]);
    //#endregion

    //#region Load data
    const load_data = React.useCallback((reload = false) => {
        if (reload) setAlarms([], "load");

        if (TB.mongoIdValidator(props.dataset)) S.getAlarmsRowsDataset(props.dataset)
            .then(({ data }) => isMounted() && setAlarms(data, "done"))
            .catch(() => isMounted() && setAlarms([], "error"));
        else if (props.context) S.getAlarmsRows(props.context)
            .then(({ data }) => isMounted() && setAlarms(data, "done"))
            .catch(() => isMounted() && setAlarms([], "error"));
        else setAlarms([], "error");
    }, [props.dataset, props.context, setAlarms, isMounted]);

    React.useEffect(() => load_data(), [load_data]);
    //#endregion

    //#region Language
    React.useEffect(() => lg.getOptionsStatic(AlarmOptions.type), [lg]);
    React.useEffect(() => lg.getOptionsStatic(AlarmOptions.aggregate), [lg]);
    //#endregion

    //#region Translated rows
    const alarms_tr = React.useMemo(() => alarms.map(a => {
        let type_translated = a.type || "",
            group_agg_tr = a.data_group_aggregate || "";

        let type = AlarmOptions.type.filter(o => o.value === a.type)[0];
        if (type) type_translated = lg.getStaticText(type.label);

        let gr_agg = AlarmOptions.aggregate.filter(o => o.value === a.data_group_aggregate)[0];
        if (gr_agg) group_agg_tr = lg.getStaticText(gr_agg.label);

        return { ...a, type_translated, group_agg_tr };
    }), [lg, alarms]);
    //#endregion

    //#region Callbacks & Inline Change
    const pick_dataset = React.useCallback((value?: string[]) => new Promise<string[]>(resolve => {
        if (TB.mongoIdValidator(props.dataset)) resolve([props.dataset]);
        else PM.renderDatasetsManyPickFromContextModal({ context: props.context, required: true, value }).then(datasets => {
            if (Array.isArray(datasets) && datasets.length > 0) resolve(datasets);
            else resolve(null);
        });
    }), [props.dataset, props.context]);

    const alarms_cb = React.useMemo(() => ({
        show_data_inclusion: (schedules: T.Schedule[]) => {
            M.renderBlankModal({
                size: "md",
                title: TC.ALARM_DATA_INCLUDE,
                children: <Schedule readOnly value={schedules} context={props.context} />,
            });
        },
        edit: (alarm: Row) => {
            M.renderAlarmFormModal({ alarm, datasets: alarm.datasets_ids, context: props.context, modalProps: { title: alarm.name, size: "md" } }).then(alarm => {
                if (alarm) S.getAlarmsRowsFromIds(alarm.id)
                    .then(({ data }) => setAlarms(p => p.map(a => data.filter(al => al.id === a.id)[0] || a)))
                    .catch(M.Alerts.loadError);
            });
        },
        add: () => {
            M.renderAlarmFormModal({ datasets: [], context: props.context, modalProps: { title: TC.ADD_ALARM, size: "md" } }).then(alarm => {
                if (alarm) S.getAlarmsRowsFromIds(alarm.id)
                    .then(({ data }) => setAlarms(p => {
                        let existing_ids = p.map(r => r.id);
                        let [to_update, to_add] = _.partition(data, r => existing_ids.includes(r.id));
                        return p.map(r => to_update.filter(row => row.id === r.id)[0] || r)
                            .concat(to_add);
                    }))
                    .catch(M.Alerts.loadError);
            });
        },
        remove: (alarm: Row) => {
            M.askConfirm().then(confirmed => {
                if (confirmed) S.removeAlarms(alarm.id)
                    .then(() => setAlarms(p => p.filter(a => a.id !== alarm.id)))
                    .catch(M.Alerts.deleteError);
            })
        },
        alerts: (alarm: Row) => {
            alert("todo");
        },
        assign: (alarm: Row) => {
            pick_dataset(alarm.datasets_ids).then(value => {
                if (Array.isArray(value) && value.length > 0) S.updateAlarmAssignation({ alarm_id: alarm.id, datasets: value }).then(() => {
                    S.getAlarmsRowsFromIds(alarm.id)
                        .then(({ data }) => setAlarms(p => p.map(a => data.filter(al => al.id === a.id)[0] || a)))
                        .catch(M.Alerts.loadError);
                }).catch(M.Alerts.updateError);
            });
        },
        manage_mailing: () => {
            PM.renderSiteSelect({ context: props.context, isRequired: true }).then(site => {
                if (site) M.renderAlarmMailing({ site });
            });
        },
        tags: (row: Row) => new Promise<T.Option[]>((resolve, reject) => {
            S.getNoteTags({ context: { roots: row.origin }, type: "alarm" })
                .then(r => resolve(r.data))
                .catch(reject);
        }),
        create_tag: (text: string, row: Row) => new Promise<T.Option>((resolve, reject) => {
            let submission = { name: text, users: [userId], sites: [], type: "alarm" } as T.NoteTag;
            S.createSubmission({ submission, path: FP.NOTE_TAG_FORM }).then(({ data }) => {
                let new_tag = data.submissions[0] as T.Submission<T.NoteTag>;
                if (new_tag) {
                    let new_option = { label: new_tag.data.name, value: new_tag._id } as T.Option;
                    resolve(new_option);
                }
                else resolve(null);
            }).catch(reject);
        }),
    }), [setAlarms, pick_dataset, userId, props.context]);

    const inline_change = React.useCallback<TableProps<Row>["onValueChange"]>(params => {
        let row = params.data,
            old_value = params.oldValue,
            new_value = params.newValue,
            field = params.colDef.field as keyof typeof alarms_tr[number];
        // This field can't be edited
        if (field !== "tags_names") M.Alerts.updateError();
        // All seems ok
        else {
            let prop: keyof T.pSQL.Alarm;
            if (field === "tags_names") {
                prop = "tags";
                old_value = (row.tags || []).join();
                new_value = (params.newValue || []).join();
            }
            else prop = field as typeof prop;

            if (!_.isEqual(old_value, params.newValue)) {
                const updatePromise = new Promise<"cancel" | Row[]>((resolve, reject) => {
                    let api_params = {
                        id: row.id,
                        field: prop,
                        force_update: true,
                        old_value: old_value,
                        new_value: new_value,
                    } as Parameters<typeof S.edit_alarm_field>[0];

                    loading.setTrue();

                    S.edit_alarm_field(api_params).then(({ data }) => {
                        if (data === "changed") M.askConfirm({ title: TC.UPDATE_FORCE_CHANGE, text: TC.UPDATE_VALUE_UNFRESH }).then(confirmed => {
                            if (confirmed) S.edit_alarm_field({ ...api_params, force_update: true }).then(({ data }) => {
                                if (data === "changed") reject("Error");
                                else resolve(data);
                            })
                            else resolve("cancel");
                        });
                        else resolve(data);
                    }).catch(reject);
                });

                updatePromise.then(new_rows => {
                    if (new_rows !== "cancel") setAlarms(p => p.map(t => new_rows.find(r => r.id === t.id) || t));
                })
                    .catch(M.Alerts.updateError)
                    .finally(loading.setFalse);
            }
        }
    }, [loading, setAlarms]);
    //#endregion

    //#region Context Menu
    const getContextMenu = React.useCallback<TableProps<Row>["getContextMenuItems"]>(event => {
        const actions = [];
        const alarm = event.node?.data as Row;
        const can_write = alarm?.user === userId || rights.isRightAllowed(RIGHTS.NRJ.WRITE_OTHER_ALARMS);

        // Create
        actions.push({ name: TC.GLOBAL_NEW, action: () => alarms_cb.add(), icon: "<i class='fa fa-plus'></i>" });
        // Edit
        if (alarm && can_write) actions.push(
            // Edit alarm data
            { name: TC.GLOBAL_EDIT, action: () => alarms_cb.edit(alarm), icon: '<i class="fa fa-pencil-alt"></i>' },
            // Edit dataset assignation
            { name: TC.ALARM_ASSIGN_DATASETS, action: () => alarms_cb.assign(alarm), icon: '<i class="fa fa-link"></i>' },
        );
        // Delete
        if (alarm && can_write) actions.push({ name: TC.GLOBAL_DELETE, action: () => alarms_cb.remove(alarm), icon: '<i class="fa fa-times"></i>' });

        actions.push("separator");
        actions.push({ name: TC.MAILING_ALARM_TITLE, action: alarms_cb.manage_mailing, icon: "<i class='fa fa-bell'></i>" });

        if (event.defaultItems) actions.push("separator");

        const defaultItems = TB.getArray(event.defaultItems).filter(TB.validString);
        return actions.concat(defaultItems)
            .map(a => typeof a === "string" ? a : { ...a, name: lg.getStaticText(a.name) });
    }, [alarms_cb, lg, rights, userId]);
    //#endregion

    //#region Columns
    const columns = React.useMemo<TableProps<Row>["columns"]>(() => [
        { field: "name", headerName: TC.GLOBAL_NAME },
        { field: "type_translated", headerName: TC.ALARM_TYPE },
        {
            field: "tags_names",
            type: CT.TYPE_SELECT,
            headerName: TC.DATASET_TAGS,
            editable: r => r.data?.user === userId || rights.isRightAllowed(RIGHTS.NRJ.WRITE_OTHER_ALARMS),
            params: {
                multiple: true,
                field_value: "tags",
                getValues: alarms_cb.tags,
                typeahead: { allowNewOption: true, onAddOption: alarms_cb.create_tag },
            }
        },
        { field: "start_date", headerName: TC.ALARM_START_DATE, type: CT.TYPE_DATE, params: { format: "DD/MM/YY HH:mm" } },
        { field: "period", headerName: TC.ALARM_PERIOD, type: CT.TYPE_FREQUENCY },
        {
            field: "data_include",
            type: CT.TYPE_ACTION_BUTTON,
            headerName: TC.ALARM_DATA_INCLUDE,
            params: {
                buttonProps: { icon: "calendar-alt", variant: "none" },
                action: (alarm) => alarms_cb.show_data_inclusion(alarm.data_include),
            }
        },
        { field: "data_group_period", headerName: TC.ALARM_GROUP_PERIOD, type: CT.TYPE_FREQUENCY },
        { field: "group_agg_tr", headerName: TC.ALARM_GROUP_AGG },
        { field: "threshold", headerName: TC.ALARM_TYPE_THRESHOLD },
        { field: "unit", headerName: TC.DATASET_UNIT },
        { field: "active", headerName: TC.ALARM_ACTIVE, type: CT.TYPE_CHECKBOX },
        {
            headerName: TC.DATASET_ORIGIN,
            children: [
                { headerName: TC.DATASET_FORM, field: "dataset" },
                { headerName: TC.DATASET_ELEMENT, field: "origin_name" },
                { headerName: FP.SITE_FORM, field: "site.name" },
                { headerName: FP.BUILDING_FORM, field: "building.name" },
                { headerName: FP.EMPLACEMENT_FORM, field: "emplacement.name" },
                { headerName: TC.GLOBAL_LOCAL, field: "local.name" },
            ]
        },
        {
            field: "edit",
            pinned: "right",
            headerName: " ",
            type: CT.TYPE_ACTION_BUTTON,
            params: {
                action: alarms_cb.edit,
                buttonProps: { icon: "pencil-alt", variant: "none" },
                isDisabled: row => row && row.user !== userId && !rights.isRightAllowed(RIGHTS.NRJ.WRITE_OTHER_ALARMS),
            }
        },
    ], [alarms_cb, rights, userId]);
    //#endregion

    return <div className={TB.getString(props.className) + " w-100"}>
        <Spinner error={is_error}>
            <Table<Row>
                sideBar
                rows={alarms_tr}
                ref={alarm_grid}
                columns={columns}
                status={alarm_status}
                export_dashboard_button
                loading={loading.value}
                columns_base="all_but_edit"
                onValueChange={inline_change}
                getContextMenuItems={getContextMenu}
                reload_button_cb={() => load_data(true)}
                adaptableId={props.origin || TABS.NRJ_ALARM_TABLE}
                autoFit={React.useMemo(() => ["data_include", "edit"], [])}
            />
        </Spinner>
    </div>;
}

export const AlarmContext: React.FC = props => {
    H.useCrumbs(TC.CPT_ALARMS);
    const [roots] = H.useRoots();
    H.useAuth({ tabName: TABS.NRJ_ALARM_TABLE });

    return <Alarm context={roots} origin={TABS.NRJ_ALARM_TABLE} />;
}