import * as S from "../services";
import { FP, T } from "../Constants";
import * as M from "../Components/Modal";

//#region Types
/** Payload for the selection of a Portfolio */
type PayloadPortfolioSelection = string;
/** Payload for the selection of a mission */
type PayloadSelectMission = Record<"mission" | "asset" | "path", string>;
/** Payload to apply a default context */
type PayloadDefaultContext = ReturnType<T.API.Access.Auth.DefaultContext>;
/** Payload for the selection of an item */
type PayloadItemSelection = Record<"item" | "path", string> & Partial<Record<"save_recent" | "ignore_mission_check", boolean>>;

type Context = T.DataContextRedux;
type Actions = T.DataContextActions;
type Payload = PayloadDefaultContext | PayloadSelectMission | PayloadPortfolioSelection | PayloadItemSelection;
//#endregion

//#region Constants & Events
/** A reference to contain the state, to save it in 'localStorage' when the page is closed */
const state_ref = { current: null as Context };
/** The list of actions that should trigger a dispatch to the 'Rights Redux' */
const CHANGE_CONTEXT_ACTIONS: Actions[] = ["LOGIN_SUCCESS", "SELECT_ITEM", "SELECT_PORTFOLIO", "DEFAULT_CONTEXT"];
/** The initial and default empty context */
const init_state: Context = {
    selectedItems: [],
    selectedSites: [],
    selectedClients: [],
    active_mission: null,
    selectedBuildings: [],
    selectedPortfolio: null,
    selectedEmplacements: [],
};

/** Save the state in localStorage when the page is closed */
window.addEventListener("beforeunload", () => {
    if (state_ref.current) {
        // Deactivate the mission when not on a mission form
        if (!window.location.pathname.includes("/mission/")) {
            // Deactivate the mission on refresh
            state_ref.current.active_mission = null;
            localStorage.removeItem("mission_id");
        }
        // Set the state for the next connection
        localStorage.setItem("redux_context", JSON.stringify(state_ref.current));
    }
});
//#endregion

const context: T.ReduxFn<Context, Actions, Payload> = (state = init_state, payload) => {
    let new_state = state;
    /** The mission was changed, and it represents another context than the current one, need to reload the rights */
    let changed_mission_context = false;

    // A mission was deactivated
    if (payload?.type === "UNSELECT_MISSION") {
        // Remove the mission from localStorage
        localStorage.removeItem("mission_id");
        // Remove the mission from the state
        new_state = { ...state, active_mission: null };
    }
    // A portfolio was selected
    else if (payload?.type === "SELECT_PORTFOLIO") {
        // Cast the action as it is
        let portfolio_id = payload.action as PayloadPortfolioSelection;
        // Make sure to deactivate the mission when context change
        localStorage.removeItem("mission_id");
        // Update the user's list of recent selections
        S.updateRecentList(portfolio_id);
        // Update the state
        new_state = { ...init_state, selectedPortfolio: portfolio_id };
    }
    // A mission was selected
    else if (payload?.type === "SELECT_MISSION") {
        let action = payload.action as PayloadSelectMission;
        // Activate the new mission
        localStorage.setItem("mission_id", action.mission);
        // Update the user's list of recent missions
        S.updateRecentMissionList(action.mission);
        // Create a copy of the initial state, to build the new state on
        new_state = { ...init_state, active_mission: action.mission, selectedItems: [action.asset] };
        // If the mission's asset is different than the current context, need to reload the rights
        changed_mission_context = !state.selectedItems.some(i => i === action.asset);
        // Update the user's list of recent selections
        S.updateRecentList(action.asset);
        // Craft the new state
        if (action.path === FP.SITE_FORM) new_state.selectedSites = [action.asset];
        if (action.path === FP.CLIENT_FORM) new_state.selectedClients = [action.asset];
        if (action.path === FP.BUILDING_FORM) new_state.selectedBuildings = [action.asset];
        if (action.path === FP.EMPLACEMENT_FORM) new_state.selectedEmplacements = [action.asset];
    }
    // Same as 'SELECT_ITEM', but for the case where a mission is active, and the new context has been checked to be in the mission
    else if (payload?.type === "SELECT_MISSION_SUB_CONTEXT") {
        let action = payload?.action as PayloadItemSelection;
        // Create a copy of the initial state, to build the new state on
        new_state = { ...init_state, active_mission: state.active_mission, selectedItems: [action.item] };
        // Craft the new state
        if (action.path === FP.SITE_FORM) new_state.selectedSites = [action.item];
        if (action.path === FP.CLIENT_FORM) new_state.selectedClients = [action.item];
        if (action.path === FP.BUILDING_FORM) new_state.selectedBuildings = [action.item];
        if (action.path === FP.EMPLACEMENT_FORM) new_state.selectedEmplacements = [action.item];
    }
    // A new context was selected
    else if (payload?.type === "SELECT_ITEM") {
        let action = payload?.action as PayloadItemSelection;
        // The context has changed and a mission was active, check that the new context is in the mission
        if (state.active_mission && !action.ignore_mission_check) S.checkContextForMission(action.item).then(linked => {
            // Item is in the mission's sub context, we can keep the mission as active
            if (linked.data) payload.asyncDispatch?.({ type: "SELECT_MISSION_SUB_CONTEXT", action });
            // Item is not part of the mission, change context and deactivate the mission
            else payload.asyncDispatch?.({ type: "SELECT_ITEM", action: { ...action, ignore_mission_check: true } as typeof action });
        }).catch(M.Alerts.loadError);
        // No active mission, just update the context
        else {
            // Deactivate the mission
            localStorage.removeItem("mission_id");
            // Create a copy of the initial state, to build the new state on
            new_state = { ...init_state, active_mission: null, selectedItems: [action.item] };
            // Update the user's list of recent selections
            if (action.save_recent) S.updateRecentList(action.item);
            // Craft the new state
            if (action.path === FP.SITE_FORM) new_state.selectedSites = [action.item];
            if (action.path === FP.CLIENT_FORM) new_state.selectedClients = [action.item];
            if (action.path === FP.BUILDING_FORM) new_state.selectedBuildings = [action.item];
            if (action.path === FP.EMPLACEMENT_FORM) new_state.selectedEmplacements = [action.item];
        }
    }
    // A default context is provided after login
    else if (payload?.type === "DEFAULT_CONTEXT") {
        let default_context = payload.action as PayloadDefaultContext;
        // A default context was correctly provided
        if (default_context) {
            // Context is a portfolio
            if (default_context.portfolio) {
                // Make sure to deactivate the mission when context change
                localStorage.removeItem("mission_id");
                // Update the user's list of recent selections
                S.updateRecentList(default_context.portfolio);
                // Update the state
                new_state = { ...init_state, selectedPortfolio: default_context.portfolio };
            }
            // Context is a selection
            else {
                // Deactivate the mission
                localStorage.removeItem("mission_id");
                // Update the user's list of recent selections
                S.updateRecentList(default_context.roots[0]);
                // Create a copy of the initial state, to build the new state on
                new_state = {
                    active_mission: null,
                    selectedPortfolio: null,
                    selectedItems: default_context.roots,
                    selectedSites: default_context.path === FP.SITE_FORM ? default_context.roots : [],
                    selectedClients: default_context.path === FP.CLIENT_FORM ? default_context.roots : [],
                    selectedBuildings: default_context.path === FP.BUILDING_FORM ? default_context.roots : [],
                    selectedEmplacements: default_context.path === FP.EMPLACEMENT_FORM ? default_context.roots : [],
                };
            }
        }
    }

    // Notify the 'Rights Redux' that the context has been changed
    if (changed_mission_context || CHANGE_CONTEXT_ACTIONS.includes(payload?.type)) payload?.asyncDispatch?.({ type: "UPDATED_CONTEXT", action: new_state });
    // Update the reference that contains the state, to save it in 'localStorage' when the page is closed
    state_ref.current = new_state;
    // Return the new updated state
    return new_state;
}

export default context;

export const unselectContextMission: T.ReduxDispatch<Actions, null> = () => ({ type: "UNSELECT_MISSION" });
export const selectContextItem: T.ReduxDispatch<Actions, PayloadItemSelection> = action => ({ type: "SELECT_ITEM", action });
export const selectContextMission: T.ReduxDispatch<Actions, PayloadSelectMission> = action => ({ type: "SELECT_MISSION", action });
export const selectDefaultContext: T.ReduxDispatch<Actions, PayloadDefaultContext> = action => ({ type: "DEFAULT_CONTEXT", action });
export const selectContextPortfolio: T.ReduxDispatch<Actions, PayloadPortfolioSelection> = action => ({ type: "SELECT_PORTFOLIO", action });