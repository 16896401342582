import { TB } from '../../Constants';
import * as echarts from 'echarts/core';
import { useLanguage } from '../../hooks';
import { CanvasRenderer } from 'echarts/renderers';
import { ScatterChart } from 'echarts/charts';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import EChartsReactCore from 'echarts-for-react/lib/core';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { GridComponent, TooltipComponent, TitleComponent, LegendComponent } from 'echarts/components';
echarts.use([TitleComponent, TooltipComponent, GridComponent, ScatterChart, CanvasRenderer, LegendComponent]);

//#region Types
type SerieItem = {
    name: string;
    color?: string;
    data: [number, number, string][];
};

type ToolTipParams = {
    bgColor?: string;
    textColor?: string;
    borderWidth?: number;
    borderColor?: string;
    transitionDuration?: number;
    valueFormatter?: ((value: number) => string);
}

type LegendParams = {
    color?: string;
    orientation?: "horizontal" | "vertical",
    formatter?: string | ((name: string) => string);
}

type DoughnutChartProps = {
    title?: string;
    height?: string;
    data: SerieItem[];
    legend?: boolean | LegendParams;
    tooltip?: boolean | ToolTipParams;
}
//#endregion

//#region Constants
const defaultLegend: LegendParams = {
    color: "#000000",
    orientation: "vertical",
}

const defaultTooltip: ToolTipParams = {
    borderWidth: 1,
    bgColor: "#FFFFFF",
    transitionDuration: 0,
}
//#endregion

const DoughnutChart: FC<DoughnutChartProps> = ({ legend, title, data, tooltip, height, ...props }) => {
    const { fetchStaticTranslations, getStaticText } = useLanguage();
    const myChart = useRef<EChartsReactCore | null>(null);

    //#region Title
    useEffect(() => {
        if (TB.isTextCode(title)) fetchStaticTranslations([title]);
    }, [fetchStaticTranslations, title]);
    //#endregion

    //#region Legend
    const legendObj = useMemo(() => {
        if (!legend) return;
        let params = typeof legend === "boolean" ? defaultLegend : { ...defaultLegend, ...TB.getObject(legend) };
        return {
            left: "left",
            orient: params.orientation,
            formatter: params.formatter,
            textStyle: {
                color: TB.getColor(params.color) || "#000000",
            }
        }
    }, [legend]);
    //#endregion

    //#region Tooltip
    const toolTipObj = useMemo(() => {
        if (!tooltip) return { show: false };
        let params = typeof tooltip === "boolean" ? defaultTooltip : { ...defaultTooltip, ...TB.getObject(tooltip) };
        return {
            trigger: 'item',
            padding: [7, 10],
            axisPointer: { type: 'none' },
            borderColor: params.borderColor,
            backgroundColor: params.bgColor,
            borderWidth: params.borderWidth,
            formatter: a => a?.value?.[2] || "",
            valueFormatter: params.valueFormatter,
            textStyle: { color: params.textColor },
            transitionDuration: params.transitionDuration,
        }
    }, [tooltip]);
    //#endregion

    const getOption = useCallback(() => ({
        legend: legendObj,
        tooltip: toolTipObj,
        xAxis: [{ splitLine: { show: false } }],
        yAxis: [{ splitLine: { show: false }, scale: true }],
        series: TB.getArray(data).map(d => ({
            barGap: 0,
            name: d.name,
            data: d.data,
            color: d.color,
            type: "scatter",
            emphasis: { focus: 'self' }
        })),
    }), [legendObj, toolTipObj, data]);

    return <>
        {TB.validString(title) && <div className='w-100 text-center'> <span className='h5'> {getStaticText(title)} </span></div >}
        < ReactEChartsCore ref={myChart} echarts={echarts} option={getOption()} style={{ height: TB.getString(height, '20rem') }} />
    </>
}

export default DoughnutChart;