import React from "react";
import moment from "moment";
import * as M from "../../Modal";
import * as H from "../../../hooks";
import * as C from "../../../Common";
import { T, TB, TC } from "../../../Constants";

export type ToolTipProps = {
    /** The state of the certification */
    last_certif?: T.FormConsumerData["last_certification"];
    /** Data about the last edit of the value */
    last_edit?: T.FormContext["previous_edits"][number];
    /** A string version of the current value */
    str_value?: string;
    /** The real value saved */
    value?: any;
    /** The _id of the item that will have a value certified */
    item_id: string;
    /** The prop of the item that will be certified */
    prop: string;
    /** The form id of the item */
    form: string;
    /** Callback after a certification was created */
    onCertification?: (certification: T.Certification) => void;
}

const ToolTip: React.FC<ToolTipProps> = ({ onCertification, ...props }) => {
    const lg = H.useLanguage();
    const [{ user }] = H.useAuth();

    const render_certif_form = React.useCallback(() => {
        let is_value_empty = props.value === null || props.value === undefined || props.value === "" || (Array.isArray(props.value) && props.value.length === 0);

        if (is_value_empty) M.renderAlert({ type: "warning", message: TC.NO_CERTIFY_EMPTY });
        else M.renderCertForm({ item_id: props.item_id, form: props.form, prop: props.prop, str_value: props.str_value, value: props.value })
            .then(certification => certification && onCertification?.(certification));
    }, [props.item_id, props.prop, props.str_value, props.value, props.form, onCertification]);

    const render_history = React.useCallback(() => M.renderCertHistory({ item: props.item_id, prop: props.prop }), [props.item_id, props.prop]);

    const state = React.useMemo(() => {
        let db_string_current_val = TB.valueToString(props.value);
        let is_certified = props.last_certif?.value === db_string_current_val;
        let is_certification_saved = is_certified && TB.mongoIdValidator(props.last_certif?._id);

        let tip_texts = "";

        if (is_certified) {
            tip_texts += lg.getStaticText(TC.CERT_CERTIFIED_THE, moment(props.last_certif.createdAt).format("DD/MM/YYYY HH:mm")) + " ";
            tip_texts += lg.getStaticText(TC.CERT_EDITED_BY, (props.last_certif as any).owner_name || user?.data?.name || "N/A");
            if (props.last_edit?.mission_name) tip_texts += " " + lg.getStaticText(TC.CERT_EDITED_FOR, props.last_edit.mission_name);
        }
        else if (props.last_edit) {
            tip_texts += lg.getStaticText(TC.CERT_EDITED_THE, props.last_edit.date_str) + " ";
            tip_texts += lg.getStaticText(TC.CERT_EDITED_BY, props.last_edit.user_name);
            if (props.last_edit.mission_name) tip_texts += " " + lg.getStaticText(TC.CERT_EDITED_FOR, props.last_edit.mission_name);
        }

        let content = <>
            <div children={tip_texts} />
            {is_certification_saved && <div className="my-2">
                <div className="fw-bold" children={props.last_certif.description} />
                <div children={moment(props.last_certif.data_date).format("DD/MM/YYYY HH:mm")} />
                {props.last_certif.files.length > 0 && <div children={lg.getStaticText(TC.CERT_NB_FILES, props.last_certif.files.length)} />}
            </div>}
            <div children={lg.getStaticText(is_certified ? TC.CERT_CLICK_TO_RECERTIFY : TC.CERT_CLICK_TO_CERTIFY)} />
        </>;

        // Value is certified & already saved in database
        if (is_certification_saved) return { color: "green", variant: "success", content, icon: "check-circle" };
        // Value is certified but not saved in database yet
        else if (is_certified) return { color: "orange", variant: "warning", content, icon: "hourglass-half" };
        // Value is not certified
        else return { icon: "info-circle", content, variant: "secondary" };
    }, [lg, props.last_certif, props.value, props.last_edit, user?.data?.name]);

    return <>
        <C.IconTip
            icon={state.icon}
            color={state.color}
            tipContent={state.content}
            onClick={render_certif_form}
            className={`pointer text-${state.variant} me-2`}
        />
        <C.IconTip
            icon="history"
            onClick={render_history}
            className="pointer text-secondary me-2"
            tipContent={TC.CERT_HISTORY_EDIT_HISTORY}
        />
    </>;
}

export default ToolTip;